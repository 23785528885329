import { Tokens } from '@Utilities/enum'
export default {
    namespaced: true,
    state: {
        isLoading: false,
        listLoading: false,
        memberProfileLoading: false,
        memberProfile: {},
        memberAppointments: [],
        memberCoverage: [],
        memberDoctors: [],
        memberCreditHistory: [],
        teamMemberListMeta: {},
        memberDoctorsMeta: {},
        teamMemberList: [],
        showReplenishModal: false,
        membersToReplenishList: [],
        filteredMember: [],
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        listLoading(state) {
            return state.listLoading
        },
        memberProfileLoading(state) {
            return state.memberProfileLoading
        },
        memberProfile(state) {
            return state.memberProfile
        },
        memberAppointments(state) {
            return state.memberAppointments
        },
        memberCoverage(state) {
            return state.memberCoverage
        },
        memberDoctors(state) {
            return state.memberDoctors
        },
        memberCreditHistory(state) {
            return state.memberCreditHistory
        },
        teamMemberList(state) {
            return state.teamMemberList
        },
        teamMemberListMeta(state) {
            return state.teamMemberListMeta
        },
        memberDoctorsMeta(state) {
            return state.memberDoctorsMeta
        },
        showReplenishModal(state) {
            return state.showReplenishModal
        },
        membersToReplenishList(state) {
            return state.membersToReplenishList
        },
        filteredMember(state) {
            return state.filteredMember
        },
    },
    actions: {
        async getMemberProfile({ commit }, payload) {
            _.has(payload, 'details') ? commit('setListLoading', true) : commit('setMemberProfileLoading', true)

            try {
                const limit = _.has(payload, 'limit') ? payload.limit : 10;
                const page = _.has(payload, 'page') ? payload.page : 1;
                const url = _.has(payload, 'details') ? `/pharma/api/team/member/${payload.id}/${payload.details}` : `/pharma/api/team/member/${payload.id}`;
                const params = _.has(payload, 'details') ? { limit, page } : {};
                const response = await api('get', url, params, Tokens.Personal, { 'user-type': '' });

                if ((response && response.data) || response.data.success) {
                    const { data, meta } = response.data
                    if (_.has(payload, 'details')) {
                        switch (payload.details) {
                        case 'brands':
                            commit('setMemberCoverage', data)
                            break;
                        case 'appointments':
                            commit('setMemberAppointments', data)
                            break;
                        case 'doctors':
                            commit('setMemberDoctors', data)
                            commit('setMemberDoctorsMeta', meta)
                            break;
                        default:
                            //CREDITS
                            commit('setMemberCreditHistory', data)
                            break;
                        }
                    } else {
                        commit('setMemberProfile', data)
                    }

                } else {
                    commit('setMemberProfile', {})
                }

            } catch (error) {
                console.warn(error)
            }

            _.has(payload, 'details') ? commit('setListLoading', false) : commit('setMemberProfileLoading', false)
        },
        async getTeamMembers({ commit }, payload) {
            commit('setIsLoading', true)
            try {
                const response = await api('get', '/pharma/api/team/member', payload.data, Tokens.Personal)
                if (response?.data?.success) {
                    if (_.has(payload, 'type') && payload.type === 'credits-allocation') {
                        commit('setMembersToReplenishList', response.data.data)
                    } else {
                        commit('setTeamMemberList', response.data.data)
                        commit('setTeamMemberListMeta', response.data.meta)
                    }
                }
            } catch (error) {
                console.warn(error)
            }
            commit('setIsLoading', false)
        }
    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
        setListLoading(state, payload) {
            state.listLoading = payload
        },
        setMemberProfileLoading(state, payload) {
            state.memberProfileLoading = payload
        },
        setMemberProfile(state, payload) {
            state.memberProfile = payload
        },
        setMemberAppointments(state, payload) {
            // FOR DOCTOR APPOINTMENTS IN SLIDEOVER
            state.memberAppointments = payload
        },
        setMemberCoverage(state, payload) {
            // FOR DOCTOR COVERAGE IN SLIDEOVER
            state.memberCoverage = payload
        },
        setMemberDoctors(state, payload) {
            // FOR MEDREP DOCTORS IN SLIDEOVER
            // state.memberDoctors = payload
            state.memberDoctors.push(...payload)
        },
        resetMemberDoctors(state,) {
            state.memberDoctors = []
        },
        setMemberCreditHistory(state, payload) {
            // FOR MEDREP CREDIT HISTORY IN SLIDEOVER
            state.memberCreditHistory = payload
        },
        setTeamMemberList(state, payload) {
            state.teamMemberList = payload
        },
        setTeamMemberListMeta(state, payload) {
            state.teamMemberListMeta = payload
        },
        setMemberDoctorsMeta(state, payload) {
            state.memberDoctorsMeta = payload
        },
        setShowReplenishModal(state, payload) {
            state.showReplenishModal = payload
        },
        setMembersToReplenishList(state, payload) {
            state.membersToReplenishList = payload

            // USE FOR MEDREP FILTER OPTION IN CREDIT PAGE
            const allMedrep = { full_name: 'All Med Rep', id: 'all' }
            const clonedMembers = _.cloneDeep(payload)
            clonedMembers.unshift(allMedrep)
            state.filteredMember = clonedMembers
        },
    },
}
