
<template>
    <div class="sign-in-form-component">
        <access-form-component form-title="Sign in to PPD Clinic Pharma">
            <template v-slot:form>
                <template v-if="!showOtp">
                    <form
                        class="access-form"
                        @submit.prevent="onVerifyEmail(email)"
                    >
                        <!-- EMAIL -->
                        <input-field-component
                            v-model="email"
                            label="Enter your email address"
                            name="email"
                            type="text"
                            placeholder="you@example.com"
                            :prepend-icon="mailIcon"
                            @update:modelValue="email = $event"
                        />
                        <p
                            v-if="!showOtp"
                            class="label mt-2"
                        >
                            {{ errormessage }}
                        </p>

                        <p class="label">By clicking continue, you agree to PPD Clinic Pharma <span class="link" @click="onShowLegalModal('terms-and-conditions')">Terms and Conditions</span> and <span class="link" @click="onShowLegalModal('privacy-policy')">Privacy Policy</span></p>

                        <button-component
                            type="primary-btn"
                            label="Continue"
                            button-type="submit"
                            block
                            :loading="isLoading"
                        />
                    </form>
                </template>

                <!-- OTP -->
                <otp-component
                    v-else
                    class="access-form space-y-8"
                    :show-feedback="showOtp"
                    :email="email"
                    @show-sign-in="showSignIn"
                    @on-resend-otp="onVerifyEmail($event)"
                />
            </template>
        </access-form-component>

        <!-- TERMS AND CONDITION MODAL -->
        <legal-modal-component
            v-model="showModal"
            :type="type"
            :title="title"
        />
    </div>
</template>

<script>
import AccessFormComponent from "./access-form-component.vue";
import OtpComponent from "./otp-component.vue";
import { InputFieldComponent } from "@CommonComponents";
import { LegalModalComponent } from "@CommonComponents";
import { MailIcon } from "@heroicons/vue/solid";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
// VALIDATION
import { useField, useForm } from "vee-validate";
import * as yup from "yup";

export default {
    name: "SignInFormComponent",
    components: {
        AccessFormComponent,
        InputFieldComponent,
        OtpComponent,
        LegalModalComponent,
    },
    props: {
        pageName: { type: String, default: '' }
    },
    setup(props) {
        const store = useStore();
        const showOtp = ref(false);
        const mailIcon = ref(MailIcon);
        const showSignIn = () => {
            showOtp.value = false;
        };

        onMounted(() => {
            store.commit('CommonUtilities/setPageName', props.pageName)
        })

        // LOADING
        const isLoading = computed(() => store.getters.isLoading);

        // FORM VALIDATION
        const schema = yup.object({
            email: yup.string().required().email(),
        });
        const theForm = useForm({
            validationSchema: schema,
        });
        const { value: email } = useField("email");

        // SUBMITTING EMAIL
        const errormessage = ref("");
        const onVerifyEmail = async (value) => {
            const isFormDirty = await theForm.validate();

            if (isFormDirty.valid) {
                const payload = {
                    email: value,
                };
                const response =
                    (await store.dispatch("onVerifyEmail", payload)) || {};
                if (response && response.success) {
                    showOtp.value = true;
                } else {
                    errormessage.value = response.message;
                }
            }
        };

        // START OF SHOW TERMS AND CONDITION MODAL
        const type = ref('');
        const title = computed(() => {
            let value;
            switch (type.value) {
            case 'terms-and-conditions':
                value = 'Terms and conditions'
                break;
            case 'privacy-policy':
                value = 'Privacy Policy'
                break;
            }
            return value
        });
        const showModal = ref(false);
        const onShowLegalModal = (val) => {
            type.value = val;
            showModal.value = true;
        };
        // START OF SHOW TERMS AND CONDITION MODAL

        return {
            mailIcon,
            email,
            showSignIn,
            showOtp,
            onVerifyEmail,
            isLoading,
            errormessage,
            onShowLegalModal,
            showModal,
            type,
            title,
        };
    },
};
</script>
<style lang="scss">
.sign-in-form-component {
    @apply w-full;
    .label {
        @apply text-sm leading-5 font-normal text-gray-500;
    }
    .link {
        @apply text-primary cursor-pointer;
    }
    .access-form {
        .input-field-component {
            label {
                @apply text-sm leading-5 font-medium text-gray-700;
            }
        }
    }
}
</style>