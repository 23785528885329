import { Tokens } from '@Utilities/enum'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        userCredits: 0,
        creditHistory: [],
        adminCreditHistory: [],
        addTeamCreditLoading: false,
        addTeamCreditMessage: "",
        isInsufficientBalance: false,
        hasNoBalance: false,
        creditPerMinute: [],
        creditLoading: false,
        creditHistoryLoading: false,
        adminCreditHistoryMeta: {},
        creditHistoryMeta: {},
        filteredDate: {
            dateFrom: null,
            dateTo: null,
        },
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        creditHistory(state) {
            return state.creditHistory
        },
        userCredits(state) {
            return state.userCredits
        },
        adminCreditHistory(state) {
            return state.adminCreditHistory
        },
        addTeamCreditLoading(state) {
            return state.addTeamCreditLoading
        },
        addTeamCreditMessage(state) {
            return state.addTeamCreditMessage
        },
        isInsufficientBalance(state) {
            return state.isInsufficientBalance
        },
        hasNoBalance(state) {
            return state.hasNoBalance
        },
        creditPerMinute(state) {
            return state.creditPerMinute
        },
        creditLoading(state) {
            return state.creditLoading
        },
        creditHistoryLoading(state) {
            return state.creditHistoryLoading
        },
        adminCreditHistoryMeta(state) {
            return state.adminCreditHistoryMeta
        },
        creditHistoryMeta(state) {
            return state.creditHistoryMeta
        },
        filteredDate(state) {
            return state.filteredDate
        },
    },
    actions: {
        async getUserCredits({ commit }) {
            commit('setIsLoading', true)

            try {
                const response = await api('get', '/pharma/api/credit/balance', {}, Tokens.Personal)
                if (response?.data?.success) {
                    const { data } = response.data
                    await commit('setIsInsufficientBalance', data.credits === 1 )
                    await commit('setHasNoBalance', data.credits === 0 )
                    commit('setUserCredits', data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setIsLoading', false)
        },
        async getMedrepHistory({ commit }, payload) {
            commit('setIsLoading', true)

            try {
                const response = await api('get', '/pharma/api/credit/history', payload, Tokens.Personal)
                if (response?.data?.success) {
                    commit('setCreditHistory', response.data.data)
                    commit('setCreditHistoryMeta', response.data.meta)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setIsLoading', false)
        },
        async getAdminCreditHistory({ commit }, payload) {
            commit('setCreditHistoryLoading', true)
            try {
                const response = await api('get', '/pharma/api/credit/history/admin', payload.data, Tokens.Personal, { 'user-type': payload.userType })
                if (response?.data?.success) {
                    commit('setAdminCreditHistory', response.data.data)
                    commit('setAdminCreditHistoryMeta', response.data.meta)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setCreditHistoryLoading', false)
        },
        async addTeamCredit({ commit }, payload) {
            commit('setAddTeamCreditLoading', true)
            try {
                const response = await api('post', '/pharma/api/team/credits', payload, Tokens.Personal)
                if (response?.data?.success) {
                    commit('setAddTeamCreditMessage', response.data.message)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setAddTeamCreditLoading', false)
        },
        async getCreditPerMinute({ commit }) {
            commit('setCreditLoading', true)

            try {
                const url = 'pharma/api/credit/per-minutes'
                const response = await api('get', url, {}, Tokens.Personal)

                if (response && response.data.success) {
                    commit('setCreditPerMinute', response.data.data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setCreditLoading', false)

        },
    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
        setCreditHistory(state, payload) {
            state.creditHistory.push(...payload);
        },
        setUserCredits(state, payload) {
            state.userCredits = payload
        },
        setAdminCreditHistory(state, payload) {
            // state.adminCreditHistory = payload
            state.adminCreditHistory.push(...payload);
        },
        resetAdminCreditHistory(state) {
            state.adminCreditHistory = []
        },
        setAddTeamCreditLoading(state, payload) {
            state.addTeamCreditLoading = payload
        },
        setAddTeamCreditMessage(state, payload) {
            state.addTeamCreditMessage  = payload
        },
        setIsInsufficientBalance(state, payload) {
            state.isInsufficientBalance  = payload
        },
        setHasNoBalance(state, payload) {
            state.hasNoBalance = payload
        },
        setCreditPerMinute(state, payload) {
            if (state.hasNoBalance) {
                _.forEach(payload, i => i.disabled = true)
            }
            if (state.isInsufficientBalance) {
                _.forEach(payload, i => { i.credit === 2 ? i.disabled = true : i.disabled = false })
            }
            state.creditPerMinute = payload
        },
        setCreditLoading(state, payload) {
            state.creditLoading = payload
        },
        setCreditHistoryLoading(state, payload) {
            state.creditHistoryLoading = payload
        },
        setAdminCreditHistoryMeta(state, payload) {
            state.adminCreditHistoryMeta = payload
        },
        setFilteredDate(state, payload) {
            if (_.isEmpty(payload)) {
                state.filteredDate = {
                    dateFrom: null,
                    dateTo: null,
                }
            } else {
                state.filteredDate = payload
            }
        },
        setCreditHistoryMeta(state, payload) {
            state.creditHistoryMeta = payload
        },
    },
}
