<template>
    <div class="upload-image-component">
        <label
            :for="`${name}-upload`"
            class="block text-sm font-medium text-gray-700 mb-1"
        >
            {{ label }}
        </label>
        <div class="flex items-center">
            <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                <img alt="image" :src="image" />
            </span>
            <div class="ml-5">
                <button
                    type="button"
                    class="white-btn"
                    @click="$refs.imageuploader.click()"
                >
                    {{ buttonLabel }}
                </button>
                <input
                    :id="`${name}-upload`"
                    ref="imageuploader"
                    :name="`${name}-upload`"
                    type="file"
                    accept="image/png, image/jpeg, image/bmp"
                    class="sr-only hidden"
                    @change="onChangeImage($event)"
                />
                <p v-if="filename" class="mt-2">{{ filename }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
    name: "UploadImageComponent",
    props: {
        label: { type: String, default: "Photo" },
        name: { type: String, default: "photo" },
        buttonLabel: { type: String, default: "Upload" },
        avatar: { type: String, Object, default: "" },
    },
    setup(props, { context }) {
        const filename = ref(null);
        const image = ref("");

        // watch(() => props.avatar, (newVal) => {
        //     console.warn('new', newVal);
        //     image.value = newVal
        // } )

        onMounted(() => {
            image.value = props.avatar;
        });

        const onChangeImage = (event) => {
            if (!event) return;
            const reader = new FileReader();
            const file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                filename.value = file.name;
                image.value = reader.result;
                const avatar = _.split(reader.result, "base64,", 2)[1];
                context.$emit("formChange", { avatar });
            };
        };

        return {
            onChangeImage,
            image,
            filename,
        };
    },
};
</script>

<style>
</style>