import {  onMounted, reactive, watch, ref } from "vue";
import { useVideoCallDetails } from "../../../functions";
import { AccountType } from "@Utilities/enum"
export default function useReceiverStateMessage(state) {
    const contentLoading = ref(false)

    // const router = useRouter()

    const { remoteStreamDetails, user_type } = useVideoCallDetails()

    let content = reactive({
        title: '',
        message: '',

    })

    const setContent = () => {
        //  <!-- STATES:  invalid_date, reciever_out, reciever_in, reciever_in_call-->
        contentLoading.value = true
        content.showNotifButton = false
        content.isPrimary = false

        const remoteUserType = remoteStreamDetails.value.user_type

        switch (state.value) {
        case "invalid_date":
            content.title = "It is not your scheduled appointment yet";
            content.message =
                    "You can only enter the call when it’s already your appointment schedule";
            content.showNotifButton = false;
            break;
        case "remote_stream_left":
        case "remote_stream_out":
            content.title = `${_.capitalize(remoteUserType)} is not in the room`;
            content.message =
                    `Notify the ${remoteUserType.toLowerCase()} that you are in the waiting room`;
            content.showNotifButton = true;
            break;
        case "remote_stream_in":
            content.title = `The ${remoteUserType} is in the room`;
            content.message = user_type === AccountType.Doctor ? "You may now enter the call" : 'Please wait for the doctor to enter the room. Doctor should enter the call first.';
            content.containerClass = 'receiver__inroom';
            break;
        case "remote_stream_call":
            content.title = `The ${remoteUserType} is in the call`;
            content.message = "You may now enter the call";
            content.containerClass = 'receiver__incall';
            break;
        }

        contentLoading.value = false

    };

    onMounted(() => {
        setContent()
    })

    watch(() => state.value, () => {
        setContent()
    } )

    return {
        content,
        contentLoading
    };
}