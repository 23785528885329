/* eslint-disable no-unused-vars */

// TODO: Replace the following with your app's Firebase project configuration

import { initializeApp, getApps } from "firebase/app"
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, onValue, serverTimestamp } from "firebase/database";

var firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const env = process.env.VUE_APP_ENVIRONMENT
// const room_info = vueRef()

let firebaseApp = {}
const apps = getApps()

// Initialize Firebase
if (!apps.length) {
    firebaseApp = initializeApp(firebaseConfig)
} else {
    firebaseApp = apps[0]
}

// Get a reference to the database service
const functions = getFunctions(firebaseApp);
const database = getDatabase(firebaseApp);
const timestamp = serverTimestamp();

const httpsCallableHelper = (value) => {
    return httpsCallable(getFunctions(), value)
}

export {
    database,
    ref,
    onValue,
    timestamp,
    httpsCallableHelper,
    functions,
    env
}

// const createRoom = firebase.functions().httpsCallable('createRoom');
// const updateRoom = firebase.functions().httpsCallable('updateRoom');
// const updateRoomUser = firebase.functions().httpsCallable('updateRoomUser');
// const updateRoomTimer = firebase.functions().httpsCallable('updateRoomTimer');

// const prepareParams = (params) => {
//     return {
//         ...params,
//         env,
//         timestamp
//     }
// }

// //function to get the whole room information.
// const getRoomInfo =  async function(room_uuid) {
//     await database.ref(`rooms/${room_uuid}`).once('value', snap => {
//         room_info.value = snap.val()
//     });
// }

// //function to get the room users.
// const getRoomUser = async (room_uuid) => {
//     database.ref(`rooms/${room_uuid}/users`).once('value', snap => {
//         room_users.value = snap.val()
//     });
// }

// //function to listen to the entire room for changes.
// const listenToRoom = async(room_uuid) => {
//     console.warn('listening to room rooms/'+room_uuid);

//     database.ref(`rooms/${room_uuid}`).on('value', snap => {
//         room_info.value = snap.val()
//     });
// }

// //function to listen for any room user changes.
// const listenToRoomUsers = async (room_uuid) => {
//     console.warn(`listening to room rooms/${room_uuid} users.`);

//     return database.ref(`rooms/${room_uuid}/users`).on('value', snap => {
//         room_users.value = snap.val()
//     });
// }

// return {
//     room_info,
//     room_users,
//     timestamp,
//     getRoomInfo,
//     getRoomUser,
//     listenToRoom,
//     listenToRoomUsers,
//     createRoom,
//     updateRoom,
//     updateRoomUser,
//     updateRoomTimer,
//     prepareParams
// }

// listenToRoomUsers();

// //call function to create the room.
// createRoom(room).then(function(result) {
//     console.warn(result);
// });

// //call function to update or add a user to the room.
// //updateRoomUser(room).then(function(result) {
// //    console.warn(result);
// //});

// //call function to update the room timer.
// updateRoomTimer(room).then(function(result) {
//     console.warn(result);
// });
