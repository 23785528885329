<template>
    <div class="welcome-modal-component">
        <slot-modal-component
            v-model="showModal"
            v-bind="slotModalProps"
        >
            <div class="welcome-body-container">
                <div class="body-content">
                    <div class="logo-container">
                        <img src="@Images/welcome-frame.svg" alt="Workflow" class="absolute top-0 right-0" />
                        <img src="@Images/pharma-full-logo.png" alt="Workflow" class="relative" />
                    </div>
                    <div class="message-and-sub-message-container">
                        <div class="message-and-sub-message-content">
                            <p
                                class="message"
                            >
                                You are all set! Welcome to PPD Clinic Pharma!
                            </p>
                            <div class="sub-message-content">
                                <p
                                    class="sub-message"
                                >
                                    A platform you can use to introduce your brands to doctors.
                                </p>
                                <p
                                    class="sub-message"
                                >
                                    How would you like to start?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FOOTER -->
            <template v-slot:footer>
                <div class="action-container">
                    <button
                        type="button"
                        class="white-btn"
                        @click="onClickGoToDashboard"
                    >
                        Go to dashboard
                    </button>
                    <button
                        type="button"
                        class="primary-btn"
                        @click="onClickTakeATour"
                    >
                        Take a tour
                    </button>
                </div>
            </template>
        </slot-modal-component>
        <onboarding-modal-component v-model="showOnBoardingModal" />
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import SlotModalComponent from "../modal/slot-modal-component.vue";
import OnboardingModalComponent from "@CommonComponents/modal/onboarding-modal-component.vue"

export default {
    name: "WelcomeModalComponent",
    components: {
        SlotModalComponent,
        OnboardingModalComponent
    },
    props: {
        modelValue: Boolean,
    },
    emits: [ "update:model-value", "on-click-go-to-dashboard" ],
    setup(props, { emit }) {
        const showModal = ref(false);
        const showOnBoardingModal = ref(false);

        //-----------------------COMPUTED-----------------------

        const slotModalProps = computed(() => {
            return {
                height: 0,
                scrollable: false,
                footerClass: "mt-0",
                isSemiTransparent: true,
                distinctClass: "welcome-modal pt-0"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        // START OF SHOW WELCOME MODAL
        const onShowModal = () => {
            showModal.value = true;
        };

        const onHideModal = () => {
            showModal.value = false;
            emit("update:model-value", showModal.value);
        };
        // END OF SHOW WELCOME MODAL

        const onClickGoToDashboard = () => {
            emit("on-click-go-to-dashboard", showModal.value);
            onHideModal()
        }

        const onClickTakeATour = () => {
            showOnBoardingModal.value =true
            onHideModal()
        }

        //-------------------METHODS ENDs HERE------------------

        watch(
            () => props.modelValue,
            (newVal) => {
                showModal.value = newVal;
            }
        );

        return {
            onShowModal,
            showModal,
            onHideModal,
            onClickGoToDashboard,
            showOnBoardingModal,
            onClickTakeATour,
            slotModalProps
        };
    },
};
</script>

<style lang="scss">
.welcome-modal-component {
    @apply pt-0;
}
.welcome-modal {
    min-width: 512px ;
    .welcome-body-container {
        @apply relative;
        .body-content {
            height: 280px;
            @apply  flex flex-col items-center justify-center bg-green-50 space-y-4;
            .message-and-sub-message-container {
                @apply flex items-center flex-col text-center;
                .message-and-sub-message-content {
                    @apply space-y-2;
                    .message {
                        @apply text-lg font-medium text-green-700;
                    }
                    .sub-message-content {
                        @apply space-y-2 ;
                        .sub-message {
                            @apply text-sm font-normal text-gray-500;
                        }
                    }
                }
            }
        }
    }
    .action-container {
        @apply flex justify-between mt-3;
    }
}
</style>