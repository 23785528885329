
// for code refactoring - use this for all scroll computation
import { ref, onUpdated } from 'vue'

export default function () {
    // SCROLL AREA HEIGHT COMPUTATION
    const viewportHeight = ref(window.innerHeight);
    const componentHeight = ref(0);
    const totalHeight = ref(0);
    const actionBtnHeight = ref(0);

    onUpdated(() => {
        const paddingBottom = 0;
        totalHeight.value =
            viewportHeight.value -
            componentHeight.value.offsetHeight -
            paddingBottom -
            actionBtnHeight.value
    })

    return {
        componentHeight,
        actionBtnHeight,
        totalHeight,
    }
}