<template>
    <!-- <keep-alive> -->
    <router-view :key="$route.fullPath" class="animated fadeIn" />
    <!-- </keep-alive> -->
</template>

<script>
/* eslint-disable no-unused-vars */
import { onBeforeUnmount, created } from "vue";
import { useStore } from "vuex";

import { useVideoCallDetails } from "./functions";

export default {
    name: "VideoCallMainComponent",
    setup() {
        const {
            localStreamDetails,
            room_uuid,
            user_type,
            firebase_timestamp,
            emptyVideoCallDetails,
        } = useVideoCallDetails();

        const store = useStore();

        const unload = () => {
            if (!emptyVideoCallDetails.value) {
                store.dispatch("VideoCall/onUpdateRoomUser", {
                    room_uuid,
                    type: "medrep_appointment",
                    userId: localStreamDetails.value.id,
                    userStatus: "inactive",
                    userType: user_type,
                    timestamp: firebase_timestamp.value,
                });
            }
        };

        window.addEventListener("beforeunload", unload);
    },
};
</script>
