<!-- eslint-disable vue/no-v-html  -->
<template>
    <div>
        <slot-modal-component
            v-model="showModal"
            :height="0"
            :scrollable="false"
            footer-class="mt-0"
            distinct-class="confirmation-modal"
            v-bind="customConfiguration || ModalConfiguration"
        >
            <load-more-component
                :height="300"
                unique-body-id="confirmation-body"
                unique-id="confirmation-read-more"
            >
                <!-- HEADER -->
                <template v-slot:header>
                    <DialogTitle
                        as="h3"
                        class="text-lg leading-6 font-medium text-gray-900"
                    >
                        Are you sure you want end this call
                    </DialogTitle>
                </template>

                <!-- BODY -->
                <template v-slot:body>
                    <ul>
                        <li
                            v-for="(terms, index) in termsContent"
                            :key="index"
                            class="text-sm text-gray-500"
                        >
                            {{ terms }}
                        </li>
                    </ul>
                </template>

                <!-- FOOTER -->
                <template v-slot:footer>
                    <button type="button" class="white-btn" @close="resetModal()">
                        Back
                    </button>
                    <button
                        type="button"
                        class="primary-btn"
                        @click="onProceed()"
                    >
                        Leave
                    </button>
                </template>
            </load-more-component>
        </slot-modal-component>
    </div>
</template>

<script>
import { computed } from "vue";
import { DialogTitle } from "@headlessui/vue";
import { SlotModalComponent, LoadMoreComponent } from "@CommonComponents";
import { ModalConfiguration } from '@Utilities/enum'
export default {
    name: "ConfirmationModalComponent",
    components: {
        SlotModalComponent,
        LoadMoreComponent,
        DialogTitle,
    },
    props: {
        value: Boolean,
        modelValue: Boolean,
        onShow: Boolean,
        hideDeclineButton: Boolean,
        hideConfirmButton: Boolean,
        hideCloseButton: Boolean,
        persistent: Boolean,
        confirmButtonOutline: Boolean,
        confirmButtonBlock: Boolean,
        declineButtonOutline: Boolean,
        interaction: { type: Object, default: null },
        customConfiguration: { type: Object, default: null },
        title: { type: String, default: null },
        titleClass: { type: String, default: null },
        headerTitle: { type: String, default: null },
        headerTitleClass: { type: String, default: null },
        customTitle: { type: String, default: null },
        titleIcon: { type: String, default: null },
        content: { type: String, default: null },
        contentClass: { type: String, default: null },
        customContent: { type: String, default: null },
        type: { type: String, default: null },
        confirmText: { type: String, default: 'Yes' },
        confirmIcon: { type: String, default: null },
        declineText: { type: String, default: 'Cancel' },
        actionClass: { type: String, default: null },
        buttonWidth: { type: String, default: '170' },
        width: { type: String, default: '400' },
        height: { type: String, default: '180' },
        declineButtonColor: { type: String, default: 'bermuda-50' },
        confirmButtonColor: { type: String, default: 'mariner-300' },
        confirmButtonsClass: { type: String, default: null },
        confirmDepressed: Boolean,
        cardTextClass: { type: String, default: null },
        cardActionClass: { type: String, default: null }
    },
    emits: [ "input", "onProceed", "onDeclined" ],
    setup(props, { emit }) {
        //COMPUTED
        const showModal = computed(() => {
            return props.modelValue
        });

        const buttonClass = computed(() => {
            let buttonClass = 'sm:justify-between sm:flex block text-center'
            if (this.hideConfirmButton || this.hideDeclineButton) {
                buttonClass = 'pharma-flex-center'
            }

            return this.actionClass || buttonClass
        });

        //METHOD
        const resetModal = () => {
            emit('input', false)
            this.showModal = false
        }

        const onProceed = (proceed) => {
            resetModal()
            if (proceed) {
                emit('onProceed', proceed)
            } else {
                emit('onDeclined', proceed)
            }
        }

        return {
            ModalConfiguration,
            showModal,
            buttonClass,
            resetModal,
            onProceed
        }
    },
};
</script>
