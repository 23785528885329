<template>
    <div v-if="!_.isEmpty(item) || isLoading" class="credit-to-use-card-component">
        <div :class="[isAppointmentCard ? 'card-no-shadow' : 'card-with-shadow default', 'overflow-visible']">
            <OutlineDatabaseIcon class="card-icon" aria-hidden="true" />
            <div v-if="!isLoading" class="credit-to-use-container">
                <div>
                    <p v-if="!isForCreditHistory" class="header-title">
                        <!-- {{ previewAppointment ? 'Credits to use' : 'Credits to spend' }} -->
                        Credits to use
                    </p>
                    <div class="credit-to-use-card-content">
                        <p class="credit-value" :class="creditValueColor">
                            {{ credits }}
                        </p>
                        <dot-component v-if="showPopoverButton" dot-color="text-gray-400" />
                        <div v-if="showPopoverButton" class="credit-status-info">
                            <p
                                class="credit-status"
                                :class="statusColor(item.credit_status || item.status)"
                            >
                                {{ _.startCase(item.credit_status || item.status) }}
                            </p>
                            <popover-card-component
                                v-if="!isForCreditHistory"
                                button-type="white-icon-btn"
                                :pop-over-btn-icon-color="'text-gray-500'"
                                :button-icon="questionMarkCircleIcon"
                            >
                                <div class="w-70 space-y-1" style="width:280px">
                                    <p
                                        class=" text-sm font-medium"
                                        :class="onHold ? 'text-indigo-600' : 'text-green-600'"
                                    >
                                        {{ _.startCase(item.credit_status) }}
                                    </p>
                                    <p class="text-sm font-normal text-gray-500">
                                        {{ creditsMessage }}
                                    </p>
                                </div>
                            </popover-card-component>
                        </div>
                    </div>
                    <div v-if="reason">
                        <p class="reason-value" v-html="$sanitize(reason)" />
                    </div>
                </div>
                <div v-if="dateLastUpdate">
                    <p class="last-update-value">
                        {{ dateLastUpdate }}
                    </p>
                </div>
            </div>
            <div v-else class="credit-to-use-container load_card_animate w-full flex flex-col">
                <p class="header-title h-4 bg-primary-100 rounded w-1/3 mt-1" />
                <div class="credit-to-use-card-content">
                    <p class="credit-value h-4 bg-primary-100 rounded w-1/4 mt-px" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    DatabaseIcon as OutlineDatabaseIcon
} from '@heroicons/vue/outline'
import { QuestionMarkCircleIcon } from '@heroicons/vue/solid'
import { ref, computed } from 'vue'
import PopoverCardComponent from '../popover-card-component.vue';
import DotComponent from '@CommonComponents/dot-component.vue';
import { STORAGE } from "@Plugins/storage.js";
import { Tokens, AccountType } from "@Utilities/enum";

export default {
    name: 'CreditToUseCardComponent',
    components: {
        OutlineDatabaseIcon,
        PopoverCardComponent,
        DotComponent,
    },
    props: {
        item: { type: [ Object, Number ], default: () => {} },
        reason: { type: String, default: '' },
        forListing: Boolean,
        isAppointmentCard: Boolean,
        isForCreditHistory: Boolean,
        dateLastUpdate: { type: String, default: '' },
        previewAppointment: Boolean,
        showStatus: { type: Boolean, default: true },
        isLoading: Boolean
    },
    setup(props) {
        const questionMarkCircleIcon = ref(QuestionMarkCircleIcon)
        const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));

        const onHold = computed(() => {
            return props.item.credit_status === "on-hold";
        })

        const showPopoverButton = computed(() => {
            return ((props.item.status !== 'accepted' && (props.item.credit_status === 'on-hold' || props.item.credit_status === 'refunded')) || props.isForCreditHistory) && props.showStatus;
        })

        const creditsMessage = computed(() => {
            switch (userType.value) {
            case AccountType.Admin:
                return onHold.value ? "We will refund the credits if the doctor didn’t respond to appointment request within 48 hours." : "We have returned the credits back to MedRep’s account"
            default:
                return onHold.value ? "We will refund your credits if the doctor didn’t respond within 48 hours." : "We have returned the credits back to your account"
            }
        })
        const statusColor = (status) => {
            switch (_.toLower(status)) {
            case "on-hold":
            case "on hold":
                return "text-indigo-600"
            case "deducted":
                return 'text-red-600'
            case "refunded":
                return 'text-green-600'
            case "replenished":
                return 'text-green-600'
            default :
                return ''
            }
        };

        const creditValueColor = computed(() => {
            let item
            switch (props.item.color_attribute) {
            case 'danger':
                item = 'red'
                break;
            case 'success':
                item = 'green'
                break;
            default:
                item = 'gray'
                break;
            }
            return `text-${item}-500`
        })

        const credits = computed(() => {
            const creditOnly = _.head(_.split(props.item.credit_use, '-'))
            if (userType.value === AccountType.Admin && (props.item.status === 'accepted' || props.item.status === 'pending')) {
                return props.item.credit_use
            } else {
                return creditOnly || props.item.credits
            }
        })
        return {
            questionMarkCircleIcon,
            onHold,
            creditsMessage,
            statusColor,
            showPopoverButton,
            creditValueColor,
            credits,
        }
    }
}
</script>

<style lang="scss">
.credit-to-use-card-component {
    .credit-to-use-container {
        @apply  flex justify-between w-full ml-0 space-y-1 #{!important};
        .header-title {
            @apply text-sm font-medium text-gray-700;
        }
        .credit-to-use-card-content {
            @apply flex items-center space-x-2;
            .credit-value {
                @apply text-sm leading-5 font-normal;
            }
            .credit-status-info {
                @apply flex items-center space-x-1;
                .credit-status {
                    @apply text-sm font-medium;
                }
            }
        }
        .reason-value {
            @apply text-sm font-normal text-gray-500;
        }
        .last-update-value {
            @apply text-sm font-medium text-gray-500;
        }
    }
    .white-icon-btn {
        @apply m-0 p-0;
        svg {
            path {
                @apply text-gray-400 #{!important};
            }
        }
    }
}
</style>