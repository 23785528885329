<template>
    <div class="popover-status-list-card-component">
        <div class="popover-status-list-card-container">
            <div class="popover-status-list-card-content">
                <p class="header-title">
                    Credit Statuses
                </p>
                <div
                    v-for="(status, index) in creditStatusList"
                    :key="index"
                    class="credit-status-list"
                >
                    <div class="credit-status-list-content">
                        <p
                            class="status"
                            :class="status.color"
                        >
                            {{ status.creditStatus }}
                        </p>
                        <p class="message">
                            {{ status.creditsMessage }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    name: "PopoverStatusListCardComponent",
    components: {
    },
    props: {
        items: { type: Object, default: null },
    },
    setup() {
        const creditStatusList = ref([
            {
                creditStatus: 'On Hold',
                creditsMessage: 'We will refund your credits if the doctor didn’t respond within 48 hours.',
                color: 'text-indigo-600'
            },
            {
                creditStatus: 'Deducted',
                creditsMessage: 'We have deducted credits for your done appointment',
                color: 'text-red-600'
            },
            {
                creditStatus: 'Refunded',
                creditsMessage: 'We have returned the credits back to your account',
                color: 'text-green-600'
            },
            {
                creditStatus: 'Replenished',
                creditsMessage: 'Your credits can be replenished by your district manager or PPD Clinic Pharma Team to send more appointment request',
                color: 'text-green-600'
            }
        ])
        return {
            creditStatusList
        }
    }
}
</script>

<style lang="scss">
.popover-status-list-card-component {
    .popover-status-list-card-container {
        .popover-status-list-card-content{
            @apply divide-y divide-gray-200;
            .header-title {
                @apply p-3;
            }
            .credit-status-list {
                @apply px-3 py-3 divide-y divide-gray-200;
                width: 395px;
                .credit-status-list-content {
                    @apply space-y-1;
                    .status {
                        @apply text-sm font-medium;
                    }
                    .message {
                        @apply text-sm font-normal text-gray-500;
                    }
                }
            }
        }
    }
}
</style>