<template>
    <div class="py-6 space-y-6">
        <h1 class="text-center mb-6 font-bold">Sample Boiler Plate</h1>

        <!-- GLOBAL COMPONENT -->
        <h4 class="font-bold text-xl text-center my-6">GLOBAL COMPONENT</h4>
        <div class="mb-6">
            <h5 class="font-bold mb-2">Global Component Sample: Spinner</h5>
            <div class="text-center">
                <spinner-component class="mx-auto inline-block" :size="40" />
            </div>
        </div>

        <!-- SAMPLE ENUM -->
        <h4 class="font-bold text-xl text-center my-6">ENUM</h4>
        <p>
            User Type (from enum):
            <strong>{{ isUserType }}</strong>
        </p>

        <!-- BUTTONS -->
        <h4 class="font-bold text-xl text-center my-6">BUTTONS ANS VUEX</h4>
        <div class="space-y-3">
            <p>
                status:
                <strong>{{ status }}</strong>
            </p>

            <!-- BLOCK BUTTON -->
            <div class="space-x-3">
                <button-component
                    type="primary-btn"
                    label="full width button"
                    block
                    @click="onHandleStore"
                />
            </div>

            <!-- PRIMARY BUTTON -->
            <div class="space-x-3">
                <!-- XS PRIMARY BUTTON -->
                <button-component
                    type="primary-btn"
                    size="xs"
                    label="extra small"
                />

                <!-- PRIMARY BUTTON WITH PREPENDED ICON -->
                <button-component type="primary-btn" size="s" label="small">
                    <template v-slot:prepend>
                        <MailIcon aria-hidden="true" />
                    </template>
                </button-component>

                <!-- DEFAULT SIZE SECONDARY BUTTON -->
                <button-component type="secondary-btn" label="default size" />

                <!-- SECONDARY BUTTON WITH APPENDED ICON -->
                <button-component
                    type="secondary-btn"
                    size="l"
                    label="large"
                    append
                >
                    <template v-slot:append>
                        <MailIcon aria-hidden="true" />
                    </template>
                </button-component>

                <!-- XL WHITE BUTTON -->
                <button-component
                    type="white-btn"
                    size="xl"
                    label="extra large"
                />

                <!-- DEFAULT PILL BUTTON -->
                <button-component type="pill-btn" label="default size" />

                <!-- DISABLED BUTTON -->
                <!-- just put disabled props to any button -->
                <button-component
                    type="white-btn"
                    size="xl"
                    label="extra large"
                    disabled
                >
                    <template v-slot:prepend>
                        <MailIcon aria-hidden="true" />
                    </template>
                </button-component>

                <!-- PRIMARY ICON BUTTON -->
                <button-component type="primary-icon-btn">
                    <PlusIconSolid aria-hidden="true" />
                </button-component>

                <!-- WHITE ICON BUTTON -->
                <button-component type="white-icon-btn" size="s">
                    <PlusIconSolid aria-hidden="true" />
                </button-component>
            </div>
        </div>

        <!-- MIXINS -->
        <h4 class="font-bold text-xl text-center my-6">MIXINS</h4>
        <div>
            <div class="text-center">Add Counter (mixins)</div>
            <!-- <div class="text-center">{{ counter }}</div> -->
            <div>
                <button-component
                    theme="primary-btn"
                    label="Increment"
                    block
                    @click.prevent="onIncrement(10)"
                />
            </div>
            <h4 class="font-bold text-xl text-center my-6">ENV</h4>
            <div class="border-b">
                <div class="text-center">Add .env</div>
                <div class="text-center">environment : {{ environment }}</div>
                <div class="text-center">client_id :{{ client_id }}</div>
                <div class="text-center">
                    client_secret: {{ client_secret }}
                </div>
                <div class="text-center">app_name: {{ app_name }}</div>
                <div class="text-center">app_version: {{ app_version }}</div>
                <div class="text-center">app_title: {{ app_title }}</div>
                <div class="text-center mb-6">
                    app_base_url: {{ app_base_url }}
                </div>
            </div>
        </div>

        <!-- CARDS -->
        <h4 class="font-bold text-xl text-center my-6">CARDS</h4>
        <div class="flex space-x-3">
            <div>
                <div class="my-3 space-y-3">
                    <p>Different types of Appointment Date</p>
                    <appointment-settings-card-component
                        v-for="(appointment, index) in doctor.appointmentData"
                        :key="index"
                        settings="date"
                        :dual-line="
                            appointment.status === 'Pending' ? true : false
                        "
                        :status="appointment.status"
                        :credit="appointment.balance"
                        :appointment="appointment"
                    />
                </div>

                <div class="my-3 space-y-3">
                    <p>Appointment Body Card</p>
                    <name-card-component :data="doctor3" />
                    <brand-card-component :data="doctor3.brandList" />

                    <note-to-doctor-component :data="doctor3.note" />
                    <remarks-card-component :data="doctor3.remarksData" />
                    <invited-card-component :data="doctor3.invited" />
                </div>
            </div>

            <div class="my-3">
                <p>Appointment Card</p>
                <appointment-card-component
                    :data="doctor3"
                    :show-note="true"
                    :show-invited="true"
                    :show-brand="true"
                    :show-name="true"
                    :show-remarks="true"
                />
            </div>
        </div>

        <!-- LIST -->
        <h4 class="font-bold">Listings</h4>
        <div>
            <div>
                <!-- <list-component :items="doctorList">
                <template v-slot:card={data}>
                   <appointment-card-component
                        :doctor="data"
                        />
                </template>
            </list-component> -->
            </div>
        </div>

        <!-- API -->
        <div v-if="profile.success" class="border-b border-t mt-5">
            <h4 class="font-bold">API</h4>
            <div class="text-center">GET axios api</div>
            <div class="text-center">EMAIL: {{ profile.data.email }}</div>
            <div class="text-center">
                NAME: {{ profile.data.first_name }}
                {{ profile.data.middle_name }} {{ profile.data.last_name }}
            </div>
            <div class="text-center">GENDER: {{ profile.data.gender }}</div>
        </div>
    </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { ref, onMounted } from "vue";
import { MailIcon } from "@heroicons/vue/solid";
import { PlusIcon as PlusIconSolid } from "@heroicons/vue/solid";
// import { useStore } from "vuex";
import { AccountType } from "@Utilities/enum";
import {
    // ListComponent,
    AppointmentSettingsCardComponent,
    NameCardComponent,
    BrandCardComponent,
    NoteToDoctorComponent,
    RemarksCardComponent,
    InvitedCardComponent,
} from "@CommonComponents";
import { AppointmentCardComponent } from "../../../components/common";
import moment from "moment";

export default {
    components: {
        MailIcon,
        PlusIconSolid,
        // ListComponent,
        AppointmentSettingsCardComponent,
        NameCardComponent,
        AppointmentCardComponent,
        BrandCardComponent,
        NoteToDoctorComponent,
        RemarksCardComponent,
        InvitedCardComponent,
    },
    props: {
        showRemarksFields: Boolean,
    },

    setup() {
        // API REQUEST / API FETCH DATA
        // const store = useStore();
        onMounted(() => {
            // store.dispatch("CommonDoctor/getDoctor", {
            //     params: {
            //         slug: "danilo-guzman",
            //     },
            // });
        });
        // const profile = computed(() => store.getters["CommonDoctor/profile"]);
        // START OF CODE FOR ENUM
        const isUserType = ref(AccountType.Doctor);
        // END OF CODE FOR ENUM

        // START OF CODE FOR STORE
        // const status = computed(() => store.getters["MedrepLogin/status"]);

        const onHandleStore = () => {
            // store.commit("MedrepLogin/setStatus", "VUEX MUTATION SUCCESSFUL!!!");
        };
        // END OF CODE FOR STORE

        // START OF CODE FOR NAVIGATION TABS

        const formatDate = (date) => {
            return moment(date).format("ddd, DD MMM YYYY");
        };

        // END OF CODE FOR NAVIGATION TABS

        // START OF CODE FOR LIST COMPONENT

        const doctorList = ref([
            {
                fullName: "Leslie Alexander",
                practice: "MD",
                email: "lesliealexander@example.com",
                specialty: [ "Dermatoimmunology", "Psychoteraphy" ],
                avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                md_since: "10 years",
                clinic: "The Medical City, Ortigas",
                city: "Pasig City, Metro Manila",
                appointment: {
                    date: formatDate(new Date()),
                    time: "8:00 AM - 11:00 AM",
                    status: "Pending",
                },
                brandList: [ "Zithromax", "Zithromax2", "Zithromax3" ],
                invited: [
                    {
                        fullName: "Leslie Alexander",
                        position: "District Manager",
                    },
                ],
                remarks: "",
            },
            {
                fullName: "Leslie Alexander",
                practice: "MD",
                email: "lesliealexander@example.com",
                specialty: [ "Dermatoimmunology", "Psychoteraphy" ],
                avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
                md_since: "10 years",
                clinic: "The Medical City, Ortigas",
                city: "Pasig City, Metro Manila",
                appointment: {
                    date: formatDate(new Date()),
                    time: "8:00 AM - 11:00 AM",
                    status: "Pending",
                },
                brandList: [ "Zithromax", "Zithromax2", "Zithromax3" ],
                invited: [
                    {
                        fullName: "Leslie Alexander",
                        position: "District Manager",
                    },
                ],
                remarks: "",
            },
        ]);

        // END OF CODE FOR LIST COMPONENT

        // START OF CODE FOR CARD COMPONENT

        const doctor = ref({
            fullName: "Leslie Alexander",
            practice: "MD",
            email: "lesliealexander@example.com",
            specialty: [ "Dermatoimmunology", "Psychoteraphy" ],
            avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            md_since: "10 years",
            clinic: "The Medical City, Ortigas",
            city: "Pasig City, Metro Manila",
            appointmentData: [
                {
                    date: formatDate(new Date()),
                    time: "8:00 AM - 11:30 AM",
                    status: "Pending",
                    balance: 30,
                },
                {
                    date: formatDate(new Date()),
                    time: "8:00 AM",
                    status: "Accepted",
                    balance: 30,
                },
                {
                    date: formatDate(new Date()),
                    time: "8:00 AM - 11:00 AM",
                    status: "Declined",
                    balance: 30,
                },
                {
                    date: formatDate(new Date()),
                    time: "11:00 AM",
                    status: "Done",
                    balance: 30,
                },
            ],
            brandList: [ "Zithromax", "Zithromax2", "Zithromax3" ],
            invited: [
                {
                    fullName: "Leslie Alexander",
                    position: "District Manager",
                },
            ],
            remarks: "",
        });

        const doctor2 = ref({
            fullName: "Leslie Alexander",
            practice: "MD",
            email: "lesliealexander@example.com",
            specialty: [ "Dermatoimmunology", "Psychoteraphy" ],
            avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            md_since: "10 years",
            clinic: "The Medical City, Ortigas",
            city: "Pasig City, Metro Manila",
            appointment: {
                date: "Wed, 05 Jan 2021",
                time: "9:45 AM",
                status: "Pending",
                credits: 2,
                remarks:
                    "Remarks Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                note: "Note Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
                reason: "Reason Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
                coverage: [ "Naphrex", "Zithromax", "Quelicin" ],
                attendees: [
                    { name: "Ibardaloza, Jade", position: "District Manager" },
                    { name: "Mendoza, Marites", position: "Area Manager" },
                ],
                enterCall: true,
            },
        });

        const doctor3 = ref({
            fullName: "Leslie Alexander",
            practice: "MD",
            email: "lesliealexander@example.com",
            specialty: [ "Dermatoimmunology", "Psychoteraphy" ],
            avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            md_since: "10 years",
            clinic: "The Medical City, Ortigas",
            city: "Pasig City, Metro Manila",
            appointment: {
                date: formatDate(new Date()),
                time: "8:00 AM - 11:30 AM",
                status: "Pending",
                balance: 30,
            },
            brandList: [ "Zithromax", "Zithromax2", "Zithromax3" ],
            invited: [
                {
                    fullName: "Leslie Alexander",
                    position: "District Manager",
                },
            ],
            note: "Note Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
            remarksData: {
                remarks: "",
                reason: "",
            },
        });

        // END OF CODE FOR CARD COMPONENT

        // START OF PLUGIN MOMENT
        const currentDate = moment(new Date()).format("LL");
        // END OF PLUGIN MOMENT

        // START OF CODE FOR .ENV
        const environment = process.env.VUE_APP_ENVIRONMENT;
        const client_id = process.env.VUE_APP_CLIENT_ID;
        const client_secret = process.env.VUE_APP_CLIENT_SECRET;
        const app_name = process.env.VUE_APP_NAME;
        const app_version = process.env.VUE_APP_VERSION;
        const app_title = process.env.VUE_APP_TITLE;
        const app_description = process.env.VUE_APP_DESCRIPTION;
        const app_base_url = process.env.VUE_APP_BASE_URL;
        // END OF CODE FOR .ENV

        return {
            // ENUM
            isUserType,
            // VUEX
            status,
            // profile,
            onHandleStore,
            // PLUGIN
            currentDate,
            // ENV
            environment,
            client_id,
            client_secret,
            app_name,
            app_version,
            app_title,
            app_description,
            app_base_url,
            doctor,
            doctor2,
            doctor3,
            doctorList,
        };
    },
    methods: {
        onClickSave(data) {
            this.cardItems5.remarks = data.reason;
        },
        // formatDate(date){
        //     return moment(date).format('ddd, DD MMM YYYY')
        // },
    },
};
</script>

<style>
</style>