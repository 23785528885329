import { Tokens } from '@Utilities/enum'
import { STORAGE } from "@Plugins/storage.js";
export default {
    namespaced: true,
    state: {
        isLoading: false,
        avatar: ""
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        avatar(state) {
            return state.avatar
        },
    },
    actions: {
        async uploadMedrepAvatar({ commit }, payload) {
            commit('setIsLoading', true)

            try {
                const url = '/pharma/api/auth/upload/avatar'
                const response = await api('post', url, payload, Tokens.Personal)
                if (response && response.data && response.data.success) {
                    const personal = STORAGE.GET_COOKIES(Tokens.Personal)
                    personal.avatar_upload = true;
                    personal.avatar = response.data.data
                    STORAGE.SET_COOKIES(Tokens.Personal, JSON.stringify(personal))
                    commit('setAvatar', response.data.data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setIsLoading', false)
        }
    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
        setAvatar(state, payload) {
            state.avatar = payload
        },
    },
}
