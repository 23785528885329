<template>
    <div v-if="!_.isEmpty(profile)" class="profile-info-card-component">
        <div v-bind="profileCardProps">
            <div :class="inline ? 'flex space-x-3' : 'space-y-3'">
                <div class="profile-card__avatar">
                    <img
                        v-bind="imgProps"
                    />
                </div>
                <div class="profile-card__details">
                    <p class="profile-name">
                        {{ profile.full_name }}
                    </p>
                    <p v-if="specialtyList" class="specialty">
                        {{ specialtyList }}
                    </p>
                    <badge-component v-if="profile.yrs_experience" class="mt-2" :label="profile.yrs_experience" />
                </div>
            </div>
            <credit-component
                v-if="_.has(profile, 'remaining_credits')"
                :credit="profile.remaining_credits"
            />
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import BadgeComponent from "../badge-component.vue";
import CreditComponent from "../credit-component.vue";

export default {
    name: "ProfileInfoCardComponent",
    components: {
        BadgeComponent,
        CreditComponent,
    },
    props: {
        profile: { type: Object, default: () => {} },
        inline: { type: Boolean, default: true },
        imgClass: { type: String, default: "h-10 w-10" },
        isCard: { type: Boolean, default: true },
        haveHoverEffect: { type: Boolean, default: true },
    },
    setup(props) {

        //------------------COMPUTED-------------------

        const specialtyList = computed(() => {
            if (_.has(props.profile, 'doctor_specialties')) {
                return _.join(props.profile.doctor_specialties, ', ')
            } else {
                return ''
            }
        })

        const imgProps = computed(() => {
            return {
                class: `inline-block rounded-full ${props.imgClass}`,
                src: props.profile.avatar,
                alt: props.profile.full_name,
                loading: "lazy"
            }
        })

        const profileCardProps = computed(() => {
            return {
                class: `${props.isCard ?
                    props.haveHoverEffect ?
                        'hover:shadow-md p-6 rounded-lg shadow' :
                        'p-6 rounded-lg shadow'
                    : props.haveHoverEffect ? 'hover:bg-gray-50 px-4 py-3 shadow-none' : ''} bg-white profile-card`
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        return {
            specialtyList,
            imgProps,
            profileCardProps
        };
    },
};
</script>

<style lang="scss">
.profile-info-card-component {
    .profile-card {
        .profile-card {
            &__avatar {
                @apply flex-shrink-0;
                img {
                    object-fit: cover;
                }
            }
            &__details {
                .profile-name {
                    @apply text-sm font-medium text-gray-700;
                }
                .specialty {
                    @apply text-sm font-medium text-gray-500;
                }
            }
        }
    }
}
</style>