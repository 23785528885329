<template>
    <div class="info-box-component" :class="[{'items-center': _.isEmpty(subTitle)}, type]">
        <div class="icon-wrapper">
            <component
                :is="iconComponent"
                v-bind="iconComponentProps"
            />
        </div>
        <div class="info-box-content">
            <p v-if="title" class="title">
                {{ title }}
            </p>
            <p v-if="subTitle" class="sub-title" v-html="$sanitize(subTitle)" />
        </div>
    </div>
</template>

<script>
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
} from "@heroicons/vue/outline";
import { computed, reactive } from '@vue/reactivity';

export default {
    name: "InfoBoxComponent",
    components: {
        CheckCircleIcon,
        ExclamationCircleIcon,
        InformationCircleIcon,
    },
    props: {
        title: { type: String, default: "" },
        subTitle: { type: String, default: "" },
        type: { type: String, default: "success" }, // success, danger, custom
        iconSize: { type: Number, default: 6 },
        icon: { type: Function, default: () => {} },
    },
    setup(props) {
        const iconComponent = computed(() => {
            let component
            switch (props.type) {
            case 'success':
                component= 'CheckCircleIcon'
                break;
            case 'danger':
                component= 'ExclamationCircleIcon'
                break;
            case 'custom':
                component= props.icon
                break;
            default:
                component= 'InformationCircleIcon'
                break;
            }
            return component
        });

        const color = computed(() => {
            let item
            switch (props.type) {
            case 'success':
                item = 'green'
                break;
            case 'danger':
                item = 'red'
                break;
            default:
                item = 'gray'
                break;
            }
            return item
        })
        const iconComponentProps = reactive({
            class: `h-${props.iconSize} w-${props.iconSize} text-${color.value}-400`,
            ariaHidden: true
        })

        return {
            iconComponent,
            iconComponentProps,
        }
    }
};
</script>

<style lang="scss">
.info-box-component {
    @apply flex p-2 rounded-md;
    .icon-wrapper {
        @apply p-0 mr-2 ;
    }
    .info-box-content {
        @apply mt-0;
        .title {
            @apply text-sm leading-5 font-medium text-gray-900;
        }
        .sub-title {
            @apply text-sm leading-5 font-normal text-gray-800;
            span {
                @apply font-medium text-gray-900;
            }
        }
    }
    &.danger {
        @apply bg-red-50;
        .info-box-content {
            .title {
                @apply text-red-900;
            }
            .sub-title {
                @apply text-red-800;
                span {
                    @apply text-red-900 #{!important};
                }
            }
        }
    }
    &.success {
        @apply bg-green-50;
        .icon-wrapper {
        }
        .info-box-content {
            .title {
                @apply text-green-900;
            }
            .sub-title {
                @apply text-green-800;
                span {
                    @apply text-green-900 #{!important};
                }
            }
        }
    }
}
</style>