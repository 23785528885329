
<template>
    <span
        class="badge-component"
        :class="[dimension, badgeColor]"
    >
        <dot-component
            v-if="withDot"
            :dot-color="dotColor"
            custom-class="-ml-0.5 mr-1.5"
        />

        {{ label }}

        <XIcon
            v-if="hasCloseIcon"
            class="close-icon"
            aria-hidden="true"
            @click="onRemove"
        />
    </span>
</template>

<script>
import { computed } from "vue";
import { XIcon } from "@heroicons/vue/outline";
import DotComponent from '@CommonComponents/dot-component.vue';
export default {
    name: "BadgeComponent",
    components: {
        XIcon,
        DotComponent,
    },
    props: {
        type: { type: String, default: "pending" }, // success, error, disable, pending
        label: { type: String, default: "accepted" },
        withDot: Boolean,
        hasCloseIcon: Boolean,
        size: { type: String, default: "" }, // default, large
    },
    emits: [ "on-remove" ],
    setup(props, { emit }) {

        const color = computed(() => {
            let item;
            switch (props.type) {
            case "success":
                item = "green";
                break;
            case "error":
                item = "red";
                break;
            case "disable":
                item = "gray";
                break;
            case "danger":
                item = "red";
                break;
            default:
                item = "indigo";
                break;
            }
            return item;
        });

        const badgeColor = computed(() => {
            return `bg-${color.value}-100 text-${color.value}-800`;
        });

        const dotColor = computed(() => {
            return `text-${color.value}-400`;
        });

        const dimension = computed(() => {
            let item;
            switch (props.size) {
            case "large":
                item = "px-3 py-0.5 text-sm";
                break;
            default:
                item = "px-2.5 py-0.5 text-xs";
                break;
            }
            return item;
        });

        const onRemove = () => {
            emit("on-remove")
        }

        return {
            dotColor,
            dimension,
            badgeColor,
            onRemove,
        };
    },
};
</script>
<style lang="scss">
.badge-component {
    @apply inline-flex items-center rounded-full font-medium;
    .close-icon {
        @apply cursor-pointer h-3 w-3 ml-2 text-indigo-400;
    }
}
</style>