<template>
    <div class="faq-component">
        <div v-if="!showFaqs && !isDashboardFaqs" class="faq-component__initial-view">
            <QuestionMarkCircleIcon aria-hidden="true" class="text-indigo-600 h-8 w-8" />
            <div class="flex items-center">
                <div>
                    <h4>Got questions?</h4>
                    <p>Read our frequently asked questions page to know more about PPD Clinic Pharma</p>
                </div>
                <ChevronRightIcon aria-hidden="true" class="cursor-pointer text-gray-400 h-10 w-10" @click="toggleFaqs" />
            </div>
        </div>

        <div v-else class="faq-component__content">
            <button-component type="white-btn" label="Back" @click="toggleFaqs">
                <template v-slot:prepend>
                    <ArrowLeftIcon class="h-4 w-4" aria-hidden="true" />
                </template>
            </button-component>
            <div class="faq-component__container">
                <div class="faq-component__divider">
                    <div class="faq-component__header">
                        <img :src="logo" />
                        <h2 class="sm:text-4xl">
                            Frequently asked questions
                        </h2>
                    </div>
                    <dl class="faq-component__dl">
                        <Disclosure
                            v-for="faq in faqs"
                            :key="faq.question"
                            v-slot="{ open }"
                            as="div"
                            class="pt-6"
                        >
                            <dt class="faq-component__dt">
                                <DisclosureButton class="disclosure-button">
                                    <span class="faq-question">{{ faq.question }}</span>
                                    <span class="faq-icon">
                                        <ChevronDownIcon
                                            :class="[
                                                open ? '-rotate-180' : 'rotate-0',
                                                'h-6 w-6 transform',
                                            ]"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </DisclosureButton>
                            </dt>
                            <DisclosurePanel as="dd" class="disclosure-panel">
                                <div class="faq-answer" v-html="$sanitize(faq.answer)" />
                            </DisclosurePanel>
                        </Disclosure>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/vue";
import {
    ChevronDownIcon,
    ArrowLeftIcon,
    QuestionMarkCircleIcon,
    ChevronRightIcon,
} from "@heroicons/vue/outline";

export default {
    name: "FaqComponent",
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronDownIcon,
        ArrowLeftIcon,
        QuestionMarkCircleIcon,
        ChevronRightIcon,
    },
    props: {
        isDashboardFaqs: Boolean
    },
    emits: [ "on-click-back" ],
    setup(props, { emit }) {
        const logo = computed(() => {
            return require(`@Images/pharma-full-logo.png`)
        });

        const faqs = ref([
            {
                question: "Where can I receive the OTP request?",
                answer:
                    "<p>OTP is sent only to your registered email.</p>",
            },
            {
                question: "Why do I need an OTP during login?",
                answer:
                    "<p>An OTP, instead of a password, is needed to be able to use your PPD Clinic Pharma account.</p>",
            },
            {
                question: "I did not receive an OTP in my email. What should I do?",
                answer:
                    "<p>Please make sure that you enter the correct email address registered to PPD Clinic Pharma. You may also check the spam mailbox.</p>",
            },
            {
                question: "What are in my account settings?",
                answer: [
                    "<p>Your account settings contain the following:</p>",
                    "<ul>",
                    "<li>Personal Details (Name, Company, District Manager)</li>",
                    "<li>Credit History</li>",
                    "<li>Brands Promoted</li>",
                    "</ul>",
                ].join(""),
            },
            {
                question: "How can I upload photos?",
                answer:
                    "<p>You can upload photos from your device using the camera or gallery.</p>",
            },
            {
                question: "What is the maximum size of picture that can be uploaded?",
                answer:
                    "<p>You can only upload a maximum of 1MB.</p>",
            },
            {
                question: "What information can I find in my dashboard?",
                answer: [
                    "<p>The information available on the dashboard are:</p>",
                    "<ul>",
                    "<li>Overview of appointments</li>",
                    "<li>Unanswered remarks card</li>",
                    "<li>News Feed (What's New)</li>",
                    "<li>Suggested doctors based on submitted preference</li>",
                    "</ul>",
                ].join(""),
            },
            {
                question: "How can I search for a doctor?",
                answer: [
                    "<p>You can search for a doctor by:</p>",
                    "<ol>",
                    "<li>Search",
                    "<p>Narrow down your results by applying any of these filters:</p>",
                    "<ul>",
                    "<li>Specialty</li>",
                    "<li>Location</li>",
                    "<li>Years of Practice</li>",
                    "<li>Day/Time Available</li>",
                    "</ul>",
                    "</li>",
                    "<li>Suggested - a list of doctors based on your preferences will be shown.</li>",
                    "</ol>",
                ].join(""),
            },
            {
                question: "What are the details found in the doctor's profile?",
                answer: [
                    "<p>The details found in the doctor's profile are:</p>",
                    "<ul>",
                    "<li>Name</li>",
                    "<li>Specialty</li>",
                    "<li>Years of Practice</li>",
                    "<li>Clinic/Hospital Name</li>",
                    "<li>Clinic/Hospital Address</li>",
                    "<li>Schedule</li>",
                    "</ul>",
                ].join(""),
            },
            {
                question: "How can I know when a doctor is available?",
                answer: "<p>Go to the doctor's profile to view their available time.</p>",
            },
            {
                question: "How do I book an appointment with a doctor?",
                answer: [
                    "<ol>",
                    "<li><strong>Select a doctor</strong></li>",
                    "<li>Tap<strong> Request an Appointment</strong></li>",
                    "<li>Fill in the necessary details (Date and Time, Brands to promote, Duration of appointment, Notes to doctor)</li>",
                    "<li>Click <strong>Next</strong></li>",
                    "<li>Review <strong>information</strong></li>",
                    "<li>Click <strong>Submit</strong></li>",
                    " </ol>",
                ].join(""),
            },
            {
                question: "How do I view my appointment request?",
                answer: [
                    "<p>To view:</p>",
                    "<ol>",
                    "<li>Go to <strong>Schedule</strong> in left side menu.</li>",
                    "<li>Navigate to the <strong>status</strong> tabs (Pending, Approved, Declined, and Cancelled)</li>",
                    " </ol>",
                ].join(""),
            },
            {
                question: "Can I upload product shots when I request an appointment?",
                answer: "<p>No, this feature is not yet available.</p>",
            },
            {
                question: "I cannot send an appointment request, what should I do?",
                answer: [
                    "<div>",
                    "Make sure that you have a stable internet connection. If you still cannot send an appointment request, please contact:",
                    "<p>Email: <a href='mailto:we.care@ppd.ph'><u>we.care@ppd.ph</u></a></p>",
                    "<div>Mobile:",
                    "<ul class='list'>",
                    "<li>09190752868 (SMART) - Tue. - Sat., 8am to 5pm</li>",
                    "<li>09176274949 (GLOBE) - Sun. - Thur, 8am - 5pm</li>",
                    "<li>09176270709 (GLOBE) - Tue. - Sat., 8am to 5pm</li>",
                    "</ul>",
                    "</div>",
                    "</div>",
                ].join(""),
            },
            {
                question: "How long do I need to wait for the doctor's response?",
                answer: "<p>Please wait up to 48 hours after setting your appointment.</p>",
            },
            {
                question: "How do I know if my request for appointment is approved?",
                answer: "<p>You will receive an app and email notification.You can also check the appointment status in the Schedule tab found on the left side menu.</p>",
            },
            {
                question: "How do I know if my request for appointment is declined?",
                answer: "<p>You will receive an app and email notification.You can also check the appointment status in the Schedule tab found on the left side menu.</p>",
            },
            {
                question: "Can I resend an appointment request?",
                answer: "<p>No, you cannot resend a previous appointment request.</p>",
            },
            {
                question: "Can I cancel an appointment request?",
                answer: "<p>No, you cannot cancel a request once you have submitted them.</p>",
            },
            {
                question: "Where can I find my approved appointments?",
                answer: "<p>You can view the approved appointments in the Schedule tab under Upcoming.</p>",
            },
            {
                question: "How do I know if my appointment request is cancelled?",
                answer: "<p>You will receive an app and email notification.You can also check the appointment status in the Schedule tab found on the left side menu.</p>",
            },
            {
                question: "Will there be a reminder before my scheduled appointment?",
                answer: "<p>Yes, an email will be sent to you on the day and 30 minutes prior to your appointment.</p>",
            },
            {
                question: "What are credits?",
                answer: "<p>Credits are PPD Clinic Pharma's virtual currency which can only be used to set an appointment with a PPD Clinic doctor. 1 credit is equivalent to 5 minutes.</p>",
            },
            {
                question: "How can I earn credits?",
                answer: "<p>Credits can not be earned. Only your District Manager or PPD Clinic Pharma's admin can replenish your credits.</p>",
            },
            {
                question: "When will my credit be deducted?",
                answer: "<p>Your credits will be deducted after requesting an appointment. If the appointment is cancelled or declined, it will automatically be refunded to your account.</p>",
            },
            {
                question: "I accidentally got disconnected during the video call, will my credit be deducted?",
                answer: "<p>Yes, your credits will still be deducted.</p>",
            },
            {
                question: "Can I make a call without using credits?",
                answer: "<p>No, you can only call a doctor by setting an appointment, which requires credits.</p>",
            },
            {
                question: "When can I request for new credit?",
                answer: "<p>You may request from your disctrict managers anytime you want.</p>",
            },
            {
                question: "How can I request for additional credits?",
                answer: "<p>Currently, requesting for additional credits directly via PPD Clinic Pharma is not yet available. You can request from your disctrict manager instead.</p>",
            },
            {
                question: "Do credits expire?",
                answer: "<p>No.</p>",
            },
            {
                question: "Will my credit be deducted if the appointment got cancelled?",
                answer: "<p>No, credits will be refunded to your account if the appointment is cancelled.</p>",
            },
            {
                question: "Is there a limit on the amount of credits I can use?",
                answer: "<p>No.</p>",
            },
            {
                question: "How can I send feedbacks?",
                answer: [
                    "<div>",
                    "You can provide your feedback about our services, by directly sending an email or calling:",
                    "<p>Email: <a href='mailto:we.care@ppd.ph'><u>we.care@ppd.ph</u></a></p>",
                    "<div>Mobile:",
                    "<ul class='list'>",
                    "<li>09190752868 (SMART) - Tue. - Sat., 8am to 5pm</li>",
                    "<li>09176274949 (GLOBE) - Sun. - Thur, 8am - 5pm</li>",
                    "<li>09176270709 (GLOBE) - Tue. - Sat., 8am to 5pm</li>",
                    "</ul>",
                    "</div>",
                    "</div>",
                ].join(""),
            },
            {
                question: "When can I send feedbacks?",
                answer: "<p>Send a feedback whenever you feel like it, so we can address your concerns and/or suggestions towards the improvement of our services.</p>",
            },
            {
                question: "Am I required to send Feedbacks?",
                answer: "<p>No. Sending any type of feedback is not mandatory.</p>",
            },
            {
                question: "Am I required to send Feedbacks?",
                answer: "<p>No. Sending any type of feedback is not mandatory.</p>",
            },
            {
                question: "How do I call a doctor?",
                answer: [
                    "<p>You can only call your doctor on the time of your appointment.</p>",
                    "<br>",
                    "<p>To call:</p>",
                    "<ol>",
                    "<li>Go to <strong>Schedule</strong> tab.</li>",
                    "<li>Select your <strong>Appointment.</strong></li>",
                    "<li>Tap the <strong>Join</strong> button. </li>",
                    "</ol>",
                ].join(""),
            },
            // {
            //     question: "How to setup and start a video call?",
            //     answer: "<p>DBG</p>",
            // },
            {
                question: "Who can initiate a call?",
                answer: "<p>You and the doctor can start a call as long as both of you are in the waiting room.</p>",
            },
            {
                question: "The doctor cannot hear or see me during the video call. What should I do?",
                answer: [
                    "<div>",
                    "Make sure that your internet connection is stable and your internet browser is updated. If the doctor still cannot hear or see you, please advise him/her to contact support at:",
                    "<p>Email: <a href='mailto:we.care@ppd.ph'><u>we.care@ppd.ph</u></a></p>",
                    "<div>Mobile:",
                    "<ul class='list'>",
                    "<li>09190752868 (SMART) - Tue. - Sat., 8am to 5pm</li>",
                    "<li>09176274949 (GLOBE) - Sun. - Thur, 8am - 5pm</li>",
                    "<li>09176270709 (GLOBE) - Tue. - Sat., 8am to 5pm</li>",
                    "</ul>",
                    "</div>",
                    "</div>",
                ].join(""),
            },
            {
                question: "I can not see or hear the doctor during video call. What should I do?",
                answer: "<p>Make sure that you have a stable internet connection. If you still cannot see nor hear the doctor in call, check that your device is not muted, or if you are using earphones, make sure that it is connected properly. This issue might also be on the doctor's side, so ask him/her.</p>",
            },
            {
                question: "How will I know if the doctor is ready to join a video call?",
                answer: "<p>A notification will inform you that the doctor is already in the waiting room.</p>",
            },
            {
                question: "Can I leave the video call?",
                answer: "<p>Yes, you can freely leave the video call anytime you want.</p>",
            },
            {
                question: "How long do I have to wait for the doctor to join the call?",
                answer: "<p>There is no time limit as to how long you'll need to wait for the doctor. Once both of you are in the waiting room, you can immediately start the call.</p>",
            },
            {
                question: "Can I chat with doctors?",
                answer: "<p>This feature is not yet available.</p>",
            },
        ]);

        const showFaqs = ref(false)
        const toggleFaqs = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            showFaqs.value = !showFaqs.value
            emit('on-click-back')
        };
        return {
            logo,
            faqs,
            showFaqs,
            toggleFaqs,
        }
    },

}
</script>

<style lang="scss">
.faq-component {
    &__initial-view {
        @apply flex space-x-3 mt-8 py-3 px-4 bg-indigo-50 mx-auto w-full max-w-sm;
        h4 {
            @apply text-lg leading-6 font-medium text-gray-700;
        }
        p {
            @apply text-sm leading-5 font-normal text-gray-500 mt-2;
        }
    }
    &__content {
        @apply absolute top-0 bg-gray-50 w-full left-0 p-4 pb-16 z-10;
    }
    &__container {
        @apply flex items-center justify-center h-full overflow-y-auto;
        min-height: calc(100vh - 70px);
    }
    &__divider {
        max-width: 800px;
        @apply w-full divide-y-2 divide-gray-200;
    }
    &__header {
        @apply text-center;
        img {
            @apply mx-auto mb-16 w-2/5;
            max-width: 200px;
        }
        h2 {
            @apply text-4xl leading-10 font-extrabold text-gray-900;
        }
    }
    &__dl {
        @apply mt-6 space-y-6 divide-y divide-gray-200;
        .disclosure-button {
            @apply text-left w-full flex justify-between items-start text-gray-400;
        }
        .disclosure-panel {
            @apply mt-2 pr-12;
            .faq-answer {
                @apply text-base leading-6 font-normal text-gray-500;
                ul {
                    @apply list-disc pl-4;
                }
                ol {
                    @apply list-decimal pl-4;
                }
                a {
                    @apply text-primary;
                }
            }
        }
    }
    &__dt {
        @apply text-lg;
        .faq-question {
            @apply text-lg leading-7 font-medium text-gray-900;
        }
        .faq-icon {
            @apply ml-6 h-7 flex items-center text-gray-400;
        }
    }
}
@screen lg {
    .faq-component {
        &__initial-view {
            width: 448px !important;
        }
    }
}
</style>