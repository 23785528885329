<template>
    <div class="search-doctors-component dashboard-grid">
        <search-doctors-filter-component
            @on-apply-filter="onApplyFilter($event)"
        />
        <search-result-component
            :is-loading="isLoading"
            :doctors="searchedResult"
            :show-empty-state="showEmptyState"
            @on-search="onSearch($event)"
            @update-keywords="keywords = $event"
            @get-doctor-id="$emit('get-doctor-id', $event)"
            @load-more-doctors="loadMoreDoctors"
        />
    </div>
</template>

<script>
import { onMounted, computed, ref, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import {
    SearchResultComponent,
    SearchDoctorsFilterComponent,
} from '.'

export default {
    name: 'SearchDoctorsComponent',
    components: {
        SearchResultComponent,
        SearchDoctorsFilterComponent,
    },
    props: {
        tabName: { type: String, default: '' },
    },
    emits: [ "get-doctor-id", "set-tab-name" ],
    setup(props, { emit }) {
        const store = useStore()

        const showEmptyState = ref(false)
        // START OF SETTING ACTIVE TAB
        onMounted(() => {
            emit("set-tab-name", props.tabName)
        })
        // END OF SETTING ACTIVE TAB

        // START OF FETCHING SEARCH RESULTS
        const page = ref(1)
        const keywords = ref("")
        const loadMoreDoctors = async () => {
            page.value++;
            await onSearch({ page: page.value });

            // for code refactor: install VueScrollTo for smooth scrolling effect
            const filterContainer = document.querySelector('.search-result-component .load-more-body')
            filterContainer.scrollTop = 0
        }
        const onSearch = async (item) => {
            page.value = item.page
            const payload = {
                keyword: keywords.value,
                page: page.value,
                ...formData.value,
            }
            await store.dispatch('CommonDoctor/onSearchDoctors', payload)
        }
        const searchedResult = computed(() => {
            return store.getters['CommonDoctor/searchedResult'];
        })
        const isLoading = computed(() => store.getters['CommonDoctor/listLoading'])
        // END OF FETCHING SEARCH RESULTS

        // START OF FILTER DATA
        const formData = ref({})
        const onApplyFilter = (data) => {
            formData.value = _.cloneDeep(data.form);
            if (data.type === 'apply-filter') {
                onSearch({ page: 1 });
            }
            if (!_.isEmpty(searchedResult.value)) {
                const searchResult = document.querySelector('.search-result-component .load-more-body');
                searchResult.scrollTop = 0
            }
        }
        // END OF FILTER DATA

        // RESET SEARCH RESULT WHEN UNMOUNTED
        onUnmounted(() => {
            store.commit('CommonDoctor/setSearchedResult', [])
        })

        return {
            onSearch,
            searchedResult,
            loadMoreDoctors,
            isLoading,
            onApplyFilter,
            keywords,
            showEmptyState,
        }
    }
}
</script>

<style lang="scss">
.search-doctors-component {
    .search-doctors-filter-component {
    }
}
</style>