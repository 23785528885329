<template>
    <div class="brands-covered-list-card-component">
        <div class="brands-covered-list-card-container">
            <div class="brand-list-card">
                <div class="brand-list-card-header-container">
                    <p class="header-title"> Brands Promoted </p>
                </div>
                <div class="brand-list-card-body-container">
                    <div v-for="(brand, index) in items" :key="index" class="brand-list-content">
                        <p class="brand-name"> {{ brand.brand_name }} </p>
                        <div class="last-cover-date-content">
                            <p class="label"> Last Promoted: </p>
                            <p class="label-value"> {{ brand.last_date_coverage }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "BrandsCoveredListCardComponent",
    components: {
    },
    props: {
        items: { type: Object, default: null },
    }
}
</script>

<style lang="scss">
.brands-covered-list-card-component {
    .brands-covered-list-card-container {
        .brand-list-card {
            @apply shadow rounded-md w-auto bg-white divide-y divide-gray-200;
            .brand-list-card-header-container {
                @apply py-4 px-6;
                .header-title {
                    @apply text-lg font-medium text-gray-900;
                }
            }
            .brand-list-card-body-container {
                @apply  divide-y divide-gray-200;
                .brand-list-content {
                    @apply py-3 px-6;
                    .brand-name {
                        @apply text-lg font-normal text-gray-900;
                    }
                    .last-cover-date-content {
                        @apply flex space-x-1;
                        .label {
                            @apply text-sm font-normal text-gray-500;
                        }
                        .label-value {
                            @apply text-sm font-medium text-primary;
                        }
                    }
                }
            }
        }
    }
}
</style>