const UpcomingScheduleComponent = () => import('./upcoming/upcoming-schedule-component.vue')
const PendingScheduleComponent = () => import('./pending/pending-schedule-component.vue')
const DoneScheduleComponent = () => import('./done/done-schedule-component.vue')
const DeclinedScheduleComponent = () => import('./declined/declined-schedule-component.vue')
const CancelledScheduleComponent = () => import('./cancelled/cancelled-schedule-component.vue')

// import PendingScheduleComponent from './pending-schedule-component.vue'
// import DoneScheduleComponent from './done-schedule-component.vue'
// import DeclinedScheduleComponent from './declined-schedule-component.vue'
// import CancelledScheduleComponent from './cancelled-schedule-component.vue'

export {
    UpcomingScheduleComponent,
    PendingScheduleComponent,
    DoneScheduleComponent,
    DeclinedScheduleComponent,
    CancelledScheduleComponent,

}