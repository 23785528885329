<template>
    <div class="appointment-slideover-component">
        <slide-over-component
            v-model="value"
            content-class="appointment-slideover__content"
            @update:modelValue="value = $event"
        >
            <div>
                <!-- STATIC HEIGHT COMPONENT -->
                <slideover-header-component
                    v-bind="slideoverHeaderProps"
                    @on-click-close-btn="hideSlideOver"
                    @get-height="headerComponentHeight = $event"
                />

                <!-- DYNAMIC HEIGHT -->
                <div class="divide-y divide-gray-200">
                    <load-more-component
                        v-bind="loadMoreProps"
                        @getActionBtnHeight="actionBtnHeight = $event"
                    >
                        <appointment-card-component
                            v-bind="appointmentCardProps"
                            @get-height="componentHeight = $event"
                        />
                        <div v-if="headerDetails.footerContent && $userType === AccountType.Medrep" class="footer-container px-6 pt-3 space-y-4">
                            <div class="footer-header">
                                <p class="text-sm font-medium text-gray-700">
                                    {{ headerDetails.footerContent.header }}
                                </p>
                            </div>
                            <div class="footer-body">
                                <div v-if="headerDetails.footerContent.listType==='check'">
                                    <div v-for="(content, index) in headerDetails.footerContent.content" :key="index" class="flex space-x-3 mb-1">
                                        <div>
                                            <OutlineCheckIcon
                                                class="text-green-400 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <p class="text-sm text-gray-500">
                                            {{ content }}
                                        </p>
                                    </div>
                                </div>
                                <ul v-else class="list-disc pl-5">
                                    <li v-for="(content, index) in headerDetails.footerContent.content" :key="index" class="text-sm text-gray-500 pl-2">
                                        {{ content }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </load-more-component>
                </div>
            </div>
        </slide-over-component>
    </div>
</template>

<script>
import SlideOverComponent from "@CommonComponents/modal/slide-over-component.vue"
import LoadMoreComponent from "@CommonComponents/load-more-component.vue";
import AppointmentCardComponent from '@CommonComponents/cards/appointment-card-component.vue';
import { ref, computed, watch } from "vue";
import { SlideoverHeaderComponent } from "./";
import { useStore } from 'vuex';
import { CheckIcon as OutlineCheckIcon } from "@heroicons/vue/outline";
import { STORAGE } from "@Plugins/storage.js";
import { AccountType, Tokens } from "@Utilities/enum"

export default {
    name: "AppointmentSlideoverComponent",
    components: {
        SlideOverComponent,
        SlideoverHeaderComponent,
        LoadMoreComponent,
        AppointmentCardComponent,
        OutlineCheckIcon
    },
    props: {
        modelValue: { type: Boolean, default: false },
    },
    emits: [ "update:modelValue" ],
    setup(props) {
        const store = useStore();
        const value = ref(props.modelValue);
        const viewportHeight = ref(window.innerHeight);
        const componentHeight = ref(0);
        const actionBtnHeight = ref(0);
        const headerComponentHeight = ref(0);

        //-----------------------COMPUTED-----------------------

        // GETTERS
        const appointment = computed(
            () => store.getters["CommonAppointment/appointment"]
        );

        const isLoading = computed(
            () => store.getters["CommonAppointment/isLoading"]
        );

        // SCROLL AREA HEIGHT COMPUTATION
        const totalHeight = computed(() => {
            const paddingBottom = 9;
            return (
                (viewportHeight.value -
                (paddingBottom +
                actionBtnHeight.value +
                headerComponentHeight.value.offsetHeight))
            );
        });

        // COMPONENT PROPERTIES
        const slideoverHeaderProps = computed(() => {
            return {
                appointment: appointment.value,
                class: headerDetails.value.headerTheme,
                buttonLabel: headerDetails.value.buttonLabel,
                disabledRequestButton: headerDetails.value.disabledButton
            }
        })

        const loadMoreProps = computed(() => {
            return {
                uniqueId: "apt-slideover-read-more",
                uniqueBodyId: `${appointment.value.status}-body-slider`,
                height: totalHeight.value,
                showScrollMore: false,
                loadMoreBodyClass: "divide-y divide-gray-200"
            }
        })

        const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));
        const appointmentCardProps = computed(() => {
            return {
                showDate: true,
                data: appointment.value,
                showInvited: appointment.value.invited,
                showBrand: true,
                showName: true,
                noShadowEffect: true,
                showRemarks: true,
                showRemarksFields: _.isEmpty(appointment.value.remarks) || _.isEmpty(appointment.value.remarks.content) ? true : false,
                showMedrepName: userType.value === AccountType.Admin,
                description:
                    appointment.value.status === 'pending'
                        ? 'The doctor may reschedule the appointment date and time'
                        : '',
                showCreditToUse: appointment.value.status !== 'served',
                showNote: appointment.value.status !== 'served',
                cardClass: "appointment-card",
                showRescheduleLabel: appointment.value.status === 'accepted',
                showShortenedTime: true,
                showStatus: userType.value !== AccountType.Admin,
                isLoading: isLoading.value
            }
        })

        // // CREDIT BALANCE
        const hasNoBalance = computed(
            () => store.getters["CommonCredit/hasNoBalance"]
        );

        // SETTING THE HEADER OF SLIDEOVER
        const headerDetails = computed(() => {
            let data = {}
            switch (appointment.value.status) {
            case 'accepted':
            case 'on_going':
                data = {
                    headerTheme: 'primary-theme',
                    buttonLabel: 'Enter Call',
                    footerContent: {
                        header: 'Reminders',
                        content: [
                            'Your appointment will have a limited duration. Please be mindful of your time.',
                            'Please be ready 30 minutes before your appointment.',
                            'Make sure you have a stable internet connection.',
                            'Be in a well-lit room.',
                            'Your approved appointment with the doctor will be cancelled if it does not take place 12 hours after the scheduled time.'
                        ],
                        listType: 'check'
                    },
                }
                break;
            case 'pending':
                data = {
                    headerTheme: 'light-indigo-theme',
                    footerContent: {
                        header: 'What to expect',
                        content: [
                            'Please wait for the doctor to respond within 48 hours.',
                            'Credits will be refunded to your account if the doctor failed to respond within 48 hours.',
                            'This appointment can be rescheduled by the doctor.'
                        ]
                    },
                    listType: 'dot'
                }
                break;
            default:
                // DONE OR DECLINED
                data = {
                    buttonLabel: 'Request an appointment',
                    headerTheme: 'default-theme',
                    disabledButton: hasNoBalance.value,
                }
            }
            return { ...data }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------
        const showSlideover = computed(
            () => store.getters["CommonAppointment/appointmentSlideover"]
        );
        const hideSlideOver = () => {
            if (showSlideover.value) {
                store.commit('CommonAppointment/setAppointmentSlideover', false)
            }
        }

        //-------------------METHODS ENDs HERE------------------

        // UPDATE VALUE EVERYTIME THE MODELVALUE CHANGES
        watch(
            () => props.modelValue,
            (newVal) => {
                value.value = newVal
            }
        )
        // CLOSE THE DOCTOR SLIDE OVER
        watch(
            () => value.value,
            (newVal) => {
                if (!newVal) {
                    hideSlideOver();

                    // RESET DOCTORS DATA EVERY TIME THE SLIDEOVER CLOSES
                    // store.commit("CommonDoctor/setDoctorProfile", {});
                    // store.commit("CommonDoctor/setDoctorSchedule", []);
                    // store.commit("CommonDoctor/setDoctorAppointments", []);
                    // store.commit("CommonDoctor/setDoctorCoverage", []);
                }
            }
        )

        return {
            componentHeight,
            actionBtnHeight,
            totalHeight,
            headerComponentHeight,
            appointment,
            headerDetails,
            value,
            isLoading,
            hideSlideOver,
            slideoverHeaderProps,
            loadMoreProps,
            appointmentCardProps,
            AccountType,
        };
    },
};
</script>

<style lang="scss">
.slide-over-modal-component {
    .appointment-slideover__content {
        .slideover-header-component {
            @apply p-6;
            // THEMES
            &.light-indigo-theme {
                @apply bg-indigo-50;
                .title {
                    @apply text-indigo-700 #{!important};
                }
                .subtitle {
                    @apply text-indigo-500 #{!important};
                }
                .close-btn {
                    @apply bg-indigo-50 text-indigo-500 #{!important};
                }
            }
            &.primary-theme {
                @apply bg-primary-50;
                .title {
                    @apply text-primary-700 #{!important};
                }
                .subtitle {
                    @apply text-primary #{!important};
                }
                .close-btn {
                    @apply bg-primary-50 text-primary #{!important};
                }
            }
            &.default-theme {
                @apply bg-gray-50;
                .title {
                    @apply text-gray-700 #{!important};
                }
                .subtitle {
                    @apply text-gray-500 #{!important};
                }
                .close-btn {
                    @apply bg-gray-50 #{!important};
                }
            }
        }
        .load-more-component {
            .load-more-body {
                // @apply overflow-visible;
                // APPOINTMENT CARD COMPONENT
                .appointment-card-component {
                    @apply overflow-visible ;
                    .appointment-settings-card-component {
                        @apply px-6 py-4;
                        .appointment-settings {
                            &__date {
                                &--container {
                                    @apply items-start;
                                }
                            }
                            &:hover {
                                @apply shadow-none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.appointment-settings-card-component.appointment-card {
    @apply px-6 py-4 #{!important};
}
.appointment-body-card-component.appointment-card {
    @apply px-6 py-3 space-y-1 #{!important};
}
</style>