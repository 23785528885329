<template>
    <div>
        <TransitionRoot as="template" :show="open">
            <Dialog
                as="div"
                :class="[{'scrollable': scrollable}, 'pharma-modal slot-modal', containerClass]"
                @close="onHideModal"
            >
                <div class="slot-modal__container">
                    <TransitionChild
                        v-bind="transitionChildContainerProps"
                    >
                        <DialogOverlay
                            :class="['slot-modal__overlay', isSemiTransparent ? 'semi-transparent' : '']"
                        />
                    </TransitionChild>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                    <span
                        class="
                            hidden
                            sm:inline-block
                            sm:align-middle
                            sm:h-screen
                        "
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <TransitionChild
                        v-bind="transitionChildContentProps"
                    >
                        <div class="slot-modal__content" :class="distinctClass">
                            <slot />
                            <div :class="contentClass">
                                <div
                                    :class="[headerClass, 'header-container']"
                                >
                                    <slot name="header" />
                                </div>

                                <div
                                    :class="[bodyClass, 'body-container']"
                                    :style="
                                        scrollable ? `height: ${height}px` : ''
                                    "
                                >
                                    <slot name="body" />
                                </div>
                            </div>
                            <div :class="footerClass" class="footer-container">
                                <slot
                                    name="footer"
                                    :onCancel="onCancel"
                                    :onProceed="onProceed"
                                />
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script>
import { ref, watch, onMounted, computed } from "vue";
import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

export default {
    name: "SlotModalComponent",
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
    },
    props: {
        modelValue: Boolean,
        contentClass: { type: String, default: "" },
        containerClass: { type: String, default: "" },
        distinctClass: { type: String, default: "" },
        headerClass: { type: String, default: "" },
        bodyClass: { type: String, default: "" },
        footerClass: { type: String, default: "" },
        height: { type: [ String, Number ], default: 300 },
        scrollable: { type: Boolean, default: true },
        isSemiTransparent: Boolean
    },
    emits: [ "update:modelValue", "modal-closed" ],
    setup(props, { emit }) {
        const open = ref(false);

        //-----------------------COMPUTED-----------------------

        const transitionChildContainerProps = computed(() => {
            return {
                as: "template",
                enter: "ease-out duration-300",
                enterFrom: "opacity-0",
                enterTo: "opacity-100",
                leave: "ease-in duration-200",
                leaveFrom: "opacity-100",
                leaveTo: "opacity-0",
            }
        })

        const transitionChildContentProps = computed(() => {
            return {
                as: "template",
                enter: "ease-out duration-300",
                enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                enterTo: "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
                leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onHideModal = () => {
            emit("update:modelValue", false);
            emit('modal-closed')
        };

        const onProceed = () => {
            onHideModal();
        };

        const onCancel = () => {
            onHideModal();
        };

        //-------------------METHODS ENDs HERE------------------

        onMounted(() => {
            open.value = props.modelValue;
        });

        watch(
            () => props.modelValue,
            (newVal) => {
                open.value = newVal;
            }
        );

        return {
            open,
            onCancel,
            onProceed,
            onHideModal,
            transitionChildContainerProps,
            transitionChildContentProps
        };
    },
};
</script>

<style lang="scss">
.pharma-modal {
    &.slot-modal {
        @apply fixed z-10 inset-0 overflow-y-auto;
        .slot-modal {
            &__container {
                @apply flex items-center justify-center min-h-screen pt-4 px-4 pb-4 text-center;
            }
            &__overlay {
                @apply fixed inset-0 bg-gray-300 bg-opacity-100 transition-opacity;
            }
            &__overlay.semi-transparent {
                @apply bg-opacity-70;
            }
            &__content {
                @apply inline-block align-bottom bg-white pt-5 pb-4 rounded-lg text-left overflow-hidden shadow-xl transform transition-all;
                .header-container {
                    @apply px-4;
                }
                .body-container {
                    @apply mt-2 px-4 overflow-y-auto;
                }
                .footer-container {
                    @apply mt-5 px-4;
                }
            }
        }

        &.scrollable {
            .slot-modal {
                &__container {
                    @apply h-screen;
                }
                &__content {
                    max-height: 90%;
                    .body-container {
                        @apply overflow-y-auto;
                    }
                }
            }
        }
    }
}
@screen sm {
    .pharma-modal {
        &.slot-modal {
            .slot-modal {
                &__container {
                    @apply block p-0;
                }
                &__content {
                    @apply my-8 align-middle max-w-md w-full py-6;
                    .header-container {
                        @apply px-6;
                    }
                    .body-container {
                        @apply px-6;
                    }
                    .footer-container {
                        @apply mt-6 px-6;
                    }
                }
            }
        }
    }
}
</style>