<template>
    <div v-if="displayCard || isLoading" class="name-card-component">
        <div
            :class="[
                isAppointmentCard
                    ? 'card-no-shadow'
                    : 'card-with-shadow default',
            ]"
        >
            <OutlineUserIcon class="card-icon" aria-hidden="true" />
            <div v-if="!isLoading" class="name-container">
                <p class="doctor-name">
                    <!-- {{ data.doctor_name }} -->
                    {{ name }}
                </p>
            </div>

            <div v-else class="name-container load_card_animate w-full">
                <p class="doctor-name h-4 bg-primary-100 rounded w-1/2 mt-px" />
            </div>
        </div>
    </div>
</template>

<script>
import { UserIcon as OutlineUserIcon } from "@heroicons/vue/outline";
import { computed } from 'vue'

export default {
    name: "NameCardComponent",
    components: {
        OutlineUserIcon,
    },
    props: {
        name: { type: String, default: null },
        forListing: Boolean,
        isAppointmentCard: Boolean,
        isLoading: Boolean
    },
    setup(props) {
        const displayCard = computed(() => {
            return props.name
        })

        return {
            displayCard
        }
    }
}
</script>

<style lang="scss">
.name-card-component {
    .name-container {
        @apply ml-0 #{!important};
        .doctor-name {
            @apply text-sm font-medium text-gray-700;
        }
    }
}
</style>