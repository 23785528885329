
<template>
    <div class="video__call__state w-full h-full text-center">
        <div v-if="state === 'permission'" class="permission__state">
            <div class="permission__content">
                <div class="icons__container">
                    <div
                        v-for="(icon, index) in icons"
                        :key="index"
                        class="
                            icon__rounded icon__indigo icon__m
                            md:icon__l
                            mx-2
                        "
                    >
                        <component :is="icon" class="icon" />
                    </div>
                </div>
                <div class="permission__message">
                    <b class="message text-sm md:text-lg">
                        Allow PPD Clinic Pharma to access your microphone and camera
                    </b>
                    <p class="sub__message text-xs md:text-sm mt-2">
                        Please go to your browser settings and grant this
                        website access to your microphone and camera
                    </p>
                </div>
            </div>
        </div>

        <div class="video__call_container">
            <video-screen-share-component
                v-if="_.isEmpty(state)"
                :setup="setup"
                @permission-failed="state = 'permission'"
                @devices="setupDevices($event)"
                @videocall-ready="$emit('videocall-ready')"
            />
        </div>
    </div>
</template>

<script>
import { VideoCameraIcon, MicrophoneIcon } from "@heroicons/vue/outline";
import VideoScreenShareComponent from "./video-screen-share-component.vue";
import { ref } from "vue";

import { useSetupSettings } from "./functions";
export default {
    name: "VideoCallStateComponent",
    components: {
        VideoCameraIcon,
        MicrophoneIcon,
        VideoScreenShareComponent,
    },
    props: {
        setup: Boolean,
    },
    emits: [ "videocall-ready" ],
    setup() {
        const state = ref("");

        const icons = ref([ VideoCameraIcon, MicrophoneIcon ]);

        const videoCallReady = ref(false);

        const { setupDevices } = useSetupSettings();

        return {
            state,
            icons,
            videoCallReady,
            setupDevices,
        };
    },
};
</script>

<style lang="scss">
.video__call__state {
    .permission__state {
        @apply w-full h-full flex items-center justify-center bg-gray-100 p-4;

        .icons__container {
            @apply flex items-center justify-center mb-4;
        }
    }
}

// @screen lg {
//     .video__call__state {
//         .permission__state {
//             .permission__content {
//                 @apply w-1/2;
//             }
//         }
//     }
// }
</style>