<template>
    <div v-if="message || isLoading" class="note-card-component">
        <div
            :class="[
                isAppointmentCard
                    ? 'card-no-shadow'
                    : 'card-with-shadow default',
            ]"
        >
            <DocumentIcon class="card-icon" aria-hidden="true" />
            <div v-if="!isLoading" class="note-container">
                <p class="note-label">{{ label }}</p>
                <div class="note-content">
                    <p
                        v-if="message"
                        class="note-message"
                    >
                        {{ message }}
                    </p>
                </div>
            </div>
            <div v-else class="note-container load_card_animate w-full">
                <p class="note-label h-4 bg-primary-100 rounded w-1/3 mt-1" />
                <div class="note-content">
                    <p class="note-message h-4 bg-primary-100 rounded w-1/2 mt-1" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DocumentIcon } from "@heroicons/vue/outline";
import { computed } from 'vue'

export default {
    name: "NoteToDoctorComponent",
    components: {
        DocumentIcon,
    },
    props: {
        data: { type: [ Object, Number ], default: () => {} },
        isAppointmentCard: Boolean,
        isLoading: Boolean
    },
    setup(props) {
        const revokedAppointment = computed(() => {
            return props.data.status === "declined" || props.data.status === "cancelled"
        })
        const label = computed(() => {
            let item;
            switch (props.data.status) {
            case "declined":
                item = "Reason declined";
                break;
            case "cancelled":
                item = "Reason cancelled";
                break;
            default:
                item = "Note to doctor";
                break;
            }
            return item;
        })

        const message = computed(() => {
            // note:
            // props.data.message is medrep's note to a doctor
            // props.data.doctor_notes is doctor's note to a medrep
            // props.data.reason is doctor's reason of declined or cancelled
            return revokedAppointment.value ? _.has(props.data, 'reason') ? props.data.reason : props.data.doctor_notes : props.data.message
            // refactor - for the mean time put props.data.doctor_notes as failback since api has not yet provided reason key
        })

        return {
            label,
            message,
        }
    },
}
</script>

<style lang="scss">
.note-card-component {
    .note-container {
        @apply ml-0 space-y-1 #{!important};
        .note-label {
            @apply text-sm font-medium text-gray-700;
        }
        .note-content {
            .note-message {
                @apply text-sm leading-5 font-normal text-gray-500
            }
        }
    }
}
</style>