<template>
    <div :class="{ 'input-group': bootstrapStyling }">
        <!-- Calendar Button -->
        <span
            v-if="calendarButton"
            class="vdp-datepicker__calendar-button"
            :class="{ 'input-group-prepend': bootstrapStyling }"
            :style="{ 'cursor:not-allowed;': disabled }"
            @click="showCalendar"
        >
            <span :class="{ 'input-group-text': bootstrapStyling }">
                <i :class="calendarButtonIcon">
                    {{ calendarButtonIconContent }}
                    <span v-if="!calendarButtonIcon">&hellip;</span>
                </i>
            </span>
        </span>
        <!-- Input -->
        <input
            :id="id"
            :ref="refName"
            :type="inline ? 'hidden' : 'text'"
            :class="computedInputClass"
            :name="name"
            :value="formattedValue"
            :open-date="openDate"
            :placeholder="placeholder"
            :clear-button="clearButton"
            :disabled="disabled"
            :required="required"
            :readonly="!typeable"
            autocomplete="off"
            class="w-full rounded-md"
            @click="showCalendar"
            @keyup="parseTypedDate"
            @blur="inputBlurred"
        />
        <!-- Clear Button -->
        <span
            v-if="clearButton && selectedDate"
            class="vdp-datepicker__clear-button"
            :class="{ 'input-group-append': bootstrapStyling }"
            @click="clearDate()"
        >
            <span :class="{ 'input-group-text': bootstrapStyling }">
                <i :class="clearButtonIcon">
                    <span v-if="!clearButtonIcon">&times;</span>
                </i>
            </span>
        </span>
        <slot name="afterDateInput" />
        <component
            :is="calendarButtonIcon"
            class="calendar-icon"
            aria-hidden="true"
        />
    </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue';
import { makeDateUtils } from "../utils/DateUtils";
export default {
    props: {
        selectedDate: Date,
        resetTypedDate: { type: [ Date ], default: () => {} },
        format: { type: [ String, Function ], default: () => {} },
        translation: { type: Object, default: () => {} },
        inline: Boolean,
        id: { type: String, default: "" },
        name: { type: String, default: "" },
        refName: { type: String, default: "" },
        openDate: Date,
        placeholder: { type: String, default: "" },
        inputClass: { type: [ String, Object, Array ], default: () => {} },
        clearButton: Boolean,
        clearButtonIcon: { type: String, default: "" },
        calendarButton: Boolean,
        calendarButtonIcon: { type: Function, default: () => {} },
        calendarButtonIconContent: { type: String, default: "" },
        disabled: Boolean,
        required: Boolean,
        typeable: Boolean,
        bootstrapStyling: Boolean,
        useUtc: Boolean
    },
    emits: [ "show-calendar", "typed-date", "close-calendar", "clear-date" ],
    setup(props, { emit }) {
        const constructedDateUtils = ref(makeDateUtils(props.useUtc));
        const input = ref(null);
        onMounted(() => {
            input.value = document.querySelector("input");
        })
        const typedDate = ref(false);
        const utils = ref(constructedDateUtils.value);
        const formattedValue = computed(() => {
            if (!props.selectedDate) {
                return null;
            }
            if (typedDate.value) {
                return typedDate.value;
            }
            return typeof props.format === "function"
                ? props.format(props.selectedDate.date)
                : utils.value.formatDate(
                    new Date(props.selectedDate),
                    props.format,
                    props.translation
                );
        });
        const computedInputClass = computed(() => {
            if (props.bootstrapStyling) {
                if (typeof props.inputClass === "string") {
                    return [ props.inputClass, "form-control" ].join(" ");
                }
                return { "form-control": true, ...props.inputClass };
            }
            return props.inputClass;
        });
        watch(
            () => { props.resetTypedDate },
            () => {
                typedDate.value = false;
            },
        )

        const showCalendar = () => {
            emit("show-calendar");
        };

        const parseTypedDate = (event) => {
            // close calendar if escape or enter are pressed
            if (
                [
                    27, // escape
                    13, // enter
                ].includes(event.keyCode)
            ) {
                input.value.blur();
            }

            if (props.typeable) {
                const typedDate = Date.parse(input.value.value);
                if (!isNaN(typedDate.value)) {
                    typedDate.value = input.value.value;
                    emit("typed-date", new Date(typedDate.value));
                }
            }
        };

        const inputBlurred = () => {
            if (props.typeable && isNaN(Date.parse(input.value.value))) {
                clearDate();
                input.value.value = null;
                typedDate.value = null;
            }

            emit("close-calendar");
        };
        const clearDate = () => {
            emit("clear-date");
        };

        return {
            formattedValue,
            computedInputClass,
            showCalendar,
            parseTypedDate,
            inputBlurred,
            clearDate,
        }
    },
};
// eslint-disable-next-line
</script>
