<template>
    <div
        aria-live="assertive"
        class="floating-feedback-component sm:p-6"
    >
        <!-- v-click-outside.prevent="onCloseFeedback" -->
        <div class="floating-feedback-container">
            <transition
                v-bind="transitionProps"
            >
                <div
                    v-if="modelValue"
                    class="floating-feedback-card-container"
                >
                    <div class="p-4">
                        <div class="floating-feedback-card-content">
                            <div class="icon-container">
                                <CheckCircleIcon
                                    class="h-6 w-6 text-green-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="feedback-message-container">
                                <p v-if="_.has(feedback, 'title') && feedback.title" class="feedback-title">
                                    {{ feedback.title }}
                                </p>
                                <p v-if="_.has(feedback, 'message') && feedback.message" class="feedback-message">
                                    {{ feedback.message }}
                                </p>
                                <div
                                    v-if="_.has(feedback, 'buttonLabel') && feedback.buttonLabel"
                                    class="feedback-button-label-container"
                                >
                                    <p
                                        class="feedback-button-label"
                                        @click="showAppointmentSlideover"
                                    >
                                        {{ feedback.buttonLabel }}
                                    </p>
                                </div>
                            </div>
                            <div class="feedback-button-container">
                                <button
                                    class="feedback-button"
                                    @click="onCloseFeedback"
                                >
                                    <span class="sr-only">Close</span>
                                    <XIcon class="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { XIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";

export default {
    name: "FloatingFeedbackComponent",
    components: {
        CheckCircleIcon,
        XIcon,
    },
    props: {
        modelValue: { type: Boolean, default: true },
    },
    setup(props) {
        const store = useStore();

        // START OF FEEDBACK CONTENT DATA
        const feedback = computed(() => store.getters['CommonUtilities/feedbackObject'])
        // END OF FEEDBACK CONTENT DATA

        // START OF SHOWING OF FLOATING FEEDBACK
        const show = ref(false);

        watch(
            () => { props.modelValue },
            (newValue) => {
                show.value = newValue
            },
        )
        // END OF SHOWING OF FLOATING FEEDBACK

        // TRANSTION PROPERTY STARTS HERE
        const transitionProps = computed(() => {
            return {
                enterActiveClass: "transform ease-out duration-300 transition",
                enterFromClass: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
                enterToClass: "translate-y-0 opacity-100 sm:translate-x-0",
                leaveActiveClass: "transition ease-in duration-100",
                leaveFromClass: "opacity-100",
                leaveToClass: "opacity-0"
            }
        })
        // TRANSTION PROPERTY ENDS HERE

        // CLOSING OF FEEDBACK STARTS HERE
        const showFeedback = computed(() => store.getters['CommonUtilities/showFeedback'])
        watch(
            () => showFeedback.value,
            (newValue) => {
                if (newValue) {
                    setTimeout(() => onCloseFeedback(), feedback.value.duration)
                }
            },
        )

        const onCloseFeedback = () => {
            store.commit('CommonUtilities/setShowFeedback', false)
        }
        // CLOSING OF FEEDBACK ENDS HERE

        // START OF SHOWING APPOINTMENT SLIDEOVER WHEN CLICKING THE FEEDBACK
        const requestedAppointment = computed(() => store.getters['CommonAppointment/requestedAppointment'])
        const showAppointmentSlideover = () => {
            const appointmentUuid = requestedAppointment.value.uuid
            store.commit('CommonAppointment/setAppointmentSlideover', true)
            store.dispatch('CommonAppointment/getAppointmentDetails', appointmentUuid)
        }
        // END OF SHOWING APPOINTMENT SLIDEOVER WHEN CLICKING THE FEEDBACK

        return {
            show,
            onCloseFeedback,
            showAppointmentSlideover,
            feedback,
            transitionProps
        };
    },
};
</script>
<style lang="scss">
.floating-feedback-component {
    @apply fixed w-full flex items-start px-4 py-6 pointer-events-none;
    bottom: 0;
    left: 0;
    .floating-feedback-container {
        @apply w-full flex flex-col items-start space-y-4;
        .floating-feedback-card-container {
            @apply max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden;
            .floating-feedback-card-content {
                @apply flex items-start;
                .icon-container {
                    @apply flex-shrink-0;
                }
                .feedback-message-container {
                    @apply ml-3 w-0 flex-1 pt-0.5;
                    .feedback-title {
                        @apply text-sm font-medium text-gray-900;
                    }
                    .feedback-message {
                        @apply mt-1 text-sm text-gray-500;
                    }
                    .feedback-button-label-container {
                        @apply mt-3 flex space-x-7;
                        .feedback-button-label {
                            @apply text-primary text-sm font-medium cursor-pointer;
                        }
                    }
                }
                .feedback-button-container {
                    @apply ml-4 flex-shrink-0 flex;
                    .feedback-button {
                        @apply bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
                    }
                }
            }
        }
    }
}
</style>