<template>
    <div :class="['navigation-tabs-component', componentClass]">
        <div class="sm:hidden nav-select">
            <label for="items" class="sr-only">Select a tab</label>
            <select
                id="tabs"
                v-model="selectedTab"
                name="tabs"
                class="
                    select-container
                "
                @change="onClickTab(selectedTab)"
            >
                <option
                    v-for="tab in modelValue"
                    :key="tab.name"
                    :selected="tab.current"
                    :value="tab"
                >
                    {{ tab.name }}
                </option>
            </select>
        </div>
        <div ref="nav" class="hidden sm:block nav-tabs" :class="tabType">
            <div
                class="nav-container"
                :class="[tabContainerClass]"
            >
                <nav
                    :class="[isFullwidth ? 'space-x-0' : '', tabsAlignment]"
                    aria-label="Tabs"
                >
                    <a
                        v-for="(tab, tabIdx) in modelValue"
                        :key="tab.name"
                        :href="tab.href"
                        class="tab-button"
                        :class="[
                            !tabIdx ? 'rounded-l-lg' : '',
                            tabIdx === modelValue.length - 1
                                ? 'rounded-r-lg'
                                : '',
                            tab.current
                                ? `selected`
                                : 'not-selected',
                            { 'inline-block text-center': isFullwidth },
                            width,
                        ]"
                        :aria-current="tab.current ? 'page' : ''"
                        @click.prevent="onClickTab(tab)"
                    >
                        <component
                            :is="tab.icon"
                            v-if="!hideIcon"
                            class="tab-icon"
                            aria-hidden="true"
                        />
                        <span>{{ tab.name }}</span>
                        <span v-if="tab.count" class="badge">{{
                            tab.count
                        }}</span>
                        <span aria-hidden="true" class="inset" />
                    </a>
                </nav>
            </div>
        </div>
        <div class="content">
            <div v-if="contentType === 'component'">
                <div v-for="(tab, index) in modelValue" :key="index">
                    <component
                        :is="tab.component"
                        v-if="tab.current"
                        v-bind="tab.props"
                    />
                </div>
            </div>
            <template v-else>
                <div v-for="(tab, index) in modelValue" :key="index">
                    <p v-if="tab.current && tab.content">
                        {{ tab.content }}
                    </p>
                    <div
                        v-if="tab.current && tab.customContent"
                        v-html="tab.customContent"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
    name: "NavigationTabsComponent",
    props: {
        modelValue: { type: Array, default: () => [] },
        tabType: { type: String, default: "tab-with-underline" }, // tab-with-underline, tab-in-pills, bar-with-underline
        contentType: { type: String, default: "text" }, // text, component
        alignment: { type: String, default: "center" }, // left, center, right
        hideIcon: { type: Boolean, default: true },
        componentClass: { type: String, default: "" },
        tabContainerClass: { type: String, default: "" },
        isFullwidth: Boolean,
        tabWidth: { type: String, default: "" }
    },
    emits: [ "update:modelValue", "click-tab" ],
    setup(props, { emit }) {
        const nav = ref(null);
        const selectedTab = ref(null);

        // COMPUTED START
        const tabsAlignment = computed(() => {
            switch (props.alignment) {
            case "left":
                return "justify-start";
            case "right":
                return "justify-end";
            default:
                return "justify-center";
            }
        })

        const width = computed(() => {
            // this is to make the width dynamic
            return props.isFullwidth
                ? `w-1/${props.modelValue.length}`
                : props.tabWidth;
        })

        // COMPUTED END

        // METHOD START
        const onClickTab = (data) => {
            const lists = _.cloneDeep(props.modelValue);
            _.map(lists, (item) => {
                if (item.name === data.name) {
                    item.current = true;
                    return;
                }
                item.current = false;
            });

            emit("update:modelValue", lists);
            emit("click-tab", _.find(lists, i => i.current).name);
        }
        // METHOD END

        return {
            nav,
            onClickTab,
            selectedTab,
            tabsAlignment,
            width
        };
    }
};
</script>

<style lang="scss">
.navigation-tabs-component {
    .nav-tabs {
        .nav-container {
            nav {
                @apply flex;

                .tab-button {
                    @apply group inline-flex items-center font-medium text-sm cursor-pointer;

                    .tab-icon {
                        @apply -ml-0.5 mr-2 h-5 w-5;
                    }

                    .inset {
                        @apply hidden;
                    }

                    .badge {
                        @apply hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block;
                    }
                }
                // width should be dynamic,
                a.fullWidth {
                    @apply w-1/4 justify-center font-medium text-sm;
                }

                a.not-selected:hover {
                    @apply text-gray-700 border-gray-300;

                    .tab-icon {
                        @apply text-gray-500;
                    }
                }

                .selected {
                    @apply border-primary text-primary-600;

                    .tab-icon {
                        @apply text-primary;
                    }

                    .badge {
                        @apply bg-primary-100 text-primary-600;
                    }
                }

                .not-selected {
                    @apply border-transparent text-gray-500;

                    .tab-icon {
                        @apply text-gray-400;
                    }

                    .badge {
                        @apply bg-gray-100 text-gray-900;
                    }
                }
            }
        }
    }
    .tab-with-underline {
        @apply border-b border-gray-200;
        .nav-container {
            nav {
                @apply -mb-px space-x-8;
                .tab-button {
                    @apply py-4 px-1 border-b-2;
                    border-radius: 0px !important;
                }
            }
        }
    }
    .tab-in-pills {
        .nav-container {
            nav {
                @apply -mb-px space-x-4;
                .tab-button {
                    @apply px-3 py-2 font-medium text-sm rounded-md;
                }
                .selected {
                    @apply bg-primary-100;

                    .badge {
                        @apply bg-green-400  text-white;
                    }
                }
            }
        }
    }
    .bar-with-underline {
        .nav-container {
            nav {
                @apply relative z-0 rounded-lg shadow divide-x divide-gray-200;
                .tab-button {
                    @apply group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium justify-center;
                    .inset {
                        @apply absolute inset-x-0 bottom-0 h-0.5;
                    }
                }
                .selected {
                    @apply text-gray-900;
                    .inset {
                        @apply inline bg-primary;
                    }
                }
                a:hover {
                    @apply bg-gray-50;
                }
                a:focus {
                    @apply z-10;
                }
            }
        }
    }
    .nav-select {
        .select-container {
            @apply block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md;
        }
    }
}
</style>