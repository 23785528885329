<template>
    <div class="tw-block tw-w-full">
        <div v-if="name === 'banner'" id="tfd-placeholder-slider">
            <div>
                <!-- big image -->
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="image"
                    :tile="tile"
                    :height="height"
                    class="mx-auto"
                />
            </div>
            <!-- thumbnail -->
        </div>
        <div v-if="name === 'thread'" id="tfd-placeholder-slider">
            <div v-for="(item, index) in items" :key="index" class="thread">
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="image"
                    :tile="tile"
                    :height="height"
                    class="tw-mb-2 tw-rounded"
                />
            </div>
        </div>
        <div v-if="name === 'thumbnail'" class="mt-6 tw-flex">
            <div
                v-for="(item, index) in items"
                :key="index"
                class="thumbnail-wrapper tw-w-full tw-mr-3"
            >
                <v-skeleton-loader
                    v-if="heading"
                    :boilerplate="boilerplate"
                    type="heading"
                    :tile="tile"
                    class="mb-3"
                />
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="image"
                    :tile="tile"
                    height="100px"
                    class="mx-auto"
                />
                <div v-if="text">
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="chip"
                        :tile="tile"
                        height="10px"
                        class="mx-auto mt-3"
                    />
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="sentences"
                        :tile="tile"
                        class="mx-auto mt-3"
                    />
                </div>
                <div v-if="extraText">
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="sentences"
                        :tile="tile"
                        class="mx-auto mt-3"
                    />
                </div>
            </div>
        </div>
        <!-- list icon -->
        <div v-if="name === 'list-icon'" class="tw-w-full tw-flex">
            <div
                v-for="(itemList, indexList) in items"
                :key="indexList"
                :class="`tw-w-1/${items}`"
            >
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="heading"
                    :tile="tile"
                />
                <v-skeleton-loader
                    v-for="(item, index) in items"
                    :key="index"
                    :boilerplate="boilerplate"
                    type="list-item-avatar-three-line"
                    :tile="tile"
                    :height="height"
                    max-width="300"
                />
            </div>
        </div>
        <!-- for announcement list -->
        <div v-if="name === 'announcement-thread'">
            <div v-for="(item, index) in items" :key="index">
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="list-item-avatar-three-line"
                    :tile="tile"
                    :height="height"
                    class="tw-mb-2"
                />
            </div>
        </div>
        <!-- brand search result -->
        <div v-if="name === 'search-result-1'">
            <div
                v-for="(item, index) in items"
                :key="index"
                class="tfd-search-result-box"
            >
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="heading"
                    :tile="tile"
                    height="18px"
                    max-width="300"
                    class="tw-mb-4"
                />
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="paragraph"
                    :tile="tile"
                    max-width="450"
                />
            </div>
        </div>
        <!-- doctor search result -->
        <div v-if="name === 'search-result-2'">
            <div
                v-for="(item, index) in items"
                :key="index"
                class="tfd-search-result-box tw-flex"
            >
                <div class="mr-4" style="width: 70px">
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="image"
                        :tile="tile"
                        max-height="64px"
                        max-width="70px"
                        class="tw-mb-4"
                    />
                </div>
                <div class="tw-w-full">
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="heading"
                        :tile="tile"
                        height="18px"
                        max-width="300"
                        class="tw-mb-4"
                    />
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="paragraph"
                        :tile="tile"
                        max-width="450"
                    />
                </div>
            </div>
        </div>
        <!-- TAB LIST -->
        <div v-if="name === 'tab-list'" class="tw-flex">
            <div
                v-for="(item, index) in items"
                :key="index"
                :class="`tw-w-1/${items} tw-px-4`"
            >
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="text"
                    :tile="tile"
                    height="18px"
                    max-width="300"
                    class="tw-mb-4"
                />
                <v-skeleton-loader
                    v-for="(listItem, listIndex) in items * 5"
                    :key="listIndex"
                    :boilerplate="boilerplate"
                    type="sentences"
                    class="tw-mt-2"
                    :tile="tile"
                    max-width="450"
                />
            </div>
        </div>

        <div v-if="name === 'three-line-paragraph'">
            <div v-for="(item, index) in items" :key="index">
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="list-item-three-line"
                    :class="customClass"
                    :tile="tile"
                    :height="height"
                    :elevation="elevation"
                />
            </div>
        </div>
        <div v-if="name === 'two-line-paragraph'">
            <div v-for="(item, index) in items" :key="index">
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="list-item-two-line"
                    :class="customClass"
                    :tile="tile"
                    :height="height"
                    :elevation="elevation"
                    :max-width="maxWidth"
                />
            </div>
        </div>

        <div v-if="name === 'one-line-paragraph'">
            <div v-for="(item, index) in items" :key="index">
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="text"
                    :class="customClass"
                    :tile="tile"
                    :height="height"
                    :elevation="elevation"
                    :max-width="maxWidth"
                />
            </div>
        </div>
        <div v-if="name === 'avatar-text'">
            <div v-for="(item, index) in items" :key="index">
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="list-item-avatar-three-line"
                    :class="customClass"
                    :tile="tile"
                    :height="height"
                />
            </div>
        </div>
        <div v-if="name === 'chip'">
            <div v-for="(item, index) in items" :key="index">
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    type="chip"
                    class="tw-mb-2"
                    :tile="tile"
                    :height="height"
                    :elevation="elevation"
                />
            </div>
        </div>

        <!-- APPOINTMENT CARD -->
        <div v-if="name === 'card-1'" :class="wrapperClass">
            <div
                v-for="(item, index) in items"
                :key="index"
                :class="customClass"
                class="card-1-placeholder"
            >
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="heading"
                        :tile="tile"
                        class="card-1-heading"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="list-item-avatar-three-line"
                        class="card-1-avatar-three-line"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="list-item-avatar"
                        :tile="tile"
                        class="card-1-avatar-one-line"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="list-item-avatar"
                        :tile="tile"
                        class="card-1-avatar-one-line"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="button"
                        :tile="tile"
                        class="card-1-button"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="button"
                        :tile="tile"
                        class="card-1-button"
                    />
                </div>
            </div>
        </div>

        <!-- ORDER HISTORY -->
        <div v-if="name === 'card-2'" :class="wrapperClass">
            <div
                v-for="(item, index) in items"
                :key="index"
                class="class-2-placeholder"
            >
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="text, text"
                        :tile="tile"
                        class="card-2-inline-text"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="heading"
                        class="card-2-header"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="text, text"
                        :tile="tile"
                        class="card-2-inline-text"
                    />
                </div>
            </div>
        </div>

        <!-- CONSULTATION HISTORY -->
        <div v-if="name === 'card-3'" :class="wrapperClass">
            <div
                v-for="(item, index) in items"
                :key="index"
                class="card-3-placeholder"
            >
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="heading"
                        :tile="tile"
                        class="card-3-heading"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="list-item-avatar-three-line"
                        class="card-3-avatar-three-line"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="list-item-avatar"
                        :tile="tile"
                        class="card-3-avatar-one-line"
                    />
                </div>
                <div>
                    <v-skeleton-loader
                        :boilerplate="boilerplate"
                        type="list-item-avatar"
                        :tile="tile"
                        class="card-3-avatar-one-line"
                    />
                </div>
            </div>
        </div>

        <!-- CUSTOMIZED PLACEHOLDER -->
        <div v-if="name === 'custom'" :class="containerClass">
            <div v-for="(item, index) in items" :key="index" :class="inLine ? `tw-w-1/${items}`: ''">
                <v-skeleton-loader
                    :boilerplate="boilerplate"
                    :type="customType"
                    :class="customClass"
                    :tile="tile"
                    :height="height"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        boilerplate: { type: Boolean, default: false },
        tile: { type: Boolean, default: true },
        name: { type: String, default: null },
        items: { type: [ Number, String ], default: 1 },
        heading: { type: Boolean, default: true },
        extraText: { type: Boolean, default: false },
        text: { type: Boolean, default: true },
        height: { type: [ String, Number ], default: 'auto' },
        maxWidth: { type: [ String, Number ], default: null },
        elevation: { type: [ String, Number ], default: null },
        customClass: { type: String, default: 'tw-mb-2' },
        wrapperClass: { type: String, default: '' },
        minHeight: { type: String, default: '' },
        maxHeight: { type: String, default: '' },
        customType: { type: String, default: '' },
        containerClass: { type: String, default: '' },
        inLine: { type: Boolean, default: false },
    }
}
</script>