<template>
    <div class="doctors-tab-component">
        <template v-if="!isLoading">
            <div v-if="!_.isEmpty(doctors)" class="divide-y divide-gray-200">
                <div v-for="(doctor, index) in doctors" :key="index" class="doctor-list">
                    <profile-info-card-component
                        :profile="doctor"
                        inline
                        :is-card="false"
                        class="cursor-pointer"
                    />
                </div>
            </div>
            <empty-state-component
                v-else
                :icon="calendarIcon"
                title="No doctors to show"
                class="mt-6"
            />
        </template>
        <spinner-component v-else class="center mt-16" :size="40" />
    </div>
</template>

<script>
import { ref } from 'vue';
import {
    ProfileInfoCardComponent,
} from '@CommonComponents';
import { EmptyStateComponent } from "@MedrepComponents";
import { CalendarIcon } from '@heroicons/vue/outline'

export default {
    name: "DoctorsTabComponent",
    components: {
        ProfileInfoCardComponent,
        EmptyStateComponent,
    },
    props: {
        doctors: { type: Array, default: () => {} },
        isLoading: { type: Boolean, default: false },
    },
    setup() {
        // // EMPTY STATE
        const calendarIcon = ref(CalendarIcon);
        return {
            calendarIcon,
        }
    }

}
</script>

<style>

</style>