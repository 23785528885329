<template>
    <div class="appointments-tab-component">
        <template v-if="!isLoading">
            <div v-if="!_.isEmpty(filteredAppointments)" class="divide-y divide-gray-200">
                <div v-for="(appointment, index) in filteredAppointments" :key="index" class="appointment-list">
                    <appointment-settings-card-component
                        v-bind="appointmentSettingsProps(appointment)"
                        @click="showAppointmentSlideover(appointment)"
                    />
                </div>
            </div>
            <empty-state-component
                v-else
                :icon="calendarIcon"
                :title="$userType === AccountType.Medrep ? 'No scheduled appointments' : 'No appointment history'"
                class="mt-6"
            />
        </template>
        <spinner-component v-else class="center mt-16" :size="40" />
    </div>
</template>

<script>
import { computed, ref } from 'vue'
import { AppointmentSettingsCardComponent } from "@CommonComponents";
import { CalendarIcon } from '@heroicons/vue/outline'
import { EmptyStateComponent } from "@MedrepComponents";
import { useStore } from 'vuex'
import { AccountType } from "@Utilities/enum";

export default {
    name: 'AppointmentsTabComponent',
    components: {
        AppointmentSettingsCardComponent,
        EmptyStateComponent
    },
    props: {
        appointments: { type: Array, default: () => {} },
        isLoading: { type: Boolean, default: false },
    },
    setup(props) {
        const store = useStore();

        // EMPTY STATE
        const calendarIcon = ref(CalendarIcon)

        // START OF SHOWING APPOINTMENT SLIDEOVER
        const showAppointmentSlideover = async (appointment) => {
            if (appointment.status === "accepted" || appointment.status === "pending" || appointment.status === "served") {
                store.commit('CommonAppointment/setAppointmentSlideover', true);
                store.dispatch('CommonAppointment/getAppointmentDetails', appointment.uuid);
            }
        }
        // END OF SHOWING APPOINTMENT SLIDEOVER

        // START OF FILTERING APPOINTMENTS, DISPLAY ONLY ACCEPTED, PENDING AND DONE APPOINTMENTS
        const filteredAppointments = computed(() => {
            return _.filter(props.appointments, i => {
                return i.status === 'accepted' || i.status === 'pending' || i.status === 'served'
            })
        })
        // END OF FILTERING APPOINTMENTS, DISPLAY ONLY ACCEPTED, PENDING AND DONE APPOINTMENTS

        const appointmentSettingsProps = (appointment) => {
            return {
                settings: 'date',
                appointment: {
                    ...appointment,
                    credit: appointment.credit_use
                },
                isCard: false
            }
        }

        //-------------------METHODS ENDs HERE------------------

        return {
            calendarIcon,
            showAppointmentSlideover,
            appointmentSettingsProps,
            AccountType,
            filteredAppointments,
        }
    }
}
</script>

<style lang="scss">
.appointments-tab-component {
    .appointment-settings-card-component {
        .appointment-settings {
            &__date {
                &--container {
                    @apply flex px-4 py-3 cursor-pointer;
                }
            }
        }
        &.served-appointment {
            .appointment-settings {
                &__date {
                    &--container {
                        @apply flex #{!important};
                    }
                }
            }
        }
    }
}
</style>