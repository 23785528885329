<template>
    <div class="call_icons" :class="color">
        <div v-if="type === 'microphone'" class="microphone_type px-2">
            <span @click="$emit('microphone')">
                <MicrophoneIcon v-if="on" class="setting_icon" />

                <IconMicOff v-if="!on" class="off_icon" />
            </span>

            <span v-if="label" class="sub__message pl-2">
                {{ label }}
            </span>
        </div>
        <div v-if="type === 'camera'" class="camera_type">
            <span @click="$emit('camera')">
                <VideoCameraIcon v-if="on" class="setting_icon" />

                <IconCameraOff v-if="!on" class="off_icon" />
            </span>

            <span v-if="label" class="sub__message">
                {{ label }}
            </span>
        </div>
    </div>
</template>

<script>
import { MicrophoneIcon, VideoCameraIcon } from "@heroicons/vue/solid";
import IconMicOff from "@Icons/ico-mic-off";
import IconCameraOff from "@Icons/ico-camera-off";
export default {
    name: "MicCameraIcon",
    components: {
        MicrophoneIcon,
        IconMicOff,
        VideoCameraIcon,
        IconCameraOff,
    },
    props: {
        label: { type: String, default: null },
        type: { type: String, default: null },
        color: { type: String, default: "text-gray-500" },
        on: Boolean,
    },
    emits: [ "microphone", "camera" ],
};
</script>

<style lang="scss">
.call_icons {
    .setting_icon {
        @apply h-5 w-5 inline-block;
    }
    .off_icon {
        @apply mx-auto inline-block fill-current text-red-500;
        line,
        path {
            @apply fill-current;
        }

        line {
            @apply stroke-current;
        }
    }
}
</style>