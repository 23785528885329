<template>
    <div class="doctor-list-component">
        <list-component
            v-bind="listProps"
        >
            <template v-slot:card="{data}">
                <profile-info-card-component
                    v-bind="profileInfoCardProps(data)"
                    @click="onClickDoctorCard(data)"
                />
            </template>
            <template v-slot:footer>
                <button-component
                    v-if="showMoreDoctorsButton"
                    v-bind="buttonProps"
                    @on-click="onClickMoreDoctor()"
                />
            </template>
        </list-component>
    </div>
</template>

<script>
import ListComponent from "../common/list-component.vue";
import ProfileInfoCardComponent from '../common/cards/profile-info-card-component.vue'
import { computed } from "vue";

export default {
    name: 'DoctorListComponent',
    components: {
        ListComponent,
        ProfileInfoCardComponent
    },
    props: {
        items: { type: Array, default: () => []  },
        scrollable: Boolean,
        loadMoreFooterClass: { type: String, default: '' },
        listMaxHeight: { type: String, default: '' },
        showMoreDoctorsButton: Boolean,
    },
    emits: [ "on-click-doctor-card", "on-click-more-doctor" ],
    setup(props, { emit }) {

        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const listProps = computed(() => {
            return {
                items: props.items,
                scrollable: props.scrollable,
                listMaxHeight: props.listMaxHeight,
                isCard: false,
                loadMoreFooterClass: props.loadMoreFooterClass
            }
        })

        const buttonProps = computed(() => {
            return {
                type: "white-btn",
                label: "More doctors",
                block: true,
                class: "h-full"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const profileInfoCardProps = (data) => {
            return {
                profile: data,
                inline: true,
                imgClass: "h-10 w-10",
                isCard: false
            }
        }

        const onClickDoctorCard = ((data) => {
            emit('on-click-doctor-card', data)
        })

        const onClickMoreDoctor = (() => {
            emit('on-click-more-doctor')
        })

        //-------------------METHODS ENDs HERE------------------

        return {
            onClickDoctorCard,
            onClickMoreDoctor,
            listProps,
            profileInfoCardProps,
            buttonProps
        }

    }
}
</script>

<style lang="scss">

</style>