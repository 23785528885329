<template>
    <div class="side-navbar-component">
        <div class="side-navbar-container">
            <div class="side-navbar-content">
                <div class="logo-container">
                    <img src="@Images/ppd-pharma-logo.png" alt="PPD Clinic Pharma" @click="redirectToDashboard" />
                </div>
                <nav class="side-navbar" aria-label="Sidebar">
                    <a
                        v-for="item in sidebarItems"
                        :key="item.name"
                        :href="item.href"
                        :class="[
                            { 'active': item.current },
                            { 'disabled': item.disable },
                            'navbtn',
                        ]"
                        @click="
                            onNavigateDashboard(
                                item.type,
                                item.current,
                                item.disable
                            )
                        "
                    >
                        <component
                            :is="item.current ? item.icon : item.iconOutLine"
                            class="nav-icon"
                            aria-hidden="true"
                        />
                        <span class="flex-1">
                            {{ item.name }}
                        </span>

                        <span v-if="item.disable" class="soon-markings">Soon</span>
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { MedrepNavItems, AdminNavItems, Tokens } from '@Utilities/enum'
import { computed } from 'vue'
import router from "@/router";
import { useStore } from "vuex";
import { STORAGE } from "@Plugins/storage.js";
import { ref } from "vue";

export default {
    name: "",
    props: {
        moduleName: { type: String, default: null },
    },
    emits: [ "page" ],
    setup(props, { emit }) {
        const store = useStore()
        const usertype = ref(STORAGE.GET_COOKIES(Tokens.Personal).user_info.user_type);

        //-----------------------COMPUTED-----------------------

        const pageName = computed(() => {
            return store.getters['CommonUtilities/pageName']
        })

        const NavItems = computed(() => {
            return usertype.value === "district_manager" ? AdminNavItems : MedrepNavItems
        })

        const sidebarItems = computed(() => {
            let items = []
            items = _.map(_.cloneDeep(NavItems.value), (item) => {
                item.current = pageName.value === item.type
                let pageTitle
                if (pageName.value === 'team') {
                    pageTitle = "Your Team"
                } else {
                    pageTitle = _.upperFirst(item.type)
                }
                if (item.current) {
                    emit("page", pageTitle);
                }

                return item;
            });

            if (pageName.value === 'profile') {
                emit("page", "Profile information");
            }
            return items;
        });

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onNavigateDashboard = (type, current, disable) => {
            if (!current && !disable) {
                router.push({ path: `/${props.moduleName}/${type}` })
                emit("page", _.upperFirst(type));
            }
        };

        const redirectToDashboard = () => {
            router.push({ path: `/${props.moduleName}/dashboard` })
        }

        //-------------------METHODS ENDs HERE------------------

        return {
            MedrepNavItems,
            onNavigateDashboard,
            sidebarItems,
            usertype,
            AdminNavItems,
            NavItems,
            redirectToDashboard,
        };
    },
};
</script>

<style lang="scss">
.side-navbar-component {
    @apply h-screen flex bg-gray-100;

    .side-navbar-container {
        @apply flex-1 flex flex-col min-h-0 bg-gray-800;

        .side-navbar-content {
            @apply flex-1 flex flex-col pb-4 overflow-y-auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                width: 0; /* Remove scrollbar space */
                height: 0;
                background: transparent; /* Optional: just make scrollbar invisible */
            }

            .side-navbar {
                @apply mt-4 flex-1 px-2 bg-gray-800 space-y-1;

                .navbtn {
                    @apply relative group flex flex-col justify-center items-center py-3.5 text-sm font-normal rounded-md text-gray-300 cursor-pointer hover:bg-gray-700;
                    width: 96px;
                }

                .active {
                    @apply bg-gray-900 text-white hover:bg-gray-900;
                    .nav-icon {
                        @apply text-gray-300;
                    }
                }
                .disabled {
                    @apply bg-gray-800 cursor-not-allowed hover:bg-transparent hover:text-gray-300;
                }

                .nav-icon {
                    @apply flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-300;
                    margin-bottom: 4px;
                }

                .soon-markings {
                    @apply absolute text-indigo-800 px-2 rounded-full bg-white font-bold border-4 border-gray-800;
                    top: 5px;
                    right: 0;
                    font-size: 10px;
                }
            }
            .logo-container {
                @apply flex items-center flex-shrink-0 cursor-pointer;

                img {
                    @apply mx-auto mt-6;
                }
            }
        }
    }
}
</style>