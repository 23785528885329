<template>
    <div class="onboarding-modal-component">
        <slot-modal-component
            v-model="showModal"
            v-bind="slotModalProps"
        >
            <div class="carousel-component-container">
                <div class="carousel-component-content">
                    <carousel-component
                        :items="items"
                        @on-moved="onChangeSlide($event)"
                        @on-move="slideIndex=$event.index"
                        @on-mounted="onChangeSlide($event)"
                    >
                        <div class="body-content">
                            <div class="body-details-container">
                                <p class="body-details-title">
                                    {{ instructionList[slideIndex].header }}
                                </p>
                                <p class="body-details-description" v-html="instructionList[slideIndex].instruction" />
                            </div>
                        </div>
                        <div class="splide__arrows">
                            <button-component
                                v-bind="prevButtonProps"
                            />
                            <button-component
                                v-bind="nextButtonProps"
                                @on-click="onClickDone"
                            />
                        </div>
                    </carousel-component>
                    <OutlineXIcon
                        aria-hidden="true"
                        class="close-icon"
                        @click="onHideModal()"
                    />
                </div>
            </div>
        </slot-modal-component>
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import SlotModalComponent from "../modal/slot-modal-component.vue";
import CarouselComponent from "@CommonComponents/carousel-component.vue";
import { defineComponent } from 'vue';
import { XIcon as OutlineXIcon } from "@heroicons/vue/outline";
import '@splidejs/splide/dist/css/splide.min.css';

export default defineComponent({
    name: "OnboardingModalComponent",
    components: {
        SlotModalComponent,
        CarouselComponent,
        OutlineXIcon
    },
    props: {
        modelValue: Boolean,
    },
    emits: [ "update:modelValue" ],
    setup(props, { emit }) {
        // START OF SHOW ONBOARDING MODAL
        const showModal = ref(false);
        const slides = ref(7);

        const isFirstSlide = ref(true);
        const isLastSlide = ref(false);

        const slideIndex = ref(0);

        const items = ref([
            `${require('@Images/onboarding-images/onboarding-image1.png')}`,
            `${require('@Images/onboarding-images/onboarding-image2.png')}`,
            `${require('@Images/onboarding-images/onboarding-image3.png')}`,
            `${require('@Images/onboarding-images/onboarding-image4.png')}`,
            `${require('@Images/onboarding-images/onboarding-image5.png')}`,
            `${require('@Images/onboarding-images/onboarding-image6.png')}`,
            `${require('@Images/onboarding-images/onboarding-image7.png')}`,
        ]);

        const instructionList = ref([
            {
                header: 'How to set up an appointment',
                instruction: 'Requesting an appointment is relatively easy. Just follow the steps.'
            },
            {
                header: 'Go to doctor’s tab',
                instruction: 'Click <strong>Doctors</strong> on the left side menu.'
            },
            {
                header: 'Find a doctor',
                instruction: 'Look for doctors by using either the <strong>Suggested</strong> or <strong>Search tool</strong>. Use the filter options to narrow your search results.'
            },
            {
                header: 'Review doctor profile',
                instruction: `Check the doctor's information and tap <strong>Request an Appointment.</strong>`
            },
            {
                header: 'Enter appointment details',
                instruction: 'Enter details like <strong>Date, Time, Brands to promote, and Duration.</strong>'
            },
            {
                header: 'Review',
                instruction: 'Review your request and Submit.'
            },
            {
                header: 'Wait for the doctor’s response',
                instruction: 'Your doctor will respond within 48 hours. Click the Schedule icon on the left side menu to view the status of your appointments. '
            }
        ])

        watch(
            () => props.modelValue,
            (newVal) => {
                showModal.value = newVal;
            }
        );

        //-----------------------COMPUTED-----------------------

        const slotModalProps = computed(() => {
            return {
                height: 0,
                scrollable: false,
                isSemiTransparent: true,
                footerClass: "mt-0",
                distinctClass: "onboarding-modal pt-0"
            }
        })

        const prevButtonProps = computed(() => {
            return {
                type: "white-btn",
                label: "Previous",
                class: `splide__arrow splide__arrow--prev ${isFirstSlide.value ? 'invisible' : ''}`
            }
        })

        const nextButtonProps = computed(() => {
            return {
                id: "next-button",
                type: "primary-btn",
                label: isLastSlide.value ? 'Finish' : 'Next',
                class: "splide__arrow splide__arrow--next"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onShowModal = () => {
            showModal.value = true;
        };
        const onHideModal = () => {
            showModal.value = false;
            emit("update:modelValue", showModal.value);
        };

        const onChangeSlide = (event) => {
            if (event.index === 0) {
                isFirstSlide.value = true
            } else {
                isFirstSlide.value = false
            }
            if (event.length === (event.index + 1)) {
                isLastSlide.value = true
            } else {
                isLastSlide.value = false
            }
            document.getElementById('next-button').disabled = false
        };

        const onMove = (event) => {
            if (event.index === 0) {
                isFirstSlide.value = true
            } else {
                isFirstSlide.value = false
            }
            if (event.length === (event.index + 1)) {
                isLastSlide.value = true
            } else {
                isLastSlide.value = false
            }
            document.getElementById('next-button').disabled = false
        };

        const onClickDone = () => {
            if (isLastSlide.value) {
                onHideModal()
            }
        }

        //-------------------METHODS ENDs HERE------------------

        return {
            onShowModal,
            showModal,
            onHideModal,
            slides,
            items,
            isFirstSlide,
            isLastSlide,
            onChangeSlide,
            onClickDone,
            onMove,
            instructionList,
            slideIndex,
            slotModalProps,
            prevButtonProps,
            nextButtonProps
        };
    },
});
</script>

<style lang="scss">
.onboarding-modal {
    min-width: 512px;
    .carousel-component {
        .carousel-container {
            .splide {
                @apply pb-4;
            }
            .splide__track {
                @apply bg-gray-100;
                height: 280px;
                .splide__list {
                    @apply flex items-center;
                    li {
                        @apply flex justify-center;
                    }
                }
            }
            .body-content {
                @apply text-left m-6 mb-10;
                min-height: 120px;
                .body-details-container {
                    .body-details-title {
                        @apply text-gray-700 text-base font-medium mb-3;
                    }
                    .body-details-description {
                        @apply text-gray-500 text-sm font-normal;
                    }
                }
            }
            .splide__arrows {
                @apply relative bottom-0 flex justify-between mx-3;
                .splide__arrow {
                    @apply w-auto h-auto opacity-100 #{!important};
                }
            }
            .splide__arrow.splide__arrow--prev {
                @apply border border-gray-300 border-solid;
            }
        }
    }

    .carousel-component-container {
        .carousel-component-content {
            .close-icon {
                @apply  absolute top-0 right-0 mr-3 mt-3 text-white bg-gray-300 h-6 w-6 rounded-md cursor-pointer;
            }
        }
    }
}
</style>
