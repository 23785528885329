<template>
    <div class="profile-page-component">
        <div class="profile-page-container dashboard-grid">
            <div class="personal-details-and-credit-history-container">
                <div class="personal-details-container">
                    <personal-details-component />
                </div>
                <div class="credit-balance-details-container">
                    <div class="credit-balance-details-card">
                        <div class="credit-balance-info-container">
                            <p class="header-title"> Credit Balance </p>
                            <p class="credit-balance"> {{ userCredits.credits }} </p>
                        </div>
                    </div>
                </div>
                <div class="credit-history-details-container">
                    <div class="credit-history-details-header-container">
                        <p class="header-title"> Credit History </p>
                        <div class="credit-status-container">
                            <popover-component
                                v-bind="popoverProps"
                            >
                                <popover-status-list-card-component />
                            </popover-component>
                        </div>
                    </div>
                    <div class="credit-history-details-body-container">
                        <!-- <div class="filter-container">
                        </div> -->
                        <div ref="listHeight" class="credit-history-list-container">
                            <div class="credit-history-list-content">
                                <div v-if="!isLoadingCreditHistoryList">
                                    <list-component
                                        v-if="!_.isEmpty(creditHistory)"
                                        v-bind="creditHistoryListProps"
                                        @on-load-more="onLoadMore"
                                    >
                                        <template v-slot:card="{ data }">
                                            <credit-to-use-card-component
                                                v-bind="creditToUseCardProps(data)"
                                            />
                                        </template>
                                    </list-component>
                                    <empty-state-component
                                        v-else
                                        v-bind="creditHistoryEmptyStateProps"
                                        class="mt-16"
                                    />
                                </div>
                                <spinner-component v-else class="center mt-32" :size="40" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="brand-container">
                <div class="brand-list-container">
                    <brands-covered-list-card-component
                        :items="brandsCoveredList"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import { ClockIcon as OutlineClockIcon } from "@heroicons/vue/outline";
import { ref, onMounted, computed, onUpdated } from "vue";
import { useStore } from "vuex";
import PopoverComponent from '@CommonComponents/cards/popover-card-component.vue'
import ListComponent from "@CommonComponents/list-component.vue";
import CreditToUseCardComponent from '@CommonComponents/cards/card-body/credit-to-use-card-component.vue'
import PersonalDetailsComponent from './components/personal-details-component.vue'
import PopoverStatusListCardComponent from './components/popover-status-list-card-component.vue'
import BrandsCoveredListCardComponent from './components/brands-covered-list-card-component.vue'
import { EmptyStateComponent } from '@MedrepComponents'

export default {
    name: "ProfilePageComponent",
    components: {
        PopoverComponent,
        ListComponent,
        CreditToUseCardComponent,
        PersonalDetailsComponent,
        PopoverStatusListCardComponent,
        BrandsCoveredListCardComponent,
        EmptyStateComponent
    },
    props: {
        pageName: { type: String, default: '' }
    },
    emits: [],
    setup(props) {
        const store = useStore()
        const questionMarkCircleIcon = ref(QuestionMarkCircleIcon)
        const creditStatus = ref("all")
        const outlineClockIcon = ref(OutlineClockIcon);
        let loadMoreLoading = ref(false);
        const page = ref(1)
        const creditHistoryEmptyStateProps = ref({
            icon: outlineClockIcon,
            title: "No credit history",
            showPopOver: false,
        })

        //-----------------------COMPUTED-----------------------

        const creditHistory = computed(() => {
            return store.getters['CommonCredit/creditHistory']
        })

        const userCredits = computed(() => {
            return store.getters['CommonCredit/userCredits']
        })

        const brandsCoveredList = computed(() => {
            return store.getters['CommonBrand/brandsCoveredList']
        })

        const isLoadingCreditHistoryList = computed(() => {
            return store.getters['CommonCredit/setIsLoading'] && page.value === 1;
        })

        const creditHistoryListProps = computed(() => {
            return {
                items: creditHistory.value,
                scrollable: true,
                isCard: true,
                loadMoreFooterClass: "bg-transparent",
                listMaxHeight: 300,
                showLoadMore: showLoadMore.value,
                loadMoreLabel: "More credit history",
                hasInfiniteLoading: true,
                loadMoreLoading: loadMoreLoading.value,
                showEndNote: true
            }
        })

        const popoverProps = computed(() => {
            return {
                buttonType: "white-icon-btn",
                popOverBtnIconColor: "text-gray-500",
                buttonIcon: questionMarkCircleIcon.value,
                slotClass: "px-0 py-0",
                sideLabel: "What are credit statuses?",
                sideLabelClass: "text-sm font-normal text-gray-500"
            }
        })

        // SHOW MORE HISTORY BUTTON
        const meta = computed(() => store.getters['CommonCredit/creditHistoryMeta'])
        const showLoadMore = computed(() => {
            return meta.value.current_page !== meta.value.last_page
        });

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const creditToUseCardProps = (data) => {
            return {
                item: data,
                isAppointmentCard: false,
                isForCreditHistory: true,
                reason: data.reason,
                dateLastUpdate: data.date_last_update
            }
        }

        const getCreditHistory = async () => {
            let payload = {
                page: page.value,
                status: creditStatus.value
            }
            await store.dispatch('CommonCredit/getMedrepHistory', payload)
        }

        const onLoadMore = async () => {
            if (showLoadMore.value) {
                loadMoreLoading.value = true;
                page.value++;
                await getCreditHistory(creditStatus.value);
                loadMoreLoading.value = false;
            }
        }

        //-------------------METHODS ENDs HERE------------------

        onMounted(async() => {
            await getCreditHistory()
            await store.dispatch("CommonCredit/getUserCredits");
            await store.dispatch("CommonBrand/getBrandsCoveredList");
            store.commit('CommonUtilities/setPageName', props.pageName)
        });

        const viewportHeight = ref(window.innerHeight);
        const creditHistoryTotalHeight = ref(0)
        const listHeight = ref(0)
        // COMPONENT HEIGHT COMPUTATION
        onUpdated(() => {
            const paddingTop = listHeight.value.getBoundingClientRect().top + 4;
            const paddingBottom = 64;

            creditHistoryTotalHeight.value =
                viewportHeight.value -
                paddingTop -
                paddingBottom
        })

        return {
            creditHistory,
            questionMarkCircleIcon,
            creditStatus,
            userCredits,
            brandsCoveredList,
            creditHistoryListProps,
            creditToUseCardProps,
            popoverProps,
            creditHistoryEmptyStateProps,
            onLoadMore,
            loadMoreLoading,
            page,
            isLoadingCreditHistoryList,
            listHeight,
            showLoadMore
        }
    }
}
</script>

<style lang="scss">
.profile-page-component {
    .profile-page-container {
        .personal-details-and-credit-history-container {
            @apply col-span-2 space-y-6;
            .credit-balance-details-container {
                .credit-balance-details-card {
                    @apply shadow rounded-md w-auto bg-white;
                    .credit-balance-info-container {
                        @apply flex justify-between py-4 px-6 items-center;
                        .credit-balance {
                            @apply text-4xl font-medium text-gray-500;
                        }
                    }
                }
            }
            .credit-history-details-container {
                .credit-history-details-header-container {
                    @apply  py-4 flex justify-between items-center;
                    .credit-status-container {
                        @apply flex items-center;
                    }
                }
            }
            .credit-history-details-body-container {
                @apply mb-4;
            }
            .header-title {
                @apply text-lg font-medium text-gray-900;
            }
            .list-component {
                .spinner-component {
                    &.load-more-loading {
                        @apply h-auto mt-2;
                    }
                }
            }
        }
    }
}
</style>