<template>
    <div class="credits-tab-component">
        <template v-if="!isLoading">
            <div class="credits-tab-component__header">
                <p class="label">Credit balance</p>
                <div class="credit-value__container ">
                    <h4>{{ creditBalance }}</h4>
                    <button-component
                        type="primary-icon-btn"
                        size="s"
                        @on-click="onShowCreditsModal"
                    >
                        <PlusIcon aria-hidden="true" />
                    </button-component>
                </div>
                <h5>Credit history</h5>
            </div>
            <div v-if="!_.isEmpty(creditHistory)" class="divide-y divide-gray-200">
                <div v-for="(credit, index) in creditHistory" :key="index" class="credits-list">
                    <credit-to-use-card-component
                        v-bind="creditToUseCardProps(credit)"
                    />
                </div>
            </div>
            <empty-state-component
                v-else
                :icon="calendarIcon"
                title="No credit history to show"
                class="mt-6"
            />
        </template>
        <spinner-component v-else class="center mt-16" :size="40" />
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import {
    CreditToUseCardComponent,
} from '@CommonComponents';
import { EmptyStateComponent } from "@MedrepComponents";
import { CalendarIcon, PlusIcon  } from '@heroicons/vue/outline'

export default {
    name: "CreditsTabComponent",
    components: {
        CreditToUseCardComponent,
        EmptyStateComponent,
        PlusIcon,
    },
    props: {
        creditBalance: { type: [ String, Number ], default: null },
        creditHistory: { type: Array, default: () => {} },
        isLoading: Boolean,
        teamMember: { type: Array, default: () => {} },
    },
    setup(props) {
        const store = useStore();

        // EMPTY STATE
        const calendarIcon = ref(CalendarIcon);

        const creditToUseCardProps = (data) => {
            return {
                item: data,
                isAppointmentCard: true,
                isForCreditHistory: true,
                showStatus: false,
                dateLastUpdate: data.date
            }
        }

        // SHOW CREDIT REPLENISH MODAL
        const onShowCreditsModal = () => {
            store.commit('AdminTeam/setShowReplenishModal', true);
            store.commit('AdminTeam/setMembersToReplenishList', props.teamMember);
        }

        return {
            calendarIcon,
            creditToUseCardProps,
            onShowCreditsModal,
        }
    }

}
</script>

<style lang="scss">
.credits-tab-component {
    &__header {
        @apply p-4 pb-0;
        .label {
            @apply text-base leading-6 font-medium text-gray-500 mb-2;
        }
        .credit-value__container {
            @apply flex items-center space-x-3 mb-6;
            h4 {
                @apply text-4xl leading-10 font-medium text-gray-700;
            }
        }
        h5 {
            @apply text-sm leading-5 font-medium text-gray-700 mb-2;
        }
    }
    .credits-list {
        @apply py-2 px-4;
        .credit-to-use-card-component {
            .card-with-shadow {
                &.default {
                    min-width: 0;
                }
            }
            .last-update-value {
                @apply text-primary-400;
            }
        }
    }
}
</style>