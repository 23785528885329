<template>
    <div class="announcement-card-component">
        <div
            :class="[
                'announcement-card-container',
                isCard ? 'card-with-shadow' : 'card-no-shadow bg-transparent',
            ]"
        >
            <div class="announcement-card-content">
                <div class="announcement-card-header">
                    <badge-component :label="data.type" :type="'pending'" />
                    <p class="text-sm font-normal">
                        {{ data.date }}
                    </p>
                </div>
                <div class="announcement-card-body">
                    <p class="topic-label">
                        {{ data.topic }}
                    </p>
                    <p class="click-to-view-label">
                        Click to view details
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BadgeComponent from "../badge-component.vue";

export default {
    name: "AnnouncementCardComponent",
    components: {
        BadgeComponent,
    },
    props: {
        data: { type: Object, default: null },
        isCard: Boolean,
    },
};
</script>

<style lang="scss">
.announcement-card-component {
    .announcement-card-container {
        .announcement-card-content {
            @apply space-y-3 px-6 py-4 w-full;
            .announcement-card-header {
                @apply flex justify-between;
            }
            .announcement-card-body {
                .topic-label {
                    @apply text-base font-medium text-gray-700;
                }
                .click-to-view-label {
                    @apply text-sm font-normal text-gray-500;
                }
            }
        }
    }
}
</style>