<template>
    <div class="global-footer text-center">
        <p>Copyright PPD Clinic Pharma {{ currentYear }}. All rights reserved.</p>
    </div>
</template>

<script>
import { computed } from "vue";
export default {
    name: "FooterComponent",
    setup() {
        const currentYear = computed(() => {
            return new Date().getFullYear();
        });

        return {
            currentYear,
        };
    },
};
</script>

<style lang="scss">
.global-footer {
    p {
        @apply text-xs text-gray-500 leading-none;
    }
}
</style>