<template>
    <div class="end-appointment-modal-component">
        <slot-modal-component
            v-model="showModal"
            :height="0"
            :scrollable="false"
            content-class="end-appointment"
            footer-class="mt-0 pharma-flex-end"
            distinct-class="end-appointment-modal"
            body-class="mb-6 mt-0"
            @close="resetModal()"
            @input="resetModal()"
        >
            <template v-slot:body>
                <div class="py-4">
                    <div class="pharma-flex-between">
                        <h3 class="message">End current appointment?</h3>
                        <div
                            class="
                                icon icon__sm
                                rounded
                                bg-gray-300
                                cursor-pointer
                            "
                            @click="resetModal()"
                        >
                            <XIcon aria-hidden="true" class="text-white" />
                        </div>
                    </div>
                    <p class="sub__message">
                        Are you sure you want to end the appointment? You cannot re-enter once you end the call.
                    </p>
                </div>
            </template>

            <template v-slot:footer>
                <div class="text-right mb-6">
                    <button-component
                        type="white-btn"
                        label="Cancel"
                        class="mr-2"
                        @click="resetModal"
                    />

                    <button-component
                        type="danger-btn"
                        label="End Appointment"
                        @click="onProceed"
                    />
                </div>
            </template>
        </slot-modal-component>
    </div>
</template>

<script>
import { SlotModalComponent } from "@CommonComponents";
import { toRef } from "vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
    name: "EndAppointmentModal",
    components: {
        SlotModalComponent,
        XIcon,
    },
    props: {
        modelValue: Boolean,
        customConfiguration: { type: Object, default: null },
    },
    emits: [ "update:modelValue", "proceed" ],
    setup(props, { emit }) {
        const showModal = toRef(props, "modelValue");

        const onChangeSettings = () => {
            resetModal();
        };

        const onProceed = () => {
            emit("proceed");
            resetModal();
        };

        const resetModal = () => {
            emit("update:modelValue", false);
        };

        const onApplySettings = () => {
            resetModal();
        };
        return {
            showModal,
            onChangeSettings,
            resetModal,
            onApplySettings,
            onProceed,
            // onShowModal,
        };
    },
};
</script>

<style lang="scss">
.end-appointment-modal {
    min-height: 180px;
    @apply py-0 #{!important};

    .end-appointment {
        min-height: 50%;
    }
    .footer-container {
        @apply h-1/2;
    }
    .end-appointment {
        @apply bg-red-50;
    }
}
</style>