<template>
    <div class="guide-card-component">
        <div class="guide-card-container">
            <div class="guide-card-content">
                <image-card-component
                    :img-src="
                        require('@Images/dashboard-images/guide-card.png')
                    "
                    :have-shadow="false"
                />
                <div class="guide-card-header-container">
                    <div class="guide-card-header-content">
                        <p class="header-title">Explore PPD Clinic Pharma</p>
                        <p class="header-subtitle">View walkthrough guide</p>
                    </div>
                    <div class="w-full">
                        <button-component
                            label="Check Guide"
                            custom-class="bg-indigo-700"
                            @on-click="onShowModal"
                        />
                    </div>
                </div>
            </div>
        </div>
        <onboarding-modal-component v-model="showModal" />
    </div>
</template>

<script>
import { ref } from "vue";
import ImageCardComponent from "./image-card-component.vue";
import OnboardingModalComponent from "@CommonComponents/modal/onboarding-modal-component.vue";

export default {
    name: 'GuideCardComponent',
    components: {
        ImageCardComponent,
        OnboardingModalComponent
    },
    props: {
    },
    setup() {
        const showModal = ref(false);
        const onShowModal = () => (showModal.value = true);

        return {
            showModal,
            onShowModal
        }
    }
}
</script>

<style lang="scss">
.guide-card-component {
    .guide-card-container {
        .guide-card-content {
            @apply relative;
            .guide-card-header-container {
                @apply absolute inset-y-0 right-0 mr-6 space-y-3 w-40 flex flex-col justify-center;
                .guide-card-header-content {
                    @apply space-y-2 ;
                    .header-title {
                        @apply text-2xl font-medium text-indigo-700;
                    }
                    .header-subtitle {
                        @apply text-sm font-normal leading-5 text-gray-500;
                    }
                }
            }
        }
    }
}
</style>