import { ref, reactive, onMounted, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";
const userType = STORAGE.GET_COOKIES(Tokens.UserType);
import {
    PlusIcon,
    UsersIcon,
    CheckIcon,
    ClockIcon,
    SearchIcon,
    CalendarIcon,
} from '@heroicons/vue/outline';

export default function () {
    const store = useStore();
    const router = useRouter();

    // START OF FETCH APPOINTMENT LIST
    const status = ref("");
    const page = ref(1);
    const isLoading = computed(() => {
        return store.getters['CommonAppointment/listLoading'] && page.value === 1;
    });
    // let scroll = 0;
    let loadMoreLoading = ref(false);
    const loadMoreAppoinments = async () => {
        if (showLoadMore.value) {
            loadMoreLoading.value = true;
            page.value++
            await getAppointmentList(status.value)
            loadMoreLoading.value = false;
            // if (userType === AccountType.Medrep) {
            //     scroll += 790
            // } else {
            //     scroll += 2050
            // }

            // TO SCROLL ON TOP OF NEW RESULT FOR LOAD OF EVERY PAGE
            // if (page.value !== 1) {
            //     const body = document.querySelector(".load-more-body");
            //     body.scrollTop = scroll;
            // }
        }
    };
    const getAppointmentList = async (value) => {
        status.value = value
        const payload = {
            status: status.value,
            page: page.value,
        }
        await store.dispatch('CommonAppointment/getAppointmentList', payload)
    }
    onBeforeUnmount(() => {
        // TO RESET TO EMPTY ALL APPOINTMENT LIST
        page.value = 1
        store.commit('CommonAppointment/resetAppointmentList', [])
    });
    // END OF FETCH APPOINTMENT LIST

    // SCROLL AREA HEIGHT COMPUTATION
    const viewportHeight = ref(window.innerHeight);
    const header = ref("");
    const totalHeight = ref(0);
    const container = ref("");
    const actionBtnHeight = ref(0);  // refactor
    onMounted(() => {
        const paddingTop = container.value.getBoundingClientRect().top;
        const paddingBottom = 24 + 28;
        const headerHeight = userType === 'medrep' ? 32 : 0;
        // const paddingBottom =
        //     viewportHeight.value -
        //     container.value.getBoundingClientRect().bottom

        totalHeight.value =
            viewportHeight.value -
            headerHeight -
            paddingTop -
            paddingBottom -
            actionBtnHeight.value

        // console.warn('total', totalHeight.value, 'vh', viewportHeight.value, 'header', headerHeight, 'pt', paddingTop, 'pb', paddingBottom, 'ab', actionBtnHeight.value);
    })
    // END OF SCROLL HEIGHT COMPUTATION

    // START OF SETTING ACTIVE CARD
    const current= ref(0);
    // END OF SETTING ACTIVE CARD

    // START OF SHOW MORE DOCTOR BUTTON
    const meta = computed(() => store.getters['CommonAppointment/appointmentMeta']);
    const showLoadMore = computed(() => {
        return meta.value.current_page !== meta.value.last_page
    });
    // END OF SHOW MORE DOCTOR BUTTON

    // START OF SHOWING AND HIDING SLIDEOVER
    const slideover = computed(
        () => store.getters["CommonAppointment/appointmentSlideover"]
    );
    const showSlideover = (uuid) => {
        current.value = uuid
        store.commit('CommonAppointment/setAppointmentSlideover', true)
    };
    const hideSlideover = () => {
        if (slideover.value && !_.startsWith(router.options.history.state.back, '/video/room/')) {
            store.commit('CommonAppointment/setAppointmentSlideover', false)
        }
    };
    // END OF SHOWING AND HIDING SLIDEOVER

    // START OF EMPTY STATE
    const calendarIcon = ref(CalendarIcon);
    const plusIcon = ref(PlusIcon);
    const schedule = ref('/medrep/schedule');
    const dashboard = ref('/medrep/doctors');
    const emptyMenuList = ref([
        { icon: UsersIcon, name: 'Suggested', link: `${dashboard.value}/suggested` },
        { icon: CheckIcon, name: 'My Doctors', link: `${dashboard.value}/mydoctors` },
        { icon: ClockIcon, name: 'Done appointments', link: `${schedule.value}/done` },
        { icon: SearchIcon, name: 'Find a Doctor', link: `${dashboard.value}/search` },
    ]);

    const emptyStateProps = reactive({
        icon: calendarIcon,
        showPopOver: userType === 'medrep',
        lists: emptyMenuList,
        buttonLabel: 'New Appointment',
        buttonIcon: plusIcon,
    });
    // END OF EMPTY STATE

    return {
        getAppointmentList,
        loadMoreAppoinments,
        isLoading,
        loadMoreLoading,
        current,
        showLoadMore,

        header,
        totalHeight,
        container,
        actionBtnHeight,

        showSlideover,
        hideSlideover,

        emptyStateProps,
    }
}