<template>
    <div class="dashboard-page-component">
        <div class="dashboard-page-container">
            <div class="appointment-and-center-card-container">
                <div class="appointment-container">
                    <div class="appointment-content">
                        <div class="appointment-header">
                            <div class="header-message-container">
                                <p class="header-message">Appointments</p>
                                <p class="header-sub-message">
                                    Check the status of your appointment requests in here.
                                </p>
                            </div>
                            <popover-card-component
                                v-if="!_.isEmpty(appointmentList)"
                                button-type="white-btn"
                                v-bind="appointmentNewButtonProps"
                                @onClickList="onClickList($event)"
                            />
                        </div>
                        <div>
                            <navigation-tabs-component
                                v-model="tablists"
                                v-bind="navigationTabProperties"
                                @update:modelValue="onChangeTab($event)"
                            />
                        </div>
                        <!-- v-click-outside.prevent="hideSlideover" -->
                        <!-- v-click-outside.prevent="hideAppointmentSlideover" -->
                        <div>
                            <empty-state-component
                                v-if="_.isEmpty(appointmentList) && !isLoadingAppointment"
                                v-bind="appointmentEmptyStateProps"
                                class="mt-16"
                            />
                            <appointment-list-component
                                v-else
                                v-bind="appointmentListProperties"
                                @on-click-appointment-card="
                                    onClickAppointmentCard($event)
                                "
                                @on-click-view-schedule="onClickList({link: 'schedule'})"
                            />
                        </div>
                    </div>
                </div>
                <div class="remarks-feedback-and-announcement-container">
                    <div class="remarks-feedback-and-announcement-content">
                        <div v-if="!_.isEmpty(forRemarksAppointmentList) || isRemarksListLoading" class="remarks-container">
                            <for-remarks-list-component
                                :img-src="
                                    require('@Images/dashboard-images/remarks-card.png')
                                "
                                :items="forRemarksAppointmentList"
                                load-more-footer-class="hidden"
                                :is-loading="isRemarksListLoading"
                                :number-of-loading-cards="2"
                                @on-click-appointment-card="
                                    onClickAppointmentCard($event)
                                "
                            />
                        </div>
                        <div class="credit-balance-details-container">
                            <credit-balance-card-component @on-click-check-history="onClickCheckHistory" />
                        </div>
                        <div class="find-doctors-container">
                            <find-doctors-card-component />
                        </div>
                        <div class="feedback-container">
                            <feedback-card-component />
                        </div>
                        <div class="faqs-container">
                            <faqs-card-component />
                        </div>
                        <div class="guide-container">
                            <guide-card-component />
                        </div>
                        <!-- <div class="announcement-container">
                            <announcement-list-component
                                :items="announcement"
                                load-more-footer-class="hidden"
                            />
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="doctor-list-container">
                <div class="doctor-list-content h-full">
                    <div class="doctor-list-header">
                        <div class="header-message-container">
                            <p class="header-message">
                                Start sending requests to these doctors
                            </p>
                            <p class="header-sub-message">
                                Here is a list of doctors according to your preferences.
                            </p>
                        </div>
                        <!-- HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX -->
                        <!-- <button-component
                            v-if="!_.isEmpty(doctorList)"
                            v-bind="showPreferenceButtonProps"
                            @click="onShowPreference()"
                        >
                            <template v-slot:prepend>
                                <AdjustmentsIcon aria-hidden="true" />
                            </template>
                        </button-component> -->
                    </div>
                    <doctor-list-component
                        v-if="!_.isEmpty(getDoctorList)"
                        v-click-outside.prevent="hideProfileSlideover"
                        v-bind="doctorListProps"
                        @on-click-doctor-card="onClickDoctorCard($event)"
                        @on-click-more-doctor="onClickMoreDoctor()"
                    />
                    <empty-state-component
                        v-else
                        v-bind="doctorEmptyStateProps"
                        class="mt-16"
                    />
                </div>
            </div>
        </div>
        <appointment-slideover-component v-model="showAppointmentSlideover" />
        <profile-slideover-component
            v-model="showProfileSlideover"
            :doctor-id="currentDoctor"
            @onClickCloseBtn="hideProfileSlideover"
        />
    </div>
</template>

<script>
// import ImageCardComponent from "./components/image-card-component.vue";
import FeedbackCardComponent from "./components/feedback-card-component.vue";
import FindDoctorsCardComponent from "./components/find-doctors-card-component.vue";
import FaqsCardComponent from "./components/faqs-card-component.vue";
import GuideCardComponent from "./components/guide-card-component.vue";
import CreditBalanceCardComponent from "@CommonComponents/cards/credit-balance-card-component";
// import AnnouncementListComponent from "./components/announcement-list-component.vue";
import ForRemarksListComponent from "./components/for-remarks-list-component.vue";
// HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX
// import { AdjustmentsIcon } from "@heroicons/vue/solid";
import {
    PlusIcon as OutlinePlusIcon,
    UsersIcon as OutlineUsersIcon,
    SearchIcon as OutlineSearchIcon,
    CalendarIcon as OutlineCalendarIcon,
    CheckIcon as OutlineCheckIcon,
    ClockIcon as OutlineClockIcon
} from "@heroicons/vue/outline";
import DoctorListComponent from "@CommonComponents/doctor-list-component.vue"
import AppointmentListComponent from "@CommonComponents/appointment-list-component.vue"
import NavigationTabsComponent from "@CommonComponents/navigation-tabs-component.vue";
import moment from "moment";
import { ref, shallowRef, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { EmptyStateComponent } from '@MedrepComponents'

import {
    ProfileSlideoverComponent,
    AppointmentSlideoverComponent,
} from "@CommonComponents/slideovers";
import PopoverCardComponent from '@CommonComponents/cards/popover-card-component.vue'
import { useRouter } from "vue-router";

export default {
    name: "DashboardPageComponent",
    components: {
        NavigationTabsComponent,
        // HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX
        // AdjustmentsIcon,
        AppointmentSlideoverComponent,
        // ImageCardComponent,
        // AnnouncementListComponent,
        ProfileSlideoverComponent,
        AppointmentListComponent,
        ForRemarksListComponent,
        DoctorListComponent,
        EmptyStateComponent,
        PopoverCardComponent,
        FeedbackCardComponent,
        FindDoctorsCardComponent,
        FaqsCardComponent,
        GuideCardComponent,
        CreditBalanceCardComponent
    },
    props: {
        pageName: { type: String, default: '' }
    },
    setup(props) {
        const store = useStore()
        const router = useRouter();
        const showModal = ref(false);

        // SET ACTIVE CARD
        const currentAppointment = ref(0);
        const currentDoctor = ref(0);

        //------------------COMPUTED-------------------

        // GETTERS

        // LIST
        const appointmentList = computed(
            () => store.getters["CommonAppointment/appointmentList"]
        );
        const forRemarksAppointmentList = computed(
            () => store.getters["CommonAppointment/forRemarksAppointmentList"]
        );
        const getDoctorList= computed(() => {
            return store.getters['CommonDoctor/suggestedDoctorList']
        })

        // SLIDEOVER
        const showAppointmentSlideover = computed(() => store.getters['CommonAppointment/appointmentSlideover']
        )
        const showProfileSlideover = computed(() => store.getters['CommonDoctor/profileSlideover']
        )

        const isLoadingAppointment = computed(() => {
            return store.getters['CommonAppointment/listLoading']
        })

        const isRemarksListLoading = computed(() => {
            return store.getters['CommonAppointment/forRemarksListLoading']
        })

        // FOR EMPTY STATE PROPERTIES
        const appointmentEmptyStateTitle = computed(() => {
            return (_.find(tablists.value, { current: true })).name === "Upcoming" ? "No upcoming appointments" : "No pending appointments"
        });

        // APPOINTMENT PROPERTIES
        const appointmentListProperties = computed(() => {
            return {
                items: appointmentList.value,
                appointmentCardProperties: appointmentCardProperties.value,
                scrollable: false,
                showViewScheduleButton: true,
                loadMoreFooterClass: "bg-transparent border-0 pt-3"
            }
        });

        const appointmentCardProperties = computed(() => {
            return {
                showNote: false,
                showMedrepName: false,
                showCreditToUse: false,
                showInvited: false,
                isLoading: isLoadingAppointment.value,
                numberOfLoadingCards: 4
            }
        });

        // TAB PROPERTIES
        const navigationTabProperties = computed(() => {
            return {
                ref: "navTabs",
                contentType: "component",
                tabType: "tab-in-pills",
                alignment: "left",
                componentClass: "my-4"
            }
        });

        const showPreferenceButtonProps = computed(() => {
            return {
                type: "white-btn",
                label: "Preferences",
                prepend: true,
                block: true,
                class: "h-full"
            }
        })

        const doctorListProps = computed(() => {
            return {
                items: getDoctorList.value,
                listMaxHeight: "calc(100vh - 286px);",
                scrollable: true,
                showMoreDoctorsButton: !_.isEmpty(getDoctorList.value),
                loadMoreFooterClass: "px-5 pb-6"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        // EMPTY STATE DATA
        const outlineCalendarIcon = ref(OutlineCalendarIcon)
        const outlinePlusIcon = ref(OutlinePlusIcon)
        const dashboard = ref('/medrep/doctors')
        const schedule = ref('/medrep/schedule')
        const menuList = ref([
            { icon: OutlineUsersIcon, name: 'Suggested', link: `${dashboard.value}/suggested` },
            { icon: OutlineCheckIcon, name: 'My Doctors', link: `${dashboard.value}/mydoctors` },
            { icon: OutlineClockIcon, name: 'Done appointments', link: `${schedule.value}/done` },
            { icon: OutlineSearchIcon, name: 'Find a Doctor', link: `${dashboard.value}/search` },
        ])

        const appointmentEmptyStateProps = ref({
            icon: outlineCalendarIcon,
            title: appointmentEmptyStateTitle,
            showPopOver: true,
            lists: menuList,
            buttonLabel: 'New Appointment',
            buttonIcon: outlinePlusIcon,
        })

        const doctorEmptyStateProps = ref({
            icon: OutlineUsersIcon,
            title: "No suggested doctors to show",
            showPopOver: false,
            // HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX
            // buttonLabel: 'Preferences',
            // buttonIcon: AdjustmentsIcon,
        })
        // END OF EMPTY STATE

        // TO RESET TO EMPTY ALL APPOINTMENT LIST WHEN UNMOUNTED
        onUnmounted(() => {
            store.commit('CommonAppointment/resetAppointmentList', [])
        });

        const appointmentNewButtonProps = ref({
            icon: outlineCalendarIcon,
            showPopOver: true,
            lists: menuList,
            buttonLabel: 'New',
            buttonIcon: outlinePlusIcon,
            popOverBtnIconColor: 'text-gray-500',
            popoverPanelClass: 'w-56',
            isPanelCenter: false,
            buttonCustomClass: 'h-full'
        })

        //------------------METHODS------------------

        const formatDate = (date) => {
            return moment(date).format("ddd, DD MMM YYYY");
        };

        // START OF SHOW preferences MODAL
        const onShowPreference = () => {
            store.commit('MedrepPreferences/setShowPreference', { action: "update", show: true })
        }
        const onShowPreferencesModal = () => {
            showModal.value = true
            store.commit('MedrepPreferences/setShowPreference', { action: "update", show: false })
        };

        // APPOINTMENT METHODS
        const onClickAppointmentCard = (event) => {
            store.commit('CommonAppointment/setAppointmentSlideover', true)
            store.dispatch('CommonAppointment/getAppointmentDetails', event.uuid)
        }

        // DOCTOR LIST METHOD
        const onClickDoctorCard = (event) => {
            currentDoctor.value = event.id
            store.commit('CommonDoctor/setProfileSlideover', true)
            store.commit('CommonDoctor/setDoctorProfile', event)
        }

        const onClickMoreDoctor = async() => {
            router.push({ path: '/medrep/doctors/search' })
        }

        // SLIDEOVER METHODS
        const hideAppointmentSlideover = () => {
            store.commit('CommonAppointment/setAppointmentSlideover', false)
        }
        const hideProfileSlideover = () => {
            store.commit('CommonDoctor/setProfileSlideover', false)
        }

        // TAB METHODS
        const selectedTab = ref('')
        const onChangeTab = async(event) => {
            if (event) {
                tablists.value = event;
            }
            selectedTab.value = await _.find(tablists.value, { current: true });
            await store.dispatch('CommonAppointment/getAppointmentList', { status: selectedTab.value.name === 'Upcoming' ? 'accepted' : 'pending', limit: 4 })
        };

        const onClickList = (list) => {
            if (list.link === 'schedule') {
                router.push({ path: `${list.link}/${_.toLower(selectedTab.value.name)}` });
            } else {
                router.push({ path: list.link });
            }
        };

        const onClickCheckHistory = () => {
            router.push({ path: '/medrep/profile' })
        }

        //------------------METHODS ENDs HERE------------------

        onMounted(async () => {
            store.commit("CommonUtilities/setPageName", props.pageName);
            await onChangeTab()
            await store.dispatch('CommonAppointment/getAppointmentList', { status: 'served', limit: 9999 })
            await store.dispatch('CommonDoctor/getDoctorList', { option: 'suggested' })
        });

        const announcement = ref([
            {
                type: "Announcement",
                topic: "What’s new on PPD Clinic Pharma v1.1",
                date: formatDate(new Date()),
            },
            {
                type: "Announcement",
                topic: "What’s new on PPD Clinic Pharma v1.2",
                date: formatDate(new Date()),
            },
        ]);

        const tablists = shallowRef([
            { name: "Upcoming", current: true },
            { name: "Pending", current: false },
        ]);

        return {
            tablists,
            onClickAppointmentCard,
            announcement,
            hideAppointmentSlideover,
            hideProfileSlideover,
            currentAppointment,
            currentDoctor,
            showAppointmentSlideover,
            onChangeTab,
            appointmentList,
            showProfileSlideover,
            onClickDoctorCard,
            appointmentEmptyStateProps,
            doctorEmptyStateProps,
            appointmentListProperties,
            appointmentCardProperties,
            navigationTabProperties,
            isLoadingAppointment,
            forRemarksAppointmentList,
            appointmentNewButtonProps,
            onClickList,
            onShowPreferencesModal,
            showModal,
            onShowPreference,
            onClickMoreDoctor,
            getDoctorList,
            showPreferenceButtonProps,
            doctorListProps,
            onClickCheckHistory,
            isRemarksListLoading
        }
    }

}
</script>

<style lang="scss">
.dashboard-page-component {
    @apply pl-5 pr-0;
    .dashboard-page-container {
        @apply flex px-0 py-0 gap-6;
        width: 69.7%;
        .appointment-and-center-card-container {
            @apply flex col-span-2 gap-6 my-6 w-full;
        }
        .appointment-container {
            @apply w-1/2;
            // width: 100%
            // min-width: 395px;
            .appointment-header {
                @apply flex justify-between;
            }
        }
        .doctor-list-container {
            @apply flex justify-end relative;
            .doctor-list-content {
                @apply bg-white fixed border-l-2 border-gray-200 right-0;
                width: 28%;
                .doctor-list-header {
                    @apply px-5 pt-6 space-y-4 mb-6;
                }
                .doctor-list-component {
                    .list-container {
                        .load-more-body {
                            ul {
                                li {
                                    &:first-of-type {
                                        @apply border-t border-gray-200;
                                    }
                                }
                            }
                        }
                        .load-more-footer {
                            .action-btn {
                                @apply py-3;
                            }
                        }
                    }
                }
            }
        }
        .remarks-feedback-and-announcement-container {
            @apply w-1/2;
            // width: 100%;
            .remarks-feedback-and-announcement-content {
                @apply space-y-3;
            }
        }
    }
}
.header-message-container {
    @apply space-y-1;
    .header-message {
        @apply text-lg font-medium text-gray-900;
    }
    .header-sub-message {
        @apply text-sm font-normal text-gray-500;
    }
}
</style>