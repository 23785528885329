<template>
    <svg
        class="pharma__icon"
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.70538 14.5454C7.09466 14.9347 7.72569 14.935 8.11538 14.5462L11.6468 11.0224C11.842 10.8276 12.158 10.8277 12.3532 11.0224L15.8846 14.5462C16.2743 14.935 16.9053 14.9347 17.2946 14.5454C17.6842 14.1558 17.6842 13.5242 17.2946 13.1346L12.7071 8.54711C12.3166 8.15658 11.6834 8.15658 11.2929 8.54711L6.70538 13.1346C6.31581 13.5242 6.31581 14.1558 6.70538 14.5454Z"
            fill="#596E8A"
        />
    </svg>
</template>

<script>
export default {
    props: {
        height: { type: String, default: "20" },
        width: { type: String, default: "20" },
    },
};
</script>