<template>
    <div class="popover-component">
        <Popover v-slot="{ open }" class="relative">
            <PopoverButton
                :class="[open ? '' : 'text-opacity-90', 'popover-component-button-container']"
            >
                <button-component
                    :type="buttonType"
                    :size="buttonSize"
                    :label="buttonLabel"
                >
                    <template v-slot:prepend>
                        <component
                            :is="buttonIcon"
                            :class="popOverBtnIconColor"
                            aria-hidden="true"
                        />
                    </template>
                </button-component>
            </PopoverButton>

            <transition
                v-bind="transitionProps"
            >
                <PopoverPanel v-if="lists" class="popover-component-card-panel">
                    <div class="popover-component-card-panel-container">
                        <div class="popover-component-card-panel-content">
                            <div
                                v-for="(list, index) in lists"
                                :key="index"
                                class="popover-component-card-panel-list"
                                @click="onClickList(list)"
                            >
                                <component
                                    :is="list.icon"
                                    aria-hidden="true"
                                    class="list-icon"
                                />
                                <p class="list-label">
                                    {{ list.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </PopoverPanel>
            </transition>
        </Popover>
    </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { computed } from "vue";

export default {
    name: "PopoverComponent",
    components: {
        Popover,
        PopoverButton,
        PopoverPanel,
    },
    props: {
        lists: { type: Array, default: () => {} },
        popOverBtnIconColor: { type: String, default: "text-gray-500" },
        buttonLabel: { type: String, default: "" },
        buttonType: { type: String, default: "white-btn" },
        buttonIcon: { type: Function, default: () => {} },
        buttonSize: { type: String, default: "" },
    },
    emits: [ "on-click-list" ],
    setup(props, { emit }) {

        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const transitionProps = computed(() => {
            return {
                enterActiveClass: "transition duration-200 ease-out",
                enterFromClass: "translate-y-1 opacity-0",
                enterToClass: "translate-y-0 opacity-100",
                leaveActiveClass: "transition duration-150 ease-in",
                leaveFromClass: "translate-y-0 opacity-100",
                leaveToClass: "translate-y-1 opacity-0"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onClickList = (list) => {
            emit("on-click-list", list);
        };

        //-------------------METHODS ENDs HERE------------------

        return {
            onClickList,
            transitionProps
        };
    },
};
</script>
<style lang="scss">
.popover-component {

}
.popover-component-button-container {
    @apply inline-flex items-center text-base mb-2 font-medium text-white bg-transparent rounded-md group hover:text-opacity-100 focus:outline-none;
}
.popover-component-card-panel {
    @apply absolute z-10 right-0 transform w-56;
    .popover-component-card-panel-container {
        @apply  overflow-hidden ring-1 ring-black ring-opacity-5 shadow-lg rounded-lg shadow-lg;
        .popover-component-card-panel-content {
            @apply relative bg-white divide-y divide-gray-200;
            .popover-component-card-panel-list {
                @apply px-4 py-3 flex items-start hover:bg-gray-50 cursor-pointer;
                .list-icon {
                    @apply text-gray-400 h-5 w-5;
                }
                .list-label {
                    @apply text-sm font-normal text-gray-700 ml-2;
                }
            }
        }
    }
}
</style>
