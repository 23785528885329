import { ref, computed, reactive, watch } from 'vue'
import { useStore } from "vuex";
import moment from "moment";
import useNavtab from "@GlobalFunctions/useNavtab.js"
import router from "@/router";
import { useRoute } from "vue-router";

export default function () {
    const store = useStore();
    const route = useRoute();
    const date = ref({
        dateTo: null,
        dateFrom: null,
    })

    // START OF NAVIGATION TAB
    const {
        creditHistoryTablists,
    } = useNavtab();

    // START OF GETTING TEAM MEMBER LIST FOR CREDITS ALLOCATION AND MEDREP DROPDOWN FILTER
    const getTeamMembers = async () => {
        const payload = {
            data: {
                limit: 1000,
            },
            type: 'credits-allocation'
        }
        await store.dispatch('AdminTeam/getTeamMembers', payload)
    }
    const filteredMedrep = computed(() => {
        return store.getters['AdminTeam/filteredMember']
    })
    const filteredMedrepLoading = computed(() => {
        return store.getters['AdminTeam/isLoading']
    })
    // END OF GETTING TEAM MEMBER LIST FOR CREDITS ALLOCATION AND MEDREP DROPDOWN FILTER

    // START OF GETTING TEAM MEMBERS FOR CREDIT ALLOCATION
    const membersToReplenishList = computed(() => {
        return store.getters['AdminTeam/membersToReplenishList']
    })
    const onShowCreditsModal = async () => {
        store.commit('AdminTeam/setShowReplenishModal', true)
        if (_.isEmpty(membersToReplenishList.value) || membersToReplenishList.value.length <= 1) {
            getTeamMembers();
        }
    }
    // END OF GETTING TEAM MEMBERS FOR CREDIT ALLOCATION

    //------- START OF GETTING CREDIT HISTORY BASED ON SELECTED FILTER --------

    const selectedTab = ref(_.head(creditHistoryTablists.value))
    const filterValue = ref(route.query.id || "all")
    const page = ref(1)

    const isShowAll = computed(() => selectedTab.value.name === 'All' || filterValue.value === 'all')

    // RESET DATA TO INITIAL STATE
    const resetAdminHistory =  () => {
        // THIS IS TO RESET THE DATA SINCE THEY ARE JUST BEING PUSH TO PREVIOUS COLLECTION OF ADMIN HISTORY
        store.commit('CommonCredit/resetAdminCreditHistory')
    }
    const initFilter = async (item) => {
        if (item) {
            date.value.dateTo = null
            date.value.dateFrom = null
            filterValue.value = "all"
            store.commit("CommonCredit/setFilteredDate", {})
        }
        page.value = 1
        resetAdminHistory();
    }

    // GET ADMIN CREDIT HISTORY
    const creditHistory = computed(() => {
        return store.getters['CommonCredit/adminCreditHistory']
    })
    const isLoadingCreditHistoryList = computed(() => {
        return store.getters['CommonCredit/creditHistoryLoading'] && page.value === 1;
    })
    const getAdminCreditHistory = async (payload) => {
        let item = {
            data: {
                page: page.value,
            },
            userType: selectedTab.value.userType
        }
        if (isShowAll.value) {
            item.data.status = "all"
        }
        if (_.has(payload, 'data')) {
            if (_.has(payload.data, 'status')) {
                item.data.status = payload.data.status
            }
            if (_.has(payload.data, 'member_id')) {
                item.data.member_id = payload.data.member_id
            }
            if (_.has(payload.data, 'to') && payload.data.to) {
                item.data.to = payload.data.to
            }
            if (_.has(payload.data, 'from') && payload.data.from) {
                item.data.from = payload.data.from
            }
        }
        await store.dispatch('CommonCredit/getAdminCreditHistory', item)
    }

    // FILTER BUTTON SETTINGS
    const showResetFilterButton = computed(() => {
        return date.value.dateTo || date.value.dateFrom
    })
    const disableFilterButton = computed(() => {
        return !date.value.dateTo || !date.value.dateFrom
    })
    const filterVisibility = ref(false)
    // THIS IS TO SET THE VISIBILITY OF WHOLE FILTER AFTER ACTIVITY SUCH AS LOAD MORE, DATE FILTER ETC
    const setShowFilter = (val) => {
        filterVisibility.value = val
    }
    const showFilter = computed(() => {
        return !_.isEmpty(creditHistory.value) || (date.value.dateTo || date.value.dateFrom) || filterVisibility.value
    })

    // CHANGING TAB
    const onChangeTab = async(event) => {
        store.commit("CommonCredit/setFilteredDate", {})
        selectedTab.value = await _.find(event, { current: true });
        router.push({ path: `/admin/credits/${selectedTab.value.tab}` });
    };

    // FILTERING DATE RANGE
    watch(date.value, (newVal) => {
        if (date.value.dateFrom && date.value.dateTo) {
            store.commit("CommonCredit/setFilteredDate", newVal)
        }
    })
    const filteredDate = computed(() => {
        return store.getters['CommonCredit/filteredDate']
    })

    const onClickFilter = async() => {
        resetAdminHistory();
        if (!date.value.dateTo || !date.value.dateFrom) return
        page.value = 1;
        setShowFilter(true);
        const payload = {
            data: {
                to: moment(date.value.dateTo).format("YYYY/MM/DD"),
                from: moment(date.value.dateFrom).format("YYYY/MM/DD"),
            },
        }
        if (isShowAll.value) {
            payload.data.status = filterValue.value
        } else {
            payload.data.status = "all"
            payload.data.member_id = filterValue.value
        }
        await getAdminCreditHistory(payload);
    };
    const onClickResetFilter = async() => {
        await initFilter(true);
        await getAdminCreditHistory();
    }

    // FILTERING ACCORDING TO CREDIT STATUS
    const filterStatus = ref([
        { label: 'All credit statuses', value: 'all' },
        { label: 'On Hold', value: 'on_hold' },
        { label: 'Refunded', value: 'refunded' },
        { label: 'Deducted', value: 'deducted' },
        { label: 'Replenished', value: 'replenished' },
    ])
    const filteStatusProps = reactive(
        {
            id: "filter-status",
            mode: "single",
            options: filterStatus,
            canDeselect: false,
        }
    )

    // FILTERING ACCORDING TO MEDREP FULL NAME
    const filterMedrepProps = reactive(
        {
            id: "filter-medrep",
            mode: "single",
            options: filteredMedrep,
            label: "full_name",
            valueProp: "id",
            trackBy: "id",
            placeholder: "Select medrep",
            loading: filteredMedrepLoading,
            canDeselect: false,
        }
    )

    watch(
        () => filterValue.value,
        (newVal) => {
            resetAdminHistory();
            setShowFilter(true);
            page.value = 1;
            let payload = {
                data: {
                    status: newVal
                }
            }
            switch (selectedTab.value.name) {
            case 'Medrep':
                if (newVal === 'all') {
                    // SELECTING 'All Med Rep' FROM MEDREP TAB
                    router.push({ path: '/admin/credits/med-rep' })
                } else {
                    // SELECTING ANY MEDREP NAME FROM MEDREP TAB
                    router.push({ path: '/admin/credits/med-rep', query: { id: newVal } })
                }
                break;
            default:
                // All
                if (!_.isNull(date.value.dateTo) && !_.isNull(date.value.dateFrom)) {
                    payload.data.to = moment(date.value.dateTo).format("YYYY/MM/DD");
                    payload.data.from = moment(date.value.dateFrom).format("YYYY/MM/DD");
                }
                getAdminCreditHistory(payload);
                break;
            }
        }
    )

    //------- END OF GETTING CREDIT HISTORY BASED ON SELECTED FILTER --------

    //------- START OF LOAD MORE HISTORY --------

    // SHOW MORE HISTORY BUTTON
    const meta = computed(() => store.getters['CommonCredit/adminCreditHistoryMeta']);
    const showLoadMore = computed(() => {
        return meta.value.current_page !== meta.value.last_page
    });

    // LOAD MORE HISTORY
    let loadMoreLoading = ref(false);
    const onLoadMore = async () => {
        if (showLoadMore.value) {
            loadMoreLoading.value = true;
            page.value++;
            setShowFilter(true);
            let payload
            if (isShowAll.value) {
                payload = {
                    data: {
                        status: filterValue.value || 'all'
                    }
                }
            } else {
                payload = {
                    data: {
                        member_id: filterValue.value,
                        status: 'all'
                    }
                }
            }
            if (!_.isNull(date.value.dateTo) && !_.isNull(date.value.dateFrom)) {
                payload.data.to = moment(date.value.dateTo).format("YYYY/MM/DD");
                payload.data.from = moment(date.value.dateFrom).format("YYYY/MM/DD");
            }
            await getAdminCreditHistory(payload);
            loadMoreLoading.value = false;
        }
    }

    const onChangeRoute = async(isFirstLoad) => {
        store.commit('CommonCredit/setCreditHistoryLoading', true); // TO FIX THE LOADING ON INITIAL CHANGING OF TAB
        await initFilter();
        setShowFilter(false);
        const tabList = _.cloneDeep(creditHistoryTablists.value)
        creditHistoryTablists.value = await _.map(tabList, (tabData) => {
            if (route.params.tabName === tabData.tab) {
                tabData.current = true
                return
            }
            tabData.current = false
        })
        creditHistoryTablists.value = tabList;
        selectedTab.value = await _.find(creditHistoryTablists.value, { current: true });
        date.value.dateTo = filteredDate.value.dateTo;
        date.value.dateFrom = filteredDate.value.dateFrom;
        if (selectedTab.value.name === 'Medrep' && (_.isEmpty(filteredMedrep.value) || filteredMedrep.value.length <= 6)) {
            // TO LOAD MEMBER LIST FOR MEDREP FILTER
            await getTeamMembers();
        }
        if (isFirstLoad && selectedTab.value.name === 'Medrep' && !_.isEmpty(route.query.id)) {
            store.commit('CommonCredit/setCreditHistoryLoading', false);
            const payload = {
                data: {
                    member_id: route.query.id,
                    status: 'all'
                }
            }
            if (!_.isNull(date.value.dateTo) && !_.isNull(date.value.dateFrom)) {
                payload.data.to = moment(date.value.dateTo).format("YYYY/MM/DD");
                payload.data.from = moment(date.value.dateFrom).format("YYYY/MM/DD");
            }
            await getAdminCreditHistory(payload);
            return
        }
        await getAdminCreditHistory();
    }

    //------- END OF LOAD MORE HISTORY --------

    return {
        date,
        onShowCreditsModal,
        creditHistory,
        isLoadingCreditHistoryList,
        loadMoreLoading,
        onChangeTab,
        onClickFilter,
        onClickResetFilter,
        filterValue,
        filteStatusProps,
        filterMedrepProps,
        selectedTab,
        onLoadMore,
        creditHistoryTablists,
        showLoadMore,
        showResetFilterButton,
        disableFilterButton,
        showFilter,
        route,
        onChangeRoute
    }
}