
// import Vue from "vue";
// const app = Vue.createApp({})
import { createApp } from "vue";
const app = createApp({})

app.directive('onscroll', {
    beforeMount(el) {
        window.addEventListener('wheel', (event) => {
            if (event.wheelDelta > 0) {
                el.classList.remove('scroll-down');
            } else {
                el.classList.add('scroll-down');
            }
        })
    }
})

// NOTE: NOT YET WORKING :(