<template>
    <div class="appointment-list-component">
        <list-component
            v-bind="listProps"
            @get-action-btn-height="actionBtnHeight=$event"
        >
            <template v-slot:card="{ data }">
                <appointment-card-component
                    :data="data"
                    v-bind="appointmentCardProperties"
                    :class="{'active': data.uuid === current}"
                    @click="onClickAppointmentCard(data)"
                />
            </template>
            <!-- <div v-if="$slots.footer" ref="actionBtnHeight" class="action-btn">
                <button-component
                    v-if="items.length > 3 && showViewScheduleButton"
                    type="white-btn"
                    label="View schedule"
                    block
                    class="h-full"
                    @click="onClickViewSchedule()"
                />
            </div> -->
            <template v-slot:footer>
                <button-component
                    v-if="showViewScheduleButton"
                    v-bind="buttonProps"
                    @click="onClickViewSchedule()"
                />
            </template>
        </list-component>
    </div>
</template>

<script>
import ListComponent from "../common/list-component.vue";
import AppointmentCardComponent from "../common/cards/appointment-card-component";
import { onMounted, ref, computed } from "vue";

export default {
    name: "AppointmentListComponent",
    components: {
        ListComponent,
        AppointmentCardComponent,
    },
    props: {
        items: { type: Array, default: () => []  },
        scrollable: Boolean,
        showViewScheduleButton: Boolean,
        loadMoreFooterClass: { type: String, default: "" },
        height: { type: [ Number, String ], default: null },
        appointmentCardProperties: { type: Object, default: null },
        buttonLabel: { type: String, default: "View schedule" },
    },
    emits: [ "on-click-appointment-card", 'on-click-view-schedule', 'get-action-btn-height' ],
    setup(props, { emit }) {
        const actionBtnHeight = ref(0);
        const current = ref(0);

        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const listProps = computed(() => {
            return {
                items: props.appointmentCardProperties.isLoading ? props.appointmentCardProperties.numberOfLoadingCards : props.items,
                scrollable: props.scrollable,
                isCard: true,
                loadMoreFooterClass: props.loadMoreFooterClass,
                listMaxHeight: props.height
            }
        })

        const buttonProps = computed(() => {
            return {
                type: "white-btn",
                label: props.buttonLabel,
                block: true,
                class: "h-full",
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onClickAppointmentCard = (data) => {
            current.value = data.uuid
            emit('on-click-appointment-card', data)
        }

        const onClickViewSchedule = () => {
            emit('on-click-view-schedule')
        }

        //-------------------METHODS ENDs HERE------------------

        onMounted(() => {
            emit("get-action-btn-height", actionBtnHeight.value);
        })

        return {
            onClickAppointmentCard,
            onClickViewSchedule,
            actionBtnHeight,
            listProps,
            buttonProps,
            current
        };
    },
};
</script>

<style lang="scss">
.appointment-list-component {
    .appointment-card-component {
        &.active {
            @apply ring-2 ring-offset-2 ring-offset-gray-100 ring-gray-500;
        }
    }
}
</style>