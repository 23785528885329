<template>
    <div
        ref="headerHeight"
        class="slideover-header-component"
    >
        <div class="">
            <h2 class="title">{{ !_.isEmpty(appointment) && _.has(appointment.msg_box, 'title') ? appointment.msg_box.title : title }}</h2>

            <p v-if="$userType === AccountType.Medrep" class="subtitle">{{ !_.isEmpty(appointment) ? appointment.msg_box.message : subtitle }}</p>

            <template v-if="showButton && $userType === AccountType.Medrep">
                <button-component
                    v-if="appendIcon"
                    type="primary-btn"
                    :label="buttonLabel"
                    class="mt-3"
                    @click="videoCall"
                >
                    <template v-slot:append>
                        <PhoneOutgoingIcon aria-hidden="true" />
                    </template>
                </button-component>

                <button-component
                    v-else
                    type="primary-btn"
                    :label="buttonLabel"
                    class="mt-3"
                    :disabled="disabledRequestButton"
                    @click="$router.push({ path: '/medrep/doctors/appointment', query: { id: appointment.doctor_id } })"
                />
                <p v-if="disabledRequestButton" class="subtitle mt-4">You need at least <span class="text-gray-700">1 credit</span> to request an appointment</p>
            </template>
        </div>
        <div v-if="showCloseBtn" class="slideover-button-container">
            <button
                type="button"
                class="close-btn slideover-close-button"
                @click="$emit('on-click-close-btn')"
            >
                <XIcon class="h-5 w-5" aria-hidden="true" />
            </button>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { XIcon } from "@heroicons/vue/outline";
import { PhoneOutgoingIcon } from "@heroicons/vue/solid";
import { AccountType } from "@Utilities/enum";

export default {
    name: "SlideoverHeaderComponent",
    components: {
        XIcon,
        PhoneOutgoingIcon,
    },
    props: {
        appointment: { type: Object, default: () => {} },
        title: { type: String, default: "" },
        subtitle: { type: String, default: "" },
        buttonLabel: { type: String, default: "" },
        showCloseBtn: { type: Boolean, default: true },
        disabledRequestButton: { type: Boolean, default: false },
    },
    emits: [ "on-click-close-btn", "get-height" ],
    setup(props, { emit }) {
        const headerHeight = ref(null);

        //-----------------------COMPUTED-----------------------

        const appendIcon = computed(() => {
            return !_.isEmpty(props.appointment) ? props.appointment.status === 'accepted' : false
        })

        const showButton = computed(() => {
            // SHOW CALL OR REQUEST APPOINTNMENT BUTTON FOR ACCEPTED APPOINTMENT AND SERVED RESPECTIVELY
            return !_.isEmpty(props.appointment) ? props.appointment.status === 'accepted' || props.appointment.status === 'served' : false
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const videoCall = () => {
            window.open(props.appointment.video_call_url, '_blank');
        }

        //-------------------METHODS ENDs HERE------------------

        onMounted(() => {
            emit("get-height", headerHeight.value);
        });

        return {
            headerHeight,
            videoCall,
            appendIcon,
            showButton,
            AccountType,
        };
    },
};
</script>

<style lang="scss">
.slideover-header-component {
    @apply flex items-start justify-between py-6;
    .title {
        @apply text-lg font-medium text-gray-900;
    }
    .subtitle {
        @apply text-sm leading-5 font-normal text-gray-500;
    }
    .slideover-button-container {
        @apply ml-3 h-7 flex items-center;
        .slideover-close-button {
            @apply p-2 bg-white rounded-full text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-white focus:ring-offset-0 focus:ring-gray-500;
        }
    }
}
</style>