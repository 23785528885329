import { ref, reactive, computed, watch } from 'vue'
import { SearchIcon } from "@heroicons/vue/outline";
import { useStore } from 'vuex'

export default function () {
    const store = useStore();

    const random = ref(null)

    // FORM DATA
    const form = ref({
        specialty: [],
        locations: [],
        years_of_practice_from: "",
        years_of_practice_to: "",
        availability: [],
    });

    // START OF MEDICAL SPECIALTY
    const specialty = ref(null)
    const getMedicalSpecialty = async () => {
        if (_.isEmpty(specialtyList.value)) {
            await store.dispatch('MedrepPreferences/getSpecialtyList')
        }
    }
    const specialtyList = computed(() => {
        return store.getters['MedrepPreferences/specialties']
    })
    const specialtyLoading = computed(() => {
        return store.getters['MedrepPreferences/specialtyLoading']
    })
    const specialtyProps = reactive({
        id: "specialties",
        mode: "multiple",
        closeOnSelect: true,
        options: specialtyList,
        label: "specialty_name",
        valueProp: "id",
        object: true,
        trackBy: "specialty_name",
        placeholder: "Type a medical specialty",
        minChars: 1,
        icon: SearchIcon,
        loading: specialtyLoading,
        resetTrigger: random,
        labelfor: "",
        prependIcon: true,
        removeOnSelect: true
    })
    watch(
        () => specialty.value,
        async (newVal) => {
            if (!newVal) return
            const isExist = await !_.isEmpty(_.find(form.value.specialty, { id: newVal[specialty.value.length - 1].id }))
            if (isExist) return
            form.value.specialty.push(newVal[specialty.value.length - 1])
        }
    )
    // END OF MEDICAL SPECIALTY

    // START OF LOCATIONS
    const location = ref(null)
    const getLocationList = async () => {
        if (_.isEmpty(locationList.value)) {
            await store.dispatch('MedrepPreferences/getLocationList')
        }
    }
    const locationList = computed(() => {
        return store.getters['MedrepPreferences/locations']
    })
    const locationLoading = computed(() => {
        return store.getters['MedrepPreferences/locationLoading']
    })
    const locationProps = reactive({
        id: "locations",
        mode: "multiple",
        closeOnSelect: true,
        options: locationList,
        label: "name",
        valueProp: "id",
        trackBy: "name",
        placeholder: "Type a province or city / municipality",
        minChars: 1,
        icon: SearchIcon,
        loading: locationLoading,
        object: true,
        resetTrigger: random,
        prependIcon: true,
        labelfor: "",
        removeOnSelect: true
    })
    watch(
        () => location.value,
        (newVal) => {
            if (!newVal) return
            const isExist = !_.isEmpty(_.find(form.value.locations, { id: newVal[location.value.length - 1].id }))
            if (isExist) return
            form.value.locations.push({
                id: newVal[location.value.length - 1].id,
                location_type: newVal[location.value.length - 1].location_type
            })
        }
    )
    // END OF LOCATION

    // START YEARS OF PRACTICE
    let yearsOfPracticeList = computed(() => store.getters['MedrepPreferences/experience'])
    _.remove(yearsOfPracticeList.value, { label: 'Any' })
    const years = ref([])
    watch(
        () => years.value,
        (newVal) => {
            form.value.years_of_practice_from = newVal.years_of_practice_from
            form.value.years_of_practice_to =  newVal.years_of_practice_to
        }
    )
    // END YEARS OF PRACTICE

    // START OF AVAILABILITY
    const availabilityList = computed(() => store.getters['MedrepPreferences/availabilities'])
    const availabilities = ref(null)
    watch(
        () => availabilities.value,
        (newVal) => {
            const item = _.map(newVal, i => i.value)
            if (item.length === 8) {
                item.pop()
            }
            form.value.availability = item
        }
    )
    // END OF AVAILABILITY

    return {
        form,
        random,
        specialty,
        getMedicalSpecialty,
        specialtyProps,

        location,
        getLocationList,
        locationProps,

        years,
        yearsOfPracticeList,

        availabilityList,
        availabilities,
    }
}