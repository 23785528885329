import { ref, computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";

export default function () {
    const store = useStore();
    const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));
    const page = ref(1);

    // START OF TABLIST DATA
    const doctorTablists = ref(
        [
            { name: "Schedule", current: true, },
            { name: "Appointments", current: false, },
            { name: "Coverage", current: false, },
        ]
    )
    // END OF TABLIST DATA

    // START OF GET MEMBER AND DOCTOR DATA
    const getMemberInfo = async (payload) => {
        await store.dispatch('AdminTeam/getMemberProfile', payload)
    }

    const getDoctorInfo = async (payload) => {
        await store.dispatch('CommonDoctor/getDoctorTabInfo', payload)
    }
    // END OF GET MEMBER AND DOCTOR DATA

    // START OF GET DOCTOR DATA
    const doctor = computed(
        () => store.getters['CommonDoctor/doctorProfile']
    )
    const getDoctorProfile = async (id) => {
        await store.dispatch('CommonDoctor/getDoctorProfile', id)
    }
    const doctorProfileProperties = reactive({
        doctor,
        tablists: doctorTablists,
        haveHoverEffect: false,
    })
    const doctorProfileLoading = computed(() => {
        return store.getters['CommonDoctor/doctorProfileLoading']
    })
    // END OF GET DOCTOR DATA

    // START OF GET SCHEDULE DATA
    const schedules = computed(() => {
        return store.getters['CommonDoctor/doctorSchedule']
    })
    const getDoctorSchedule = async (id) => {
        const doctorSchedulePayload = {
            id,
            option: 'schedule',
        }
        await getDoctorInfo(doctorSchedulePayload);
    }
    // END OF GET SCHEDULE DATA

    // START OF GET MEMBER DATA
    const member = computed(
        () => store.getters['AdminTeam/memberProfile']
    )
    const getMemberProfile = async (id) => {
        const memberProfilePayload = {
            id,
        }
        getMemberInfo(memberProfilePayload);
    }

    const memberProfileLoading = computed(() => {
        return store.getters['AdminTeam/memberProfileLoading']
    })

    const memberProfileProperties = reactive({
        profile: member,
        inline: false,
        imgClass: 'h-16 w-16',
        haveHoverEffect: false,
        isCard: false,
    })
    // END OF GET MEMBER DATA

    // START OF GET COVERAGE DATA
    const coverage = computed(() => {
        if (userType.value === 'district_manager') {
            return store.getters['AdminTeam/memberCoverage']
        } else {
            return store.getters['CommonDoctor/doctorCoverage']
        }
    })

    const getCoverage = async (id) => {
        if (userType.value === 'district_manager') {
            const memberCoveragePayload = {
                id,
                details: 'brands',
                limit: 500,
            }
            await getMemberInfo(memberCoveragePayload);
        } else {
            const doctorCoveragePayload = {
                id,
                option: 'coverage',
                page: page.value,
            }
            await getDoctorInfo(doctorCoveragePayload);
        }
    }

    const listLoading = computed(() => {
        if (userType.value === 'district_manager') {
            return store.getters['AdminTeam/listLoading'] && page.value === 1;
        } else {
            return store.getters['CommonDoctor/isLoading'] && page.value === 1;
        }
    })
    // END OF GET COVERAGE DATA

    // START OF GET APPOINTMENTS DATA
    const appointments = computed(() => {
        if (userType.value === 'district_manager') {
            return store.getters['AdminTeam/memberAppointments']
        } else {
            return store.getters['CommonDoctor/doctorAppointments']
        }
    })
    const getAppointment = async (id) => {
        if (userType.value === 'district_manager') {
            const memberAppointmentPayload = {
                id,
                details: 'appointments',
                limit: 500,
            }
            await getMemberInfo(memberAppointmentPayload);
        } else {
            const doctorAppointmentPayload = {
                id,
                option: 'appointments',
            }
            await getDoctorInfo(doctorAppointmentPayload);
        }
    }
    // END OF GET APPOINTMENTS DATA

    // START OF GET MEDREP'S DOCTORS DATA
    const doctors = computed(() => {
        return store.getters['AdminTeam/memberDoctors']
    })
    const getDoctor = async (id, limit) => {
        const memberDoctorPayload = {
            id,
            details: 'doctors',
            limit: limit ? limit : 10,
            page: page.value,
        }
        await getMemberInfo(memberDoctorPayload);
    }
    // END OF GET MEDREP'S DOCTORS DATA

    // START OF GET CREDITS HISTORY DATA
    const creditBalance = computed(() => {
        return _.head(_.split(member.value.remaining_credits, ' '))
    })
    const creditHistory = computed(() => {
        return store.getters['AdminTeam/memberCreditHistory']
    })
    const getCreditHistory = async (id) => {
        const creditHistoryPayload = {
            id,
            details: 'credits',
            limit: 10,
        }
        await getMemberInfo(creditHistoryPayload);
    }
    // END OF GET CREDITS HISTORY DATA

    // START OF SHOW MORE DOCTOR BUTTON
    const memberDoctorsMeta = computed(() => store.getters['AdminTeam/memberDoctorsMeta']);
    const doctorTabInfoMeta = computed(() => store.getters['CommonDoctor/doctorTabInfoMeta']);
    const meta = ref(memberDoctorsMeta.value)
    watch(
        () => memberDoctorsMeta.value,
        (newVal) => {
            meta.value = newVal
        }
    )
    watch(
        () => doctorTabInfoMeta.value,
        (newVal) => {
            meta.value = newVal
        }
    )
    const showLoadMore = computed(() => {
        return meta.value.current_page !== meta.value.last_page
    });
    // END OF SHOW MORE DOCTOR BUTTON

    // START OF LOAD MORE DATA
    let loadMoreLoading = ref(false);
    const loadMoreData = async (type, id) => {
        if (showLoadMore.value) {
            loadMoreLoading.value = true;
            page.value++
            switch (type) {
            case 'doctors':
                await getDoctor(id)
                break;
            case 'coverage':
                await getCoverage(id)
                break;
            }
            loadMoreLoading.value = false;
        }
    };
    // END OF LOAD MORE DATA

    // RESET DATA WHEN SELECTING OTHER TABS
    const resetData = () => {
        page.value = 1
        store.commit("AdminTeam/resetMemberDoctors")
        store.commit("CommonDoctor/resetDoctorCoverage")
        store.commit("CommonDoctor/resetDoctorTabInfoMeta")
    }

    return {
        doctorTablists,
        doctor,
        doctorProfileLoading,
        doctorProfileProperties,
        getDoctorProfile,
        schedules,
        getDoctorSchedule,
        member,
        memberProfileProperties,
        memberProfileLoading,
        getMemberProfile,
        coverage,
        listLoading,
        getCoverage,
        appointments,
        getAppointment,
        doctors,
        getDoctor,
        creditBalance,
        creditHistory,
        getCreditHistory,
        loadMoreData,
        resetData,
    }
}