<template>
    <div class="preferences-steps-component">
        <div class="preferences-steps-container">
            <div class="preference-steps-content">
                <steps-info-cmponent
                    :steps="preferencesProperties.steps"
                    :current-step="currentStep"
                    @on-click-skip="onClickSkip($event)"
                />
                <div class="preference-steps-header-container">
                    <div class="preference-steps-header-content">
                        <p
                            class="header-title"
                        >
                            {{ steps[ currentStep - 1 ].question }}
                        </p>
                        <p
                            class="header-subtitle"
                        >
                            {{ steps[ currentStep - 1 ].note }}
                        </p>
                    </div>
                </div>
                <div v-if="showInputField" class="search-field-container">
                    <multiple-select-component
                        v-if="currentStep === 1"
                        v-model="specialty"
                        v-bind="specialtyProps"
                        :model-value="selectedSpecialtiesList"
                        @on-load-data="getMedicalSpecialty"
                        @update:model-value="addToList(specialty)"
                    />
                    <multiple-select-component
                        v-else
                        v-model="location"
                        v-bind="locationProps"
                        :model-value="selectedLocationList"
                        @on-load-data="getLocationList"
                        @update:model-value="addToList(location)"
                    />
                </div>
                <div class="preference-steps-body-container" style="min-height:160px">
                    <div v-if="bodyHeader && !_.isEmpty(selectedList)" class="preference-steps-body-content">
                        <div class="preference-steps-body-header-container">
                            <p class="body-header-title">
                                {{ bodyHeader }}
                            </p>
                        </div>
                        <div class="selected-container">
                            <div v-for="(selected, index) in selectedList" :key="index" class="selected-content">
                                <p class="text-green-800 font-medium">
                                    {{ selected[displayDataBy] }}
                                </p>
                                <span
                                    class="selected text-sm font-medium"
                                >
                                    <XIcon
                                        class="h-3 text-green-400"
                                        @click="onClickRemove(selected)"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentStep === 3 || currentStep === 4" class="years-of-practice-and-availability-container">
                        <div v-if="currentStep === 3" class="years-of-practice-content">
                            <button-component
                                v-for="(yearRange, index) in yearsOfPracticeList"
                                :key="index"
                                type="white-btn"
                                :class="[
                                    {
                                        'text-green-800 bg-green-50 border-none' : selectedYearsOfPractice === yearRange.value
                                    },
                                    'mr-2 mb-3'
                                ]"
                                :label="yearRange.label"
                                @onClick="setSelectedYearsOfPractice(yearRange)"
                            />
                        </div>
                        <div v-else>
                            <checkbox-component
                                v-model="availabilities"
                                :options="availabilityList"
                                name="availability"
                            />
                        </div>
                    </div>
                </div>
                <div
                    :class="[
                        currentStep > 1 ? 'justify-between' : 'justify-end',
                        'preference-action flex'
                    ]"
                >
                    <button-component
                        v-if="currentStep > 1"
                        type="white-btn"
                        custom-class="flex justify-end"
                        label="Previous"
                        @on-click="onClickPrev"
                    />
                    <button-component
                        :type="disableNextBtn ? 'white-btn' : 'primary-btn'"
                        custom-class="flex justify-end"
                        :label="currentStep === 4 ? 'Finish' : 'Next'"
                        :disabled="disableNextBtn"
                        @on-click="onClickNext"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import StepsInfoCmponent from './steps-info-component.vue'
import usePreferences from '@MedrepFunctions/usePreferences.js'
import { CheckboxComponent, MultipleSelectComponent } from '@CommonComponents'
import {
    SearchIcon,
    XIcon
} from '@heroicons/vue/outline'
import { useStore } from 'vuex'

export default {
    name: "PreferencesStepsComponent",
    components: {
        StepsInfoCmponent,
        XIcon,
        CheckboxComponent,
        MultipleSelectComponent
    },
    props: {
        isUpdate: Boolean,
    },
    emits: [ "on-enter-key", "on-click-save" ],
    setup(props, { emit }) {
        const store = useStore()
        const {
            form,
            specialty,
            specialtyProps,
            getMedicalSpecialty,
            locationProps,
            getLocationList,
            location,
            availabilities,
            availabilityList,
            yearsOfPracticeList,
            years
        } = usePreferences();
        const selectedSpecialties = ref([])
        const selectedLocation = ref([])
        const currentStep = ref(1)
        const selectedYearsOfPractice = ref("")
        const searchIcon = ref(SearchIcon)

        //------------------COMPUTED-------------------

        const showInputField = computed(() => {
            return currentStep.value === 1 || currentStep.value === 2
        });

        const getPreferences = computed(
            () => store.getters["MedrepPreferences/userPreference"]
        );

        const disableNextBtn = computed(() => {
            return currentStep.value === 1 ?
                _.isEmpty(selectedSpecialties.value) :
                currentStep.value === 2 ?
                    _.isEmpty(selectedLocation.value) :
                    currentStep.value === 3 ?
                        !selectedYearsOfPractice.value :
                        currentStep.value === 4 ?
                            _.isEmpty(availabilities.value) :
                            false
        });

        const displayDataBy = computed(() => {
            return steps.value[currentStep.value - 1].type === 'specialty' ? 'specialty_name' : 'name'
        });

        const selectedList = computed(() => {
            return currentStep.value === 1 ? selectedSpecialties.value : currentStep.value === 2 ? selectedLocation.value : []
        });

        const bodyHeader = computed(() => {
            return steps.value[currentStep.value - 1].bodyHeader
        });

        const preferencesProperties = computed(() => {
            return {
                steps: steps.value,
            }
        });

        //------------------METHODS------------------

        const onClickNext = () => {
            if (currentStep.value < 4 ) {
                currentStep.value ++
            } else {
                onClickSave()
            }
        }

        const onClickPrev = () => {
            if (currentStep.value > 1 ) {
                currentStep.value --
            }
        }

        const selectedSpecialtiesList = computed(() => {
            return [ ...selectedSpecialties.value ]
        });

        const selectedLocationList = computed(() => {
            return [ ...selectedLocation.value ]
        });

        const addToList = async(event) => {
            if (currentStep.value === 1) {
                if (!event) return
                const isExist = !_.isEmpty(_.find(selectedSpecialties.value, { specialty_name: event[event.length - 1].specialty_name }))
                if (isExist) return
                selectedSpecialties.value.push(event[event.length - 1])
            } else {
                if (!event) return
                const isExist = !_.isEmpty(_.find(selectedLocation.value, { name: event[event.length - 1].name }))
                if (isExist) return
                selectedLocation.value.push(event[event.length - 1])
            }
        };

        const onClickSave = async() => {
            const formData = _.cloneDeep(form.value);
            const payload = {
                ...formData,
                specialty_ids: _.map(form.value.specialty, 'id')
            }
            delete payload.specialty
            await store.dispatch('MedrepPreferences/managePreference', { payload, action: props.isUpdate ?  'update' : 'create' })
            emit("on-click-save")
        }

        const setSelectedYearsOfPractice = (event) => {
            selectedYearsOfPractice.value = event.value
            years.value = event
        };

        const onClickRemove = (seleted) => {
            if (currentStep.value === 1 ) {
                _.remove(selectedSpecialties.value, { specialty_name: seleted.specialty_name })
                _.remove(form.value.specialty, { specialty_name: seleted.specialty_name })
            }
            if (currentStep.value === 2) {
                _.remove(selectedLocation.value, { name: seleted.name })
                _.remove(form.value.locations, { id: seleted.id })
            }
        };

        const onClickSkip = (step) => {
            if (step === 3) {
                selectedYearsOfPractice.value = ""
                years.value.years_of_practice_from = null
                years.value.years_of_practice_to = null
            }
            if (step === 4) {
                availabilities.value = []
            }
            onClickNext()
        }

        //------------------METHODS ENDs HERE------------------

        const steps = ref([
            {
                step: 1,
                question: 'What kind of doctors are you targeting?',
                note: `Select your preferred doctor's medical specialty`,
                bodyHeader: 'Selected Medical Specialties',
                type: 'specialty',
            },
            {
                step: 2,
                question: 'What is your target doctor’s location?',
                note: 'Select your preferred doctor’s location',
                bodyHeader: 'Selected locations',
                type: 'localtion',
            },
            {
                step: 3,
                question: 'What’s your preferred doctor’s years of practice?',
                note: 'Select your preferred doctor’s years of practice',
                bodyHeader: '',
                type: 'year_of_practice',
            },
            {
                step: 4,
                question: 'What day of doctor’s availability do you prefer?',
                note: 'Select your preferred doctor’s availability',
                bodyHeader: '',
                type: 'availability',
            }
        ])

        onMounted(async() => {
            await store.dispatch('MedrepPreferences/getPreference')
            if (props.isUpdate) {
                const schedule = await _.map((getPreferences.value.availability), (availability) => {
                    return { ..._.find(availabilityList.value, { value: _.toInteger(availability) }) }
                })
                const specialties = await _.map(getPreferences.value.specialties, (item) => {
                    return {
                        id: item.id,
                        specialty_name: item.name
                    }
                });
                if (schedule.length === 7) {
                    await schedule.push({ value: null, label: "Any Day" })
                }
                const location = await _.map(getPreferences.value.locations, (location) => {
                    return {
                        id: location.id,
                        location_type: location.location_type
                    }
                })
                selectedSpecialties.value.push(...specialties)
                selectedLocation.value.push(...getPreferences.value.locations)
                form.value.specialty.push(...specialties)
                form.value.locations.push(...location)
                selectedYearsOfPractice.value = `${getPreferences.value.years_of_practice_from},${getPreferences.value.years_of_practice_to}`
                years.value={
                    years_of_practice_from: getPreferences.value.years_of_practice_from,
                    years_of_practice_to: getPreferences.value.years_of_practice_to
                }
                availabilities.value = schedule
            }
        });

        return {
            steps,
            currentStep,
            preferencesProperties,
            showInputField,
            searchIcon,
            bodyHeader,
            selectedSpecialties,
            selectedLocation,
            selectedList,
            displayDataBy,
            availabilities,
            form,
            onClickNext,
            onClickPrev,
            addToList,
            specialty,
            specialtyProps,
            getMedicalSpecialty,
            locationProps,
            getLocationList,
            location,
            disableNextBtn,
            availabilityList,
            yearsOfPracticeList,
            selectedYearsOfPractice,
            setSelectedYearsOfPractice,
            years,
            onClickSave,
            onClickSkip,
            onClickRemove,
            getPreferences,
            selectedSpecialtiesList,
            selectedLocationList
        }
    }
}
</script>

<style lang="scss">
.preferences-steps-component {
    @apply flex h-full items-center;
    .preferences-steps-container {
        @apply w-full;
        .preference-steps-content {
            @apply space-y-6;
            .preference-steps-header-container {
                .preference-steps-header-content {
                    @apply space-y-1;
                    .header-title {
                        @apply text-lg font-medium text-gray-700;
                    }
                    .header-subtitle {
                        @apply text-sm font-normal text-gray-500;
                    }
                }
            }
            .preference-steps-body-container {
                .preference-steps-body-content {
                    @apply space-y-3;
                    .preference-steps-body-header-container {
                        .body-header-title {
                            @apply text-sm font-medium text-gray-700;
                        }
                    }
                    .selected-container {
                        .selected-content {
                            @apply inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 space-x-1 mr-3 mb-3;
                        }
                    }
                }
            }
        }
    }
}
</style>