<template>
    <div class="member-profile-component">
        <!-- STATIC HEIGHT COMPONENT -->
        <template v-if="!isLoading">
            <div ref="componentHeight" class="member-profile-component">
                <slideover-header-component
                    title="Member Profile"
                    @on-click-close-btn="onShowSlideover"
                />

                <profile-info-card-component
                    v-bind="memberProfileProperties"
                />

                <coverage-tab-component
                    :coverage="coverage"
                />

                <navigation-tabs-component
                    v-model="memberTablists"
                    :is-fullwidth="true"
                    @click-tab="selectNavTab($event)"
                />
            </div>

            <!-- DYNAMIC HEIGHT/ TAB CONTENT -->
            <template v-for="(tab, index) in memberTablists" :key="index">
                <template v-if="tab.current">
                    <load-more-component
                        :unique-body-id="_.toLower(`${tab.name}-body`)"
                        :unique-id="_.toLower(`${tab.name}-slideover-read-more`)"
                        :height="totalHeight"
                        :load-more-loading="hasInfiniteLoading ? loadMoreLoading : false"
                        @get-action-btn-height="actionBtnHeight = $event"
                        @infinite-scroll="handleScroll(tab.name)"
                    >
                        <!-- :show-scroll-more="false" -->
                        <component
                            :is="selectedComponent(tab.name)"
                            :appointments="appointments"
                            :doctors="doctors"
                            :credit-history="creditHistory"
                            :team-member="[member]"
                            :credit-balance="creditBalance"
                            :is-loading="listLoading"
                        />
                        <template
                            v-if="tab.name === 'Credits' && !hasInfiniteLoading"
                            v-slot:footer
                        >
                            <div class="w-full px-6 pb-6">
                                <button-component
                                    type="white-btn"
                                    label="View credit history"
                                    block
                                    @click="onViewCreditHistory(member.id)"
                                />
                            </div>
                        </template>
                    </load-more-component>
                </template>
            </template>
        </template>
        <spinner-component v-else class="center h-screen" :size="40" />
    </div>
</template>

<script>
import { SlideoverHeaderComponent } from "@CommonComponents/slideovers";
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useProfileSlide from '@GlobalFunctions/useProfileSlide.js';
import useScrollHeight from '@GlobalFunctions/useScrollHeight.js';
import {
    LoadMoreComponent,
    ProfileInfoCardComponent,
    NavigationTabsComponent,
} from "@CommonComponents";
import CoverageTabComponent from "@CommonComponents/coverage-tab-component.vue";
import AppointmentsTabComponent from "@CommonComponents/appointments-tab-component.vue";
import CreditsTabComponent from "./credits-tab-component.vue";
import DoctorsTabComponent from "./doctors-tab-component.vue";

export default {
    name: "MemberProfileComponent",
    components: {
        SlideoverHeaderComponent,
        ProfileInfoCardComponent,
        CoverageTabComponent,
        LoadMoreComponent,
        AppointmentsTabComponent,
        DoctorsTabComponent,
        CreditsTabComponent,
        NavigationTabsComponent,
    },
    props: {
        memberId: { type: [ String, Number ], default: null, },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        const {
            memberProfileProperties,
            member,
            getMemberProfile,
            getCoverage,
            coverage,
            listLoading,
            appointments,
            getAppointment,
            doctors,
            getDoctor,
            creditHistory,
            creditBalance,
            getCreditHistory,
            loadMoreData,
            loadMoreLoading,
            resetData,
        } = useProfileSlide();

        const {
            componentHeight,
            actionBtnHeight,
            totalHeight,
        } = useScrollHeight();

        // START OF FETCHING DATA
        const memberTablists = ref([])
        const isLoading = ref(true)
        onMounted( async () => {
            isLoading.value = true;
            await getMemberProfile(props.memberId);
            await getCoverage(props.memberId);
            await getAppointment(props.memberId);
            await getDoctor(props.memberId, 500);
            memberTablists.value = [
                { name: "Appointments", current: true, },
                { name: `Doctors(${doctors.value.length})`, current: false, },
                { name: "Credits", current: false, },
            ]
            isLoading.value = false;
        })

        const selectNavTab = async (tabname) => {
            resetData();
            switch (tabname) {
            case 'Appointments':
                await getAppointment(props.memberId);
                break;
            case 'Credits':
                await getCreditHistory(props.memberId);
                break;
            default:
                // 'Doctors'
                await getDoctor(props.memberId);
                break;
            }
        }
        // END OF FETCHING DATA

        watch(
            () => props.memberId,
            async (newVal) => {
                resetData();
                await getMemberProfile(newVal);
                // RESETTING TO APPOINTMENTS TAB EVERYTIME MEMBER ID IS CHANGED
                _.forEach(memberTablists.value, i => {
                    i.name === 'Appointments' ? i.current = true : i.current = false
                });

                // FETCH NEW COVERAGE WHEN MEMBER ID IS CHANGED
                await getCoverage(newVal)

                // FETCH NEW APPOINTMENTS WHEN MEMBER ID IS CHANGED
                await getAppointment(newVal)

                // FETCH DOCTORS LIST JUST TO DISPLAY THE NUMBER OF DOCTORS ON TAB WHEN MEMBER ID IS CHANGED
                await getDoctor(props.memberId);
                memberTablists.value[1].name = `Doctors(${doctors.value.length})`
            }
        )

        // CLOSE SLIDEOVER
        const onShowSlideover = () => {
            store.commit("CommonDoctor/setProfileSlideover", false);
        };

        // REDIRECT TO CREDITS HISTORY PAGE
        const onViewCreditHistory = (id) => {
            router.push({ path: '/admin/credits/med-rep', query: { id } })
        }

        // SETTING OF SELECTED TAB CONTENT COMPONENT
        const selectedComponent = (name) => {
            let item
            if (_.includes(name, 'Doctors')) {
                item = "doctors"
            } else {
                item = name
            }
            return `${_.toLower(item)}-tab-component`;
        };

        // START OF INFINITE LOADING
        const hasInfiniteLoading = ref(false);
        const handleScroll = (tab) => {
            if (_.includes(tab, 'Doctor')) {
                hasInfiniteLoading.value = true
                loadMoreData('doctors', props.memberId);
            } else {
                hasInfiniteLoading.value = false
            }
        }
        // END OF INFINITE LOADING

        return {
            memberTablists,
            onShowSlideover,
            componentHeight,
            actionBtnHeight,
            totalHeight,
            selectedComponent,
            member,
            memberProfileProperties,
            coverage,
            listLoading,
            isLoading,
            appointments,
            doctors,
            creditBalance,
            creditHistory,
            selectNavTab,
            onViewCreditHistory,
            loadMoreLoading,
            handleScroll,
            hasInfiniteLoading,
        };
    },
};
</script>

<style lang="scss">
.member-profile-component {
    .slideover-header-component {
        @apply px-6;
    }
    .profile-info-card-component {
        .profile-card {
            @apply flex justify-between px-6;
            @apply px-6 #{!important};
        }
    }
    .appointments-tab-component {
        .appointment-list {
            .appointment-settings {
                &__date--container {
                     @apply px-6 #{!important};
                }
            }
        }
    }
}
</style>