<template>
    <div class="access-form-component">
        <div
            class="
                form-container
                sm:px-6
                lg:px-20
                xl:px-24
            "
        >
            <!-- lg:flex-none -->
            <div class="form-content lg:max-w-md">
                <div>
                    <img
                        src="@Images/pharma-full-logo.png"
                        class="form-content__logo"
                        alt="PPD Clinic Pharma"
                    />
                    <h2>
                        {{ formTitle }}
                    </h2>
                </div>
                <slot name="form" />
            </div>

            <!-- FAQS -->
            <faq-component />

            <!-- FOOTER -->
            <footer-component class="mt-8" />
        </div>

        <!-- RESERVE FOR INFOGRAPHIC SECTION -->
        <!-- <div class="hidden lg:block relative w-0 flex-1">
        </div> -->
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import FooterComponent from "../footer-component.vue";
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";
import { useRouter } from "vue-router"
import { FaqComponent } from '@CommonComponents';

export default {
    name: "AccessFormHeaderComponent",
    components: {
        FooterComponent,
        FaqComponent,
    },
    props: {
        formTitle: { type: String, default: "Create an account" },
    },
    setup() {
        const router = useRouter();
        const personalToken = ref(STORAGE.GET_COOKIES(Tokens.Personal));

        onMounted(() => {
            if (!_.isEmpty(personalToken.value)) {
                const { user_type } = personalToken.value.user_info;
                if (user_type === "medrep") {
                    router.push({ path: '/medrep' });
                } else {
                    router.push({ path: '/admin' });
                }
            }
        })
    },
};
</script>

<style lang="scss">
.access-form-component {
    @apply relative bg-white flex ;
    .access-form {
        @apply space-y-6 mt-8 bg-white px-10 py-8;
    }
    .form-container {
        @apply flex-1 flex flex-col justify-center py-12 px-4 ;
        background: #f3f4f6;
        .form-content {
            @apply mx-auto w-full max-w-sm ;
            &__logo {
                @apply w-auto mx-auto mb-6;
                height: 72px;
            }
            h2 {
                @apply text-3xl leading-9 font-semibold text-gray-900 text-center ;
            }

        }
    }
}
@screen lg {
    .access-form-component {
        .form-container {
            .form-content {
                width: 448px !important;
            }
        }
    }
}
</style>