<template>
    <!-- FORM -->
    <access-form-component>
        <template v-slot:form>
            <form class="access-form" @submit.prevent="onShowTcModal">
                <!-- EMAIL -->
                <input-field-component
                    v-model="email"
                    name="email"
                    type="email"
                    placeholder="you@example.com"
                    readonly
                    :prepend-icon="mailIcon"
                />

                <!-- LAST NAME -->
                <input-field-component
                    v-model="lastname"
                    name="last-name"
                    type="text"
                    label="Last name"
                />

                <!-- FIRST NAME -->
                <input-field-component
                    v-model="firstname"
                    name="first-name"
                    type="text"
                    label="First name"
                />

                <!-- UPLOAD PHOTO -->
                <div>
                    <upload-image-component
                        :avatar="uploadedImage"
                        label="Profile Photo"
                    />
                </div>

                <!-- REGISTER BUTTON -->
                <button-component
                    button-type="submit"
                    type="primary-btn"
                    label="Register"
                    block
                />
            </form>
        </template>
    </access-form-component>
    <!-- TERMS AND CONDITION MODAL -->
    <legal-modal-component v-model="showModal" />
</template>

<script>
import AccessFormComponent from "./access-form-component.vue";
import { InputFieldComponent, UploadImageComponent } from "@CommonComponents";
import { LegalModalComponent } from "@CommonComponents";
import { MailIcon } from "@heroicons/vue/solid";
import { computed, ref } from "vue";

export default {
    components: {
        AccessFormComponent,
        InputFieldComponent,
        UploadImageComponent,
        LegalModalComponent,
    },
    setup() {
        const mailIcon = ref(MailIcon);
        const email = ref("sarahguevarra@gmail.com");
        const firstname = ref("");
        const lastname = ref("");

        // START OF AVATAR UPLOAD
        const uploadedImage = computed(() => {
            return require("../../../assets/images/default-avatar.png");
        });
        // END OF AVATAR UPLOAD

        // START OF SHOW T&C MODAL
        const showModal = ref(false);
        const onShowTcModal = () => (showModal.value = true);

        // START OF SHOW T&C MODAL

        return {
            mailIcon,
            email,
            firstname,
            lastname,
            uploadedImage,
            onShowTcModal,
            showModal,
        };
    },
};
</script>