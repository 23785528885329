<template>
    <div class="doctor-profile-slideover-component" :class="currentComponent">
        <slide-over-component
            v-model="value"
            content-class="doctor-profile-slideover__content"
            @update:modelValue="value = $event"
        >
            <component
                :is="currentComponent"
                :doctor-id="doctorId"
                :member-id="doctorId"
            />
        </slide-over-component>
    </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from 'vuex'
import { SlideOverComponent } from "@CommonComponents";
import DoctorProfileComponent from "@MedrepComponents/doctors/components/doctor-info/doctor-profile-component.vue";
import MemberProfileComponent from "@AdminComponents/member-profile/member-profile-component.vue";

export default {
    name: "ProfileSlideoverComponent",
    components: {
        SlideOverComponent,
        DoctorProfileComponent,
        MemberProfileComponent,
    },
    props: {
        modelValue: Boolean,
        doctorId: { type: [ String, Number ], default: null, },
        type: { type: String, default: 'doctor', }, // doctor, member
    },
    setup(props) {
        const store = useStore();
        const value = ref(props.modelValue);
        // UPDATE VALUE EVERYTIME THE MODELVALUE CHANGES
        watch(
            () => props.modelValue,
            (newVal) => {
                value.value = newVal
            }
        )
        // CLOSE THE DOCTOR SLIDE OVER
        watch(
            () => value.value,
            (newVal) => {
                if (!newVal) {
                    store.commit('CommonDoctor/setProfileSlideover', false)

                    // RESET ALL APPOINTMENT DATA EVERY TIME THE SLIDEOVER CLOSES
                    // store.commit("CommonAppointment/setUpcomingAppointmentList", {});
                    // store.commit("CommonAppointment/setPendingAppointmentList", []);
                    // store.commit("CommonAppointment/setDeclinedAppointmentList", []);
                    // store.commit("CommonAppointment/setCancelledAppointmentList", []);
                    // store.commit("CommonAppointment/setForRemarksAppointmentList", []);
                }
            }
        )

        // IDENTIFYING CURRENT COMPONENT
        const currentComponent = computed(() => {
            return `${props.type}-profile-component`
        })

        return {
            value,
            currentComponent,
        };
    },
};
</script>

<style lang="scss">
.slide-over-modal-component {
    .doctor-profile-slideover__content {
        .load-more-component {
            .load-more-body {
            }
        }
    }
}
</style>