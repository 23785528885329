<template>
    <div class="announcement-list-component">
        <div class="announcement-list-container">
            <div class="announcement-list-content">
                <list-component
                    v-bind="listProps"
                >
                    <template v-slot:card="{data}">
                        <announcement-card-component
                            :data="data"
                        />
                    </template>
                </list-component>
                <div class="announcement-list-footer">
                    <button-component
                        type="white-icon-btn"
                        size="s"
                    >
                        <ArrowLeftIcon
                            class="arrow-color prev"
                        />
                    </button-component>
                    <button-component
                        type="white-icon-btn"
                        size="s"
                    >
                        <ArrowRightIcon
                            class="arrow-color next"
                        />
                    </button-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/vue/solid";
import AnnouncementCardComponent from "@CommonComponents/cards/announcement-card-component.vue"
import ListComponent from "@CommonComponents/list-component.vue"
import { computed } from "vue";

export default {
    name: 'AnnouncementListComponent',
    components: {
        ArrowRightIcon,
        ArrowLeftIcon,
        ListComponent,
        AnnouncementCardComponent
    },
    props: {
        imgSrc: { type: String, default: '' },
        haveShadow: { type: Boolean, default: true },
        items: { type: Array, default: () => []  },
        loadMoreFooterClass: { type: String, default: '' }
    },
    setup(props) {

        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const listProps = computed(() => {
            return {
                items: props.items,
                scrollable: false,
                isCard: false,
                loadMoreFooterClass: props.loadMoreFooterClass
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        return {
            listProps
        }
    }
}
</script>

<style lang="scss">
.announcement-list-component {
    .announcement-list-container {
        @apply shadow overflow-hidden rounded-md ;
        .announcement-list-content {
            @apply divide-y divide-gray-200 ;
            .announcement-list-footer {
                @apply flex justify-between px-7 py-6 bg-white ;
            }
        }
    }
}
</style>