<template>
    <div class="receiver__state">
        <div class="state__container" :class="content.containerClass">
            <div v-if="!_.isEmpty(state)" class="__content">
                <div class="icon__container">
                    <div v-if="state === 'invalid_date'" class="logo">
                        <CalendarIcon />
                    </div>
                    <div v-else class="avatar mx-auto">
                        <img :src="remoteStreamDetails.avatar" />
                    </div>
                </div>
                <div class="message__container">
                    <h1 class="text-base md:text-lg">{{ content.title }}</h1>
                    <p>{{ content.message }}</p>
                </div>

                <div v-if="content.showNotifButton" class="__notifier">
                    <div class="button__notifier">
                        <button-component
                            type="white-btn"
                            :disabled="notificationResend"
                            :label="buttonNotifLabel"
                            @click="onSendNofication()"
                        >
                            <template v-slot:prepend>
                                <BellIcon aria-hidden="true" />
                            </template>
                        </button-component>
                    </div>

                    <div
                        v-if="showNotifierInfo"
                        class="notifier__info animate__animated animate__fadeIn"
                    >
                        <div class="notifier__content">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <span class="icon">
                                        <BellIcon aria-hidden="true" />
                                    </span>
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-sm leading-5 font-medium">
                                        Notify the
                                        {{ remoteStreamDetails.user_type }}
                                    </h3>
                                    <p class="text-sm leading-5 font-normal">
                                        Click to notify the
                                        {{ remoteStreamDetails.user_type }}
                                        that you’re waiting
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <spinner-component v-else :size="48" />
        </div>
    </div>
</template>

<script>
import { ref, toRefs, computed, onUpdated } from "vue";
import useReceiverStateMessage from "./function/message";
import useSendNotification from "./function/sendNotification";
import { CalendarIcon } from "@heroicons/vue/outline";
import { BellIcon } from "@heroicons/vue/solid";

import { useVideoCallDetails } from "../../functions";

export default {
    name: "ReceiverStateComponent",
    components: {
        CalendarIcon,
        BellIcon,
    },
    props: {
        state: { type: String, default: "" },
    },
    setup(props) {
        const avatar = ref(null);
        let showNotifierInfo = ref(false);
        const { state } = toRefs(props);
        const { content, contentLoading } = useReceiverStateMessage(state);

        const { remoteStreamDetails, room_uuid } = useVideoCallDetails();

        onUpdated(() => {
            if (content.showNotifButton) {
                setTimeout(() => {
                    showNotifierInfo.value = true;
                }, 3000);
            }
        });

        const buttonNotifLabel = computed(() => {
            const label = notificationResend.value
                ? `Notify again in ${interval.value}s`
                : `Notify ${_.capitalize(remoteStreamDetails.value.user_type)}`;

            return label;
        });

        const { interval, notificationResend, onSendNofication } =
            useSendNotification(remoteStreamDetails, room_uuid);

        return {
            contentLoading,
            onSendNofication,
            content,
            showNotifierInfo,
            interval,
            notificationResend,
            avatar,
            remoteStreamDetails,
            buttonNotifLabel,
        };
    },
};
</script>

<style lang="scss">
.receiver__state {
    @apply relative;
    .receiver__inroom {
        @apply bg-indigo-50  #{!important};
        h1,
        p {
            @apply text-indigo-700 #{!important};
        }
    }
    .receiver__incall {
        @apply bg-primary-50  #{!important};
        h1,
        p {
            @apply text-primary-700 #{!important};
        }
    }
    .state__container {
        min-height: 50vmin;
        @apply bg-gray-100 flex items-center justify-center h-full p-4;
        .__content {
            h1 {
                @apply text-lg leading-6 font-medium text-gray-700;
            }
            p {
                @apply text-sm leading-5 font-normal text-gray-500;
            }

            .logo {
                @apply bg-indigo-700 m-auto;
                svg {
                    @apply text-white m-auto w-1/2 h-full;
                }
            }

            .logo,
            .avatar {
                @apply rounded-full;
                height: 64px;
                width: 64px;
                img {
                    @apply inline-block h-full w-full rounded-full;
                    object-fit: cover;
                }
            }

            .message__container {
                @apply text-center my-4;
            }

            .button__notifier {
                @apply text-center;
            }

            .notifier__info {
                @apply w-4/5 mt-4 group bg-white p-4 rounded-lg absolute z-10;
                left: 50%;
                transform: translate(-50%, 0px);
                .notifier__content {
                    .icon {
                        @apply block h-8 w-8 rounded-full bg-indigo-100;
                        svg {
                            @apply text-indigo-400 m-auto w-1/2 h-full;
                        }
                    }
                }
                @screen lg {
                    @apply w-4/6;
                }
            }
        }
    }
}
</style>