<template>
    <div class="team-member-list-component">
        <div class="team-member-list-container space-y-4">
            <div class="header-container">
                <p class="header-title">Team Members</p>
                <button-component
                    type="primary-btn"
                    prepend
                    label="Allocate credits"
                    @on-click="onShowCreditsModal"
                >
                    <template v-slot:prepend>
                        <outline-plus-icon class="h-4 w-4" aria-hidden="true" />
                    </template>
                </button-component>
            </div>
            <div>
                <table-card-component
                    v-bind="tableProps"
                    @table-ref="tableRef=$event"
                    @on-click-next="onPaginate('next')"
                    @on-click-prev="onPaginate('prev')"
                    @get-member-id="$emit('get-member-id', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TableCardComponent from '@CommonComponents/table-card-component.vue'
import { ref, computed, onMounted } from "vue";
import { DatabaseIcon } from "@heroicons/vue/solid";
import { useStore } from 'vuex';
import { PlusIcon as OutlinePlusIcon } from "@heroicons/vue/outline";
import { useRoute } from 'vue-router';

export default {
    name: "TeamMemberListComponent",
    components: {
        TableCardComponent,
        OutlinePlusIcon
    },
    props: {
        memberLimit: { type: Number, default: null }
    },
    emits: [ "table-ref", "get-member-id" ],
    setup(props, { emit }) {
        const store = useStore();
        const route = useRoute();
        const databaseIcon = ref(DatabaseIcon)
        const tableRef = ref(null)

        const teamMemberList = computed(
            () => store.getters["AdminTeam/teamMemberList"]
        );

        const cardData = computed(
            () => {
                const item = {
                    data: teamMemberList.value,
                    thClass: "",
                    config: [
                        {
                            label: "MEDICAL REPRESENTATIVE",
                            type: "label-with-avatar",
                            key: {
                                nameKey: "full_name",
                                avatarKey: "avatar"
                            },
                            class: "text-gray-900",
                        },
                        {
                            label: "BRANDS PROMOTING",
                            type: "label",
                            key: "brand",
                            class: "text-gray-500",
                        },
                        {
                            label: _.includes(route.path, '/admin/team/' )? "Credit balance" : "Remaining credits",
                            type: "label-with-icon",
                            key: {
                                nameKey: "remaining_credits",
                                iconKey: databaseIcon.value
                            },
                            class: "text-gray-700",
                            iconClass: "text-gray-400"
                        }
                    ],
                    nextButtonState: nextButtonState.value,
                    prevButtonState: prevButtonState.value
                }
                if (_.includes(route.path, '/admin/team/')) {
                    // REMOVE BRANDS COVERING FOR TEAM PAGE
                    const filteredItem = _.filter(item.config, i => i.label !== 'BRANDS PROMOTING' )
                    item.config = filteredItem
                    return item;
                } else {
                    return item;
                }
            }
        )

        // START OF FETCHING TEAM MEMBERS
        const fetchTeamMembers = async (payload) => {
            await store.dispatch('AdminTeam/getTeamMembers', payload)
        }
        const teamMemberListMeta = computed(() => store.getters['AdminTeam/teamMemberListMeta'])

        const nextButtonState = computed(() => {
            let state = ''
            if (!_.isEmpty(teamMemberListMeta.value) && teamMemberListMeta.value.current_page < teamMemberListMeta.value.last_page) {
                state = 'show'
            } else {
                state = 'hide'
            }
            return state
        })

        const prevButtonState = computed(() => {
            let state = ''
            if (!_.isEmpty(teamMemberListMeta.value) && teamMemberListMeta.value.current_page > 1) {
                state = 'show'
            } else {
                state = 'hide'
            }
            return state
        })

        const onPaginate = (event) => {
            let page = 1
            if (event==='next') {
                page = teamMemberListMeta.value.current_page + 1
                const payload = {
                    data: {
                        limit: props.memberLimit,
                        page: page,
                    }
                }
                fetchTeamMembers(payload);
            }
            if (event==='prev') {
                page = teamMemberListMeta.value.current_page - 1
                const payload = {
                    data: {
                        limit: props.memberLimit,
                        page: page,
                    }
                }
                fetchTeamMembers(payload);
            }
        }

        const tableProps  = computed(
            () => {
                return {
                    cardData: cardData.value,
                    paginate: teamMemberListMeta.value.per_page < teamMemberListMeta.value.total,
                    paginateType: "nextPrevPagination",
                    isLoading: isLoadingTeamMembers.value,
                }
            }
        );

        const isLoadingTeamMembers = computed(() => {
            return store.getters['AdminTeam/isLoading']
        })
        onMounted(async() => {
            const payload = {
                data: {
                    limit: props.memberLimit,
                },
            }
            await fetchTeamMembers(payload)
            emit('table-ref', tableRef.value)
        });

        // START OF SHOW ALLOCATE CREDITS MODAL
        const membersToReplenishList = computed(() => {
            return store.getters['AdminTeam/membersToReplenishList']
        })
        const onShowCreditsModal = async () => {
            store.commit('AdminTeam/setShowReplenishModal', true)
            if (_.isEmpty(membersToReplenishList.value) || membersToReplenishList.value.length <= 1) {
                const payload = {
                    data: {
                        limit: 1000,
                    },
                    type: 'credits-allocation'
                }
                await fetchTeamMembers(payload);
            }

        }
        // END OF SHOW ALLOCATE CRESITS MODAL

        return {
            databaseIcon,
            cardData,
            tableProps,
            teamMemberList,
            tableRef,
            nextButtonState,
            prevButtonState,
            teamMemberListMeta,
            isLoadingTeamMembers,
            onPaginate,
            onShowCreditsModal
        }
    }
}
</script>

<style lang="scss">
.team-member-list-component {
    .team-member-list-container {
        .header-container {
            @apply flex justify-between items-center;
            .header-title {
                @apply text-lg font-medium text-gray-900;
            }
            .header-sub-title {
                @apply text-sm font-normal text-gray-500;
            }
        }
    }
}
</style>