<template>
    <div class="device-settings-modal-component">
        <slot-modal-component
            v-model="showModal"
            :height="0"
            :scrollable="false"
            footer-class="mt-0"
            distinct-class="device-settings-modal"
            @close="resetModal()"
            @input="resetModal()"
            @modal-closed="resetModal()"
        >
            <template v-slot:header>
                <div class="pharma-flex-between px-4">
                    <h3 class="message">Device Settings</h3>
                    <div
                        class="icon icon__sm rounded bg-gray-300 cursor-pointer"
                        @click="resetModal()"
                    >
                        <XIcon aria-hidden="true" class="text-white" />
                    </div>
                </div>
            </template>
            <template v-slot:body>
                <div class="px-4 space-y-3 flex flex-col">
                    <div>
                        <settings-component
                            @settings-applied="onUpdateSettings()"
                        />
                    </div>
                </div>
            </template>
        </slot-modal-component>
    </div>
</template>

<script>
import SettingsComponent from "./settings-component.vue";
import { SlotModalComponent } from "@CommonComponents";
import { toRef } from "vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
    name: "SettingsModalComponent",
    components: {
        SettingsComponent,
        SlotModalComponent,
        XIcon,
    },
    props: {
        modelValue: Boolean,
        customConfiguration: { type: Object, default: null },
    },
    emits: [ "update:modelValue", "settings-applied" ],
    setup(props, { emit }) {
        const showModal = toRef(props, "modelValue");

        const onChangeSettings = () => {
            resetModal();
        };

        const onUpdateSettings = () => {
            emit("settings-applied");
            resetModal();
        };

        const resetModal = () => {
            showModal.value = false
            emit("update:modelValue", false);
        };

        const onApplySettings = () => {
            resetModal();
        };
        return {
            showModal,
            onChangeSettings,
            onUpdateSettings,
            resetModal,
            onApplySettings,
            // onShowModal,
        };
    },
};
</script>