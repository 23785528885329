
<template>
    <SwitchGroup as="div" class="flex items-center">
        <Switch
            v-model="enabled"
            :class="[
                enabled ? 'bg-green-700' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            ]"
            @update:modelValue="processEvents"
        >
            <span
                aria-hidden="true"
                :class="[
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                ]"
            />
        </Switch>
        <SwitchLabel as="span" class="ml-3">
            <span class="text-sm font-medium text-gray-900">
                {{ label }}
            </span>
        </SwitchLabel>
    </SwitchGroup>
</template>

<script>
import { ref } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
    name: "SwitchComponent",
    components: {
        Switch,
        SwitchGroup,
        SwitchLabel,
    },
    props: {
        modelValue: Boolean,
        defaultValue: Boolean,
        selected: Boolean,
        returnValue: Boolean,
        label: { type: String, default: null },
    },

    emits: [ "update:modelValue", "changed:value" ],
    setup(props, { emit }) {
        const enabled = ref(props.defaultValue);

        const processEvents = () => {
            // emit("update:modelValue", enabled.value);
            emit("changed:value", enabled.value);
        };

        return {
            enabled,
            processEvents,
        };
    },
};
</script>