<template>
    <div class="divider-component">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center">
            <span v-if="type === 'text'" class="divider__text">{{ text }}</span>

            <div v-if="type === 'button'" class="divider__icon">
                <slot name="button-icon" />
            </div>

            <span v-if="type === 'icon'" class="divider__icon">
                <slot name="icon" />
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "DividerComponent",
    props: {
        type: { type: String, default: "text" },
        text: { type: String, default: "Not you?" },
    },
};
</script>

<style lang="scss">
.divider-component {
    @apply relative my-4;
    .divider {
        &__text {
            @apply px-2 bg-white text-sm text-gray-500;
        }
        &__button {
            @apply inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white;
            &:hover {
                @apply bg-gray-50;
            }
            &:focus {
                @apply outline-none ring-2 ring-offset-2 ring-indigo-500;
            }
        }
        &__icon {
            @apply bg-white px-2 text-gray-500;
        }
    }
}
</style>