<template>
    <div v-if="displayCard || isLoading" class="invited-card-component">
        <div
            :class="[
                isAppointmentCard
                    ? 'card-no-shadow'
                    : 'card-with-shadow default',
            ]"
        >
            <OutlineCheckIcon
                class="card-icon text-green-400"
                aria-hidden="true"
            />
            <div v-if="!isLoading" class="invited-container">
                <p class="header-title">
                    Invited on this appointment:
                </p>
                <div
                    v-for="(item, index) in data"
                    :key="index"
                    class="invited-list"
                >
                    <p class="p__class">
                        {{ item.fullName }}
                    </p>
                    <p class="p__class">
                        {{ item.position }}
                    </p>
                </div>
            </div>
            <div v-else class="invited-container load_card_animate w-full">
                <p class="header-title h-4 bg-primary-100 rounded w-1/3 mt-px" />
                <div
                    v-for="(item, index) in 2"
                    :key="index"
                    class="invited-list"
                >
                    <p class="p__class h-4 bg-primary-100 rounded w-1/4" />
                    <p class="p__class h-4 bg-primary-100 rounded w-1/3 mt-1" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CheckIcon as OutlineCheckIcon } from "@heroicons/vue/outline";
import { computed } from 'vue'

export default {
    name: "InvitedCardComponent",
    components: {
        OutlineCheckIcon,
    },
    props: {
        data: { type: Object, default: null },
        isAppointmentCard: Boolean,
        isLoading: Boolean
    },
    setup(props) {
        const displayCard = computed(() => {
            return props.data
        })

        return {
            displayCard
        }
    }
}
</script>

<style lang="scss">
.invited-card-component {
    .invited-container {
        @apply ml-0 space-y-1 #{!important};
        .header-title {
            @apply text-sm font-medium text-gray-700;
        }
        .invited-list {
            @apply flex flex-col;
        }
    }
    .p__class {
        @apply text-sm font-medium font-normal text-gray-500  px-0;
    }
}
</style>