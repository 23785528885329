/* eslint-disable indent */
/* eslint-disable no-undef */
import axios from 'axios'
import { useStore } from "vuex";
import { Tokens } from '@Utilities/enum'
import { STORAGE } from "@Plugins/storage.js";
const userType = STORAGE.GET_COOKIES(Tokens.UserType);

const api = async function(METHOD = 'GET', REQUEST_URI = '', PARAMS = {}, authorization, headers = {}) {
    let defaultHeaders = {
        'Accept': 'application/json',
        'platform-type': 'web',
        'app-version': process.env.VUE_APP_VERSION,
        'app-name': process.env.VUE_APP_NAME
    }

    if (_.isEmpty(headers)) {
        headers = { 'user-type': userType }
    } else if (_.has(headers, 'user-type') && !headers['user-type']) {
        headers = {}
    }
    defaultHeaders = _.merge(defaultHeaders, headers)

    const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: defaultHeaders
    })
    if (authorization) {
        let token_expired = false
        let token = JSON.parse(localStorage.getItem(`${authorization}`))
        if (!_.isEmpty(token)) {
            if (!_.isEmpty(token.expiration_date)) {
                const now = new Date().getTime()
                const expiration_date = new Date(token.expiration_date).getTime()
                token_expired = now >= expiration_date
            } else {
                token_expired = true
            }
        }
        if (_.isEmpty(token) || token_expired) {

            const store = useStore();
            store.dispatch('auth/clientTokenRequest');

            // await store.dispatch('auth/clientTokenRequest')
            token = JSON.parse(localStorage.getItem(`${authorization}`))
        }
        // not working
        axiosInstance.defaults.headers.common['Authorization'] = `${token.token_type} ${token.access_token}`
        axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    }
    METHOD = METHOD.toUpperCase()

    try {
        let response = {}
        switch (METHOD) {
            case 'GET':
                response = await axiosInstance.get(REQUEST_URI, { params: PARAMS })
                break
            case 'POST':
                response = await axiosInstance.post(REQUEST_URI, PARAMS)
                break
            case 'PATCH':
                response = await axiosInstance.post(REQUEST_URI, PARAMS)
                break
            case 'DELETE':
                response = await axiosInstance.get(REQUEST_URI, { params: PARAMS })
                break
        }
        return response
    } catch (error) {
        if (error.response) {
            if (error.response.statusText === 'Unauthorized' || error.response.status === 401) {
                localStorage.removeItem(authorization)
                location.reload()
            }
        }

    }
}

export default api