import { Tokens } from '@Utilities/enum'
export default {
    namespaced: true,
    state: {
        isLoading: false,
    },
    getters: {
        isLoading: (state) => {
            return state.isLoading
        },
    },
    actions: {
        async sendFeedback({ commit }, payload) {
            try {
                commit('setIsLoading', true)

                const response = await api('POST', 'pharma/api/appointment/feedback/add', payload, Tokens.Client, { 'user-type': payload.user_type })

                console.warn(response)
                commit('setIsLoading', false)
            } catch (error) {
                console.warn(error)
            }
        },
        async sendRemarks({ commit }) {
            try {
                commit('setIsLoading', true)

                commit('setIsLoading', false)
            } catch (error) {
                console.warn(error)
            }
        },

    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
    },
}
