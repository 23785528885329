import { createApp } from "vue";
import App from "./App.vue";
import {
    SpinnerComponent,
    ButtonComponent,
} from '@GlobalComponents';
import router from "./router";
import store from "./store";
import "./plugins/directives" // --- not working
import './assets/scss/tailwind.scss'
import api from './utilities/api'
import vClickOutside from "click-outside-vue3"
import Vue3Sanitize from "vue-3-sanitize";
import _ from 'lodash';
import i18n from './plugins/methods'
import VueSplide from '@splidejs/vue-splide';
import 'animate.css';

import GlobalHelperMixins from './plugins/mixins/global_helper_mixins'

const app = createApp(App)

app.config.globalProperties._ = _
app.config.globalProperties.api = api

window.api = api;

const defaultOptions = {
    allowedTags: [ 'a', 'b', 'p', 'div', 'span', 'ul', 'ol', 'li', 'strong', '<br>', 'u' ],
    allowedAttributes: {
        'a': [ 'href' ]
    }
};

// GLOBAL COMPONENTS REGISTATION
app.component('SpinnerComponent', SpinnerComponent)
app.component('ButtonComponent', ButtonComponent)

app.mixin(GlobalHelperMixins)

app.use(store)
    .use(router)
    .use(vClickOutside)
    .use(Vue3Sanitize, defaultOptions)
    .use(VueSplide)
    .use(i18n)
    .mount("#app");