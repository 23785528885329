import { Tokens } from '@Utilities/enum'

export default {
    namespaced: true,
    state: {
        specialties: [],
        specialtyLoading: false,
        locations: [],
        locationLoading: false,
        preferenceLoading: false,
        userPreference: [],
        preferenceMessage: "",
        showPreference: {
            action: "update", // update, create
            show: false
        },
        experience: [
            { value: "", label: "Any" },
            { value: "0,5", label: "< 5 years", years_of_practice_from: "0", years_of_practice_to: "5" },
            { value: "6,10", label: "6 to 10 years", years_of_practice_from: "6", years_of_practice_to: "10" },
            { value: "11,15", label: "11 to 15 years", years_of_practice_from: "11", years_of_practice_to: "15" },
            { value: "16,20", label: "16 to 20 years", years_of_practice_from: "16", years_of_practice_to: "20" },
            { value: "21,25", label: "21 to 25 years", years_of_practice_from: "21", years_of_practice_to: "25" },
            { value: "26,0", label: "26 years or more", years_of_practice_from: "26", years_of_practice_to: "0" },
        ],
        availabilities: [
            { value: 1, label: "Monday" },
            { value: 2, label: "Tuesday" },
            { value: 3, label: "Wednesday" },
            { value: 4, label: "Thursday" },
            { value: 5, label: "Friday" },
            { value: 6, label: "Saturday" },
            { value: 7, label: "Sunday" },
            { value: null, label: "Any Day" },
        ],
    },
    getters: {
        specialties(state) {
            return state.specialties
        },
        specialtyLoading(state) {
            return state.specialtyLoading
        },
        locations(state) {
            return state.locations
        },
        locationLoading(state) {
            return state.locationLoading
        },
        experience(state) {
            return state.experience
        },
        availabilities(state) {
            return state.availabilities
        },
        preferenceLoading(state) {
            return state.preferenceLoading
        },
        userPreference(state) {
            return state.userPreference
        },
        preferenceMessage(state) {
            return state.preferenceMessage
        },
        showPreference(state) {
            return state.showPreference
        },
    },
    actions: {
        async getSpecialtyList({ commit }, payload) {
            commit('setSpecialtyLoading', true)

            try {
                const params = {
                    limit: 300
                }
                const url = payload ? `api/specialty/search/${payload}` : 'api/specialty/search'
                const response = await api(
                    'get',
                    url,
                    params,
                    Tokens.Personal,
                    { 'app-name': 'md-web-portal' }
                )

                if (response && _.has(response.data, 'data')) {
                    const { data } = response.data
                    commit('setSpecialties', data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setSpecialtyLoading', false)
        },
        async getLocationList({ commit }, payload) {
            commit('setLocationLoading', true)
            // code refactor in the future ( optimize search location), for now, load 3500 location - sarah
            try {
                const params = {
                    limit: 3500
                }
                const url = payload ? `api/location/search/${payload}` : 'api/location/search'
                const response = await api(
                    'get',
                    url,
                    params,
                    Tokens.Personal,
                )

                if (response && _.has(response.data, 'data')) {
                    const { data } = response.data
                    commit('setLocations', data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setLocationLoading', false)
        },
        async managePreference({ commit }, data) {
            commit('setPreferenceLoading', true)

            try {
                const url = `pharma/api/preference/${data.action}`
                const response = await api(
                    'post',
                    url,
                    data.payload,
                    Tokens.Personal
                )

                if (response?.data?.success) {
                    commit('setPreferenceMessage', response.data.message)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setPreferenceLoading', false)
        },
        async getPreference({ commit }) {
            commit('setPreferenceLoading', true)
            try {
                const url = 'pharma/api/preference/get'
                const response = await api(
                    'get',
                    url,
                    {},
                    Tokens.Personal,
                )

                if (response && response.data && response.data.success) {
                    commit('setUserPreference', response.data.data)
                } else {
                    // HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX
                    // commit('setShowPreference', { action: "create", show: true })
                    commit('setPreferenceMessage', response.message)
                }
            } catch (error) {
                console.warn(error)
                commit('setPreferenceLoading', false)
            }
            commit('setPreferenceLoading', false)
        }
    },
    mutations: {
        setSpecialties(state, payload) {
            state.specialties = payload
        },
        setSpecialtyLoading(state, payload) {
            state.specialtyLoading = payload
        },
        setLocations(state, payload) {
            state.locations = payload
        },
        setLocationLoading(state, payload) {
            state.locationLoading = payload
        },
        setPreferenceLoading(state, payload) {
            state.preferenceLoading = payload
        },
        setUserPreference(state, payload) {
            state.userPreference = payload
        },
        setPreferenceMessage(state, payload) {
            state.preferenceMessage = payload
        },
        setShowPreference(state, payload) {
            state.showPreference = payload
        },
    }
}
