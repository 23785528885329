<template>
    <div class="doctors-page-component">
        <navigation-tabs-component
            v-if="tabName"
            v-model="tablistsSched"
            @update:modelValue="onChangeTab($event)"
        />
        <!-- TAB CONTENT -->
        <div class="tab-content" :class="{'no-tab-content' : !tabName }">
            <router-view
                @get-doctor-id="doctorId = $event"
                @set-tab-name="tabName = $event"
            />
        </div>

        <profile-slideover-component
            v-model="showProfileSlideover"
            :doctor-id="doctorId"
        />
        <appointment-slideover-component
            v-model="showAppointmentSlideover"
        />
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { NavigationTabsComponent } from "@CommonComponents";
import {
    ProfileSlideoverComponent,
    AppointmentSlideoverComponent,
} from "@CommonComponents/slideovers";
import useNavtab from "@GlobalFunctions/useNavtab.js"

export default {
    name: "DoctorsPageComponent",
    components: {
        NavigationTabsComponent,
        ProfileSlideoverComponent,
        AppointmentSlideoverComponent,
    },
    props: {
        pageName: { type: String, default: "" },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        // GET DOCTOR ID
        const doctorId = ref(null);

        // START OF SHOW DOCTOR SLIDEOVER
        const showProfileSlideover = computed(() => store.getters["CommonDoctor/profileSlideover"]);
        // END OF SHOW SLIDEOVER

        // START OF SHOW APPOINTMENT SLIDEOVER
        const showAppointmentSlideover = computed(() => store.getters['CommonAppointment/appointmentSlideover'])
        // END OF SHOW APPOINTMENT SLIDEOVER

        // START OF HIDE SLIDEOVER
        const hideSlideover = () => {
            store.commit("CommonDoctor/setProfileSlideover", false);
            store.commit('CommonAppointment/setAppointmentSlideover', false);
        }
        // END OF HIDE SLIDEOVER

        // START OF NAVIGATION TAB
        const {
            tablistsSched,
        } = useNavtab();

        // SET ACTIVE TAB WHEN CLICKING REDIRECTION LINK FROM EMPTY STATES OR ANY OTHER PAGE ETC.
        const tabName = ref("")
        // refactor- maybe we dont need this
        const selectActiveTab = (list) => {
            _.forEach(tablistsSched.value, (i) => {
                list === i.tab ? (i.current = true) : (i.current = false);
            });
        };
        onMounted(() => {
            selectActiveTab(tabName.value);
            store.commit("CommonUtilities/setPageName", props.pageName);
        });

        // REDIRECT TO DESIGNATED ROUTE WHEN SWITCHING TO ANOTHER TAB
        const onChangeTab = async(event) => {
            if (event) {
                tablistsSched.value = event;

                const tabname = _.find(tablistsSched.value, (i) => i.current).tab;

                router.push({ path: `/medrep/doctors/${tabname}` });
                // HIDE SLIDEOVER
                if (showProfileSlideover.value || showAppointmentSlideover.value) {
                    hideSlideover();
                }
            }
        };
        // END OF NAVIGATION TAB

        return {
            tablistsSched,
            tabName,
            onChangeTab,
            showProfileSlideover,
            showAppointmentSlideover,
            doctorId,
        };
    },
};
</script>

<style lang="scss">
.doctors-page-component {
    @apply h-full relative;
    .navigation-tabs-component {
        @apply relative;
        z-index: 1;
        .nav-tabs {
            height: 54px;
            z-index: 1;
            @apply w-full bg-white;
        }
    }
    .tab-content {
        @apply absolute top-0 w-full h-full;
        padding-top: 54px;
        // height: calc( 100% - 54px);
        &.no-tab-content {
            // padding-top: 0;
            // height: 100%;
            // @apply h-full;
            // top: -54px;
        }
    }
}
</style>