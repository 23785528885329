<template>
    <div class="load-more-component" :style="`width: ${width}`">
        <!-- HEADER -->
        <div v-if="$slots.header" class="load-more-header">
            <slot name="header" />
        </div>

        <!-- BODY -->
        <!-- code refactor: remove loadMoreBodyClass -->
        <div
            :id="uniqueBodyId"
            :class="['load-more-body', loadMoreBodyClass]"
            :style="scrollable ? `height: ${scrollHeight}px` : ''"
            @scroll.passive="handleScroll($event)"
        >
            <slot />
        </div>
        <spinner-component v-if="loadMoreLoading" class="load-more-loading center" :size="28" />

        <!-- code refactor: remove loadMoreFooterClass -->
        <!-- FOOTER: FLOATING SCROLL -->
        <div :class="['load-more-footer', loadMoreFooterClass]">
            <div
                v-if="isOverflown && scrollable && showScrollMore "
                :id="uniqueId"
                :class="setbgColor"
                class="read-more-btn"
            >
                <button type="button" class="pill-btn__xs">
                    {{ loadMoreLabel }}
                </button>
            </div>
            <div v-if="$slots.footer" ref="actionBtnHeight" class="action-btn">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUpdated, watch, computed } from "vue";

export default {
    name: "LoadMoreComponent",
    props: {
        height: { type: [ Number, String ], default: 300 },
        width: { type: String, default: "unset" },
        scrollable: { type: Boolean, default: true },
        showScrollMore: { type: Boolean, default: true },
        loadMoreLabel: { type: String, default: "Scroll down to read more" },
        uniqueId: { type: [ Number, String ], default: null },
        uniqueBodyId: { type: [ Number, String ], default: null },
        bgColor: { type: String, default: "white" },
        loadMoreFooterClass: { type: String, default: "" },
        loadMoreBodyClass: { type: String, default: "" },
        loadMoreLoading: Boolean,
    },
    emits: [ "get-action-btn-height", "infinite-scroll" ],
    setup(props, { emit, slots }) {
        const isOverflown = ref(false);
        const scrollHeight = ref(0);
        const scrollPosition = ref(0);
        const actionBtnHeight = ref(0);

        const debouncedScroll = _.debounce((event) => {
            const element = event.srcElement
            const currentScrollPosition = element.scrollTop;
            if (isOverflown.value) {
                const el = document.getElementById(props.uniqueId);
                if (currentScrollPosition > scrollPosition.value) {
                    el.classList.add("scroll-down");
                } else {
                    el.classList.remove("scroll-down");
                }
            }
            scrollPosition.value = currentScrollPosition;
            const scrollHeight = element.scrollHeight;
            const totalHeight = _.ceil(element.scrollTop + element.offsetHeight);
            if (!(scrollHeight > totalHeight)) {
                emit("infinite-scroll")
            }
        }, 15);

        const checkOverFlow = () => {
            // GET HEIGHT OF THE CONTENT BODY
            const content = document.getElementById(props.uniqueBodyId)
            if (_.isNull(content) || _.isNull(content.firstElementChild)) {
                return
            }
            let contentHeight = 0
            contentHeight = content.firstElementChild.offsetHeight;
            // scrollHeight IS THE HEIGHT OF THE CONTENT CONTAINER
            isOverflown.value = contentHeight > scrollHeight.value;
        };

        const handleScroll = (event) => {
            debouncedScroll(event);
        };

        watch(
            () => props.height,
            (newVal) => {
                scrollHeight.value = newVal;
                setTimeout(() => checkOverFlow(), 0)
            }
        );

        // SET DYNAMIC BACKGROUND COLOR
        const setbgColor = computed(() => {
            return `from-${props.bgColor}`
        })

        onMounted(() => {
            if (slots.footer) {
                emit("get-action-btn-height", actionBtnHeight.value.offsetHeight);
            } else {
                emit("get-action-btn-height", 0);
            }
            scrollHeight.value = props.height
        });

        onUpdated(() => {
            setTimeout(() => checkOverFlow(), 0)
        });

        return {
            isOverflown,
            scrollHeight,
            handleScroll,
            actionBtnHeight,
            setbgColor,
        };
    },
};
</script>

<style lang="scss">
.load-more-component {
    .load-more-header {
        @apply px-6;
    }
    .load-more-body {
        @apply overflow-y-auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0; /* Remove scrollbar space */
            background: transparent; /* Optional: just make scrollbar invisible */
        }
    }
    .load-more-footer {
        @apply relative bg-white;
        .read-more-btn {
            top: -54px;
            @apply flex justify-center absolute w-full justify-center left-0 py-3 overflow-hidden bg-gradient-to-t;
            // background: linear-gradient(0deg, rgba(255,255,255,100) 0%, rgba(255,255,255,.7) 50%, rgba(255,255,255,0) 100%);
            button {
                transition: all ease 0.3s;
            }
            &.scroll-down {
                @apply bg-none;
                z-index: -1;
                button {
                    @apply opacity-0;
                    transform: translateY(100%);
                }
            }
        }
        .action-btn {
            @apply flex pt-2 justify-between;
        }
    }
}
</style>