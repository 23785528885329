<template>
    <div class="feedback-card-component">
        <div class="feedback-card-container">
            <div class="feedback-card-content">
                <image-card-component
                    :img-src="
                        require('@Images/dashboard-images/feedback-card.png')
                    "
                    :have-shadow="false"
                />
                <div class="feedback-card-header-container">
                    <div class="feedback-card-header-content">
                        <p class="header-title">Got a minute?</p>
                        <p class="header-subtitle">Help us improve your overall experience</p>
                    </div>
                    <div>
                        <a href="mailto:we.care@ppd.ph?subject=New feedback from PPD Clinic Pharma">
                            <button-component
                                type="primary-btn"
                                label="Share a feedback"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageCardComponent from "./image-card-component.vue";

export default {
    name: 'FeedbackCardComponent',
    components: {
        ImageCardComponent
    },
    props: {
    }
}
</script>

<style lang="scss">
.feedback-card-component {
    .feedback-card-container {
        .feedback-card-content {
            @apply relative;
            .feedback-card-header-container {
                @apply absolute bottom-0 mb-6 ml-6 space-y-3 w-40;
                .feedback-card-header-content {
                    @apply space-y-2;
                    .header-title {
                        @apply text-xl font-medium text-green-700;
                    }
                    .header-subtitle {
                        @apply text-sm font-normal text-gray-500;
                    }
                }
            }
        }
    }
}
</style>