<template>
    <div v-if="displayCard || isLoading" class="appointment-settings-card-component" :class="containerClass">
        <!-- for refactor: remove appointment date here -->
        <!-- APPOINTMENT DATE -->
        <div
            v-if="settings === 'date'"
            class="appointment-settings appointment-settings__date"
        >
            <div
                :class="{'dualLine' : dualLine}"
                class="appointment-settings__date--container"
            >
                <div :class="[ { 'w-full' : isLoading }, 'date-details']">
                    <CalendarIcon aria-hidden="true" class="card-icon" />
                    <div v-if="!isLoading" class="date-details-content mt-px">
                        <!-- FOR PREVIEW REQUEST APPOINTMENT -->
                        <template v-if="previewAppointment">
                            <template v-if="!_.isEmpty(appointment.appointment_date)">
                                <p class="new-requested-date__label">Appointment date</p>
                                <div class="flex items-center space-x-1 mb-2">
                                    <p class="new-requested-date">{{ appointment.appointment_date }}</p>
                                    <dot-component dot-color="text-gray-400" />
                                    <p class="appointment-time">{{ !appointment.appointment_time ? 'Any time of the day' : appointment.appointment_time }}</p>
                                </div>
                            </template>
                            <p
                                v-if="description"
                                class="text-sm leading-5 font-normal text-gray-500"
                            >
                                {{ description }}
                            </p>
                        </template>

                        <template v-else>
                            <template v-if="!appointment.appointment_time && !appointment.appointment_date">
                                <p
                                    class="font-text-sm leading-5 font-medium text-gray-700"
                                >
                                    The doctor will provide the schedule for this appointment
                                </p>
                            </template>
                            <template v-else>
                                <p>{{ appointment.appointment_date }}</p>
                                <dot-component v-if="!dualLine" dot-color="text-gray-400" />
                                <p class="appointment-time">{{ appointmentTime }}</p>
                                <p
                                    v-if="description"
                                    class="text-sm leading-5 font-normal text-gray-500 mt-2"
                                >
                                    {{ description }}
                                </p>
                            </template>
                        </template>
                    </div>
                    <div v-else class="date-details-content mt-px load_card_animate w-full flex justify-between">
                        <p class="h-4 bg-primary-100 rounded w-1/2 mt-px" />
                        <p class="h-4 bg-primary-100 rounded w-1/4 mt-px" />
                    </div>
                </div>
                <!-- BADGE IS REMOVED FOR PREVIEW APPOINTMENT -->
                <badge-component
                    v-if="type && !previewAppointment && showAppointmentStatus && !isLoading"
                    :label="appointment.status ? $CAPITALIZE(status) : ''" :type="type"
                    class="self-start"
                />
                <credit-component
                    v-if="!type && !forRemarks && !isLoading"
                    :credit="appointmentCredit"
                />
                <ArrowRightIcon v-if="forRemarks && !isLoading" class="arrow-color" />
            </div>
        </div>

        <!-- APPOINTMENT CLINIC -->
        <div
            v-if="settings === 'clinic'"
            class="appointment-settings appointment-settings__clinic"
        >
            <OfficeBuildingIcon aria-hidden="true" />
            <p>{{ appointment.clinic_name }}</p>
        </div>

        <!-- APPOINTMENT CITY -->
        <div
            v-if="settings === 'city'"
            class="appointment-settings appointment-settings__city"
        >
            <LocationMarkerIcon aria-hidden="true" />
            <p>{{ appointment.location }}</p>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import {
    CalendarIcon,
    OfficeBuildingIcon,
    LocationMarkerIcon,
    ArrowRightIcon,
} from "@heroicons/vue/outline";
import BadgeComponent from "../badge-component.vue";
import CreditComponent from "../credit-component.vue";
import DotComponent from '@CommonComponents/dot-component.vue';

export default {
    name: "AppointmentSettingsCardComponent",
    components: {
        CalendarIcon,
        OfficeBuildingIcon,
        LocationMarkerIcon,
        BadgeComponent,
        CreditComponent,
        ArrowRightIcon,
        DotComponent,
    },
    props: {
        appointment: { type: [ Object, Number ], default: () => {} },
        settings: { type: String, default: () => {} }, // date, city, clinic
        dualLine: { type: Boolean, default: true },
        description: { type: String, default: "" },
        forRemarks: Boolean,
        previewAppointment: Boolean,
        showAppointmentStatus: { type: Boolean, default: true },
        showShortenedTime: Boolean,
        isLoading: Boolean
    },
    setup(props) {
        const type = computed(() => {
            switch (props.appointment.status) {
            case "on_going":
            case "accepted":
                return "success";
            case "declined":
                return "disable";
            case "cancelled":
                return "danger";
            case "served":
                return "";
            default:
                // Pending
                return "pending";
            }
        });

        const status = computed(() => {
            return _.join(_.split(props.appointment.status, '_'), '');
        })

        const containerClass = computed(() => `${props.appointment.status}-appointment`)

        const displayCard = computed(() => {
            return !_.isEmpty(props.appointment)
        })

        const appointmentTime = computed(() => {
            if (props.showShortenedTime) {
                let item
                switch (props.appointment.appointment_time) {
                case "Whole afternoon":
                    item = "Afternoon"
                    break;
                case "Whole morning":
                    item = "Morning"
                    break;
                case null:
                    item = "Any time of the day"
                    break;
                default:
                    item = props.appointment.appointment_time
                    break;
                }
                return item;
            } else {
                return !props.appointment.appointment_time ? "Any time of the day" : props.appointment.appointment_time;
            }
        })

        const appointmentCredit = computed(() => _.head(_.split(props.appointment.credit, '-')))

        return {
            type,
            displayCard,
            containerClass,
            appointmentTime,
            appointmentCredit,
            status,
        }
    },
}
</script>

<style lang="scss">
.appointment-settings-card-component {
    @apply w-full;
    .appointment-settings {
        // min-width: 395px;
        @apply flex items-center space-x-2;
        svg {
            @apply h-5 w-5;
            path {
                @apply text-gray-400;
            }
        }
        p {
            @apply text-sm leading-5 font-medium text-gray-500;
            margin-bottom: 2px;
        }
        &__date {
            // @apply cursor-default ;
            &--container {
                // px-4 py-3
                @apply flex items-center justify-between w-full bg-white;
                .date-details {
                    @apply flex space-x-2 items-center;
                    .date-details-content {
                        @apply flex items-center text-left space-x-2 text-gray-400;
                        .new-requested-date {
                            @apply text-gray-500;
                            &__label {
                                @apply text-gray-700;
                            }
                        }
                    }
                    .card-icon {
                        @apply text-gray-400 ;
                        height: 20px;
                        min-height: 20px;
                        width: 20px;
                        min-width: 20px;
                    }
                }
                p {
                    @apply text-gray-700;
                }
                &.dualLine {
                    .date-details {
                        @apply flex items-start;
                        .date-details-content {
                            margin-top: -2px;
                            @apply inline-block space-x-0;
                        }
                    }
                    .appointment-time {
                        @apply text-gray-500 ;
                    }
                }
            }
        }

        &:hover {
            @apply bg-white shadow-md;
        }
    }
    svg.arrow-color {
        path {
            @apply text-gray-500;
        }
    }
    &.served-appointment {
        .appointment-settings {
            &__date {
                &--container {
                    @apply flex block;
                }
                .date-details {
                    @apply mb-1;
                }
            }
        }
    }
}
@media screen and (min-width: 1600px) {
    .appointment-settings-card-component {
        &.served-appointment {
            .appointment-settings {
                &__date {
                    &--container {
                        @apply flex;
                    }
                }
            }
        }
    }
}
</style>