import { ref, computed, onMounted, onBeforeUnmount, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from "vue-router";
import { UsersIcon } from '@heroicons/vue/outline';

export default function () {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { currentRoute } = router;

    const page = ref(1);
    // START OF FETCH DOCTOR LIST
    const isLoading = computed(() => {
        if (currentRoute.value.name === 'search-doctors-page') {
            return store.getters['CommonDoctor/searchLoading'] && page.value === 1;
        } else {
            return store.getters['CommonDoctor/listLoading'] && page.value === 1;
        }
    })

    // START OF SHOW MORE DOCTOR BUTTON
    const meta = computed(() => store.getters['CommonDoctor/doctorMeta'])
    const showLoadMore = computed(() => {
        return !_.isEmpty(meta.value) && meta.value.current_page !== meta.value.last_page
    })
    // END OF SHOW MORE DOCTOR BUTTON

    const status = ref("");
    // let scroll = 0;
    let loadMoreLoading = ref(false);
    const loadMoreDoctors = async () => {
        if (showLoadMore.value) {
            loadMoreLoading.value = true;
            page.value++
            await getDoctorList(status.value)
            loadMoreLoading.value = false;
            // scroll += 1250

            // TO SCROLL ON TOP OF NEW RESULT FOR LOAD OF EVERY PAGE
            // if (page.value !== 1) {
            //     const body = document.querySelector(".load-more-body");
            //     body.scrollTop = scroll;
            // }
        }
    };
    const getDoctorList = async (value) => {
        status.value = value
        const payload = {
            option: status.value,
            page: page.value,
        }
        await store.dispatch('CommonDoctor/getDoctorList', payload)
    }
    onBeforeUnmount(() => {
        page.value = 1
    });
    // END OF FETCH APPOINTMENT LIST

    // SCROLL AREA HEIGHT COMPUTATION
    const viewportHeight = ref(window.innerHeight);
    const header = ref("");
    const totalHeight = ref(0);
    const container = ref("");
    const actionBtnHeight = ref(0);  // refactor

    onMounted(() => {
        const paddingTop = container.value.getBoundingClientRect().top;
        const paddingBottom = 24 + 28;
        let headerHeight;
        switch (route.name) {
        case "suggested-doctors-page":
            headerHeight = 54;
            break;
        case "search-doctors-page":
            headerHeight = 0;
            break;
        case "my-doctors-page":
            headerHeight = 40;
            break;
        }
        // const paddingBottom =
        //     viewportHeight.value -
        //     container.value.getBoundingClientRect().bottom
        totalHeight.value =
            viewportHeight.value -
            headerHeight -
            paddingTop -
            paddingBottom -
            actionBtnHeight.value

        // console.warn('total', totalHeight.value, 'vh', viewportHeight.value, 'header', headerHeight, 'pt', paddingTop, 'pb', paddingBottom, 'ab', actionBtnHeight.value);
    })
    // END OF SCROLL HEIGHT COMPUTATION

    // START OF SETTING ACTIVE CARD
    const current= ref(0);
    // END OF SETTING ACTIVE CARD

    // START OF SHOWING AND HIDING SLIDEOVER
    const showSlideover = (id) => {
        current.value = id
        store.commit('CommonDoctor/setProfileSlideover', true)
        // HIDE APPOINTMENT SLIDER WHEN NEW DOCTOR INFO IS VIEWED
        store.commit('CommonAppointment/setAppointmentSlideover', false)
    };
    const hideSlideover = () => {
        // HIDE BOTH SLIDEOVER WHEN CLICKED OUTSIDE
        store.commit('CommonDoctor/setProfileSlideover', false)
        store.commit('CommonAppointment/setAppointmentSlideover', false)
    };
    // END OF SHOWING AND HIDING SLIDEOVER

    // START OF EMPTY STATE
    const usersIcon = ref(UsersIcon)
    const emptyStateProps = reactive({
        icon: usersIcon,
        showPopOver: true,
    })
    /// END OF EMPTY STATE

    return {
        getDoctorList,
        loadMoreDoctors,
        isLoading,
        loadMoreLoading,
        current,
        showLoadMore,

        header,
        totalHeight,
        container,
        actionBtnHeight,

        showSlideover,
        hideSlideover,

        emptyStateProps,
    }
}