<template>
    <div class="video__exit_page">
        <div v-if="!_.isEmpty(content)" class="video__exit_main">
            <div class="exit__page_container">
                <div :class="['icon__container', content.bg]">
                    <component
                        :is="content.icon"
                        :class="['icon__base', content.iconColor]"
                    />
                </div>

                <p class="message">{{ content.message }}</p>

                <p class="sub__message">
                    {{ content.subMessage }}
                </p>

                <div v-if="content.path" class="text-center">
                    <button-component
                        :custom-class="content.btnClass"
                        class="p-2 mt-5"
                        small
                        @click="buttonClicked(content.path)"
                    >
                        {{ content.button }}
                    </button-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import {
    ClockIcon,
    StatusOfflineIcon,
    CheckCircleIcon,
} from "@heroicons/vue/outline";
import { useVideoCallDetails } from "./functions";
export default {
    name: "VideoExitPageComponent",
    components: {
        ClockIcon,
        StatusOfflineIcon,
        CheckCircleIcon,
    },
    props: {
        typePages: { type: String, default: null },
        channelId: { type: String, default: "" },
    },
    setup() {
        const { user_type, status, user_uuid } = useVideoCallDetails();
        const router = useRouter();

        const content = computed(() => {
            let content = {};
            switch (status) {
            case "time":
                content = {
                    icon: ClockIcon,
                    iconColor: "text-indigo-700",
                    bg: "bg-indigo-50",
                    message: "Time limit reached",
                    subMessage:
                            "The time limit for this appointment has been fully consumed.",
                    button:
                            user_type === "medrep"
                                ? "Add remarks"
                                : "Share a feedback",
                    path:
                            user_type === "medrep"
                                ? "video-call-remarks"
                                : "video-call-feedbacks",
                };
                break;
            case "feedback-sent":
                content = {
                    icon: CheckCircleIcon,
                    iconColor: "text-green-700",
                    bg: "bg-green-50",
                    message: "Thank you for your feedback",
                    subMessage: "We look forward to your next appointment.",
                };
                break;
            case "disconnected":
                content = {
                    icon: StatusOfflineIcon,
                    iconColor: "text-white",
                    bg: "bg-red-500",
                    message: "You have been disconnected",
                    subMessage:
                            "Check your internet connection and try joining the call again.",
                    button: "Rejoin call",
                    path: "video-call-room",
                };
                break;

            default:
                break;
            }

            return content;
        });

        const buttonClicked = (name) => {
            if (name === "ppd-clinic") {
                window.location.replace(process.env.VUE_APP_PPD_CLINIC_URL);
            }
            const query = {
                user_type,
                uuid: user_uuid,
            };

            router.push({ name, query });
        };

        return {
            buttonClicked,
            content,
        };
    },
};
</script>

<style lang="scss">
.video__exit_page {
    .video__exit_main {
        @apply flex h-screen justify-center items-center;
    }
    .icon__container {
        @apply rounded-full mx-auto mb-4;
        height: 64px;
        width: 64px;
    }

    .icon__base {
        @apply h-full w-10 mx-auto;
    }

    .exit__page_container {
        @apply w-6/12 m-auto self-center text-center;
    }
    .message {
        @apply mb-2;
    }
}
</style>
