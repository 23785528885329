<template>
    <div class="list-component">
        <div
            :class="[isCard ? 'card-container' : 'list-container', 'flex w-auto w-full']"
        >
            <load-more-component
                v-bind="loadMoreProps"
                @infinite-scroll="handleScroll"
            >
                <template v-slot:header>
                    <slot name="header" />
                </template>
                <ul
                    role="list"
                    :class="[ isCard ? 'space-y-2' : 'divide-y divide-gray-200', '']"
                >
                    <li
                        v-for="(item, index) in items"
                        :key="index"
                        :class="[isCard ? '' : 'hover:bg-gray-50', 'cursor-pointer']"
                    >
                        <slot name="card" :data="item" />
                    </li>
                </ul>
                <button-component
                    v-if="showLoadMore && !hasInfiniteLoading"
                    type="white-btn"
                    label="More history"
                    block
                    class="mt-3"
                    @click="$emit('on-load-more')"
                />
                <info-box-component
                    v-if="!showLoadMore && showEndNote"
                    type="custom"
                    title="Looks like you’ve reached the end"
                    :icon="FolderOpenIcon"
                />
                <template v-slot:footer>
                    <div v-if="$slots.footer" ref="actionBtnHeight" class="w-full">
                        <slot name="footer" />
                    </div>
                </template>
            </load-more-component>
        </div>
    </div>
</template>

<script>
import LoadMoreComponent from '../common/load-more-component.vue'
import { computed, onMounted, ref } from "vue";
import InfoBoxComponent from "./info-box-component.vue";
import { FolderOpenIcon } from '@heroicons/vue/outline';

export default {
    name: "ListComponent",
    components: {
        LoadMoreComponent,
        InfoBoxComponent
    },
    props: {
        items: { type: [ Array, Number ], default: () => [] },
        sticky: { type: Boolean },
        scrollable: { type: Boolean, default: true },
        listMaxWidth: { type: String, default: '395' },
        listMaxHeight: { type: [ Number, String ], default: 300 },
        showSpaceBetweenCard: { type: Boolean },
        isCard: { type: Boolean, default: true },
        loadMoreComponentStyle: { type: String, default: '' },
        loadMoreBodyClass: { type: String, default: '' },
        showLoadMore: Boolean,
        loadMoreLabel: { type: String, default: 'Show more' },
        hasInfiniteLoading: Boolean,
        loadMoreLoading: Boolean,
        loadMoreFooterClass: { type: String, default: '' },
        showEndNote: Boolean,
    },
    emits: [ 'get-action-btn-height', 'on-load-more' ],
    setup(props, { emit, slots }) {
        const actionBtnHeight = ref(null);

        //-----------------------COMPUTED-----------------------

        const uniqueId = computed(() => _.random(0, 1000000000));
        const uniqueBodyId = computed(() => _.random(0, 1000000000));

        // COMPONENT PROPERTIES
        const cardProperties = computed(() => {
            return {
                ...props.items.cardProperties,
                cardInfo: props.items.cardInfo,
                isCardForList: true,
                showSpaceBetweenCard: props.items.cardProperties.showSpaceBetweenCard
            }
        })

        const loadMoreProps = computed(() => {
            return {
                height: props.listMaxHeight,
                style: props.loadMoreComponentStyle,
                scrollable: props.scrollable,
                uniqueId: uniqueId.value,
                uniqueBodyId: uniqueBodyId.value,
                loadMoreFooterClass: props.loadMoreFooterClass,
                loadMoreBodyClass: props.loadMoreBodyClass,
                loadMoreLoading: props.hasInfiniteLoading ? props.loadMoreLoading : false
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        onMounted(() => {
            if (slots.footer) {
                emit("get-action-btn-height", actionBtnHeight.value);
            }
        })

        // START OF INFINITE LOADING
        const handleScroll = () => {
            if (props.hasInfiniteLoading) {
                emit("on-load-more");
            }
        }
        // END OF INFINITE LOADING

        return {
            cardProperties,
            actionBtnHeight,
            loadMoreProps,
            handleScroll,
            FolderOpenIcon
        }
    }
}
</script>

<style lang="scss">
.list-component {
    @apply flex h-full w-full;
    .card-container {
        .load-more-body {
            @apply px-1 py-1 ;
        }
    }
    .list-container {
        @apply bg-white ;

    }
    .appointment-settings, .appointment-settings__date--container {
        @apply hover:shadow-none hover:bg-transparent bg-transparent ;
    }
    .load-more-component {
        @apply w-full #{!important} ;
    }
}
</style>