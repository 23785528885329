<template>
    <div class="input-field-component">
        <div class="flex justify-between">
            <label
                :for="name"
                :class="{ 'sr-only': !label }"
                class="label"
            >
                {{ label }}
            </label>
            <span v-if="optionalLabel" class="optional-label">{{ optionalLabel }}</span>
        </div>

        <div class="relative rounded-md shadow-sm">
            <div v-if="prependIcon" class="input__class">
                <component
                    :is="prependIcon"
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                />
            </div>

            <!-- code refactor: this text area should have a separate component -->
            <textarea
                v-if="type === 'textarea'"
                :v-model="modelValue"
                v-bind="textAreaProps"
                @input="onHandleInput"
            />

            <input
                v-else
                :id="name"
                v-model="value"
                v-bind="inputProps"
                :class="prependIcon ? 'pl-10' : ''"
                class="
                    text__input
                    focus:ring-gray-500
                    focus:border-gray-500
                    sm:text-sm
                "
                @input="onHandleInput"
                @focus="onHandleFocus"
                @click="onHandleClick"
                @keyup.enter="onKeyEnter"
            />
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
export default {
    name: "InputFieldComponent",
    props: {
        modelValue: { type: String, default: "" },
        type: { type: String, default: "text" },
        name: { type: String, default: "" },
        label: { type: String, default: "" },
        optionalLabel: { type: String, default: "" },
        prependIcon: { type: [ Function, Boolean ], default: () => {} },
        placeholder: { type: String, default: "" },
        readonly: Boolean,
        height: { type: String, default: "" },
        rows: { type: Number, default: null },
    },
    emits: [ "update:modelValue", "onHandleFocus", "onHandleClick", "on-key-enter" ],
    setup(props, { emit }) {
        const value = ref(props.modelValue);
        const onHandleInput = (event) =>
            emit("update:modelValue", event.target.value);
        const onHandleFocus = () => emit("onHandleFocus");
        const onHandleClick = () => emit("onHandleClick");
        const onKeyEnter = () => emit("on-key-enter");

        const inputProps = reactive({
            type: props.type,
            name: props.name,
            placeholder: props.placeholder,
            readonly: props.readonly,
            autocomplete: "off",
        })

        // COMPONENT PROPERTIES
        const textAreaProps = computed(() => {
            return {
                id: props.name,
                type: "textarea",
                name: props.name,
                height: props.height,
                rows: props.rows,
                class: `${props.prependIcon ? 'pl-10' : ''} text__area focus:ring-gray-500 focus:border-gray-500 sm:text-sm`,
                placeholder: props.placeholder,
                readonly: props.readonly,
                autocomplete: "off"
            }
        })

        return {
            onHandleInput,
            onHandleFocus,
            onHandleClick,
            onKeyEnter,
            value,
            inputProps,
            textAreaProps
        };
    },
};
</script>

<style lang="scss">
.input-field-component {
    input[readonly] {
        @apply text-base leading-6 font-normal text-gray-500;
        &:focus {
            @apply ring-transparent border-transparent #{!important};
        }
    }
    .input__class {
        @apply absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none;
    }

    .text__area,
    .text__input {
        @apply block w-full px-3 py-2 border shadow-sm border-gray-300 rounded-md;
    }
    .optional-label {
        @apply text-sm leading-5 font-normal text-gray-500
    }
    .label {
        @apply block text-gray-700 mb-1;
    }
}
</style>