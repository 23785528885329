// refactor - move this to medrep store
import { Tokens } from '@Utilities/enum'
export default {
    namespaced: true,
    state: {
        profileSlideover: false,
        isLoading: false,
        listLoading: false,
        searchLoading: false,
        doctorProfileLoading: false,
        myDoctorList: [],
        suggestedDoctorList: [],
        doctorProfile: {},
        doctorSchedule: [],
        doctorAppointments: [],
        doctorCoverage: [],
        searchedResult: [],
        doctorMeta: {},
        doctorTabInfoMeta: {},
    },
    getters: {
        // refactor - move to common
        profileSlideover(state) {
            return state.profileSlideover
        },
        isLoading(state) {
            return state.isLoading
        },
        listLoading(state) {
            return state.listLoading
        },
        searchLoading(state) {
            return state.searchLoading
        },
        doctorProfileLoading(state) {
            return state.doctorProfileLoading
        },
        myDoctorList(state) {
            return state.myDoctorList
        },
        suggestedDoctorList(state) {
            return state.suggestedDoctorList
        },
        doctorProfile(state) {
            return state.doctorProfile
        },
        doctorSchedule(state) {
            return state.doctorSchedule
        },
        doctorAppointments(state) {
            return state.doctorAppointments
        },
        doctorCoverage(state) {
            return state.doctorCoverage
        },
        searchedResult(state) {
            return state.searchedResult
        },
        doctorMeta(state) {
            return state.doctorMeta
        },
        doctorTabInfoMeta(state) {
            return state.doctorTabInfoMeta
        },
    },
    actions: {
        async getDoctorList({ commit }, payload) {
            commit('setListLoading', true)

            try {
                const url = '/pharma/api/doctor/get'
                const response = await api('get', url, payload, Tokens.Personal)

                if (response && _.has(response.data, 'data')) {
                    if (!_.isEmpty(payload)) {
                        const { data, meta } = response.data
                        commit("setDoctorMeta", meta)

                        switch (payload.option) {
                        case 'my_doctors':
                            commit('setMyDoctorList', data)
                            break;
                        default:
                            // SUGGESTED
                            commit('setSuggestedDoctorList', data)
                            break;
                        }
                    }
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setListLoading', false)
        },
        async getDoctorProfile({ commit }, payload) {
            commit('setDoctorProfileLoading', true)

            try {
                const url = `/pharma/api/doctor/profile/${payload}`
                const response = await api('get', url, {}, Tokens.Personal, { 'user_type': 'doctor' })

                if (response && response.data.success) {
                    const { data } = response.data

                    commit('setDoctorProfile', data)
                } else {
                    commit('setDoctorProfile', {})
                }

            } catch (error) {
                console.warn(error)
            }

            commit('setDoctorProfileLoading', false)
        },
        async getDoctorTabInfo({ commit }, payload) {
            commit('setIsLoading', true)

            try {
                const limit = _.has(payload, 'limit') ? payload.limit : 10
                const page = _.has(payload, 'page') ? payload.page : 1;
                const params = { limit, page }
                const url = `/pharma/api/doctor/profile/${payload.id}/${payload.option}`
                const response = await api('get', url, params, Tokens.Personal, { 'user-type': 'doctor' })

                if (response && response.data.success) {
                    const { data, meta } = response.data
                    commit('setDoctorTabInfoMeta', meta)
                    switch (payload.option) {
                    case 'schedule':
                        commit('setDoctorSchedule', data.schedules)
                        break;
                    case 'appointments':
                        commit('setDoctorAppointments', data)
                        break;
                    default:
                        // COVERAGE
                        commit('setDoctorCoverage', data)
                        break;
                    }

                } else {
                    switch (payload.option) {
                    case 'schedule':
                        commit('setDoctorSchedule', [])
                        break;
                    case 'appointments':
                        commit('setDoctorAppointments', [])
                        break;
                    default:
                        // COVERAGE
                        commit('setDoctorCoverage', [])
                        break;
                    }
                }

            } catch (error) {
                console.warn(error)
            }

            commit('setIsLoading', false)
        },
        async onSearchDoctors({ commit }, payload) {
            commit('setSearchLoading', true)

            try {
                const url = _.has(payload, 'keyword') ? `/pharma/api/doctor/search/${payload.keyword}` : '/pharma/api/doctor/search'
                const params = _.cloneDeep(payload)
                _.has(payload, 'keyword') ? delete params.keyword : ''
                const response = await api('get', url, params, Tokens.Personal)

                if (response && _.has(response.data, 'data')) {
                    const { data, meta } = response.data
                    commit("setDoctorMeta", meta)
                    commit('setSearchedResult', data)
                }

            } catch (error) {
                console.warn(error)
            }

            commit('setSearchLoading', false)
        },
    },
    mutations: {
        setProfileSlideover(state, payload) {
            state.profileSlideover = payload
        },
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
        setDoctorProfileLoading(state, payload) {
            state.doctorProfileLoading = payload
        },
        setListLoading(state, payload) {
            state.listLoading = payload
        },
        setSearchLoading(state, payload) {
            state.searchLoading = payload
        },
        setMyDoctorList(state, payload) {
            // state.myDoctorList = payload
            state.myDoctorList.push(...payload);
        },
        resetMyDoctorList(state) {
            state.myDoctorList = []
        },
        setSuggestedDoctorList(state, payload) {
            state.suggestedDoctorList = payload
            // state.suggestedDoctorList.push(...payload);
        },
        setDoctorProfile(state, payload) {
            // FOR DOCTOR PROFILE IN SLIDEOVER
            state.doctorProfile = payload
        },
        setDoctorSchedule(state, payload) {
            // FOR DOCTOR SCHED IN SLIDEOVER
            state.doctorSchedule = payload
        },
        resetDoctorSchedule(state) {
            state.doctorSchedule = []
        },
        setDoctorAppointments(state, payload) {
            // FOR DOCTOR APPOINTMENTS IN SLIDEOVER
            state.doctorAppointments = payload
        },
        setDoctorCoverage(state, payload) {
            // FOR DOCTOR COVERAGE IN SLIDEOVER
            // state.doctorCoverage = payload
            state.doctorCoverage.push(...payload);
        },
        resetDoctorCoverage(state) {
            state.doctorCoverage = []
        },
        setSearchedResult(state, payload) {
            // FOR DOCTOR SEARCH
            state.searchedResult = payload
        },
        setDoctorMeta(state, payload) {
            // FOR DOCTOR PAGINATION
            state.doctorMeta = payload
        },
        setDoctorTabInfoMeta(state, payload) {
            // FOR DOCTOR PAGINATION
            state.doctorTabInfoMeta = payload
        },
        resetDoctorTabInfoMeta(state) {
            state.doctorTabInfoMeta = []
        },
    },
}
