<template>
    <div class="feedback__component">
        <div v-if="!isLoading" class="feedback__container">
            <div class="feedback__tips">
                <div class="icons__container mb-4">
                    <div class="icon__indigo icon__rounded icon__l">
                        <ChatIcon class="icon" />
                    </div>
                </div>

                <div class="feedback__message">
                    <b class="message"> Share us your feedback </b>
                    <p class="sub__message mt-2">
                        We are always trying to make our service better for you
                        and your feedback is invaluable.
                    </p>
                </div>
            </div>

            <div class="feedback__form">
                <p class="message">
                    Rate your {{ UserType === "MedRep" ? "Doctor" : "MedRep" }}
                </p>
                <rating-component
                    v-model="feedback.rate"
                    :max-rating="5"
                    class="mb-4"
                />

                <p class="message">How’s your experience with our app?</p>

                <div class="mt-1 mb-6">
                    <textarea
                        v-model="feedback.experience"
                        rows="3"
                        class="__text-area"
                        placeholder="Say something about our app"
                    />
                </div>

                <div class="textbox message">
                    <p class="alignleft">
                        Any recommendations you would like to share?
                    </p>
                    <p class="alignright text-gray-500">Optional</p>
                </div>
                <div style="clear: both" />

                <div class="mt-1 mb-6">
                    <textarea
                        v-model="feedback.recommendation"
                        rows="3"
                        class="__text-area"
                        placeholder="Say something to improve our app"
                    />
                </div>

                <button-component
                    type="primary-btn"
                    label="Share feedback"
                    custom-class="responsive_button"
                    @click="submitFeedback"
                />
            </div>
        </div>
        <div v-else class="full_height">
            <spinner-component
                :size="48"
                class="room__invalid pharma-flex-center"
            />
        </div>
    </div>
</template>

<script>
import { ChatIcon } from "@heroicons/vue/outline";
import { reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useVideoCallDetails } from "../functions";
import RatingComponent from "@CommonComponents/rating-component.vue";
export default {
    name: "FeedbackComponent",
    components: {
        ChatIcon,
        RatingComponent,
    },
    setup() {
        const {
            UserType,
            videoCallDetails,
            user_type,
            queryParams,
            // emptyVideoCallDetails,
        } = useVideoCallDetails();
        const store = useStore();
        const router = useRouter();
        const feedback = reactive({
            rate: 5,
            experience: "",
            recommendation: "",
        });

        const isLoading = computed(() => {
            return store.getters["VideoCall/isLoading"];
        });

        onMounted(() => {
            // if (emptyVideoCallDetails.value) {
            //     router.push({
            //         name: "video-call-room",
            //         query: queryParams,
            //     });
            // }
        });

        const submitFeedback = () => {
            const { doctor, medrep, appointment_details } =
                videoCallDetails.value;

            const { rate, experience, recommendation } = feedback;

            const payload = {
                doctor_id: doctor.id,
                doctor_appointment_id: appointment_details.id,
                pharma_user_id: medrep.id,
                rating: rate,
                feedback: { experience, recommendation },
                type: "medrep_appointment",
                user_type,
            };

            store.dispatch("VideoCallAppointment/sendFeedback", payload);

            const query = {
                ...queryParams,
                status: "feedback-sent",
            };

            router.push({ name: "video-call-ended", query });
        };

        return {
            submitFeedback,
            UserType,
            feedback,
            isLoading,
        };
    },
};
</script>

<style lang="scss">
.feedback__component {
    @apply flex h-screen justify-center p-8;
    .feedback__tips {
        @apply text-center mb-8;
    }
    .feedback__form {
        .message {
            @apply text-sm mb-2;
        }
    }
    .responsive_button {
        @apply inline-block ml-auto w-full lg:w-auto lg:block bg-green-700;
    }
}

@screen lg {
    .feedback__component {
        @apply items-center;
        .feedback__container {
            @apply w-4/12;
        }
    }
}

.alignleft {
    float: left;
}
.alignright {
    float: right;
}
</style>