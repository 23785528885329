import { ref, computed, shallowRef } from 'vue'

export default function () {
    // START OF NAVIGATION TAB
    const tablists = ref([
        { name: "Upcoming", tab: 'upcoming', current: true },
        { name: "Pending",  tab: 'pending', current: false },
        { name: "Done",  tab: 'done', current: false },
        { name: "Declined",  tab: 'declined', current: false },
        { name: "Cancelled",  tab: 'cancelled', current: false },
    ]);
    const AdminTeamtablists = ref([
        { name: "Upcoming", tab: 'upcoming', current: true },
        { name: "Pending",  tab: 'pending', current: false },
        { name: "Completed",  tab: 'done', current: false },
        { name: "Declined",  tab: 'declined', current: false },
        { name: "Cancelled",  tab: 'cancelled', current: false },
    ]);

    const tablistsSched = ref([
        { name: "Suggested", tab: "suggested", current: true },
        { name: "Search", tab: "search", current: false },
        { name: "My Doctors", tab: "mydoctors", current: false },
    ]);

    // TAB PROPERTIES FOR PILL TAB
    const navigationTabProperties = computed(() => {
        return {
            ref: "navTabs",
            contentType: "component",
            tabType: "tab-in-pills",
            alignment: "left",
            componentClass: "my-4"
        }
    });

    // TABLIST FOR ADMIN CREDITS PAGE
    const creditHistoryTablists = shallowRef([
        { name: "All", current: true, userType: "", tab: 'all' },
        { name: "Medrep", current: false, userType: "medrep", tab: 'med-rep' },
        { name: "You", current: false, userType: "district_manager", tab: 'you' },
    ]);

    const onChangeTab = async(event) => {
        if (event) {
            tablists.value = event;
        }
    };

    return {
        tablists,
        AdminTeamtablists,
        tablistsSched,
        navigationTabProperties,
        onChangeTab,
        creditHistoryTablists,
    }
}