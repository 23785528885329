<template>
    <div class="spinner-component" :class="classContainer">
        <svg
            class="animate-spin"
            :class="textColor"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            :viewBox="`0 0 24 24`"
            :height="size"
            :width="size"
        >
            <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
            />
            <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
        </svg>
    </div>
</template>

<script>
import { computed } from 'vue';
export default {
    name: "SpinnerComponent",
    props: {
        size: { type: [ String, Number ], default: 24 },
        color: { type: String, default: "primary" },
        classContainer: { type: String, default: "" },
    },
    setup(props) {
        const textColor = computed(() => `text-${props.color}`);

        return {
            textColor,
        }
    }
};
</script>

<style lang="scss">
.spinner-component {
    &.center {
        @apply h-full flex items-center justify-center ;
    }
}
</style>