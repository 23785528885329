<template>
    <div class="find-doctors-card-component">
        <div class="find-doctors-card-container">
            <div class="find-doctors-card-content">
                <image-card-component
                    :img-src="
                        require('@Images/dashboard-images/find-doctors-card.png')
                    "
                    :have-shadow="false"
                />
                <div class="find-doctors-card-header-container">
                    <div class="find-doctors-card-header-content">
                        <p class="header-title">Find doctors</p>
                        <p class="header-subtitle">Start sending appointment requests</p>
                    </div>
                    <div>
                        <button-component
                            type="primary-btn"
                            label="Search doctors"
                            @on-click="onClickSearchDoctors"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageCardComponent from "./image-card-component.vue";
import { useRouter } from "vue-router";

export default {
    name: 'FindDoctorsCardComponent',
    components: {
        ImageCardComponent
    },
    props: {
    },
    setup() {
        const router = useRouter();
        const onClickSearchDoctors = () => {
            router.push({ path: '/medrep/doctors/search' })
        }

        return {
            router,
            onClickSearchDoctors
        }
    }
}
</script>

<style lang="scss">
.find-doctors-card-component {
    .find-doctors-card-container {
        .find-doctors-card-content {
            @apply relative;
            .find-doctors-card-header-container {
                @apply absolute bottom-0 mb-6 ml-6 space-y-3 w-56;
                .find-doctors-card-header-content {
                    @apply space-y-2;
                    .header-title {
                        @apply text-xl font-medium text-green-700;
                    }
                    .header-subtitle {
                        @apply text-sm font-normal text-gray-500;
                    }
                }
            }
        }
    }
}
</style>