<template>
    <div class="multiple-select-component">
        <label v-if="labelFor" :for="id">{{ labelFor }}</label>
        <Multiselect
            :id="id"
            ref="multiselect"
            v-model="value"
            v-bind="multiSelectProps"
            @select="setSelected(value)"
            @clear="setSelected()"
            @open="openDropDown"
        >
            <template v-slot:clear>
                <span class="hidden" />
            </template>
            <template v-slot:caret>
                <ChevronDownIcon aria-hidden="true" class="multiselect-caret" />
            </template>
        </Multiselect>
        <div class="icon">
            <component
                :is="icon"
                class="icon"
                aria-hidden="true"
            />
        </div>

        <!-- SELECTED ITEMS DISPLAY -->
        <div
            v-if="!_.isEmpty(value) && showTagList && mode === 'multiple'"
            class="space-x-3 mt-4"
        >
            <template v-for="(item, index) in value" :key="index">
                <badge-component
                    :label="item[label]"
                    has-close-icon
                    @on-remove="onRemove(item)"
                />
            </template>
        </div>
    </div>
</template>

<script>
// see https://www.npmjs.com/package/@vueform/multiselect for further documentation
import Multiselect from '@vueform/multiselect'
import { ref, watch } from 'vue'
import BadgeComponent from '../badge-component.vue';
import { ChevronDownIcon } from '@heroicons/vue/outline'
import { computed } from "vue";

export default {
    name: "MultipleSelectComponent",
    components: {
        Multiselect,
        BadgeComponent,
        ChevronDownIcon,
    },
    props: {
        id: { type: String, default: '', },
        modelValue: { type: [ Array, Object, String, Number ], default: () => {}, },
        mode: { type: String, default: '', }, // tags, multiple, single
        closeOnSelect: { type: Boolean, default: true, },
        object: Boolean,
        options: { type: [ Array, Object ], default: () => {}, },
        placeholder: { type: String, default: '', },
        labelFor: { type: String, default: '', },
        noOptionsText: { type: String, default: 'Loading...', },
        loading: Boolean,
        filterResults: { type: Boolean, default: true, },
        resolveOnLoad: { type: Boolean, default: true, },
        resetTrigger: { type: Number, default: 0, },
        trackBy: { type: String, default: 'label', },
        label: { type: String, default: 'label', },
        valueProp: { type: String, default: 'value', },
        icon: { type: Function, default: () => {} },
        prependIcon: Boolean,
        showTagList: Boolean,
        removeOnSelect: Boolean,
        canDeselect: { type: Boolean, default: true, },
    },
    emits: [ "update:model-value", "on-load-data" ],
    setup(props, { emit }) {
        const value = ref(props.modelValue)

        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const multiSelectProps = computed(() => {
            return {
                mode: props.mode,
                closeOnSelect: props.closeOnSelect,
                searchable: true,
                object: props.object,
                options: props.options,
                placeholder: props.placeholder,
                noOptionsText: props.noOptionsText,
                loading: props.loading,
                classes: { singleLabel: [ 'multiselect-single-label input-label', props.removeOnSelect ? 'remove-on-select' : '' ], multipleLabel: 'multiselect-multiple-label input-label' },
                class: props.prependIcon ? 'prepend-icon' : '',
                filterResults: props.filterResults,
                resolveOnLoad: props.resolveOnLoad,
                trackBy: props.trackBy,
                label: props.label,
                valueProp: props.valueProp,
                canDeselect: props.canDeselect,
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const setSelected = (value) => {
            emit("update:model-value", value);
        }

        const openDropDown = () => {
            emit("on-load-data");
        }

        // REMOVE SELECTED OPTION
        const onRemove = (item) => {
            value.value = _.filter(value.value, i => i !== item);
            if (_.isEmpty(value.value)) {
                multiselect.value.clear()
            } else {
                emit("update:model-value", value.value);
            }
        }

        //-------------------METHODS ENDs HERE------------------

        // TO RESET THE SELECTED VALUES
        const multiselect = ref(null)
        watch(
            () => props.resetTrigger,
            () => {
                multiselect.value.clear()
            }
        )

        // UPDATE VALUE EVERYTIME MODELVALUE IS UPDATED
        watch(
            () => props.modelValue,
            (newVal) => {
                value.value = newVal
            }
        )
        return {
            value,
            setSelected,
            openDropDown,
            multiselect,
            onRemove,
            multiSelectProps
        }

    }

}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.multiple-select-component {
    @apply relative;
    label {
        @apply text-sm leading-5 font-medium text-gray-700 mb-1 block;
    }
    .icon {
        @apply absolute h-5 w-5 text-gray-400;
        bottom: 5px;
        left: 5px;
    }
    .multiselect {
        @apply rounded-md;
        &.is-active {
            @apply shadow-none;
        }
        &.is-open {
            @apply rounded-md;
        }
        input {
            @apply shadow-sm text-sm border border-gray-300 rounded-md;
            &:focus {
                @apply ring-2 ring-gray-500 border-gray-500;
            }
        }
        .input-label {
            @apply text-sm leading-5 font-normal text-gray-900;
        }
        &.prepend-icon {
            .multiselect-search, .input-label, .multiselect-placeholder {
                @apply pl-10;
            }
        }
        .multiselect-placeholder, .multiselect-no-results {
            @apply text-sm text-gray-500;
        }
        .multiselect-single-label.remove-on-select {
            &.input-label {
                @apply hidden;
            }
        }
        .multiselect-multiple-label {
            // TO REMOVE THE "1 OPTION SELECTED"
            &.input-label {
                @apply hidden;
            }
        }
        .multiselect-clear {
            .multiselect-clear-icon {
                @apply transition-none ;
            }
        }
        .multiselect-dropdown {
            @apply rounded-md;
            bottom: -5px;
            .multiselect-options {
                .multiselect-option {
                    &.is-pointed {
                        @apply bg-gray-200;
                    }
                    &.is-selected {
                        @apply bg-primary-700 #{!important};
                        span {
                            @apply text-white #{!important};
                        }
                    }
                    span {
                        @apply text-sm leading-5 font-normal text-gray-900;
                    }
                    &.is-disabled {
                        span {
                            @apply text-sm leading-5 font-normal text-gray-400;
                        }
                    }

                }
            }
        }
        .multiselect-spinner {
            @apply bg-primary;
        }
        .multiselect-caret {
            @apply bg-transparent text-gray-500 h-4 w-4;
        }
    }
}
</style>