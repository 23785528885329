<template>
    <div class="dot-component">
        <svg
            :class="[dotColor, dotSize, customClass]"
            fill="currentColor"
            viewBox="0 0 8 8"
        >
            <circle cx="4" cy="4" r="3" />
        </svg>
    </div>
</template>

<script>
import { computed } from "vue";
export default {
    name: "DotComponent",
    props: {
        dotColor: { type: String, default: "" },
        customClass: { type: String, default: "" },
        size: { type: [ String, Number ], default: "2" },
    },
    setup(props) {
        const dotSize = computed(() => {
            return `h-${props.size} w-${props.size}`;
        });

        return {
            dotSize,
        };
    },

}
</script>

<style lang="scss">

</style>