/* eslint-disable no-unused-vars */
import { Tokens } from '@Utilities/enum'
import { database, env, timestamp, httpsCallableHelper, ref, onValue } from "@Plugins/functions/firebase.js"

const createRoom = httpsCallableHelper('createRoom');
const updateRoom = httpsCallableHelper('updateRoom');
const updateRoomUser = httpsCallableHelper('updateRoomUser');
const updateRoomTimer = httpsCallableHelper('updateRoomTimer');

export default {
    namespaced: true,
    state: {
        videoCallDetails: {},
        hasChange: '',
        cameraDevices: [],
        audioDevices: [],
        selectedAudioDevice: {},
        selectedVideoDevice: {},
        hasCamera: true,
        hasAudio: true,
        isLoading: false,
        videoCallStarted: false,
        videoCallReady: false,
        // Firebase Room
        room_info: {},
        room_updating: false,
        room_users: []
    },
    getters: {
        properties: (state) => {
            return state.properties
        },
        audioDevices: (state) => {
            return state.audioDevices
        },
        cameraDevices: (state) => {
            return state.cameraDevices
        },
        selectedAudioDevice: (state) => {
            return state.selectedAudioDevice
        },
        selectedVideoDevice: (state) => {
            return state.selectedVideoDevice
        },
        hasCamera: (state) => {
            return state.hasCamera
        },
        hasAudio: (state) => {
            return state.hasAudio
        },
        hasChange: (state) => {
            return state.hasChange
        },

        videoCallDetails: (state) => {
            return state.videoCallDetails
        },
        isLoading: (state) => {
            return state.isLoading
        },
        videoCallStarted: state => {
            return state.videoCallStarted
        },

        videoCallReady: state => {
            return state.videoCallReady
        },
        room_info: state => {
            return state.room_info
        },
        room_updating: state => {
            return state.room_updating
        },
        room_users: state => {
            return state.room_users
        },
        firebase_timestamp: state => {
            return timestamp
        }
    },
    actions: {
        async videoCallAddAudience({ commit }, payload) {
            try {
                commit('setIsLoading', true)
                //pits change $apiCalll to api
                const response = await api('POST', 'api/video-call/audience/add', payload, Tokens.Client)

                if (response && response.data) {
                    // commit('setVideocallDetails', response.data)
                }

                commit('setIsLoading', false)
            } catch (error) {
                console.warn(error)
            }
        },
        async updateVideoCallAudience(payload) {
            try {
                const response = await api('PATCH', `api/video-call/audience/update/${ payload.call_audience_id }`, payload, Tokens.Client)
                return response

            } catch (error) {
                //
            }
        },
        async validateVideocall({ commit }, payload) {
            try {
                commit('setIsLoading', true)
                const response = await api('GET', 'pharma/api/video-call/validate', payload, Tokens.Client, { 'user-type': payload.user_type })

                if (response && response.data) {
                    if (response.data.data.valid) {
                        commit('setVideocallDetails', response.data.data)
                    } else {
                        commit('setIsLoading', false)
                    }
                } else {
                    commit('setIsLoading', false)
                }

            } catch (error) {
                commit('setIsLoading', false)
                console.warn(error)
            }

        },
        async enterVideoCall({ commit }, payload) {
            try {
                commit('setIsLoading', true)
                const response = await api('POST', 'pharma/api/video-call/add', payload, Tokens.Client, { 'user-type': payload.user_type })

                commit('setIsLoading', false)
                if (response && response.data) {
                    return response.data
                }

            } catch (error) {
                console.warn(error)
            }

        },
        async sendCallNotification({ commit }, payload) {
            try {
                commit('setIsLoading', false)
                const uri = `pharma/api/video-call/notify/${payload.user_type}`
                await api('GET', uri, { appointment_uuid: payload.room_uuid }, Tokens.Client)

            } catch (error) {
                console.warn(error)
            }

        },
        async initAppointmentRoom({ dispatch, state, commit }, payload) {
            try {
                commit('setIsLoading', true)
                const { room_uuid, userType, userId } = payload

                if (_.isEmpty(state.room_info)) {
                    createRoom({
                        env,
                        timestamp,
                        ...payload
                    })
                } else {
                    dispatch('onUpdateRoomUser', {
                        room_uuid,
                        userId,
                        userType,
                        timestamp,
                        userStatus: "active",
                    })
                }

                await dispatch('listenToRoom', room_uuid);
                await dispatch('listenToRoomUsers', room_uuid);
            } catch (error) {
                console.warn(error)
            }
            commit('setIsLoading', false)
        },

        async onUpdateRoomUser({ commit }, payload) {
            try {
                updateRoomUser({
                    ...payload,
                    env
                })
            } catch (error) {
                console.warn(error)
            }
        },

        async onUpdateRoom({ commit }, payload) {
            try {
                commit('setRoomUpdating', true)
                updateRoom({
                    ...payload,
                    env,
                    timestamp
                })
            } catch (error) {
                console.warn(error)
            }
        },

        async getRoomInfo({ commit }, room_uuid) {
            const room_ref = ref(database, `rooms/${room_uuid}`);
            onValue(room_ref, (snapshot) => {
                commit('setRoomInfo', snapshot.val());
            });
        },

        async listenToRoom({ commit, state }, room_uuid) {
            console.warn('listening to room rooms/'+room_uuid);

            const room_ref = ref(database, `rooms/${room_uuid}`);
            onValue(room_ref, (snapshot) => {
                commit('setRoomInfo', snapshot.val())
                commit('setRoomUpdating', !_.isEqual(snapshot.val(), state.room_info ))
            });
        },

        async listenToRoomUsers({ commit }, room_uuid) {
            console.warn(`listening to room rooms/${room_uuid} users.`);

            const room_ref = ref(database, `rooms/${room_uuid}/users`);
            onValue(room_ref, (snapshot) => {
                commit('setRoomUsers', snapshot.val())
            });
            return room_ref;
        },
    },
    mutations: {
        setVideoCallProperties(state, payload) {
            const { reset, key, data } = payload

            if (reset) {
                state[key] = null

            } else {
                state[key] = data
            }

            state.hasChange = new Date().getTime();
        },
        setIsLoading(state, payload) {
            state.isLoading = payload
        },

        setVideoCallStarted(state, payload) {
            state.videoCallStarted = payload
        },

        setVideocallDetails(state, payload) {
            state.videoCallDetails = payload
        },
        setVideoCallReady(state, payload) {
            state.videoCallReady = payload
        },
        setRoomInfo(state, payload) {
            state.room_info = payload
        },
        setRoomUpdating(state, payload) {
            state.updating = payload
        },
        setRoomUsers(state, payload) {
            state.room_users = payload
        }
    },
}
