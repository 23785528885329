import { ref, reactive, computed, watch } from 'vue'
import { SearchIcon } from "@heroicons/vue/outline";
import { LocationMarkerIcon } from "@heroicons/vue/solid";
import { useStore } from 'vuex'

export default function () {
    const store = useStore();

    // const random = ref(null)

    // FORM DATA
    const form = ref({
        specialty_ids: [],
        province_ids: [],
        municipality_ids: [],
        years_of_practice: "",
        availability: [ 1, 2, 3, 4, 5, 6, 7, ],
    });

    // START OF MEDICAL SPECIALTY
    const specialty = ref(null)
    const getPreferences = async () => {
        await store.dispatch('MedrepPreferences/getPreference')
    }
    const preferences = computed(
        () => store.getters["MedrepPreferences/userPreference"]
    );
    const specialtyList = computed(() => {
        return preferences.value.specialties;
    })
    const specialtyLoading = computed(() => {
        return store.getters['MedrepPreferences/preferenceLoading']
    })
    const specialtyProps = reactive({
        id: "specialties",
        mode: "single",
        labelFor: "Medical Specialties",
        options: specialtyList,
        label: "name",
        valueProp: "id",
        trackBy: "specialty_name",
        placeholder: "Search",
        prependIcon: true,
        icon: SearchIcon,
        loading: specialtyLoading,
        // resetTrigger: random,
    })
    watch(
        () => specialty.value,
        (newVal) => {
            if (newVal) {
                form.value.specialty_ids = [ newVal ]
            }
        }
    )
    // END OF MEDICAL SPECIALTY

    // START OF LOCATIONS
    const location = ref(null)
    const getLocationList = async () => {
        await store.dispatch('MedrepPreferences/getLocationList')
    }
    const locationList = computed(() => {
        return store.getters['MedrepPreferences/locations']
    })
    const locationLoading = computed(() => {
        return store.getters['MedrepPreferences/locationLoading']
    })
    const locationProps = reactive({
        id: "locations",
        mode: "single",
        labelFor: "Location",
        options: locationList,
        label: "name",
        valueProp: "id",
        trackBy: "name",
        placeholder: "Search location",
        prependIcon: true,
        icon: LocationMarkerIcon,
        loading: locationLoading,
        object: true,
        // resetTrigger: random,
    })
    watch(
        () => location.value,
        (newVal) => {
            if (newVal) {
                if (newVal.location_type === 'province') {
                    form.value.province_ids = [ newVal.id ]
                    form.value.municipality_ids = [ ]
                } else {
                    form.value.municipality_ids = [ newVal.id ]
                    form.value.province_ids = [ ]
                }
            }
        }
    )
    // END OF LOCATION

    // START YEARS OF PRACTICE
    const yearsOfPracticeList = computed(() => store.getters['MedrepPreferences/experience'])
    const years = ref(yearsOfPracticeList.value[0])
    watch(
        () => years.value,
        (newVal) => {
            form.value.years_of_practice = newVal.value
        }
    )
    // END YEARS OF PRACTICE

    // START OF AVAILABILITY
    const availabilityList = computed(() => store.getters['MedrepPreferences/availabilities'])
    const availabilities = ref(availabilityList.value)
    watch(
        () => availabilities.value,
        (newVal) => {
            const item = _.map(newVal, i => i.value)
            if (item.length === 8) {
                item.pop()
            }
            form.value.availability = item
        }
    )
    // END OF AVAILABILITY

    return {
        form,
        // random,
        preferences,
        specialty,
        getPreferences,
        specialtyProps,

        location,
        getLocationList,
        locationProps,

        years,
        yearsOfPracticeList,

        availabilityList,
        availabilities,
    }
}