<template>
    <div class="schedule-tab-component">
        <template v-if="!isLoading">
            <div v-if="!_.isEmpty(schedules)" class="divide-y divide-gray-200">
                <div v-for="(sched, index) in schedules" :key="index" class="schedule-tab">
                    <div class="schedule-tab__day">{{ sched.day }}</div>
                    <div class="schedule-tab__time">
                        <badge-component
                            v-for="(time, i) in sched.schedules" :key="i" type="disable"
                            with-dot :label="time.label" size="large"
                        />
                    </div>
                </div>
            </div>
            <empty-state-component
                v-else
                :icon="calendarIcon"
                title="Doctor is accepting requests"
                subtitle="You can still send an appointment request to this doctor despite of not having schedule displayed"
                class="mt-6"
            />
        </template>
        <spinner-component v-else class="center mt-16" :size="40" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { BadgeComponent } from '@CommonComponents'
import { CalendarIcon } from '@heroicons/vue/outline'
import { EmptyStateComponent } from "@MedrepComponents";

export default {
    name: 'ScheduleTabComponent',
    components: {
        BadgeComponent,
        EmptyStateComponent,
    },
    props: {
        schedules: { type: Object, default: () => {} },
        isLoading: { type: Boolean, default: false },
    },
    setup() {
        // EMPTY STATE
        const calendarIcon = ref(CalendarIcon)

        return {
            calendarIcon,
        }
    }

}
</script>

<style lang="scss">
.schedule-tab-component {
    .schedule-tab {
        @apply flex w-full py-3 px-6 cursor-pointer ;
        &__day {
            @apply text-center text-xs leading-4 font-semibold tracking-wider uppercase text-primary ;
            max-width: 15%;
            min-width: 15%;
        }
        &__time {
            margin-top: -3px;
            .badge-component {
                @apply mr-2 mb-2 ;
            }
        }
        &:last-of-type {
            @apply border-b border-gray-200 #{!important} ;
        }
    }
}
</style>