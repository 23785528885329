<template>
    <div class="thread-component appointment-thread-component">
        <div ref="container" class="schedule-component-container">
            <empty-state-component
                v-if="_.isEmpty(appointments) && !isLoading"
                v-bind="emptyStateProps"
                :title="emptyTitle"
                class="mt-16"
            />
            <template v-else>
                <!-- HEADER -->
                <div ref="header" class="thread-header mb-2">
                    <h3>{{ title }}</h3>
                </div>

                <load-more-component
                    v-click-outside.prevent="hideSlideover"
                    :unique-body-id="`${type}-body`"
                    :unique-id="uniqueId"
                    :height="totalHeight"
                    :load-more-loading="hasInfiniteLoading ? loadMoreLoading : false"
                    bg-color="gray-50"
                    @get-action-btn-height="actionBtnHeight = $event"
                    @infinite-scroll="handleScroll"
                >
                    <div class="space-y-3">
                        <template
                            v-for="appointment in isLoading ? numberOfLoadingCards : appointments"
                            :key="appointment.id"
                        >
                            <appointment-card-component
                                :data="appointment"
                                :class="{'active': appointment.uuid === current}"
                                v-bind="appointmentProps"
                                @click="showSlider(appointment.uuid)"
                            />
                        </template>
                        <div>
                            <button-component
                                v-if="showLoadMore && !hasInfiniteLoading"
                                type="white-btn"
                                :label="loadMoreLabel"
                                block
                                class="mt-3"
                                :loading="loadMoreLoading"
                                @click="loadMoreAppoinments"
                            />
                            <info-box-component
                                v-if="!showLoadMore"
                                type="custom"
                                title="Looks like you’ve reached the end"
                                :icon="FolderOpenIcon"
                            />
                        </div>
                    </div>
                </load-more-component>
            </template>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import {
    LoadMoreComponent,
    AppointmentCardComponent,
    InfoBoxComponent,
} from '@CommonComponents'
import { EmptyStateComponent } from '@MedrepComponents';
import useSchedule from '@GlobalFunctions/useSchedule.js';
import { AccountType } from '@Utilities/enum';
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";
import { FolderOpenIcon } from '@heroicons/vue/outline';

export default {
    name: 'AppointmentThreadComponent',
    components: {
        LoadMoreComponent,
        AppointmentCardComponent,
        EmptyStateComponent,
        InfoBoxComponent,
    },
    props: {
        tabName: { type: String, default: '' },
        title: { type: String, default: '' },
        uniqueId: { type: String, default: '' },
        type: { type: String, default: '' },
        emptyTitle: { type: String, default: '' },
        appointments: { type: Array, default: () => {} },
        loadMoreLabel: { type: String, default: 'More appointments' },
        hasInfiniteLoading: Boolean,
        numberOfLoadingCards: { type: Number, default: 4 },
        showStatus: Boolean,
    },
    emits: [ "get-appointment-uuid", "set-tab-name" ],
    setup(props, { emit }) {
        const {
            getAppointmentList,
            loadMoreAppoinments,
            // appointmentProps,
            isLoading,
            header,
            totalHeight,
            container,
            actionBtnHeight,
            current,
            showSlideover,
            hideSlideover,
            emptyStateProps,
            showLoadMore,
            loadMoreLoading,
        } = useSchedule();

        // START OF SETTING ACTIVE TAB
        onMounted(async () => {
            emit("set-tab-name", props.tabName)
        })
        // END OF SETTING ACTIVE TAB

        // START OF SHOW APPOINTMENT SLIDEOVER
        const showSlider = (uuid) => {
            emit("get-appointment-uuid", uuid )
            showSlideover(uuid)
        }
        // END OF SHOW APPOINTMENT SLIDEOVER

        // START OF FETCH APPOINTMENT LIST
        onMounted(async() => {
            await getAppointmentList(props.type)
        })
        // END OF FETCH APPOINTMENT LIST

        // START OF SETTING APPOINTMENT PROPS
        const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));
        const isRevoked = computed(() => {
            return props.type === 'declined' || props.type === 'cancelled'
        })
        const appointmentProps = ref({
            showCreditToUse: isRevoked.value,
            showNote: isRevoked.value,
            showMedrepName: userType.value === AccountType.Admin,
            inline: true,
            showStatus: props.showStatus,
            isLoading,
        });
        // END OF SETTING APPOINTMENT PROPS

        // START OF INFINITE LOADING
        const handleScroll = () => {
            if (props.hasInfiniteLoading) {
                loadMoreAppoinments();
            }
        }
        // END OF INFINITE LOADING

        return {
            appointmentProps,
            isLoading,
            loadMoreLoading,
            current,
            showSlider,
            hideSlideover,
            header,
            totalHeight,
            container,
            actionBtnHeight,
            emptyStateProps,
            loadMoreAppoinments,
            showLoadMore,
            handleScroll,
            FolderOpenIcon,
        }
    }

}
</script>

<style lang="scss">
.thread-component {
    &.appointment-thread-component{
        .thread-header {
            h3 {
                @apply text-lg leading-6 font-medium text-gray-900;
            }
        }
        .load-more-component {
            .load-more-body {
                @apply p-1 pb-0;
            }
            .load-more-footer {
                .action-btn {
                    @apply bg-gray-50 ;
                }
            }
        }
        .appointment-card-component {
            &.active {
                @apply ring-2 ring-offset-2 ring-offset-gray-100 ring-gray-500;
            }
            .appointment-settings-card-component, .appointment-body-card-component {
                @apply py-3 #{!important};
            }
            &:last-of-type {
                @apply mb-3 #{!important};
            }
        }
    }
}
</style>