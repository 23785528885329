<template>
    <div class="medrep-access-layout">
        <main class="medrep-access-layout__container">
            <slot />
        </main>
    </div>
</template>

<script>

export default {
    name: 'MedrepAccessLayoutComponent',
    components: {
    }
}
</script>

<style lang="scss">
.medrep-access-layout {
    @apply bg-gray-50 min-h-screen;
    &__container {
        @apply h-full;
    }
}
</style>
// note:
//     Access layout refers to layout for both signin and registration.