<template>
    <div class="remarks__component">
        <div
            class="remarks__container"
            :class="{
                'remarks__container-center': user_type === AccountType.Doctor,
            }"
        >
            <div class="remarks__tips">
                <div class="icons__container mb-4">
                    <div class="icon__success icon__rounded icon__m">
                        <CheckIcon class="icon" />
                    </div>
                </div>

                <div class="remarks__message">
                    <b class="message"> Appointment Ended </b>
                    <p class="sub__message mt-2">
                        {{ content }}
                    </p>
                </div>
            </div>

            <div v-if="user_type === AccountType.Medrep" class="remarks__form">
                <p class="message">
                    Did you provide a sample of your brand for the doctor?
                </p>
                <div class="checkbox__container mb-8 flex">
                    <div
                        v-for="(box, index) in sampleBrand"
                        :key="index"
                        :class="['checkbox', { 'mr-8': !index }]"
                    >
                        <input
                            id="test"
                            v-model="checkedState[index]"
                            type="checkbox"
                            class="__checkbox mr-2"
                            :value="box.value"
                            :checked="box.value === remarks.sample_given"
                            @change="onCheckboxChange(box.value, index)"
                        />
                        <label class="capitalize">{{ box.label }}</label>
                    </div>
                </div>

                <p class="message">Add remarks</p>

                <div class="mt-1 mb-6">
                    <textarea
                        v-model="remarks.remark"
                        rows="3"
                        class="__text-area"
                        placeholder="Type your remarks"
                    />
                </div>

                <button-component
                    type="primary-btn"
                    label="Add remarks"
                    custom-class="responsive_button ml-auto"
                    :disabled="!canProceed"
                    @click="submitRemarks"
                />
            </div>

            <button-component
                v-if="user_type === 'doctor'"
                type="primary-btn"
                label="Share a feedback"
                custom-class="responsive_button mx-auto"
                @click="submitRemarks"
            />
        </div>
    </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
import { AccountType } from "@Utilities/enum";
import { ref, reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import { useVideoCallDetails } from "../functions";
export default {
    name: "RemarksComponent",
    components: {
        CheckIcon,
    },
    setup() {
        const store = useStore();
        const { user_type, emptyVideoCallDetails, queryParams, videoCallDetails } =
            useVideoCallDetails();
        const router = useRouter();

        const sampleBrand = ref([
            { label: "Yes", value: 1 },
            { label: "No", value: 0 },
        ])

        const checkedState  = ref([ true, false ])

        // REMARKS FORM DATA
        const remarks = reactive({
            sample_given: 1,
            remark: "",
        });

        onMounted(() => {
            if (emptyVideoCallDetails.value) {
                router.push({
                    name: "video-call-room",
                    query: queryParams,
                });
            }
        });

        const canProceed = computed(() => {
            return (
                !_.isEmpty(remarks.remark) &&
                user_type === AccountType.Medrep &&
                (checkedState.value[0] || checkedState.value[1])
            );
        });

        const onCheckboxChange = (value, index) => {
            remarks.sample_given = value

            checkedState.value = _.map(_.cloneDeep(checkedState.value), (selected, i) => {
                if (i === index && selected) {
                    return selected = true
                } else {
                    return selected = false
                }
            })
        };

        const submitRemarks = async() => {
            if (user_type === AccountType.Medrep) {
                if (canProceed.value) {
                    const { doctor, appointment_details } = videoCallDetails.value
                    const payload = {
                        doctor_id: doctor.id,
                        doctor_appointment_id: appointment_details.id,
                        content: remarks,
                    }
                    await store.dispatch('CommonAppointment/submitRemarks', payload)
                }
            }

            router.push({
                name: "video-call-feedbacks",
                query: router.currentRoute.value.query,
            });
        };

        const content = computed(() => {
            let label = "";
            if (user_type === AccountType.Doctor) {
                label = "Share us your feedback and earn rewards";
            }

            if (user_type === AccountType.Medrep) {
                label =
                    "Well done! You can now add remarks to your appointment.";
            }
            return label;
        });

        return {
            canProceed,
            submitRemarks,
            user_type,
            remarks,
            content,
            AccountType,
            sampleBrand,
            checkedState,
            onCheckboxChange
        };
    },
};
</script>

<style lang="scss">
.remarks__component {
    @apply flex h-screen justify-center p-8;
    .remarks__container-center {
        transform: translate(0, 36%);
    }
    .remarks__tips {
        @apply text-center mb-8;
    }
    .remarks__form {
        .message {
            @apply text-sm mb-2;
        }
    }
    .responsive_button {
        @apply inline-block  w-full lg:w-auto lg:block bg-green-700;
    }
}

@screen lg {
    .remarks__component {
        @apply items-center;
        .remarks__container {
            @apply w-4/12;
        }
    }
}
</style>