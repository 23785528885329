<template>
    <div class="rating__component">
        <div class="rating__container">
            <div
                v-for="index in maxRating"
                :key="index"
                :class="['rate', { 'rate-active': index <= rate }]"
            >
                <StarIcon @click="onRateChange(index)" />
            </div>
        </div>
    </div>
</template>

<script>
import { StarIcon } from "@heroicons/vue/solid";
import { ref } from "vue";
export default {
    name: "RatingComponent",
    components: {
        StarIcon,
    },
    props: {
        modelValue: { type: Number, default: 0 },
        maxRating: { type: Number, default: 5 },
    },
    emits: [ "update:modelValue" ],
    setup(props, { emit }) {
        let rate = ref(props.modelValue);

        const onRateChange = (index) => {
            rate.value = index;
            emit("update:modelValue", rate.value);
        };

        return {
            rate,
            onRateChange,
        };
    },
};
</script>

<style lang="scss">
.rating__component {
    .rating__container {
        @apply flex;
        .rate {
            @apply text-yellow-200 cursor-pointer;
            width: 24px;
            height: 24px;
        }
        .rate-active {
            @apply text-yellow-400;
        }
    }
}
</style>