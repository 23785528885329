import { RouterViewComponent } from '@GlobalComponents';
import { SignInFormComponent } from '../common/access';

const CommonModuleRoutes = [
    {
        path: "/",
        name: "sign-in",
        component: RouterViewComponent,
        props: { moduleName: 'access-form' },
        meta: {
            title: 'PPD Clinic Pharma',
            layout: 'medrep-access-layout-component'
        },
        children: [
            {
                path: "",
                component: SignInFormComponent,
                name: 'sign-in-page',
            },
        ]
    },
]

export default CommonModuleRoutes