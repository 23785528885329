
import { useStore } from "vuex";
import { computed } from 'vue';
import { useRouter } from 'vue-router'
import { AccountType } from "@Utilities/enum"
import _ from "lodash";
import moment from "moment";

export default function useVideoCallDetails () {
    const store = useStore()
    const router = useRouter();

    const { currentRoute } = router

    const queryParams = currentRoute.value.query

    const { user_type, uuid, status } = queryParams

    const { room_uuid } = currentRoute.value.params

    const UserType = computed(
        () => {
            return user_type === AccountType.Doctor ? 'Doctor' : 'MedRep'
        }
    );

    const  videoCallReady = computed(
        () => store.getters["VideoCall/videoCallReady"]
    )

    const videoCallDetails = computed(
        () => store.getters["VideoCall/videoCallDetails"]
    );

    const videoCallStarted = computed(
        () => store.getters["VideoCall/videoCallStarted"]
    );

    const room_info = computed(
        () => store.getters["VideoCall/room_info"]
    );

    const room_updating = computed(
        () => store.getters["VideoCall/room_updating"]
    );

    const room_users = computed(
        () => store.getters["VideoCall/room_users"]
    );

    const firebase_timestamp = computed(
        () => store.getters["VideoCall/firebase_timestamp"]
    );

    const emptyVideoCallDetails = computed(
        () => _.isEmpty(videoCallDetails.value)
    )

    const appointmentDetails = computed(() => {
        return videoCallDetails.value.appointment_details
    })

    const callDuration = computed(() => {
        let duration = 0;
        if (!_.isEmpty(appointmentDetails.value)) {
            const timeRemaining = getTimeRemaining(
                room_info.value.timer,
                appointmentDetails.value.duration
            );

            duration  = parseInt(timeRemaining);
        }
        return duration

    })

    const remoteStreamDetails = computed(
        () => {
            let details = {}
            if (!emptyVideoCallDetails.value) {
                details = user_type === AccountType.Doctor ? videoCallDetails.value.medrep : videoCallDetails.value.doctor
                details.user_type = user_type === AccountType.Medrep ? 'Doctor' : 'MedRep'
            }

            return details
        }
    );

    const localStreamDetails = computed(
        () => {

            let details = {}
            if (!emptyVideoCallDetails.value) {
                details = user_type === AccountType.Doctor ? videoCallDetails.value.doctor : videoCallDetails.value.medrep
                details.user_type = user_type === AccountType.Medrep ? 'MedRep' : 'Doctor';
            }

            return details
        }
    );

    const getTimeRemaining = (time_started, { time, value }) => {

        const timeLimit = moment(time_started).add(time, value);
        const timeNow = moment({})
        const remaining = moment.duration(timeLimit.diff(timeNow))

        return remaining.asSeconds()

    }

    // TIME REMAINING FOR TESTING
    // const getTimeRemaining = () => {

    //     const timeLimit = moment().add(86400, 'seconds'); // equivalent to 1 day
    //     const timeNow = moment({})
    //     const remaining = moment.duration(timeLimit.diff(timeNow))

    //     return remaining.asSeconds()

    // }

    const appointmentState = (users) => {
        let state = ''
        if (
            !_.isEmpty(videoCallDetails.value) &&
            appointmentDetails.value

        ) {
            const appointment_details = appointmentDetails.value

            const appointment_date = moment(new Date(appointment_details.appointment_date)).format("DD MMM YYYY");

            const dateNow = moment({}).format("DD MMM YYYY");

            if (dateNow === appointment_date && !_.isEmpty(users)) {
                state = remoteState(users)
            } else {
                state = 'invalid_date'
            }

        }

        return state
    }

    const remoteState = (users) => {
        // var remote_firebase = users[`${remoteStreamDetails.value.user_type.toLowerCase()}-${remoteStreamDetails.value.id}`]
        var remote_firebase = users[`${remoteStreamDetails.value.id}`]
        let state = ''
        if (!_.isEmpty(remote_firebase)) {
            switch (remote_firebase.status) {
            case 'active':
                state = 'remote_stream_in'
                break;
            case 'in-call':
                state = 'remote_stream_call'
                break;
            case 'left':
                state = 'remote_stream_left'
                break;
            default:
                state = 'remote_stream_out'
                break;
            }
        } else {
            state = 'remote_stream_out'
        }

        return state
    }

    return {
        queryParams,
        appointmentDetails,
        emptyVideoCallDetails,
        remoteStreamDetails,
        localStreamDetails,
        videoCallDetails,
        videoCallReady,
        UserType,
        videoCallStarted,
        room_uuid,
        user_type,
        user_uuid: uuid,
        status,
        room_info,
        room_updating,
        room_users,
        firebase_timestamp,
        callDuration,
        appointmentState,
        remoteState
        // getTimeRemaining
    };

}