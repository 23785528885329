import SpinnerComponent from './spinner-component.vue'
import ButtonComponent from './button-component.vue'
// import RouterViewComponent from './router-view-component.vue'

const RouterViewComponent = () => import("./router-view-component.vue")

export {
    SpinnerComponent,
    ButtonComponent,
    RouterViewComponent
}