<template>
    <div class="empty-state-component">
        <template v-if="showDefault">
            <component
                :is="icon"
                aria-hidden="true"
                :class="[iconColor, 'empty-icon']"
            />
            <p class="message-title">
                {{ title }}
            </p>
            <p
                v-if="subtitle"
                class="message-subtitle"
            >
                {{ subtitle }}
            </p>
            <div v-if="customContent" v-html="$sanitize(customContent)" />
        </template>
        <template v-if="showPopOver && buttonLabel">
            <popover-component
                v-bind="popoverComponentProps"
                @on-click-list="onClickList($event)"
            />
        </template>
    </div>
</template>

<script>
import PopoverComponent from "./popover-component.vue";
import { useRouter } from "vue-router";
import { computed } from "vue";

export default {
    name: "EmptyStateComponent",
    components: { PopoverComponent },
    props: {
        icon: { type: Function, default: () => {} },
        iconColor: { type: String, default: "text-gray-400" },
        title: { type: String, default: "" },
        subtitle: { type: String, default: "" },
        customContent: { type: String, default: "" },
        showDefault: { type: Boolean, default: true },
        showPopOver: Boolean,
        lists: { type: Array, default: () => {} },
        popOverBtnIconColor: { type: String, default: "text-gray-500" },
        buttonLabel: { type: String, default: "" },
        buttonType: { type: String, default: "white-btn" },
        buttonIcon: { type: Function, default: () => {} },
        buttonSize: { type: String, default: "" },
    },
    setup(props) {
        const router = useRouter();

        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const popoverComponentProps = computed(() => {
            return {
                lists: props.lists,
                popOverBtnIconColor: props.popOverBtnIconColor,
                buttonLabel: props.buttonLabel,
                buttonType: props.buttonType,
                buttonIcon: props.buttonIcon,
                buttonSize: props.buttonSize,
                class: "mt-4"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onClickList = (list) => {
            router.push({ path: list.link });
        };

        //-------------------METHODS ENDs HERE------------------

        return {
            onClickList,
            popoverComponentProps
        };
    },
};
</script>

<style lang="scss">
.empty-state-component {
    @apply flex flex-col justify-center items-center py-1 px-8 text-center;
    .empty-icon {
        @apply h-9 w-9;
        max-height: 36px;
        max-width: 36px;
    }
    .message-title {
        @apply text-sm leading-5 font-medium text-gray-800 mb-1 mt-2;
    }
    .message-subtitle {
        @apply text-xs leading-4 font-normal text-gray-500;
    }
}
</style>