<template>
    <div class="schedule-page-component">
        <navigation-tabs-component
            v-if="tabName"
            v-model="tablists"
            @update:modelValue="onChangeTab($event)"
        />
        <!-- TAB CONTENT -->
        <div class="tab-content medrep-schedule-tab">
            <router-view
                @get-appointment-uuid="appointmentUuid = $event"
                @set-tab-name="tabName = $event"
            />
        </div>

        <appointment-slideover-component
            v-if="tabName !== 'declined' && tabName !== 'cancelled'"
            v-model="showSlideover"
        />
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { NavigationTabsComponent } from "@CommonComponents";
import { AppointmentSlideoverComponent } from "@CommonComponents/slideovers";
import useNavtab from "@GlobalFunctions/useNavtab.js"

export default {
    name: 'SchedulePageComponent',
    components: {
        NavigationTabsComponent,
        AppointmentSlideoverComponent,
    },
    props: {
        pageName: { type: String, default: '' }
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        // START OF GET APPOINTMENT EVERYTIME THERE IS A NEW UUID
        const appointmentUuid = ref(null);
        watch(
            () => appointmentUuid.value,
            (newVal) => {
                store.dispatch('CommonAppointment/getAppointmentDetails', newVal);
            }
        )
        // END OF GET APPOINTMENT EVERYTIME THERE IS A NEW UUID

        // START OF HIDE AND SHOW SLIDEOVER
        const showSlideover = computed(
            () => store.getters["CommonAppointment/appointmentSlideover"]
        );
        const hideSlideover = () => {
            store.commit('CommonAppointment/setAppointmentSlideover', false)
        }
        // END OF HIDE AND SHOW SLIDEOVER

        // START OF NAVIGATION TAB
        const {
            tablists,
        } = useNavtab();
        // SET ACTIVE TAB WHEN CLICKING REDIRECTION LINK FROM EMPTY STATES OR ANY OTHER PAGE ETC.
        const tabName = ref("")
        const selectActiveTab = (list) => {
            _.forEach(tablists.value, (i) => {
                list === i.tab ? (i.current = true) : (i.current = false);
            });
        };
        onMounted(() => {
            selectActiveTab(tabName.value);
            store.commit('CommonUtilities/setPageName', props.pageName)
        });

        // REDIRECT TO DESIGNATED ROUTE WHEN SWITCHING TO ANOTHER TAB
        const onChangeTab = async(event) => {
            if (event) {
                tablists.value = event;

                const tabname = _.find(tablists.value, (i) => i.current).tab;

                router.push({ path: `/medrep/schedule/${tabname}` });
                // HIDE SLIDEOVER
                if (showSlideover.value) {
                    hideSlideover();
                }
            }
        };
        // END OF NAVIGATION TAB

        return {
            tablists,
            tabName,
            showSlideover,
            appointmentUuid,
            onChangeTab,
        };
    }

}
</script>

<style lang="scss">
.schedule-page-component {
    @apply h-full relative;
    .navigation-tabs-component {
        @apply relative;
        z-index: 1;
        .nav-tabs {
            height: 54px;
            z-index: 1;
            @apply w-full bg-white;
        }
    }
    .tab-content {
        @apply absolute top-0 w-full h-full;
        padding-top: 54px;
    }
    .declined-schedule-component, .cancelled-schedule-component {
        .appointment-thread-component {
            .credit-to-use-card-component {
                .popover-card-component {
                    .popover-card-panel {
                        top: -100px;
                        left: 0;
                    }
                }
            }
        }
    }
}
</style>