import { RouterViewComponent } from '@GlobalComponents';
import {
    AdminDashboardPageComponent,
    TeamPageComponent,
    CreditsPageComponent,
    ProfilePageComponent
} from '@AdminComponents';

import {
    UpcomingScheduleComponent,
    PendingScheduleComponent,
    DoneScheduleComponent,
    DeclinedScheduleComponent,
    CancelledScheduleComponent,
} from '@CommonComponents/schedule';

const AdminModuleRoutes = [
    {
        path: '/admin',
        name: "admin",
        component: RouterViewComponent,
        redirect: { path: '/admin/dashboard' },
        props: { moduleName: 'admin' },
        meta: {
            title: "PPD Clinic Pharma",
            layout: "admin-dashboard-layout-component",
        },
        children: [
            {
                path: "dashboard",
                component: AdminDashboardPageComponent,
                name: 'dashboard-page',
                props: { pageName: 'dashboard' },
                meta: { title: "PPD Clinic Pharma | Dashboard" },
            },
            {
                path: "team",
                component: TeamPageComponent,
                name: 'team-page',
                props: { pageName: 'team' },
                redirect: { path: '/admin/team/upcoming' },
                children: [
                    {
                        path: "upcoming",
                        name: 'upcoming-team-page',
                        component: UpcomingScheduleComponent,
                        props: { tabName: 'upcoming' },
                    },
                    {
                        path: "pending",
                        name: 'pending-team-page',
                        component: PendingScheduleComponent,
                        props: { tabName: 'pending' },
                    },
                    {
                        path: "done",
                        name: 'done-team-page',
                        component: DoneScheduleComponent,
                        props: { tabName: 'done' },
                    },
                    {
                        path: "declined",
                        name: 'declined-team-page',
                        component: DeclinedScheduleComponent,
                        props: { tabName: 'declined' },
                    },
                    {
                        path: "cancelled",
                        name: 'cancelled-team-page',
                        component: CancelledScheduleComponent,
                        props: { tabName: 'cancelled' },
                    },
                ]
            },
            {
                path: "credits",
                component: CreditsPageComponent,
                name: 'credits-page',
                props: { pageName: 'credits' },
                redirect: { path: '/admin/credits/all' },
                children: [
                    {
                        path: ":tabName",
                        name: 'all-credit-history-page',
                        component: CreditsPageComponent,
                    }
                ]
            },
            {
                path: "profile",
                component: ProfilePageComponent,
                name: 'profile-page',
                props: { pageName: 'profile' },
            },
        ],
    },
]

export default AdminModuleRoutes