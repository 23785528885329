<template>
    <svg
        class="pharma__icon"
        :width="width"
        :height="height"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line
            x1="1"
            y1="-1"
            x2="23.7588"
            y2="-1"
            transform="matrix(0.727013 0.686623 -0.727013 0.686623 1 2.12079)"
            stroke="#6B7280"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.35248 6.12104V8.12097C7.35248 9.77783 8.77464 11.121 10.529 11.121C11.1348 11.121 11.701 10.9608 12.1827 10.6829L7.35248 6.12104ZM13.7056 12.1212C12.8208 12.749 11.7209 13.121 10.529 13.121C7.60509 13.121 5.23483 10.8824 5.23483 8.12097C5.23483 7.56869 4.76078 7.12097 4.17601 7.12097C3.59124 7.12097 3.11719 7.56869 3.11719 8.12097C3.11719 11.6474 5.87829 14.5649 9.47013 15.0501V17.121H6.29366C5.70889 17.121 5.23483 17.5687 5.23483 18.121C5.23483 18.6733 5.70889 19.121 6.29366 19.121H14.7642C15.349 19.121 15.8231 18.6733 15.8231 18.121C15.8231 17.5687 15.349 17.121 14.7642 17.121H11.5878V15.0501C12.9514 14.8659 14.1953 14.3311 15.2138 13.5456L13.7056 12.1212ZM16.6127 12.1203L15.0817 10.6743C15.5526 9.92696 15.8231 9.0538 15.8231 8.12097C15.8231 7.56869 16.2971 7.12097 16.8819 7.12097C17.4667 7.12097 17.9407 7.56869 17.9407 8.12097C17.9407 9.60793 17.4498 10.9866 16.6127 12.1203ZM13.5012 9.18165L7.43226 3.44989C7.75497 2.11611 9.01891 1.12097 10.529 1.12097C12.2833 1.12097 13.7054 2.46412 13.7054 4.12097V8.12097C13.7054 8.49439 13.6332 8.85188 13.5012 9.18165Z"
            fill="#6B7280"
        />
    </svg>
</template>

<script>
export default {
    props: {
        height: { type: String, default: "20" },
        width: { type: String, default: "20" },
    },
};
</script>