import { Tokens } from '@Utilities/enum'
import { STORAGE } from '@Plugins/storage'
import _ from 'lodash'
export default {
    namespaced: true,
    state: {
        appointment: {},
        isLoading: false,
        appointmentSlideover: false,
        appointmentList: [],
        listLoading: false,
        forRemarksAppointmentList: [],
        forRemarksAppointmentListLoading: false,
        doneAppointmentList: [],
        upcomingAppointmentList: [],
        pendingAppointmentList: [],
        declinedAppointmentList: [],
        cancelledAppointmentList: [],
        appointmentUuid: '',
        remarksResult: {},
        remarksLoading: false,
        appointmentMeta: {},
        brandList: [],
        brandLoading: false,
        requestedAppointment: {},
        requestAppointmentLoading: false,
        appointmentListLoading: false,
        otherAppointmentListLoading: false,
        otherAppointmentList: [],
        appointmentListMeta: {},
        otherAppointmentListMeta: {}
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        appointment(state) {
            return state.appointment
        },
        appointmentList(state) {
            return state.appointmentList
        },
        forRemarksAppointmentList(state) {
            return state.forRemarksAppointmentList
        },
        appointmentSlideover(state) {
            return state.appointmentSlideover
        },
        listLoading(state) {
            return state.listLoading
        },
        forRemarksListLoading(state) {
            return state.forRemarksListLoading
        },
        upcomingAppointmentList(state) {
            return state.upcomingAppointmentList
        },
        pendingAppointmentList(state) {
            return state.pendingAppointmentList
        },
        doneAppointmentList(state) {
            return state.doneAppointmentList
        },
        declinedAppointmentList(state) {
            return state.declinedAppointmentList
        },
        cancelledAppointmentList(state) {
            return state.cancelledAppointmentList
        },
        appointmentUuid(state) {
            return state.appointmentUuid
        },
        remarksResult(state) {
            return state.remarksResult
        },
        remarksLoading(state) {
            return state.remarksLoading
        },
        appointmentMeta(state) {
            return state.appointmentMeta
        },
        brandList(state) {
            return state.brandList
        },
        brandLoading(state) {
            return state.brandLoading
        },
        requestedAppointment(state) {
            return state.requestedAppointment
        },
        requestAppointmentLoading(state) {
            return state.requestAppointmentLoading
        },
        otherAppointmentList(state) {
            return state.otherAppointmentList
        },
        otherAppointmentListLoading(state) {
            return state.otherAppointmentListLoading
        },
        appointmentListLoading(state) {
            return state.appointmentListLoading
        },
        appointmentListMeta(state) {
            return state.appointmentListMeta
        },
        otherAppointmentListMeta(state) {
            return state.otherAppointmentListMeta
        }
    },
    actions: {
        async getAppointmentList({ commit }, payload) {
            // commit(payload.status === "done" ? 'setForRemarksListLoading' : 'setListLoading', true)
            switch (payload.status) {
            case "accepted":
            case "pending":
                commit('setListLoading', true)
                commit('setAppointmentListLoading', true)
                break;
            case "served":
                commit('setForRemarksListLoading', true)
                commit('setOtherAppointmentListLoading', true)
                break;
            default:
                commit('setOtherAppointmentListLoading', true)
                break;
            }
            commit('setListLoading', true)
            try {
                const userType = STORAGE.GET_COOKIES(Tokens.UserType)
                const url = `/pharma/api/appointment/get`
                // payload.limit = 4
                const response = await api('get', url, payload, Tokens.Personal, { 'user-type': userType })

                commit('setAppointmentMeta', response.data.meta)

                if (response && response.data) {
                    if (!_.isEmpty(payload)) {
                        const { data, meta } = response.data
                        switch (payload.status) {
                        case "served":
                            commit('setForRemarksAppointmentList', data)
                            commit('setOtherAppointmentList', data)
                            commit('setDoneAppointmentList', data)
                            commit('setOtherAppointmentListMeta', meta)
                            break;
                        case "accepted":
                            commit('setUpcomingAppointmentList', data)
                            commit('setAppointmentList', data)
                            commit('setAppointmentListMeta', meta)
                            break;
                        case "pending":
                            commit('setPendingAppointmentList', data)
                            commit('setAppointmentList', data)
                            commit('setAppointmentListMeta', meta)
                            break;
                        case "declined":
                            commit('setDeclinedAppointmentList', data)
                            commit('setOtherAppointmentList', data)
                            commit('setOtherAppointmentListMeta', meta)
                            break;

                        default:
                            // CANCELLED
                            commit('setCancelledAppointmentList', data)
                            commit('setOtherAppointmentList', data)
                            commit('setOtherAppointmentListMeta', meta)
                            break;
                        }
                    }
                }
            } catch (error) {
                console.warn(error)
            }
            commit('setForRemarksListLoading', false)
            commit('setListLoading', false)
            commit('setAppointmentListLoading', false)
            commit('setOtherAppointmentListLoading', false)
        },

        async getAppointmentDetails({ commit }, payload) {
            if (!payload) return

            commit('setIsLoading', true)
            try {
                const url = `/pharma/api/appointment/get/${payload}`
                const response = await api('get', url, payload, Tokens.Personal)
                if (response && response.data.success) {
                    commit('setAppointment', response.data.data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setIsLoading', false)
        },

        async submitRemarks({ commit }, payload) {
            if (!payload) return
            commit('setRemarksLoading', true)
            try {
                const url = `/pharma/api/appointment/remarks/add`
                const response = await api('post', url, payload, Tokens.Personal)
                if (response && response.data.success) {
                    commit('setRemarksResult', response.data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setRemarksLoading', false)
        },

        async getBrandList({ commit }, payload) {
            commit('setBrandLoading', true)

            try {
                const url = 'pharma/api/brand/to/cover'
                const response = await api('get', url, payload, Tokens.Personal)

                if (response && response.data.success) {
                    const { data } = response.data
                    commit('setBrandList', data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setBrandLoading', false)

        },

        async requestAppointment({ commit }, payload) {
            commit('setRequestAppointmentLoading', true)

            try {
                const url = 'pharma/api/appointment/request'
                const response = await api('post', url, payload, Tokens.Personal)

                if (response && response.data.success) {
                    commit('setRequestedAppointment', response.data.data)
                }
                commit('setRequestAppointmentLoading', false)
                return response.data
            } catch (error) {
                console.warn(error)
            }

            commit('setRequestAppointmentLoading', false)

        },
    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
        setAppointment(state, payload) {
            state.appointment = payload
        },
        setAppointmentSlideover(state, payload) {
            state.appointmentSlideover = payload
        },
        setAppointmentList(state, payload) {
            state.appointmentList = payload
        },
        setListLoading(state, payload) {
            state.listLoading = payload
        },
        setIsListLoading(state, payload) {
            state.isLoading = payload
        },
        setForRemarksAppointmentList(state, payload) {
            state.forRemarksAppointmentList = payload
        },
        setForRemarksListLoading(state, payload) {
            state.forRemarksListLoading = payload
        },
        setUpcomingAppointmentList(state, payload) {
            // state.upcomingAppointmentList = payload;
            state.upcomingAppointmentList.push(...payload);
        },
        setPendingAppointmentList(state, payload) {
            // state.pendingAppointmentList = payload;
            state.pendingAppointmentList.push(...payload);
        },
        setDoneAppointmentList(state, payload) {
            // state.doneAppointmentList = payload
            state.doneAppointmentList.push(...payload);
        },
        setDeclinedAppointmentList(state, payload) {
            // state.declinedAppointmentList = payload;
            state.declinedAppointmentList.push(...payload);
        },
        setCancelledAppointmentList(state, payload) {
            // state.cancelledAppointmentList = payload;
            state.cancelledAppointmentList.push(...payload);
        },
        resetAppointmentList(state, payload) {
            state.appointmentList = payload;
            state.forRemarksAppointmentList = payload;
            state.upcomingAppointmentList = payload;
            state.pendingAppointmentList = payload;
            state.doneAppointmentList = payload
            state.declinedAppointmentList = payload;
            state.cancelledAppointmentList = payload;
        },
        setAppointmentUuid(state, payload) {
            state.appointmentUuid = payload
        },
        setRemarksResult(state, payload) {
            state.remarksResult = payload
        },
        setRemarksLoading(state, payload) {
            state.remarksLoading = payload
        },
        setAppointmentMeta(state, payload) {
            state.appointmentMeta = payload
        },
        setBrandList(state, payload) {
            state.brandList = payload
        },
        setBrandLoading(state, payload) {
            state.brandLoading = payload
        },
        setRequestedAppointment(state, payload) {
            state.requestedAppointment = payload
        },
        setRequestAppointmentLoading(state, payload) {
            state.requestAppointmentLoading = payload
        },
        setOtherAppointmentList(state, payload) {
            state.otherAppointmentList = payload
        },
        setAppointmentListLoading(state, payload) {
            state.appointmentListLoading = payload
        },
        setOtherAppointmentListLoading(state, payload) {
            state.otherAppointmentListLoading = payload
        },
        setAppointmentListMeta(state, payload) {
            state.appointmentListMeta = payload
        },
        setOtherAppointmentListMeta(state, payload) {
            state.otherAppointmentListMeta = payload
        },
    },
}
