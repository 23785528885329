<template>
    <div class="empty-state-component">
        <template v-if="showDefault">
            <component
                :is="icon"
                aria-hidden="true"
                :class="iconColor"
                class="empty-icon"
            />
            <p class="text-sm leading-5 font-medium text-gray-800 mb-1 mt-2">
                {{ title }}
            </p>
            <p
                v-if="subtitle"
                class="text-xs leading-4 font-normal text-gray-500"
            >
                {{ subtitle }}
            </p>
            <div v-if="customContent" v-html="$sanitize(customContent)" />
        </template>
    </div>
</template>

<script>
export default {
    name: "EmptyStateComponent",
    components: {},
    props: {
        icon: { type: Function, default: () => {} },
        iconColor: { type: String, default: "text-gray-400" },
        title: { type: String, default: "" },
        subtitle: { type: String, default: "" },
        customContent: { type: String, default: "" },
        showDefault: { type: Boolean, default: true },
    }
};
</script>

<style lang="scss">
.empty-state-component {
    @apply flex flex-col justify-center items-center py-1 px-8 text-center;
    .empty-icon {
        @apply h-9 w-9;
        max-height: 36px;
        max-width: 36px;
    }
}
</style>