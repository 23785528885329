<template>
    <div class="vdp-datepicker" :class="[wrapperClass, isRtl ? 'rtl' : '']">
        <date-input
            :id="id"
            :selected-date="selectedDate"
            :reset-typed-date="resetTypedDate"
            :format="format"
            :translation="translation"
            :inline="inline"
            :name="name"
            :ref-name="refName"
            :open-date="openDate"
            :placeholder="placeholder"
            :input-class="inputClass"
            :typeable="typeable"
            :clear-button="clearButton"
            :clear-button-icon="clearButtonIcon"
            :calendar-button="calendarButton"
            :calendar-button-icon="calendarButtonIcon"
            :calendar-button-icon-content="calendarButtonIconContent"
            :disabled="disabled"
            :required="required"
            :bootstrap-styling="bootstrapStyling"
            :use-utc="useUtc"
            @show-calendar="showCalendar"
            @close-calendar="close"
            @typed-date="setTypedDate"
            @clear-date="clearDate"
        >
            <slot name="afterDateInput" />
        </date-input>

        <!-- Day View -->
        <picker-day
            v-if="allowedToShowView('day')"
            :page-date="pageDate"
            :selected-date="selectedDate"
            :show-day-view="showDayView"
            :full-month-name="fullMonthName"
            :allowed-to-show-view="allowedToShowView"
            :disabled-dates="disabledDates"
            :highlighted="highlighted"
            :calendar-class="calendarClass"
            :calendar-style="calendarStyle"
            :translation="translation"
            :page-timestamp="pageTimestamp"
            :is-rtl="isRtl"
            :monday-first="mondayFirst"
            :day-cell-content="dayCellContent"
            :use-utc="useUtc"
            @changed-month="handleChangedMonthFromDayPicker"
            @select-date="selectDate"
            @show-month-calendar="showMonthCalendar"
            @selected-disabled="selectDisabledDate"
        >
            <slot name="beforeCalendarHeader" />
        </picker-day>

        <!-- Month View -->
        <picker-month
            v-if="allowedToShowView('month')"
            :page-date="pageDate"
            :selected-date="selectedDate"
            :show-month-view="showMonthView"
            :allowed-to-show-view="allowedToShowView"
            :disabled-dates="disabledMonths"
            :calendar-class="calendarClass"
            :calendar-style="calendarStyle"
            :translation="translation"
            :is-rtl="isRtl"
            :use-utc="useUtc"
            @select-month="selectMonth"
            @show-year-calendar="showYearCalendar"
            @changed-year="setPageDate"
        >
            <slot name="beforeCalendarHeader" />
        </picker-month>

        <!-- Year View -->
        <picker-year
            v-if="allowedToShowView('year')"
            :page-date="pageDate"
            :selected-date="selectedDate"
            :show-year-view="showYearView"
            :allowed-to-show-view="allowedToShowView"
            :disabled-dates="disabledYears"
            :calendar-class="calendarClass"
            :calendar-style="calendarStyle"
            :translation="translation"
            :is-rtl="isRtl"
            :use-utc="useUtc"
            @select-year="selectYear"
            @changed-decade="setPageDate"
        >
            <slot name="beforeCalendarHeader" />
        </picker-year>
    </div>
</template>
<script>
import en from "../locale/translations/en";
import DateInput from "./DateInput.vue";
import PickerDay from "./PickerDay.vue";
import PickerMonth from "./PickerMonth.vue";
import PickerYear from "./PickerYear.vue";
import utils, { makeDateUtils } from "../utils/DateUtils";

export default {
    components: {
        DateInput,
        PickerDay,
        PickerMonth,
        PickerYear,
    },
    props: {
        modelValue: { type: [ String, Date ], default: null },
        name: { type: String, default: "" },
        refName: { type: String, default: "" },
        id: { type: String, default: "" },
        format: { type: [ String, Function ], default: 'dd MMM yyyy' },
        language: { type: Object, default: () => en },
        openDate: { type: Date, default: () => {}, validator: val => utils.validateDateInput(val) },
        // DO NOT put default value for dayCellContent
        // eslint-disable-next-line vue/require-default-prop
        dayCellContent: Function,
        fullMonthName: Boolean,
        disabledDates: { type: Object, default: () => {} },
        disabledMonths: { type: Object, default: () => {} },
        disabledYears: { type: Object, default: () => {} },
        highlighted: { type: Object, default: () => {} },
        placeholder: { type: String, default: "" },
        inline: Boolean,
        calendarClass: { type: [ String, Object, Array ], default: () => {} },
        inputClass: { type: [ String, Object, Array ], default: () => {} },
        wrapperClass: { type: [ String, Object, Array ], default: () => {} },
        mondayFirst: Boolean,
        clearButton: Boolean,
        clearButtonIcon: { type: String, default: "" },
        calendarButton: Boolean,
        calendarButtonIcon: { type: Function, default: () => {} },
        calendarButtonIconContent: { type: String, default: "" },
        bootstrapStyling: Boolean,
        initialView: { type: String, default: "" },
        disabled: Boolean,
        required: Boolean,
        typeable: Boolean,
        useUtc: Boolean,
        minimumView: { type: String, default: 'day' },
        maximumView: { type: String, default: 'year' }
    },
    emits: [ "selected", "input", "cleared", "selected-disabled", "changed-month", "changed-year", "changed-month", "closed", "update:model-value" ],
    data() {
        const startDate = this.openDate ? new Date(this.openDate) : new Date();
        const constructedDateUtils = makeDateUtils(this.useUtc);
        const pageTimestamp = constructedDateUtils.setDate(startDate, 1);
        return {
            /*
             * Vue cannot observe changes to a Date Object so date must be stored as a timestamp
             * This represents the first day of the current viewing month
             * {Number}
             */
            pageTimestamp,
            /*
             * Selected Date
             * {Date}
             */
            selectedDate: null,
            /*
             * Flags to show calendar views
             * {Boolean}
             */
            showDayView: false,
            showMonthView: false,
            showYearView: false,
            /*
             * Positioning
             */
            calendarHeight: 0,
            resetTypedDate: new Date(),
            utils: constructedDateUtils,
        };
    },
    computed: {
        computedInitialView() {
            if (!this.initialView) {
                return this.minimumView;
            }

            return this.initialView;
        },
        pageDate() {
            return new Date(this.pageTimestamp);
        },

        translation() {
            return this.language;
        },

        calendarStyle() {
            return {
                position: this.isInline ? "static" : undefined,
            };
        },
        isOpen() {
            return this.showDayView || this.showMonthView || this.showYearView;
        },
        isInline() {
            return !!this.inline;
        },
        isRtl() {
            return this.translation.rtl === true;
        },
    },
    watch: {
        value(value) {
            this.setValue(value);
        },
        openDate() {
            this.setPageDate();
        },
        initialView() {
            this.setInitialView();
        },
        modelValue(value) {
            this.selectedDate = value
            // this.$emit("selected-date", value)
        },
        selectedDate(value) {
            this.$emit("update:model-value", value)
        },

    },
    mounted() {
        this.init();
    },
    methods: {
        /**
         * Called in the event that the user navigates to date pages and
         * closes the picker without selecting a date.
         */
        resetDefaultPageDate() {
            if (this.selectedDate === null) {
                this.setPageDate();
                return;
            }
            this.setPageDate(this.selectedDate);
        },
        /**
         * Effectively a toggle to show/hide the calendar
         * @return {mixed}
         */
        showCalendar() {
            if (this.disabled || this.isInline) {
                return false;
            }
            if (this.isOpen) {
                return this.close(true);
            }
            this.setInitialView();
        },
        /**
         * Sets the initial picker page view: day, month or year
         */
        setInitialView() {
            const initialView = this.computedInitialView;
            if (!this.allowedToShowView(initialView)) {
                throw new Error(
                    `initialView '${this.initialView}' cannot be rendered based on minimum '${this.minimumView}' and maximum '${this.maximumView}'`
                );
            }
            switch (initialView) {
            case "year":
                this.showYearCalendar();
                break;
            case "month":
                this.showMonthCalendar();
                break;
            default:
                this.showDayCalendar();
                break;
            }
        },
        /**
         * Are we allowed to show a specific picker view?
         * @param {String} view
         * @return {Boolean}
         */
        allowedToShowView(view) {
            const views = [ "day", "month", "year" ];
            const minimumViewIndex = views.indexOf(this.minimumView);
            const maximumViewIndex = views.indexOf(this.maximumView);
            const viewIndex = views.indexOf(view);

            return (
                viewIndex >= minimumViewIndex && viewIndex <= maximumViewIndex
            );
        },
        /**
         * Show the day picker
         * @return {Boolean}
         */
        showDayCalendar() {
            if (!this.allowedToShowView("day")) {
                return false;
            }
            this.close();
            this.showDayView = true;
            return true;
        },
        /**
         * Show the month picker
         * @return {Boolean}
         */
        showMonthCalendar() {
            if (!this.allowedToShowView("month")) {
                return false;
            }
            this.close();
            this.showMonthView = true;
            return true;
        },
        /**
         * Show the year picker
         * @return {Boolean}
         */
        showYearCalendar() {
            if (!this.allowedToShowView("year")) {
                return false;
            }
            this.close();
            this.showYearView = true;
            return true;
        },
        /**
         * Set the selected date
         * @param {Number} timestamp
         */
        setDate(timestamp) {
            const date = new Date(timestamp);
            this.selectedDate = date;
            this.setPageDate(date);
            this.$emit("selected", date);
            this.$emit("input", date);
        },
        /**
         * Clear the selected date
         */
        clearDate() {
            this.selectedDate = null;
            this.setPageDate();
            this.$emit("selected", null);
            this.$emit("input", null);
            this.$emit("cleared");
        },
        /**
         * @param {Object} date
         */
        selectDate(date) {
            this.setDate(date.timestamp);
            if (!this.isInline) {
                this.close(true);
            }
            this.resetTypedDate = new Date();
        },
        /**
         * @param {Object} date
         */
        selectDisabledDate(date) {
            this.$emit("selected-disabled", date);
        },
        /**
         * @param {Object} month
         */
        selectMonth(month) {
            const date = new Date(month.timestamp);
            if (this.allowedToShowView("day")) {
                this.setPageDate(date);
                this.$emit("changed-month", month);
                this.showDayCalendar();
            } else {
                this.selectDate(month);
            }
        },
        /**
         * @param {Object} year
         */
        selectYear(year) {
            const date = new Date(year.timestamp);
            if (this.allowedToShowView("month")) {
                this.setPageDate(date);
                this.$emit("changed-year", year);
                this.showMonthCalendar();
            } else {
                this.selectDate(year);
            }
        },
        /**
         * Set the datepicker value
         * @param {Date|String|Number|null} date
         */
        setValue(date) {
            if (typeof date === "string" || typeof date === "number") {
                let parsed = new Date(date);
                date = isNaN(parsed.valueOf()) ? null : parsed;
            }
            if (!date) {
                this.setPageDate();
                this.selectedDate = null;
                return;
            }
            this.selectedDate = date;
            this.setPageDate(date);
        },
        /**
         * Sets the date that the calendar should open on
         */
        setPageDate(date) {
            if (!date) {
                if (this.openDate) {
                    date = new Date(this.openDate);
                } else {
                    date = new Date();
                }
            }
            this.pageTimestamp = this.utils.setDate(new Date(date), 1);
        },
        /**
         * Handles a month change from the day picker
         */
        handleChangedMonthFromDayPicker(date) {
            this.setPageDate(date);
            this.$emit("changed-month", date);
        },
        /**
         * Set the date from a typedDate event
         */
        setTypedDate(date) {
            this.setDate(date.getTime());
        },
        /**
         * Close all calendar layers
         * @param {Boolean} emitEvent - emit close event
         */
        close(emitEvent) {
            this.showDayView = this.showMonthView = this.showYearView = false;
            if (!this.isInline) {
                if (emitEvent) {
                    this.$emit("closed");
                }
                document.removeEventListener("click", this.clickOutside, false);
            }
        },
        /**
         * Initiate the component
         */
        init() {
            if (this.value) {
                this.setValue(this.value);
            }
            if (this.isInline) {
                this.setInitialView();
            }
        },
    },
};
// eslint-disable-next-line
</script>
<style lang="scss">
@import '../styles/style'
</style>
