<template>
    <div class="admin-image-card-component">
        <div :class="[haveShadow ? 'shadow shadow-base' : '', 'image-card-container']">
            <div class="image-container">
                <img :src="imgSrc" alt="Workflow" />
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
import {

} from "@CommonComponents";

export default {
    name: 'AdminImageCardComponent',
    props: {
        imgSrc: { type: String, default: '' },
        haveShadow: { type: Boolean, default: true },
    }
}
</script>

<style lang="scss">
.admin-image-card-component {
    .image-card-container {
        @apply overflow-hidden rounded-md w-auto ;
        img {
            @apply w-full;
        }
    }
}
</style>