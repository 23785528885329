<template>
    <div class="credit-balance-card-component">
        <div class="credit-balance-card-container">
            <div class="credit-balance-card-content">
                <div class="credit-balance-info-container">
                    <p class="header-title"> Your credit balance is </p>
                    <p class="credit-balance"> {{ credits.credits }} </p>
                </div>
                <div class="flex items-center">
                    <button-component
                        type="primary-btn"
                        label="Check history"
                        @on-click="onClickCheckHistory"
                    />
                </div>
            </div>
            <div class="footer-message-container">
                <p class="footer-message"> Please make sure you have enough credits to send appointment requests to doctors. </p>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";

export default {
    name: 'CreditBalanceCardComponent',
    components: {
    },
    props: {
    },
    emits: [ "on-click-check-history" ],
    setup(props, { emit }) {
        const router = useRouter();
        const store = useStore();
        const onClickCheckHistory = () => {
            emit('on-click-check-history')
        }

        const credits = computed(() => store.getters['CommonCredit/userCredits'])

        onMounted(async () => {
            await store.dispatch("CommonCredit/getUserCredits");
        })

        return {
            onClickCheckHistory,
            router,
            store,
            credits
        }
    }
}
</script>

<style lang="scss">
.credit-balance-card-component {
    .credit-balance-card-container {
        @apply shadow rounded-md w-auto bg-white p-6 space-y-2;
        .credit-balance-card-content {
            @apply flex justify-between;
            .credit-balance-info-container {
                @apply space-y-1;
                .header-title {
                    @apply text-sm font-normal text-gray-700;
                }
                .credit-balance {
                    @apply text-4xl font-medium text-gray-500;
                }
            }
        }
        .footer-message-container {
            .footer-message {
                @apply text-sm font-normal text-gray-500;
            }
        }
    }
}
</style>