<template>
    <div class="medrep-dashboard-layout flex">
        <dashboard-sidebar :module-name="moduleName" :class="[ !showPages ? 'invisible' : '']" @page="page = $event" />
        <div :class="['antialiased font-sans w-full', !showPages ? 'invisible' : '']">
            <dashboard-top-navbar :page="page" :module-name="moduleName" />
            <main class="medrep-dashboard-layout__container p-0 bg-gray-50">
                <slot />
            </main>
        </div>
        <!-- HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX -->
        <!-- <preferences-component
            v-if="isShowPreference.show"
            :is-update="isShowPreference.action === 'update'"
            @on-click-save="onShowPreferencesModal"
            @on-click-close="onClickClose"
        />
        <preferences-success-modal-component v-model="showSuccessModal" @on-click-set-photo="onClickSetPhoto" /> -->
        <set-profile-photo-modal-component v-model="showSetProfileModal" @on-click-save-photo="onClickSavePhoto" />
        <welcome-modal-component v-model="showSetWelcomeModal" @on-click-go-to-dashboard="onClickGoToDashboard" />

        <!-- FLOATING FEEDBACK MESSAGE -->
        <floating-feedback-component v-model="showFeedback" />
    </div>
</template>

<script>
import {
    DashboardSidebar,
    DashboardTopNavbar,
    FloatingFeedbackComponent,
} from "@CommonComponents";
import { computed, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import router from "@/router";
// HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX
// import PreferencesComponent from '../modules/medrep/components/preferences/preferences-component.vue'
// import PreferencesSuccessModalComponent from "@CommonComponents/modal/preferences-success-modal-component.vue"
import SetProfilePhotoModalComponent from "@CommonComponents/modal/set-profile-photo-modal-component.vue"
import WelcomeModalComponent from "@CommonComponents/modal/welcome-modal-component.vue"

import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";

export default {
    name: "MedrepDashboardLayoutComponent",
    components: {
        DashboardSidebar,
        DashboardTopNavbar,
        FloatingFeedbackComponent,
        // HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX
        // PreferencesComponent,
        // PreferencesSuccessModalComponent,
        SetProfilePhotoModalComponent,
        WelcomeModalComponent
    },
    props: {
        moduleName: { type: String, default: null },
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const showPages = ref(false);
        const page = ref("");

        const showPreference = ref(false);
        const showSuccessModal = ref(false);
        const showSetProfileModal = ref(false);
        const showSetWelcomeModal = ref(false);

        //------------------COMPUTED-------------------

        const isShowPreference = computed(
            () => store.getters["MedrepPreferences/showPreference"]
        );

        const preferenceMessage = computed(
            () => store.getters["MedrepPreferences/preferenceMessage"]
        );

        // SHOW FEEDBACK MESSAGE
        const showFeedback = computed(() => store.getters['CommonUtilities/showFeedback'])

        //------------------COMPUTED ENDS HERE------------------

        //------------------METHODS------------------

        const onShowPreferencesModal = async() => {
            if (isShowPreference.value.action === "create") {
                showSuccessModal.value = true
                showPages.value = true
            } else {
                const feedback = {
                    title: preferenceMessage.value[0],
                    message: preferenceMessage.value[1],
                }
                store.commit('CommonUtilities/setFeedbackObject', feedback);
            }
            await store.dispatch('CommonDoctor/getDoctorList', { option: 'suggested' })
            store.commit('MedrepPreferences/setShowPreference', { action: "", show: false })
        };

        const onClickClose = () => {
            store.commit('MedrepPreferences/setShowPreference', { action: "", show: false })
        };

        const onClickSetPhoto = () => {
            showSuccessModal.value = false
            showSetProfileModal.value = true
        }

        const onClickSavePhoto = () => {
            showSetProfileModal.value = false
            showSetWelcomeModal.value = true
        }

        const onClickGoToDashboard = () => {
            showSetWelcomeModal.value = false
            router.push({ path: `/medrep/dashboard` })
        }

        //------------------METHODS ENDs HERE------------------

        // TO CLOSE SLIDEOVER WHEN REROUTING
        watch(
            () => route.params,
            () => {
                store.commit("CommonDoctor/setProfileSlideover", false);
                store.commit("CommonAppointment/setAppointmentSlideover", false);
            }
        );

        watch(
            () => isShowPreference.value,
            () => {
                showPages.value = !isShowPreference.value.show
            },
        )

        onMounted(async () => {
            await store.dispatch('MedrepPreferences/getPreference')
            const isNeedToSetPhoto = !STORAGE.GET_COOKIES(Tokens.Personal).avatar_upload
            if (isNeedToSetPhoto && !isShowPreference.value.show) {
                showSetProfileModal.value = true
            }
            showPages.value = !isShowPreference.value.show
        });

        return {
            page,
            showFeedback,
            showSuccessModal,
            showPreference,
            onShowPreferencesModal,
            isShowPreference,
            onClickClose,
            onClickSetPhoto,
            showSetProfileModal,
            showSetWelcomeModal,
            onClickSavePhoto,
            onClickGoToDashboard,
            preferenceMessage,
            showPages
        };
    },
};
</script>

<style lang="scss">
.medrep-dashboard-layout {
    @apply h-screen overflow-hidden;
    &__container {
        @apply overflow-x-auto;
        height: calc(100% - 78px);
    }
}
</style>