<template>
    <div class="preferences-component">
        <div class="preferences-container">
            <OutlineXIcon
                v-if="isUpdate"
                aria-hidden="true"
                class="close-icon"
                @click="onClickClose"
            />
            <component
                :is="currentComponent"
                :is-update="isUpdate"
                @on-click-save="onClickSave"
                @on-click-get-started="onClickGetStarted"
            />
        </div>
    </div>
</template>

<script>
import PreferencesStepsComponent from './components/preferences-steps-component.vue'
import PreferencesRemarksComponent from './components/preferences-remarks-component.vue'
import { computed, ref } from "vue";
import {
    XIcon as OutlineXIcon
} from '@heroicons/vue/outline'

export default {
    name: "PreferencesComponent",
    components: {
        PreferencesStepsComponent,
        PreferencesRemarksComponent,
        OutlineXIcon
    },
    props: {
        isUpdate: Boolean,
    },
    emits: [ "on-click-save", "on-click-close" ],
    setup(props, { emit }) {
        const showPreferenceSteps = ref(false)

        //-----------------------COMPUTED-----------------------

        const currentComponent = computed(() => {
            if (showPreferenceSteps.value || props.isUpdate) {
                return 'preferences-steps-component'
            }
            return 'preferences-remarks-component'
        });

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onClickGetStarted = () => {
            showPreferenceSteps.value = true;
        };

        const onClickSave = () => {
            emit("on-click-save")
        }

        const onClickClose = () => {
            emit("on-click-close")
        }

        //-------------------METHODS ENDs HERE------------------

        return {
            onClickSave,
            currentComponent,
            showPreferenceSteps,
            onClickGetStarted,
            onClickClose
        }
    }
}
</script>

<style lang="scss">
.preferences-component {
    @apply fixed inset-0 bg-white transition-opacity z-50;
    .preferences-container {
        max-width: 512px;
        @apply mx-auto h-full;
        .close-icon {
            @apply  text-white bg-gray-300 h-6 w-6 absolute right-0 mt-4 mr-4 rounded-md cursor-pointer;
        }
    }
}
</style>