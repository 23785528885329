<template>
    <svg
        class="pharma__icon"
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line
            x1="1"
            y1="-1"
            x2="22.5192"
            y2="-1"
            transform="matrix(0.686624 0.727013 -0.686624 0.727013 1 2)"
            stroke="#6B7280"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.80048 4.01196H4.80025C3.75099 4.01196 2.90039 4.9126 2.90039 6.02358V14.0701C2.90039 15.181 3.75099 16.0817 4.80025 16.0817H10.4998C11.5491 16.0817 12.3997 15.181 12.3997 14.0701V12.0582L4.80048 4.01196ZM17.04 14.0634L14.2996 11.1618V8.0352C14.2996 7.65423 14.5029 7.30596 14.8247 7.13558L16.7245 6.12977C17.019 5.97388 17.3687 5.99054 17.6488 6.1738C17.9288 6.35707 18.0993 6.6808 18.0993 7.02939V13.0643C18.0993 13.4128 17.9288 13.7366 17.6488 13.9198C17.4642 14.0406 17.2493 14.0891 17.04 14.0634ZM12.3997 9.15015L7.54697 4.01196H10.4998C11.5491 4.01196 12.3997 4.9126 12.3997 6.02358V9.15015Z"
            fill="#6B7280"
        />
    </svg>
</template>

<script>
export default {
    props: {
        height: { type: String, default: "20" },
        width: { type: String, default: "20" },
    },
};
</script>