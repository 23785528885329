<template>
    <div
        class="thread-component doctor-thread-component"
        :class="{'dashboard-grid' : type}"
    >
        <div ref="container" class="col-start-2 col-span-1 h-full">
            <template v-if="!isLoading">
                <template v-if="!_.isEmpty(doctors)">
                    <div ref="header" class="thread-header">
                        <h3>{{ title }}</h3>
                        <!-- HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX -->
                        <!-- <button-component
                            v-if="showHeaderButton"
                            type="white-btn"
                            :label="headerButtonLabel"
                            @click="$emit('on-show-preference')"
                        >
                            <template v-slot:prepend>
                                <AdjustmentsIcon class="text-gray-500" aria-hidden="true" />
                            </template>
                        </button-component> -->
                    </div>

                    <load-more-component
                        v-click-outside.prevent="hideSlideover"
                        :unique-id="uniqueId"
                        :unique-body-id="type ? `${type}-body` : 'search-results-body'"
                        :height="totalHeight"
                        :load-more-loading="hasInfiniteLoading ? loadMoreLoading : false"
                        bg-color="gray-50"
                        @get-action-btn-height="actionBtnHeight = $event"
                        @infinite-scroll="handleScroll"
                    >
                        <div class="space-y-2">
                            <template
                                v-for="doctor in doctors"
                                :key="doctor.id"
                            >
                                <profile-info-card-component
                                    :profile="doctor"
                                    :class="{'active': doctor.id === current}"
                                    inline
                                    @click="showSlideover(doctor.id), $emit('get-doctor-id', doctor.id )"
                                />
                            </template>
                            <div>
                                <!-- TYPE IS EMPTY FOR FOR SEARCH RESULTS -->
                                <button-component
                                    v-if="showLoadMore && !hasInfiniteLoading"
                                    type="white-btn"
                                    label="More doctors"
                                    block
                                    class="mt-3"
                                    :loading="loadMoreLoading"
                                    @click="onLoadMoreDoctors"
                                />
                                <info-box-component
                                    v-if="!showLoadMore"
                                    type="custom"
                                    title="Looks like you’ve reached the end"
                                    :icon="FolderOpenIcon"
                                />
                            </div>
                        </div>
                    </load-more-component>
                </template>

                <template v-else>
                    <empty-state-component
                        v-if="type"
                        v-bind="emptyStateProps"
                        :title="emptyTitle"
                        :button-label="emptyButtonLabel"
                        :button-icon="icon"
                        :lists="emptyMenuList"
                        class="mt-16"
                    />
                    <!-- HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX -->
                    <!-- @click="$emit('on-show-preference')" -->
                    <empty-state-component
                        v-else-if="showEmptyState"
                        :icon="SearchIcon"
                        title="No results found"
                        class="mt-12"
                    />
                </template>
            </template>
            <spinner-component v-else class="center mt-24" :size="40" />
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import {
    ProfileInfoCardComponent,
    LoadMoreComponent,
    InfoBoxComponent,
} from '@CommonComponents'
// HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX
// import { AdjustmentsIcon, } from '@heroicons/vue/outline'
import { EmptyStateComponent } from '@MedrepComponents';
import { SearchIcon, FolderOpenIcon } from '@heroicons/vue/outline';
import useDoctor from '@MedrepFunctions/useDoctor.js';

export default {
    name: 'DoctorThreadComponent',
    components: {
        ProfileInfoCardComponent,
        LoadMoreComponent,
        // HIDDEN BECAUSE PREFERENCE IS NOW SET TO BAYMAX
        // AdjustmentsIcon,
        EmptyStateComponent,
        InfoBoxComponent,
    },
    props: {
        title: { type: String, default: '' },
        showHeaderButton: Boolean,
        headerButtonLabel: { type: String, default: '' },
        uniqueId: { type: String, default: '' },
        type: { type: String, default: '' },
        emptyTitle: { type: String, default: '' },
        emptyButtonLabel: { type: String, default: '' },
        emptyMenuList: { type: Array, default: () => {} },
        showEmptyState: Boolean,
        icon: { type: Function, default: () => {} },
        doctors: { type: Array, default: () => {} },
        hasInfiniteLoading: Boolean,
    },
    emits: [ "get-doctor-id", "set-tab-name", "on-show-preference", "load-more-doctors" ],
    setup(props, { emit }) {
        const {
            getDoctorList,
            loadMoreDoctors,
            isLoading,
            header,
            totalHeight,
            container,
            actionBtnHeight,
            current,
            showSlideover,
            hideSlideover,
            emptyStateProps,
            showLoadMore,
            loadMoreLoading,
        } = useDoctor();

        // START OF FETCHING DOCTOR LIST
        onMounted(async () => {
            if (props.type) {
                await getDoctorList(props.type)
            }
        })
        // END OF FETCH DOCTOR LIST

        // START OF LOAD MORE DOCTORS FROM A FOOTER BUTTON
        const onLoadMoreDoctors = () => {
            if (props.type) {
                loadMoreDoctors();
            } else {
                emit("load-more-doctors");
            }
        }
        // END OF LOAD MORE DOCTORS FROM A FOOTER BUTTON

        // START OF LOAD MORE DOCTORS FROM INFINITE LOADING
        const handleScroll = () => {
            if (props.hasInfiniteLoading) {
                loadMoreDoctors();
            }
        }
        // END OF LOAD MORE DOCTORS FROM INFINITE LOADING

        return {
            header,
            totalHeight,
            container,
            actionBtnHeight,
            showSlideover,
            hideSlideover,
            current,
            isLoading,
            loadMoreLoading,
            emptyStateProps,
            showLoadMore,
            onLoadMoreDoctors,
            handleScroll,
            SearchIcon,
            FolderOpenIcon,
        }
    }

}
</script>

<style lang="scss">
.thread-component {
    &.doctor-thread-component {
        .thread-header {
            h3 {
                @apply text-lg leading-6 font-medium text-gray-900;
            }
        }
        .load-more-component {
            .load-more-body {
                @apply p-1 pb-0 ;
            }
            .load-more-footer {
                .action-btn {
                    @apply bg-gray-50 ;
                }
            }
        }
        .profile-info-card-component {
            .profile-card {
                @apply cursor-pointer ;
                &:hover {
                    @apply bg-white shadow-md;
                }
            }
            &.active {
                .profile-card {
                    @apply ring-2 ring-offset-2 ring-offset-gray-100 ring-gray-500;
                }
            }
        }
    }
}
</style>