<template>
    <div class="video__buttons">
        <div class="block md:hidden xl:hidden lg:hidden videomobile_button">
            <span
                class="text-xs align-middle text-center mx-auto font-medium p-5"
                :class="timerColor"
            >
                {{ $COVERTOMINUTES(timerCount) }} remaining
            </span>
        </div>
        <div class="video__button_container">
            <mic-camera-icon
                type="microphone"
                :on="hasAudio"
                @microphone="audioChange(!hasAudio)"
            />

            <div class="mx-5" />

            <mic-camera-icon
                type="camera"
                :on="hasCamera"
                @camera="cameraChange(!hasCamera)"
            />

            <div class="mx-5" />

            <popover-card-component
                ref="popover_card"
                popover-panel-class="leave__dialog"
                :has-button="false"
            >
                <template v-slot:popover_button>
                    <div class="bg-red-600 icon__r icon__rounded">
                        <PhoneIcon
                            type="white-btn"
                            class="text-white"
                            color="white"
                            aria-hidden="true"
                        />
                    </div>
                </template>

                <div class="leave__buttons">
                    <h1 class="message text-center mb-4">Leave the call?</h1>
                    <button-component
                        type="danger-btn"
                        label="Leave"
                        class="block w-full mb-2"
                        @click="$emit('leave-call')"
                    />
                </div>
                <template v-slot:popover_close_button>
                    <button-component
                        type="white-btn"
                        label="Stay"
                        class="block w-full"
                        @click="$refs.popover_card.onClosePopOver"
                    />
                </template>
            </popover-card-component>

            <div class="mx-5" />

            <CogIcon
                class="setting_icon hidden lg:block"
                aria-hidden="true"
                @click="$emit('show-settings')"
            />

            <div class="block lg:hidden">
                <IconFlipCamera
                    class="text-gray-500 h-8 w-8"
                    width="24"
                    height="24"
                    @click="$emit('flip-camera')"
                />
            </div>
            <div class="mx-5" />

            <div>
                <DesktopComputerIcon
                    v-if="checkMonitor"
                    class="h-10 w-10 p-2 hover:bg-gray-200 text-gray-500"
                    @click="screenShare()"
                />
                <button-component
                    v-else
                    button-type="submit"
                    type="danger-btn"
                    label="Stop Sharing"
                    @click="stopShare"
                />
            </div>
            <div class="absolute right-3 z-10 hidden md:block">
                <span
                    v-if="callIsOngoing"
                    class="
                        text-xs
                        inline-block
                        align-middle
                        text-right
                        mt-2
                        font-medium
                    "
                    :class="timerColor"
                >
                    {{ $COVERTOMINUTES(timerCount) }} remaining
                </span>
                <div
                    v-else
                    class="animate-pulse bg-gray-200 rounded h-4 z-10 w-20"
                />
            </div>
        </div>
    </div>
</template>

<script>
import router from "@/router";
import { CogIcon, PhoneIcon, DesktopComputerIcon } from "@heroicons/vue/solid";
import MicCameraIcon from "./mic-camera-icon.vue";
import { IconFlipCamera } from "@Icons";
import { ref, computed, watch, onMounted } from "vue";
import PopoverCardComponent from "@CommonComponents/cards/popover-card-component";
import { useSetupSettings, useVideoCallDetails } from "../functions";
import DeviceDetector from "device-detector-js";
import { Status } from "@Utilities/enum";
export default {
    name: "VideoScreenButtonsComponent",
    components: {
        CogIcon,
        PhoneIcon,
        DesktopComputerIcon,
        MicCameraIcon,
        PopoverCardComponent,
        IconFlipCamera,
    },
    props: {
        type: { type: String, default: "" },
        remoteConnected: Boolean,
        showMonitor: Boolean,
    },

    emits: [
        "leave-call",
        "screen-share",
        "show-settings",
        "time-alertmessage",
        "end-callpoints",
        "time-count",
        "stop-sharing",
        "disable-share",
        "flip-camera",
    ],

    setup(props, { emit }) {
        const { cameraChange, audioChange, hasCamera, hasAudio } =
            useSetupSettings();

        const {
            queryParams,
            room_info,
            UserType,
            callDuration,
            room_updating,
        } = useVideoCallDetails();
        //DATA
        const screen = ref(false);
        const timerCount = ref("0");
        const endCallPoints = ref(false);
        const checkMonitor = ref(true);

        onMounted(async () => {
            await getCallDuration();
            startCall();
        });

        watch(
            () => room_info.value,
            () => {
                getCallDuration();
            }
        );

        const getCallDuration = async () => {
            timerCount.value = callDuration.value;
        };

        const startCall = () => {
            const countdown = setInterval(() => {
                --timerCount.value;

                if (_.includes([ 10, 30, 60 ], timerCount.value)) {
                    emit("time-count", timerCount.value);
                }

                if (!timerCount.value || timerCount.value < 0) {
                    clearInterval(countdown);

                    if (router.currentRoute.value.name === "video-in-call") {
                        const query = {
                            ...queryParams,
                            status: "time",
                        };
                        endCallPoints.value = true;
                        emit("end-callpoints", endCallPoints.value);
                        router.push({ name: "video-call-ended", query });
                    }
                }
            }, 1000);
        };

        const callIsOngoing = computed(() => {
            return room_info.value.status === Status.Ongoing;
        });

        const timerColor = computed(() => {
            let color = "";
            if (timerCount.value >= 31 && timerCount.value <= 60) {
                // less than 1 minute
                color = "text-alertTime-100";
            } else if (timerCount.value >= 11 && timerCount.value <= 30) {
                // less 30 seconds
                color = "text-alertTime-200";
            } else if (timerCount.value <= 10) {
                // less 10 seconds
                color = "text-alertTime-300";
            } else {
                color = "text-gray-500";
            }
            emit("time-alertmessage", color);
            return color;
        });

        const shareMethods = computed(() => {
            return router.currentRoute.value.query.user_type === "medrep";
        });

        watch(
            () => props.showMonitor,
            (newValue) => {
                checkMonitor.value = newValue;
            }
        );

        const isDesktop = computed(() => {
            const deviceDetector = new DeviceDetector();
            const detector = deviceDetector.parse(window.navigator.userAgent);

            return detector.device.type === "desktop";
        });

        const screenShare = () => {
            if (!shareMethods.value) {
                emit("disable-share", "invalid_user");
                return;
            }

            if (!isDesktop.value) {
                emit("disable-share", "invalid_device");
                return;
            }

            if (props.remoteConnected) {
                emit("screen-share");
            }
        };

        const stopShare = () => {
            checkMonitor.value = true;
            emit("stop-sharing", true);
        };

        return {
            hasCamera,
            hasAudio,
            screen,
            screenShare,
            cameraChange,
            audioChange,
            timerColor,
            timerCount,
            checkMonitor,
            stopShare,
            UserType,
            shareMethods,
            room_updating,
            callIsOngoing,
            isDesktop,
        };
    },
};
</script>

<style lang="scss">
.video__buttons {
    @apply absolute bottom-0 bg-white w-full;
    .video__button_container {
        @apply flex justify-center items-center mx-auto text-gray-500 p-3;
    }
    .setting_icon {
        @apply h-5 w-5  cursor-pointer;
    }
    .leave__dialog {
        top: -162px !important;
        .leave__buttons {
            min-width: 280px;
        }
    }
}
.videomobile_button {
    @apply text-center p-0 m-0 pt-2 pb-2 mb-2 bg-gray-200;
}
</style>