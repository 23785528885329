<template>
    <div
        :class="[
            'appointment-card-component',
            {
                'card-no-shadow': noShadowEffect,
            },
        ]"
    >
        <!-- CARD HEADER -->
        <template v-if="showDate">
            <div>
                <!-- RESCHEDULE TEXT -->
                <div v-if="isRescheduled && !isLoading" class="reschedule-date">
                    <p v-if="isDateRescheduled">The doctor rescheduled this appointment</p>
                    <p v-else-if="isTimeRescheduled">The doctor changed the time of appointment</p>
                </div>
                <appointment-settings-card-component
                    v-if="!_.isEmpty(data) || isLoading"
                    v-bind="appointmentSettingsProps"
                />
                <div v-if="isRescheduled && !isLoading && (isDateRescheduled || isTimeRescheduled)" class="reschedule-date__details">
                    <p class="label">Your Proposed Schedule</p>
                    <appointment-settings-card-component
                        v-if="!_.isEmpty(data)"
                        v-bind="rescheduledAppointmentSettingsProps"
                    />
                </div>
            </div>
        </template>
        <!-- CARD BODY -->
        <appointment-body-card-component
            v-if="!_.isEmpty(data) || isLoading"
            v-bind="appointmentBodyProps"
        />
    </div>
</template>

<script>
import moment from "moment";
import {
    AppointmentSettingsCardComponent,
    AppointmentBodyCardComponent,
} from "./index";
import { computed } from 'vue';

export default {
    name: "AppointmentCardComponent",
    components: {
        AppointmentSettingsCardComponent,
        AppointmentBodyCardComponent,
    },
    props: {
        data: { type: [ Object, Number ], default: null },
        showRemarksFields: Boolean,
        showDate: { type: Boolean, default: true },
        showNote: { type: Boolean, default: true },
        showInvited: { type: Boolean, default: false },
        showBrand: { type: Boolean, default: true },
        showName: { type: Boolean, default: true },
        showRemarks: { type: Boolean, default: true },
        showCreditToUse: { type: Boolean, default: true },
        showMedrepName: { type: Boolean, default: true },
        description: { type: String, default: "" },
        noShadowEffect: Boolean,
        noHoverEffect: Boolean,
        cardClass: { type: String, default: "" },
        previewAppointment: Boolean,
        showRescheduleLabel: Boolean,
        showShortenedTime: Boolean,
        showStatus: Boolean,
        isLoading: Boolean,
    },
    setup(props) {

        //------------------COMPUTED-------------------

        const dual = computed(() => {
            return props.data.status === 'pending' ||
                props.data.status === 'declined' ||
                props.data.status === 'cancelled'
        })

        const isRescheduled = computed(() => {
            return props.data.proposed_appointment_date && props.showRescheduleLabel
        })

        const isDateRescheduled = computed(() => {
            return props.data.proposed_appointment_date.appointment_date && props.data.proposed_appointment_date.appointment_date !== props.data.appointment_date
        })

        const isTimeRescheduled = computed(() => {
            return props.data.proposed_appointment_date.appointment_time !== props.data.appointment_time
        })

        const appointmentSettingsProps = computed(() => {
            return {
                settings: 'date',
                dualLine: dual.value,
                appointment: {
                    ...props.data,
                    credit: props.data.credit_use
                },
                description: props.description,
                isAppointmentCard: true,
                class: `py-2.5 px-3 ${props.cardClass}`,
                previewAppointment: props.previewAppointment,
                showShortenedTime: props.showShortenedTime,
                isLoading: props.isLoading
            }
        })

        const rescheduledAppointmentSettingsProps = computed(() => {
            return {
                settings: 'date',
                dualLine: dual.value,
                appointment: {
                    ...props.data.proposed_appointment_date,
                },
                showAppointmentStatus: false,
                class: `reschedule-appointment ${props.cardClass}`,
            }
        })

        const appointmentBodyProps = computed(() => {
            return {
                showNote: props.showNote,
                doctor: props.data,
                showInvited: props.showInvited,
                showBrand: props.showBrand,
                showName: props.showName,
                showRemarks: props.showRemarks,
                showCreditToUse: props.showCreditToUse,
                showRemarksFields: props.showRemarksFields,
                showMedrepName: props.showMedrepName,
                class: props.cardClass,
                previewAppointment: props.previewAppointment || !(props.data.status === 'served'),
                showStatus: props.showStatus,
                isLoading: props.isLoading
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        return {
            dual,
            isRescheduled,
            appointmentSettingsProps,
            rescheduledAppointmentSettingsProps,
            appointmentBodyProps,
            isDateRescheduled,
            isTimeRescheduled,
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format("ddd, DD MMM YYYY");
        },
    },
};
</script>

<style lang="scss">
.appointment-card-component {
    @apply divide-y divide-gray-200 h-auto cursor-pointer;
    @apply relative bg-white shadow overflow-visible rounded-md hover:shadow-md focus:ring-offset-gray-100 focus:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-indigo-500;
    .appointment-settings-card-component {
        .appointment-settings {
            @apply hover:shadow-none;
        }
        .date-details {
            .date-details-content {
                p {
                    @apply mb-0;
                }
            }
        }
    }
    .reschedule-date {
        p {
            @apply text-sm leading-5 font-medium text-indigo-700 mt-4 -mb-2 px-6;
        }
        &__details {
            .label {
                @apply -mt-1 pb-1 pr-3 uppercase text-xs leading-4 font-medium tracking-wide uppercase text-gray-500;
                padding-left: 56px;
            }
            .reschedule-appointment {
                @apply pb-2.5 px-3;
                padding-left: 56px !important;
                @apply pt-0 #{!important};
                p {
                    @apply text-xs leading-4 font-normal text-gray-500;
                }
                .card-icon {
                    @apply hidden;
                }
                .date-details-content {
                    @apply ml-0 #{!important};
                }
            }
        }
    }
}
.appointment-card-component.card-no-shadow {
    @apply relative bg-white shadow-none overflow-hidden cursor-default;
}
</style>