<template>
    <div class="table-card-component">
        <div ref="tableRef" :class="['table-card-container', isCard ? 'inside-card' : '']">
            <table class="table-container">
                <thead class="table-thead-container">
                    <tr>
                        <th
                            v-for="(th, index) in cardData.config"
                            :key="index"
                            scope="col"
                            :class="['table-th-container', cardData.thClass, th.perThClass]"
                        >
                            {{ th.label }}
                        </th>
                    </tr>
                </thead>
                <tbody
                    v-click-outside.prevent="hideSlideover"
                    class="table-tbody-container"
                >
                    <tr
                        v-for="(item, dataIndex) in cardData.data"
                        :key="dataIndex"
                        @click="onSelectMember(item.id)"
                    >
                        <td
                            v-for="(i, index) in cardData.config"
                            :key="index"
                            :class="['table-td-container', cardData.tdClass, i.perTdClass]"
                        >
                            <div v-if="i.type==='label-with-avatar'" class="label-with-avatar-container">
                                <img :src="getData(i, item, 'avatarKey')" alt="">
                                <p :class="i.class">
                                    {{ getData(i, item, 'nameKey') }}
                                </p>
                            </div>
                            <div v-if="i.type==='label'" class="label-container">
                                <p :class="i.class">
                                    {{ getData(i, item) }}
                                </p>
                            </div>
                            <div v-if="i.type==='label-with-icon'" class="label-with-icon-container">
                                <component
                                    :is="i.key.iconKey"
                                    :class="['h-5 w-5', i.iconClass]"
                                    aria-hidden="true"
                                />
                                <p :class="i.class">
                                    {{ getData(i, item, 'nameKey') }}
                                </p>
                            </div>
                            <div v-if="i.type==='input-field'">
                                <div :class="i.class">
                                    <input-field-component
                                        v-model="item[i.key]"
                                        :type="i.inputType ? i.inputType : 'text'"
                                        label=""
                                        :placeholder="i.placeholder"
                                        @input="setInput"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="paginate" class="px-6 py-3 bg-white">
                <div v-if="paginateType==='nextPrevPagination'" class="next-prev-pagination-container">
                    <button-component
                        v-bind="prevProps"
                        @on-click="onClickPrev"
                    />
                    <button-component
                        v-bind="nextProps"
                        @on-click="onClickNext"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { InputFieldComponent } from "@CommonComponents";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
    name: "TableCardComponent",
    components: {
        InputFieldComponent
    },
    props: {
        cardData: { type: Object, default: null },
        paginate: Boolean,
        paginateType: { type: String, default: "nextPrevPagination" },
        nextButtonState: { type: String, default: "hide" }, // hide, show, disable
        prevButtonState: { type: String, default: "hide" }, // hide, show, disable
        isCard: { type: Boolean, default: true },
        showProfile: { type: Boolean, default: true },
        isLoading: Boolean,
    },
    emits: [ "table-ref", "on-click-next", "on-click-prev", "input-value-change", "get-member-id" ],
    setup(props, { emit }) {
        const store = useStore();
        const tableRef = ref(null)
        const inputValues = ref({})

        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const prevProps = computed(() => {
            return {
                type: "white-btn",
                label: "Previous",
                class: props.cardData.prevButtonState === 'hide' ? 'invisible' : props.cardData.prevButtonState === 'disabled' ? 'disabled' : 'show',
                loading: props.isLoading,
            }
        })

        const nextProps = computed(() => {
            return {
                type: "white-btn",
                label: "Next",
                class: props.cardData.nextButtonState === 'hide' ? 'invisible' : props.cardData.nextButtonState === 'disabled' ? 'disabled' : 'show',
                loading: props.isLoading
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const getData = (event, item, objKey) => {
            let data = ""
            if (!_.isEmpty(objKey)) {
                data = item[event.key[objKey]]
            } else {
                data = item[event.key]
            }
            if (_.isEmpty(data)) return
            return data
        }

        const onClickNext = () => {
            emit('on-click-next')
        }

        const onClickPrev = () => {
            emit('on-click-prev')
        }

        const setInput = () => {
            emit("input-value-change", props.cardData.data);
        }

        const onSelectMember = (id) => {
            if (props.showProfile) {
                emit('get-member-id', id);
                store.commit('CommonDoctor/setProfileSlideover', true);
            }
        }

        const hideSlideover = () => {
            // HIDE BOTH SLIDEOVER WHEN CLICKED OUTSIDE
            store.commit('CommonDoctor/setProfileSlideover', false)
        };

        //-------------------METHODS ENDs HERE------------------

        onMounted(async() => {
            emit('table-ref', tableRef.value)
        });

        return {
            getData,
            tableRef,
            onClickNext,
            onClickPrev,
            inputValues,
            setInput,
            onSelectMember,
            hideSlideover,
            prevProps,
            nextProps
        }
    }
}
</script>

<style lang="scss">
.table-card-component {
    .table-card-container {
        @apply overflow-hidden divide-y divide-gray-200;
        .table-container {
            table-layout: fixed;
            @apply w-full divide-y divide-gray-200;
            .table-thead-container {
                @apply bg-gray-50;
                .table-th-container {
                    @apply px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
                    &:first-of-type {
                        @apply pl-6 pr-0;
                    }
                    &:last-of-type {
                        @apply pl-0 pr-6;
                    }
                }
            }
            .table-tbody-container {
                @apply bg-white divide-y divide-gray-200 cursor-pointer;
                .table-td-container {
                    @apply py-4 px-4;
                    &:first-of-type {
                        @apply pl-6 pr-0;
                    }
                    &:last-of-type {
                        @apply pl-0 pr-6;
                    }
                    .label-with-avatar-container {
                        @apply flex items-center;
                        img {
                            object-fit: cover;
                            @apply h-10 w-10 rounded-full;
                        }
                        p {
                            @apply ml-4 text-sm font-medium;
                        }
                    }
                    .label-container {
                        p {
                            @apply text-sm font-medium overflow-ellipsis overflow-hidden;
                        }
                    }
                    .label-with-icon-container {
                        @apply flex items-center;
                        p {
                            @apply ml-3 text-sm font-medium flex items-center;
                        }
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }
        }
    }
    .table-card-container.inside-card {
        @apply shadow border border-gray-200 rounded-lg
    }
    .next-prev-pagination-container {
        @apply flex justify-between;
        // .prev-btn {
        //     @apply relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50;
        // }
        // .next-btn {
        //     @apply ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50;
        // }
    }
}
</style>