<template>
    <div class="set-profile-photo-modal-component">
        <slot-modal-component
            v-model="showModal"
            v-bind="slotModalProps"
        >
            <div class="set-profile-photo-body-container">
                <div class="set-profile-photo-container">
                    <div class="set-profile-photo-content">
                        <div class="message-and-sub-message-container">
                            <p
                                class="message"
                            >
                                Set your profile photo
                            </p>
                            <p
                                class="sub-message"
                            >
                                Uploading your profile photo will help doctors acknowledge your account credibility.
                            </p>
                        </div>
                        <div class="profile-photo-container">
                            <div class="profile-photo">
                                <img
                                    v-if="avatarValue"
                                    v-bind="imgProps"
                                />
                                <UserIcon
                                    v-else
                                    v-bind="imgEmptyStateProps"
                                />
                            </div>
                            <div class="action-container">
                                <p
                                    class="note"
                                >
                                    Select a photo
                                </p>
                                <button-component
                                    v-bind="changeAvatarButtonProps"
                                    @on-click="$refs.pickFile.click()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <p v-if="isFileSizeExceed" class="file-limit-message">
                    Your file exceeded 1MB
                </p>
            </div>
            <!-- FOOTER -->
            <template v-slot:footer>
                <div class="footer-action-container">
                    <button-component
                        v-bind="saveAvatarButtonProps"
                        @on-click="onClickUsePhoto"
                    />
                </div>
            </template>
            <input
                v-bind="inputPickFileProps"
                @change="handleFileUpload"
            />
        </slot-modal-component>
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { Tokens } from "@Utilities/enum";
import SlotModalComponent from "../modal/slot-modal-component.vue";
import { UserIcon } from "@heroicons/vue/solid";
import { STORAGE } from "@Plugins/storage.js";
import { useStore } from "vuex";

export default {
    name: "SetProfilePhotoModalComponent",
    components: {
        SlotModalComponent,
        UserIcon
    },
    props: {
        modelValue: Boolean,
    },
    emits: [ "update:model-value", "on-click-save-photo" ],
    setup(props, { emit }) {
        const store = useStore();
        const showModal = ref(false);
        const enableUsePhotoButton = ref(false);
        const avatar = ref(STORAGE.GET_COOKIES(Tokens.Personal).avatar);
        const avatarSize = ref(null);

        //-----------------------COMPUTED-----------------------

        const avatarValue = computed(() => {
            return STORAGE.GET_COOKIES(Tokens.Personal).avatar_upload || enableUsePhotoButton.value ? avatar.value : null
        })

        const slotModalProps = computed(() => {
            return {
                height: 0,
                scrollable: false,
                isSemiTransparent: true,
                footerClass: "mt-0",
                distinctClass: "set-profile-photo-modal pt-0"
            }
        })

        const imgProps = computed(() => {
            return {
                class: "w-full bg-gray-100 h-16 w-16",
                src: avatarValue.value,
                alt: "avatar"
            }
        })

        const imgEmptyStateProps = computed(() => {
            return {
                class: "text-gray-300 bg-gray-100 pt-2",
                ariaHidden: true
            }
        })

        const changeAvatarButtonProps = computed(() => {
            return {
                type: "white-btn",
                label: !_.isEmpty(avatarValue.value) ? 'Change' : 'Browse'
            }
        })

        const saveAvatarButtonProps = computed(() => {
            return {
                type: _.isEmpty(avatarValue.value) || isFileSizeExceed.value ? 'white-btn' : 'primary-btn',
                label: "Use photo",
                disabled: _.isEmpty(avatarValue.value) || isFileSizeExceed.value
            }
        })

        const inputPickFileProps = computed(() => {
            return {
                ref: "pickFile",
                type: "file",
                class: "invisible absolute w-0",
                accept: "image/x-png,image/gif,image/jpeg"
            }
        })

        const isFileSizeExceed = computed(() => {
            return avatarSize.value > 1e+6
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onShowModal = () => {
            showModal.value = true;
        };

        const onHideModal = () => {
            showModal.value = false;
            emit("update:model-value", showModal.value);
        };

        const handleFileUpload = (event) => {
            avatarSize.value = event.target.files[0].size
            const selectedFile = event.target.files[0];
            const idxDot = selectedFile.name.lastIndexOf(".") + 1;
            const extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
                const reader = new FileReader();
                if (event.target.files[0]) {
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = () => {
                        avatar.value = reader.result;
                        enableUsePhotoButton.value = true
                    };
                }
            }
        }

        const onClickUsePhoto = async() => {
            if (!_.isEmpty(avatar.value) || !isFileSizeExceed.value) {
                const payload = {
                    avatar: btoa(avatar.value)
                }
                await store.dispatch('CommonPharmaUser/uploadMedrepAvatar', payload)
                emit('on-click-save-photo')
            }
        }

        //-------------------METHODS ENDs HERE------------------

        watch(
            () => props.modelValue,
            (newVal) => {
                showModal.value = newVal;
            }
        );

        return {
            onShowModal,
            showModal,
            onHideModal,
            avatar,
            handleFileUpload,
            onClickUsePhoto,
            enableUsePhotoButton,
            avatarValue,
            slotModalProps,
            imgProps,
            imgEmptyStateProps,
            changeAvatarButtonProps,
            saveAvatarButtonProps,
            inputPickFileProps,
            avatarSize,
            isFileSizeExceed
        };
    },
};
</script>

<style lang="scss">
.set-profile-photo-modal-component {
    @apply pt-0;
}
.set-profile-photo-modal {
    min-width: 512px !important;
    .set-profile-photo-body-container {
        .set-profile-photo-container {
            @apply flex flex-col items-center justify-center bg-gray-50 space-y-2;
            height:366px;
            .set-profile-photo-content {
                @apply flex items-center flex-col text-center space-y-7;
                .message-and-sub-message-container {
                    @apply space-y-2;
                    .message {
                        @apply text-lg font-medium text-gray-700;
                    }
                    .sub-message {
                        @apply text-sm font-normal text-gray-500 px-14;
                    }
                }
                .profile-photo-container {
                    @apply flex items-center flex-col text-center space-y-4;
                    .profile-photo {
                        @apply overflow-hidden h-16 w-16 rounded-full;
                        img {
                            object-fit: cover;
                            border-radius:50%;
                        }
                    }
                    .action-container {
                        @apply space-y-2;
                        .note {
                            @apply text-base font-normal text-gray-700;
                        }
                    }
                }
            }
        }
        .file-limit-message {
            @apply text-red-600 text-sm mx-auto left-0 right-0 text-center mt-1 absolute;
        }
    }
    .footer-action-container {
        @apply flex justify-end mt-6;
    }
}
</style>