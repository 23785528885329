<template>
    <div ref="container" class="search-result-component">
        <div ref="header" class="search-result-header">
            <h3>Search Doctors</h3>
            <input-field-component
                v-model="keywords"
                name="search"
                type="text"
                placeholder="Type a doctor name"
                :prepend-icon="SearchIcon"
                @on-key-enter="onSearch"
            />
        </div>

        <doctor-thread-component
            v-bind="searchProps"
            :doctors="doctors"
            @load-more-doctors="$emit('load-more-doctors')"
            @get-doctor-id="$emit('get-doctor-id', $event)"
        />
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import {
    SearchIcon,
} from '@heroicons/vue/outline'
import { InputFieldComponent, } from '@CommonComponents'
import {
    DoctorThreadComponent,
} from '@MedrepComponents'

export default {
    name: 'SearchResultComponent',
    components: {
        InputFieldComponent,
        DoctorThreadComponent,
    },
    props: {
        doctors: { type: Array, default: () => {} },
        isLoading: Boolean,
        showEmptyState: Boolean,
    },
    emits: [ "get-doctor-id", "on-search", "load-more-doctors", "update-keywords" ],
    setup(props, { emit }) {
        const keywords = ref('')

        // START OF SHOWING EMPTY STATE ONLY AFTER SEARCH
        const showEmpty = ref(false)
        watch(
            () => { props.showEmptyState },
            (newVal) => {
                showEmpty.value = newVal
            }
        )
        // END OF SHOWING EMPTY STATE ONLY AFTER SEARCH

        // START OF UPDATE PARENT COMPONENT OF THE KEYWORDS INPUT
        watch(
            () => keywords.value,
            (newVal) => {
                emit("update-keywords", newVal)
            }
        )
        // END OF UPDATE PARENT COMPONENT OF THE KEYWORDS INPUT

        // START OF ENTER KEYWORDS
        const onSearch = async () => {
            showEmpty.value = true
            emit("on-search", { page: 1 })
        }
        // END OF ENTER KEYWORDS

        // SEARCH RESULTS BINDED PROPS
        const searchProps = ref({
            emptyTitle: "No results found",
            uniqueId: "search-dr-read-more",
            showEmptyState: showEmpty,
        })

        return {
            onSearch,
            keywords,
            showEmpty,
            searchProps,
            SearchIcon,
        }
    }

}
</script>

<style lang="scss">
.search-result-component {
    .search-result-header {
        @apply space-y-4 pb-4;
        h3 {
            @apply text-lg leading-6 font-medium text-gray-900;
        }
    }
}
</style>