export const STORAGE = {
    SET_COOKIES: function(key, value) {
        // localStorage[key] = JSON.stringify(value)
        localStorage[key] = value
    },
    REMOVE_COOKIES: function(key) {
        localStorage.removeItem(key)
    },
    GET_COOKIES: function(key) {
        return JSON.parse(localStorage.getItem(key))
    }
}

// export default SET_COOKIES
// export default REMOVE_COOKIES
// export default GET_COOKIES

// export default {
//     SET_COOKIES,
//     REMOVE_COOKIES,
//     GET_COOKIES,
// }