<template>
    <TransitionRoot as="template" :show="modelValue">
        <Dialog
            class="slide-over-modal-component"
        >
            <!-- @close="onCloseSlideover" -->
            <div class="absolute inset-0 overflow-hidden">
                <DialogOverlay class="absolute inset-0" />

                <div class="slide-over-modal-component__container">
                    <TransitionChild
                        v-bind="transitionChildProps"
                    >
                        <div class="w-screen">
                            <!-- <div class="w-full max-w-md"> -->
                            <div :class="['slide-over-modal-component-container', containerClass]">
                                <div
                                    :class="contentClass"
                                    class="slide-over-modal-component__content"
                                >
                                    <!-- Replace with your content -->
                                    <slot />
                                    <!-- /End replace -->
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { ref, computed } from "vue";
import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

export default {
    name: "SlideOverComponent",
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
    },
    props: {
        modelValue: Boolean,
        panelTitle: { type: String, default: "" },
        containerClass: { type: String, default: "" },
        headerClass: { type: String, default: "" },
        contentClass: { type: String, default: "" },
    },
    emits: [ "update:modelValue" ],
    setup(props, { emit }) {
        const header = ref(null);

        //-----------------------COMPUTED-----------------------

        const transitionChildProps = computed(() => {
            return {
                as: "template",
                enter: "transform transition ease-in-out duration-500 sm:duration-500",
                enterFrom: "translate-x-full",
                enterTo: "translate-x-0",
                leave: "transform transition ease-in-out duration-500 sm:duration-500",
                leaveFrom: "translate-x-0",
                leaveTo: "translate-x-full",
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onCloseSlideover = () => {
            emit("update:modelValue", false);
        };

        //-------------------METHODS ENDs HERE------------------

        return {
            onCloseSlideover,
            header,
            transitionChildProps
        };
    },
};
</script>
<style lang="scss">
.slide-over-modal-component {
    @apply fixed overflow-hidden;
    z-index: 2;
    &__container {
        // pl-10
        @apply fixed inset-y-0 right-0 max-w-full flex;
        .slide-over-modal-component-container {
            @apply h-full flex flex-col bg-white shadow-xl overflow-hidden pb-3;
        }
    }
    &__content {
        @apply relative flex-1;
    }
    &__content,
    &__header {
        // @apply px-4 ;
    }
    // &__content,
    // &__header {
    // @apply px-4 ;
    // }
    // &__header {
    //     @apply py-6 ;
    // }
}
@screen sm {
    .slide-over-modal-component {
        &__container {
            max-width: calc((100% - 136px) / 3); /// 112 + 24 ( width of sidebar + right padding)
        }
        &__content,
        &__header {
            // @apply px-6 ;
        }
    }
}
</style>