<template>
    <div class="steps-info-component">
        <div class="steps-info-container">
            <div class="steps-info-content">
                <span class="steps-details-container">
                    <p class="steps-details-content">
                        STEPS {{ currentStep }} of {{ steps.length }}
                    </p>
                </span>
                <div class="steps-track" style="">
                    <div v-for="(step, index) in steps" :key="index" class="flex items-center">
                        <CheckCircleIcon v-if="currentStep > index + 1" class="h-6 text-indigo-600" />
                        <dot-component
                            v-else
                            :dot-color="currentStep === index + 1 ? 'text-indigo-600' : 'text-gray-300'"
                            :custom-class="[
                                currentStep === index + 1 ? 'bg-indigo-200' : '' ,
                                'p-1 rounded-full transition duration-400 ease-in']"
                            size="5"
                        />
                    </div>
                </div>
            </div>
            <div>
                <button-component
                    v-if="currentStep === 3 || currentStep === 4"
                    type="white-btn"
                    label="Skip"
                    @onClick="onClickSkip(currentStep)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import DotComponent from '@CommonComponents/dot-component.vue';

export default {
    name: "StepsInfoComponent",
    components: {
        CheckCircleIcon,
        DotComponent,
    },
    props: {
        steps: { type: Array, default: null },
        currentStep: { type: Number, default: 1 },
    },
    emits: [ "on-click-skip" ],
    setup(props, { emit }) {
        const onClickSkip = (step) => {
            emit('on-click-skip', step)
        }

        return {
            onClickSkip
        }
    }
}
</script>

<style lang="scss">
.steps-info-component {
    .steps-info-container {
        @apply flex justify-between;
        .steps-details-container {
            @apply space-y-2;
            .steps-details-content {
                @apply text-xs font-semibold uppercase text-gray-600;
            }
        }
        .steps-track {
            @apply flex space-x-1;
            height: 24px;
        }
    }
}
</style>