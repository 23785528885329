<template>
    <div ref="wrapperHeight" class="search-doctors-filter-component">
        <div ref="componentHeight" class="search-filter search-filter__header">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Filters</h3>
            <button-component
                type="white-btn"
                label="Clear filters"
                @click="clearFilter"
            />
        </div>

        <load-more-component
            unique-id="filter-read-more"
            unique-body-id="filter-body"
            :height="totalHeight"
            bg-color="gray-50"
            @get-action-btn-height="actionBtnHeight = $event"
        >
            <div class="search-filter search-filter__content px-0.5 mb-6">
                <!-- MEDICAL SPECIALTIES -->
                <div class="input__medical-specialties">
                    <multiple-select-component
                        v-model="specialty"
                        v-bind="specialtyProps"
                        @on-load-data="getPreferences"
                    />
                </div>

                <!-- LOCATION -->
                <div class="input__location">
                    <multiple-select-component
                        v-model="location"
                        v-bind="locationProps"
                        @on-load-data="getLocationList"
                    />
                </div>

                <!-- YEARS OF PRACTICE -->
                <div class="input__years-of-practice">
                    <radio-button-component
                        v-model="years"
                        :options="yearsOfPracticeList"
                        label="Years of Practice"
                        :return-object="true"
                    />
                </div>

                <!-- AVAILABILITY -->
                <div class="input__availability">
                    <checkbox-component
                        v-model="availabilities"
                        :options="availabilityList"
                        label="Availability"
                        name="availability"
                    />
                </div>
            </div>
            <template v-slot:footer>
                <button-component
                    type="white-btn"
                    label="Apply filters"
                    block
                    @click="onApplyFilter"
                />
            </template>
        </load-more-component>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import useFilterSearch from '@MedrepFunctions/useFilterSearch.js';
import {
    LoadMoreComponent,
    RadioButtonComponent,
    CheckboxComponent,
    MultipleSelectComponent,
} from "@CommonComponents";

export default {
    name: "SearchDoctorsFilterComponent",
    components: {
        LoadMoreComponent,
        RadioButtonComponent,
        CheckboxComponent,
        MultipleSelectComponent,
    },
    props: {},
    emits: [ "on-apply-filter", ],
    setup(props, { emit }) {
        const {
            form,
            preferences,
            specialty,
            getPreferences,
            specialtyProps,

            location,
            getLocationList,
            locationProps,

            years,
            yearsOfPracticeList,

            availabilityList,
            availabilities,
        } = useFilterSearch();

        // SCROLL AREA HEIGHT COMPUTATION
        const viewportHeight = ref(window.innerHeight);
        const componentHeight = ref(0);
        const totalHeight = ref(0);
        const wrapperHeight = ref(null);
        const actionBtnHeight = ref(0);

        onMounted(() => {
            const paddingTop = wrapperHeight.value.getBoundingClientRect().top;
            const paddingBottom =
                viewportHeight.value -
                wrapperHeight.value.getBoundingClientRect().bottom;
            totalHeight.value =
                viewportHeight.value -
                componentHeight.value.offsetHeight -
                paddingTop -
                paddingBottom -
                actionBtnHeight.value;
        });

        // START OF CLEAR FILTERS
        const clearFilter = async () => {
            specialty.value = null;
            location.value = null;
            years.value = yearsOfPracticeList.value[0];
            availabilities.value = availabilityList.value;
            await setInitialFilter();
            // for code refactor: install VueScrollTo for smooth scrolling effect
            const filterContainer = document.querySelector('.search-doctors-filter-component .load-more-body')
            filterContainer.scrollTop = 0
        };
        // END OF CLEAR FILTERS

        // START OF APPLY FILTER
        const setFormData = (form, type) => {
            emit("on-apply-filter", { form, type });
        }
        const onApplyFilter = () => {
            setFormData(form.value, 'apply-filter');
        }
        const setInitialFilter = async () => {
            const preferredProvinceIds = [];
            const preferredMunicipalityIds = [];

            if (_.isEmpty(preferences.value)) {
                await getPreferences();
            }

            const preferredSpecialtyIds = _.map(preferences.value.specialties, i => i.id)
            preferences.value.locations.forEach((i) => {
                if (i.location_type === 'province') {
                    preferredProvinceIds.push(i.id);
                } else {
                    preferredMunicipalityIds.push(i.id);
                }
            })
            form.value = {
                specialty_ids: preferredSpecialtyIds,
                province_ids: preferredProvinceIds,
                municipality_ids: preferredMunicipalityIds,
                years_of_practice: "",
                availability: [ 1, 2, 3, 4, 5, 6, 7, ],
            };
        }
        onMounted(async () => {
            await setInitialFilter();
            setFormData(form.value);
        })
        // END OF APPLY FILTER

        return {
            form,
            specialty,
            getPreferences,
            specialtyProps,
            location,
            getLocationList,
            locationProps,
            yearsOfPracticeList,
            years,
            availabilityList,
            availabilities,
            clearFilter,
            onApplyFilter,
            componentHeight,
            totalHeight,
            wrapperHeight,
            actionBtnHeight,
        };
    },
};
</script>

<style lang="scss">
.search-doctors-filter-component {
    @apply col-span-1 h-full mx-1;
    .search-filter {
        &__header {
            @apply pb-4 flex justify-between;
        }
        &__content {
            @apply space-y-4;
        }
    }
    .load-more-component {
        .load-more-footer {
            .action-btn {
                @apply bg-gray-50;
            }
        }
    }
}
</style>