<template>
    <div v-if="displayCard || isLoading" class="brand-card-component">
        <div
            :class="[
                isAppointmentCard
                    ? 'card-no-shadow'
                    : 'card-with-shadow default',
            ]"
        >
            <OutlineChatAltIcon class="card-icon" aria-hidden="true" />
            <div v-if="!isLoading" class="brand-container">
                <p class="header-label">{{ data.length > 1 ? 'Brands' : 'Brand' }} {{ previewAppointment ? 'to promote' : 'promoted' }}</p>
                <div class="brand-cover-list">
                    <div v-for="(item, index) in data" :key="index">
                        <badge-component
                            type="pending"
                            with-dot
                            :label="item"
                        />
                    </div>
                </div>
            </div>
            <div v-else class="brand-container load_card_animate w-full">
                <p class="h-4 header-label bg-primary-100 rounded w-1/3 mt-px" />
                <div class="brand-cover-list">
                    <div v-for="(i, index) in 3" :key="index">
                        <p class="h-4 bg-primary-100 rounded w-1/4 mt-px" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ChatAltIcon as OutlineChatAltIcon
} from '@heroicons/vue/outline'
import BadgeComponent from '../../badge-component.vue';
import { computed } from 'vue'

export default {
    name: "NameCardComponent",
    components: {
        OutlineChatAltIcon,
        BadgeComponent,
    },
    props: {
        data: { type: Object, default: null },
        isAppointmentCard: Boolean,
        previewAppointment: Boolean,
        isLoading: Boolean
    },
    setup(props) {
        const displayCard = computed(() => {
            return props.data
        })

        return {
            displayCard
        }
    }
}
</script>

<style lang="scss">
.brand-card-component {
    .brand-container {
        @apply ml-0 space-y-1 #{!important};
        .header-label {
            @apply text-sm font-medium text-gray-700;
        }
        .brand-cover-list {
            @apply space-y-1;
        }
    }
}
</style>