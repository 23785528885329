<template>
    <fieldset class="checkbox-component">
        <legend :class="{ 'sr-only': !label }">
            {{ label }}
        </legend>

        <div class="checkbox-component__container">
            <div
                v-for="(option, index) in options"
                :key="option.id"
                class="checkbox-component__input"
            >
                <div class="flex items-center h-5">
                    <input
                        :id="`${name}-${index}`"
                        v-model="value"
                        :value="option"
                        :aria-describedby="`${name}-${index}-description`"
                        :name="name"
                        type="checkbox"
                        class="focus:ring-0"
                        @change="handleClick(value, index)"
                    />
                </div>
                <div class="checkbox-component__label">
                    <label :for="`${name}-${index}`">
                        {{ option.label }}
                    </label>
                </div>
            </div>
        </div>
    </fieldset>
</template>
<script>
import { ref, watch } from "vue";
export default {
    name: "CheckboxComponent",
    props: {
        modelValue: { type: Object, default: () => {} },
        options: { type: Array, default: () => {} },
        label: { type: String, default: "" },
        name: { type: String, default: "" },
    },
    emits: [ "update:model-value" ],
    setup(props, { emit }) {
        const value = ref(props.modelValue);
        const handleClick = (event, index) => {
            if (props.name === "availability") {
                if (index === 7) {
                    // TO TOGGLE ANY DAY CHECKBOX
                    const anyday = _.findIndex(value, {
                        label: "Any Day",
                    });
                    if (
                        value.value.length === props.options.length - 1 &&
                        anyday < 0
                    ) {
                        // TO DESELECT ALL DAYS
                        emit("update:model-value", []);
                    } else {
                        const selected = [];
                        _.forEach(props.options, (item) => {
                            selected.push(item);
                        });
                        emit("update:model-value", selected);
                    }
                } else {
                    // DESELECT ANY DAY WHEN ONE OF OTHER DAY IS DESELECTED
                    const any = { value: null, label: "Any Day" };
                    let arr = _.filter(
                        _.cloneDeep(event),
                        (i) => !_.isEqual(i, any)
                    );
                    if (arr.length === 7) {
                        // AUTO SELECT ANY DAY WHEN MON TO SUNDAY IS SELECTED
                        arr.push(any);
                    }
                    emit("update:model-value", arr);
                }
            } else {
                emit("update:model-value", event);
            }
        };

        watch(
            () => props.modelValue,
            (newVal) => {
                value.value = newVal
            }
        )

        return {
            handleClick,
            value,
        };
    },
};
</script>
<style lang="scss">
.checkbox-component {
    @apply space-y-3 ;
    legend {
        @apply block text-sm font-medium text-gray-700 ;
    }
    &__container {
        @apply grid grid-cols-2 gap-3 grid-flow-col grid-rows-5 ;
    }
    &__input {
        @apply relative flex items-start cursor-pointer ;
        input {
            @apply h-4 w-4 text-indigo-600 border-gray-300 rounded ;
        }
    }
    &__label {
        @apply text-sm w-full ;
        label {
            @apply pl-3 block font-medium text-gray-700 cursor-pointer ;
        }
    }
}
</style>