<template>
    <div class="single-otp-input-component">
        <input
            ref="input"
            v-model="baseValue"
            :type="inputType"
            min="0"
            max="9"
            maxlength="1"
            pattern="[0-9]"
            class="otp-input"
            :class="inputClasses"
            @input="handleOnChange"
            @keydown="handleOnKeyDown"
            @paste="handleOnPaste"
            @focus="handleOnFocus"
            @blur="handleOnBlur"
        />
        <span v-if="!isLastChild && separator">
            <span v-html="$sanitize(separator)" />
        </span>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
export default {
    name: "SingleOtpInputComponent",
    props: {
        value: { type: [ Number, String ], default: null },
        separator: { type: String, default: "" },
        inputClasses: { type: String, default: "" },
        inputType: {
            type: String,
            default() {
                return "tel";
            },
        },
        shouldAutoFocus: Boolean,
        focus: Boolean,
        isLastChild: Boolean,
    },
    emits: [ "on-change", "on-keydown", "on-paste", "on-blur", "on-focus", "update-value" ],
    setup(props, { emit }) {
        const model = ref(props.value || "");
        const input = ref(null);
        onMounted(() => {
            if (input.value && props.focus && props.shouldAutoFocus) {
                input.value.focus();
            }
        });

        const baseValue = ref(props.modelValue)
        // UPDATE BASEVALUE EVERYTIME THE MODELVALUE CHANGES
        watch(
            () => props.value,
            (newValue, oldValue) => {
                baseValue.value = newValue
                if (newValue !== oldValue) {
                    model.value = newValue;
                }
            }
        );
        // SEND UP THE UPDATED MODELVALUE TO PARENT
        watch(
            () => baseValue.value,
            (newVal) => {
                emit('update-value', newVal)
            }
        )

        // whenever question changes, this function will run
        watch(
            () => props.focus,
            (newFocusValue, oldFocusValue) => {
                // Check if focusedInput changed
                // Prevent calling function if input already in focus
                if (
                    oldFocusValue !== newFocusValue &&
                    input.value &&
                    props.focus
                ) {
                    input.value.focus();
                    input.value.select();
                }
            }
        );

        const handleOnChange = () => {
            if (("" + model.value).length > 1) {
                model.value = ("" + model.value)[0];
            }
            emit("on-change", model.value);
            // if (this.model.length > 1) {
            //     this.model = this.model.slice(0, 1);
            // }
            // return this.$emit("on-change", this.model);
        };

        const handleOnKeyDown = (event) => {
            // Only allow characters 0-9, DEL, Backspace and Pasting
            const keyEvent = event || window.event;
            const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
            if (
                isCodeNumeric(charCode) ||
                charCode === 8 ||
                charCode === 86 ||
                charCode === 46 ||
                charCode === 37 ||
                charCode === 39
            ) {
                emit("on-keydown", event);
            } else {
                keyEvent.preventDefault();
            }
        };

        const isCodeNumeric = (charCode) => {
            // numeric keys and numpad keys
            return (
                (charCode >= 48 && charCode <= 57) ||
                (charCode >= 96 && charCode <= 105)
            );
        };

        const handleOnPaste = (event) => {
            emit("on-paste", event);
        };

        const handleOnFocus = () => {
            input.value.select();
            emit("on-focus");
        };

        const handleOnBlur = () => {
            emit("on-blur");
        };

        return {
            model,
            input,
            handleOnChange,
            handleOnKeyDown,
            isCodeNumeric,
            handleOnPaste,
            handleOnFocus,
            handleOnBlur,
            baseValue,
        };
    },
};
</script>
<style lang="scss">
.single-otp-input-component {
    @apply flex items-center;
    // FIX ON FAILED maxlength="1" ON INPUT ON LAST CHILD
    &:last-of-type {
        input {
            padding: 15px !important;
        }
    }
}
</style>
