<template>
    <div class="admin-dashboard-layout flex">
        <dashboard-sidebar :module-name="moduleName" @page="page = $event" />
        <div class="antialiased font-sans w-full">
            <dashboard-top-navbar :page="page" :module-name="moduleName" />
            <main class="admin-dashboard-layout__container p-0 bg-gray-50">
                <slot />
            </main>
        </div>

        <!-- FLOATING FEEDBACK MESSAGE -->
        <floating-feedback-component
            v-model="showFeedback"
        />

        <replenish-team-credits-modal-component
            :team-members="membersToReplenishList"
            :team-member-loading="isLoadingTeamMembers"
        />
    </div>
</template>

<script>
import {
    DashboardSidebar,
    DashboardTopNavbar,
    FloatingFeedbackComponent,
} from "@CommonComponents";
import { ReplenishTeamCreditsModalComponent } from '@AdminComponents/modal'
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
    name: "AdminDashboardLayoutComponent",
    components: {
        DashboardSidebar,
        DashboardTopNavbar,
        FloatingFeedbackComponent,
        ReplenishTeamCreditsModalComponent,
    },
    props: {
        moduleName: { type: String, default: null },
    },
    setup() {
        const store = useStore();
        const page = ref("");

        // SHOW FEEDBACK MESSAGE
        const showFeedback = computed(() => store.getters['CommonUtilities/showFeedback'])

        // GET TEAM MEMBER LIST FOR ALLOCATION OF CREDITS
        const membersToReplenishList = computed(
            () => store.getters["AdminTeam/membersToReplenishList"]
        );
        const isLoadingTeamMembers = computed(() => {
            return store.getters['AdminTeam/isLoading']
        })

        onMounted(async () => {

        });

        return {
            page,
            showFeedback,
            membersToReplenishList,
            isLoadingTeamMembers,
        };
    },
};
</script>

<style lang="scss">
.admin-dashboard-layout {
    @apply h-screen overflow-hidden;
    &__container {
        @apply overflow-x-auto;
        height: calc(100% - 78px);
    }
}
</style>