<template>
    <div v-if="credit" class="credit-component">
        <DatabaseIcon aria-hidden="true" />
        <span class="text-sm font-medium text-gray-700">
            {{ credit }}
        </span>
    </div>
</template>

<script>
import { DatabaseIcon } from "@heroicons/vue/solid";
export default {
    name: "CreditComponent",
    components: {
        DatabaseIcon,
    },
    props: {
        credit: { type: [ String, Number ], default: null },
    },
};
</script>

<style lang="scss">
.credit-component {
    @apply flex space-x-3;
    svg {
        @apply h-5 w-5;
        path {
            @apply text-gray-400 #{!important};
        }
    }
}
</style>