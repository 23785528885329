import { createRouter, createWebHistory } from "vue-router";

import MedrepModuleRoutes from '../modules/medrep/routes/index'
import AdminModuleRoutes from '../modules/admin/routes/index'
import CommonModuleRoutes from '../components/routes/index'

import VideoCallModuleRoutes from "../modules/video/routes";

import BoilerPlateComponent from  "@MedrepViews/boilerplate"
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from '@Utilities/enum'

const usertype = STORAGE.GET_COOKIES(Tokens.Personal) && STORAGE.GET_COOKIES(Tokens.Personal).user_info ? STORAGE.GET_COOKIES(Tokens.Personal).user_info.user_type : '';
const ModuleRoutes = usertype === "medrep" ? MedrepModuleRoutes : AdminModuleRoutes

const routes = [
    ...VideoCallModuleRoutes,
    ...ModuleRoutes,
    ...CommonModuleRoutes,
    { path: '/boilerplate/medrep', component: BoilerPlateComponent }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
})

export default router;
