<template>
    <div class="otp-component">
        <info-box-component
            v-if="showFeedback"
            :title="title"
            sub-title="Do not share it with anyone."
        />
        <div class="flex items-center">
            <otp-input-component
                ref="otpInput"
                separator=""
                :num-inputs="6"
                :should-auto-focus="true"
                input-type="number"
                @on-change="handleOnChange($event)"
                @on-complete="handleOnComplete"
            />

            <spinner-component
                v-if="isLoading"
                class="mx-auto inline-block"
                :size="24"
            />
        </div>

        <p
            v-if="!success"
            class="text-sm leading-5 font-normal text-gray-500"
            style="margin-top: 8px !important"
        >
            Invalid OTP
        </p>

        <div>
            <p class="text-sm mb-4 leading-5 font-normal text-gray-700">
                Didn’t receive an OTP?
            </p>
            <button-component
                type="secondary-btn"
                :label="disabled ? `Resend OTP again in ${countdown}s` : 'Resend OTP'"
                block
                :disabled="disabled"
                @click="onResendOtp"
            />
            <divider-component type="text" text="Not you?" />
            <button-component
                type="white-btn"
                label="Change Email"
                block
                @click="onChangeEmail"
            />
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";
import OtpInputComponent from "./otp/otp-input-component.vue";
import InfoBoxComponent from "../info-box-component.vue";
import DividerComponent from "../divider-component.vue";
import { useStore } from "vuex";

export default {
    name: "OtpComponent",
    components: {
        DividerComponent,
        InfoBoxComponent,
        OtpInputComponent,
    },
    props: {
        showFeedback: { type: Boolean, default: true },
        email: { type: String, default: "" },
    },
    emits: [ "on-resend-otp", "show-sign-in" ],
    setup(props, { emit }) {
        const store = useStore();

        // INPUT OTP CODE
        const otpInput = ref(null);
        let success = ref(true);
        const handleOnComplete = async (value) => {
            const payload = {
                otp: value,
                email: store.getters.email,
            };
            const response = await store.dispatch("onVerifyOtp", payload);
            if (!response.success) {
                success.value = response.success;
                handleClearInput();
            } else {
                switch (response.user.user_info.user_type) {
                case "medrep":
                    window.location.href = "/medrep/dashboard";
                    break;
                default:
                    // TO ADMIN
                    window.location.href = "/admin/dashboard";
                    break;
                }
            }
        };
        const handleOnChange = () => {
            // console.warn("OTP changed: ", value);
        };
        const handleClearInput = () => {
            otpInput.value.clearInput();
        };

        const disabled = ref(false)
        const countdown = ref(9)
        // RESEND OTP
        const onResendOtp = () => {
            disabled.value = true;
            // OTP COUNTDOWN
            const onSetCountDown = setInterval(() => {
                --countdown.value
                if (countdown.value <= 0) {
                    clearInterval(onSetCountDown)
                    disabled.value = false
                    countdown.value = 9
                }
            }, 1000);
            handleClearInput();
            emit("on-resend-otp", store.getters.email);
            success.value = true;
        };

        // LOADING
        const isLoading = computed(() => store.getters.isLoading);

        // CHANGE EMAIL
        const onChangeEmail = () => {
            emit("show-sign-in");
        };

        const title = computed(() => {
            return `An OTP has been sent to ${props.email}`
        })

        return {
            otpInput,
            success,
            handleOnComplete,
            handleOnChange,
            onChangeEmail,
            isLoading,
            onResendOtp,
            disabled,
            countdown,
            title,
        };
    },
};
</script>

<style>
</style>