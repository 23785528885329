<template>
    <div class="suggested-doctors-component">
        <doctor-thread-component
            v-bind="suggestedProps"
            @on-show-preference="onShowPreference"
            @get-doctor-id="$emit('get-doctor-id', $event)"
        />
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import {
    DoctorThreadComponent,
} from '@MedrepComponents'

export default {
    name: 'SuggestedDoctorsComponent',
    components: {
        DoctorThreadComponent,
    },
    props: {
        tabName: { type: String, default: '' },
    },
    emits: [ "get-doctor-id", "set-tab-name" ],
    setup(props, { emit }) {
        const store = useStore();

        // START OF SETTING ACTIVE TAB
        onMounted(async () => {
            emit("set-tab-name", props.tabName)
        })

        // SUGGESTED DOCTORS DATA
        const doctors= computed(() => store.getters['CommonDoctor/suggestedDoctorList']);

        // SUGGESTED DOCTORS BINDED PROPS
        const suggestedProps = ref({
            title: "Suggested Doctors",
            type: "suggested",
            doctors: doctors,
            emptyTitle: "No suggested doctors to show",
            uniqueId: "suggested-doctors-read-more",
            showHeaderButton: true,
            headerButtonLabel: "Preferences",
        })

        // START OF SHOW PREFERENCE MODAL
        const onShowPreference = () => {
            store.commit('MedrepPreferences/setShowPreference', { action: "update", show: true })
        }
        // END OF SHOW PREFERENCE MODAL

        return {
            suggestedProps,
            onShowPreference,
        }
    }
}
</script>

<style lang="scss">
.suggested-doctors-component {
    .thread-component {
        &.doctor-thread-component {
            .thread-header {
                @apply flex justify-between items-center pb-4 ;
            }
        }
    }
}
</style>