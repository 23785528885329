<template>
    <div class="team-page-component dashboard-grid-2">
        <div class="team-appointments-container">
            <div class="header-message-container">
                <h2 class="header-message">Team Appointments</h2>
                <p class="header-sub-message">Workcation is a property rental website.</p>
            </div>

            <navigation-tabs-component
                v-model="AdminTeamtablists"
                v-bind="navigationTabProperties"
                @update:modelValue="onChangeTab($event)"
            />
            <div class="tab-content admin-schedule-content">
                <router-view
                    load-more-label="Show more"
                    @get-appointment-uuid="appointmentUuid = $event"
                    @set-tab-name="tabName = $event"
                />
            </div>
        </div>

        <div>
            <team-member-list-component
                :member-limit="5"
                @table-ref="tableRef = $event"
                @get-member-id="onSelectMember($event)"
            />

            <profile-slideover-component
                v-model="showProfileSlideover"
                :doctor-id="memberId"
                :type="type"
            />

            <appointment-slideover-component
                v-if="tabName !== 'declined' && tabName !== 'cancelled'"
                v-model="showAppointmentSlideover"
            />
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref, watch, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
import NavigationTabsComponent from "@CommonComponents/navigation-tabs-component.vue";
import TeamMemberListComponent from "@AdminComponents/dashboard/components/team-member-list-component.vue";
import {
    ProfileSlideoverComponent,
    AppointmentSlideoverComponent,
} from "@CommonComponents/slideovers";
import useNavtab from "@GlobalFunctions/useNavtab.js"

export default {
    name: "TeamPageComponent",
    components: {
        NavigationTabsComponent,
        ProfileSlideoverComponent,
        AppointmentSlideoverComponent,
        TeamMemberListComponent,
    },
    props: {
        pageName: { type: String, default: '' }
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        // GET DOCTOR ID
        const memberId = ref(null);

        // TEAM MEMBERS
        const tableRef = ref(0)

        // START OF GET APPOINTMENT EVERYTIME THERE IS A NEW UUID
        const appointmentUuid = ref(null);
        watch(
            () => appointmentUuid.value,
            (newVal) => {
                store.dispatch('CommonAppointment/getAppointmentDetails', newVal);
            }
        )
        // END OF GET APPOINTMENT EVERYTIME THERE IS A NEW UUID

        // START OF SHOW DOCTOR SLIDEOVER
        const showProfileSlideover = computed(() => store.getters["CommonDoctor/profileSlideover"]);
        // END OF SHOW SLIDEOVER

        // START OF SHOW APPOINTMENT SLIDEOVER
        const showAppointmentSlideover = computed(() => store.getters['CommonAppointment/appointmentSlideover'])
        // END OF SHOW APPOINTMENT SLIDEOVER

        // START OF HIDE SLIDEOVER
        const type = ref('member')
        const hideSlideover = () => {
            store.commit("CommonDoctor/setProfileSlideover", false);
            store.commit('CommonAppointment/setAppointmentSlideover', false);
        }
        onBeforeUnmount(() => {
            hideSlideover();
        })
        // END OF HIDE SLIDEOVER

        // START OF NAVIGATION TAB
        const {
            AdminTeamtablists,
            navigationTabProperties,
        } = useNavtab();

        // SET ACTIVE TAB WHEN CLICKING REDIRECTION LINK FROM EMPTY STATES OR ANY OTHER PAGE ETC.
        const tabName = ref("")
        const selectActiveTab = (list) => {
            _.forEach(AdminTeamtablists.value, (i) => {
                list === i.tab ? (i.current = true) : (i.current = false);
            });
        };
        onMounted(() => {
            selectActiveTab(tabName.value);
            store.commit("CommonUtilities/setPageName", props.pageName);
        });

        // REDIRECT TO DESIGNATED ROUTE WHEN SWITCHING TO ANOTHER TAB
        const onChangeTab = async(event) => {
            if (event) {
                AdminTeamtablists.value = event;

                const tabname = _.find(AdminTeamtablists.value, (i) => i.current).tab;

                router.push({ path: `/admin/team/${tabname}` });
                // HIDE SLIDEOVER
                if (showProfileSlideover.value || showAppointmentSlideover.value) {
                    hideSlideover();
                }
            }
        };
        // END OF NAVIGATION TAB

        // ON SELECT MEMBER FROM SLIDEOVER
        const onSelectMember = (id) => {
            memberId.value = id;
            type.value = 'member';
        }

        return {
            AdminTeamtablists,
            tabName,
            showAppointmentSlideover,
            showProfileSlideover,
            navigationTabProperties,
            appointmentUuid,
            onChangeTab,
            tableRef,
            memberId,
            type,
            onSelectMember,
        }
    }

}
</script>

<style lang="scss">
.team-page-component {
    .team-member-list-component  {
        .table-card-container {
            .table-container {
                .table-thead-container {
                    .table-th-container {
                        &:last-of-type {
                            @apply w-1/3;
                            min-width: 180px;
                        }
                    }
                }
            }
        }
    }
}
</style>