import { RouterViewComponent } from '@GlobalComponents';

import {
    RoomComponent,
    RemarksComponent,
    FeedbackComponent,
    VideoInCallComponent,
    VideoExitPageComponent,
    VideoCallMainComponent
} from '@VideoCallComponents';

const VideoCallModuleRoutes = [
    {
        path: '/video',
        component: RouterViewComponent,
        props: { moduleName: 'VideoCall' },
        meta: {
            title: 'PPD Clinic Pharma',
            layout: 'video-call-layout-component'
        },
        children: [
            {
                path: 'room/:room_uuid',
                component: VideoCallMainComponent,
                props: true,
                children: [
                    {
                        path: '',
                        component: RoomComponent,
                        name: 'video-call-room'
                    },
                    {
                        path: 'in-call/',
                        component: VideoInCallComponent,
                        name: 'video-in-call'
                    },
                    {
                        path: 'appointment-ended/',
                        component: VideoExitPageComponent,
                        name: 'video-call-ended'
                    },
                    {
                        path: 'remarks/',
                        component: RemarksComponent,
                        name: 'video-call-remarks'
                    },
                    {
                        path: 'feedback/',
                        component: FeedbackComponent,
                        name: 'video-call-feedbacks'
                    }
                ]
            }
        ]
    }
]

export default VideoCallModuleRoutes