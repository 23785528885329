import { RouterViewComponent } from '@GlobalComponents';
import {
    DashboardPageComponent,
    DoctorsPageComponent,
    SuggestedDoctorsComponent,
    SearchDoctorsComponent,
    MyDoctorsComponent,
    SchedulePageComponent,
    AppointmentComponent,
    ProfilePageComponent,
} from '@MedrepComponents';

import {
    UpcomingScheduleComponent,
    PendingScheduleComponent,
    DoneScheduleComponent,
    DeclinedScheduleComponent,
    CancelledScheduleComponent,
} from '@CommonComponents/schedule';

const MedrepModuleRoutes = [
    {
        path: '/medrep',
        name: "medrep",
        component: RouterViewComponent,
        redirect: { path: '/medrep/dashboard' },
        props: { moduleName: 'medrep' },
        meta: {
            title: 'PPD Clinic Pharma',
            layout: "medrep-dashboard-layout-component",
        },
        children: [
            {
                path: "dashboard",
                component: DashboardPageComponent,
                name: 'dashboard-page',
                props: { pageName: 'dashboard' },
                meta: { title: "PPD Clinic Pharma | Dashboard" },
            },
            {
                path: "doctors",
                component: DoctorsPageComponent,
                name: 'doctors-page',
                props: { pageName: 'doctors' },
                redirect: { path: '/medrep/doctors/suggested' },
                children: [
                    {
                        path: "suggested",
                        name: 'suggested-doctors-page',
                        component: SuggestedDoctorsComponent,
                        props: { tabName: 'suggested' },
                    },
                    {
                        path: "search",
                        name: 'search-doctors-page',
                        component: SearchDoctorsComponent,
                        props: { tabName: 'search' },
                    },
                    {
                        path: "mydoctors",
                        name: 'my-doctors-page',
                        component: MyDoctorsComponent,
                        props: { tabName: 'mydoctors' },
                    },
                    {
                        path: "appointment",
                        name: 'appointment-page',
                        component: AppointmentComponent,
                    },
                ]
            },
            {
                path: "schedule",
                component: SchedulePageComponent,
                name: 'schedule-page',
                props: { pageName: 'schedule' },
                redirect: { path: '/medrep/schedule/upcoming' },
                children: [
                    {
                        path: "upcoming",
                        name: 'upcoming-schedule-page',
                        component: UpcomingScheduleComponent,
                        props: { tabName: 'upcoming' },
                    },
                    {
                        path: "pending",
                        name: 'pending-schedule-page',
                        component: PendingScheduleComponent,
                        props: { tabName: 'pending' },
                    },
                    {
                        path: "done",
                        name: 'done-schedule-page',
                        component: DoneScheduleComponent,
                        props: { tabName: 'done' },
                    },
                    {
                        path: "declined",
                        name: 'declined-schedule-page',
                        component: DeclinedScheduleComponent,
                        props: { tabName: 'declined' },
                    },
                    {
                        path: "cancelled",
                        name: 'cancelled-schedule-page',
                        component: CancelledScheduleComponent,
                        props: { tabName: 'cancelled' },
                    },
                ]
            },
            {
                path: "profile",
                component: ProfilePageComponent,
                name: 'profile-page',
                props: { pageName: 'profile' },
            },
        ],
    },
]

export default MedrepModuleRoutes