<template>
    <svg
        class="pharma__icon"
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.70538 8.54446C7.09466 8.15519 7.72569 8.15484 8.11538 8.54369L11.6468 12.0674C11.842 12.2622 12.158 12.2622 12.3532 12.0674L15.8846 8.54369C16.2743 8.15484 16.9053 8.15519 17.2946 8.54446C17.6842 8.93403 17.6842 9.56565 17.2946 9.95523L12.7071 14.5427C12.3166 14.9333 11.6834 14.9333 11.2929 14.5427L6.70538 9.95523C6.31581 9.56565 6.31581 8.93403 6.70538 8.54446Z"
            fill="#596E8A"
        />
    </svg>
</template>

<script>
export default {
    props: {
        height: { type: String, default: "24" },
        width: { type: String, default: "24" },
    },
};
</script>