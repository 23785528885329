<template>
    <div class="local__stream-state-screen">
        <div
            :class="[
                setup
                    ? 'local__stream-setting-up'
                    : 'local__stream-view-ongoing',
                { sharescreen__state: viewScreenShare },
            ]"
        >
            <div
                v-show="minimizeVideo"
                class="bg-white pharma-flex-between rounded-lg"
            >
                <mic-camera-icon
                    v-bind="audioIconProps"
                    class="rounded-lg"
                    style="width: 80% !important"
                />

                <div class="minimizer__button bg-white rounded-r-lg">
                    <button-component
                        size="xs"
                        type="white-icon-btn"
                        @click="minimizeVideo = !minimizeVideo"
                    >
                        <IconArrowDown />
                    </button-component>
                </div>
            </div>

            <div v-show="!minimizeVideo" class="h-full w-full">
                <div
                    v-show="connected"
                    class="local__stream_container relative"
                >
                    <div v-if="!setup" class="minimizer__button">
                        <button-component
                            size="xs"
                            type="white-icon-btn"
                            @click="minimizeVideo = !minimizeVideo"
                        >
                            <IconArrowUp />
                        </button-component>
                    </div>
                    <div
                        v-show="hasCamera"
                        id="local__stream-view"
                        :class="$device.isMobile && setup ? 'h-full' : ''"
                    />

                    <div v-show="!hasCamera" class="local__stream-video-mute">
                        <div class="icon__l mx-auto">
                            <img
                                class="avatar__image"
                                :src="localStreamDetails.avatar"
                                loading="lazy"
                            />
                        </div>
                    </div>

                    <mic-camera-icon v-bind="audioIconProps" class="absolute" />
                </div>

                <div v-show="viewScreenShare && remoteConnected" class="remote relative">
                    <div
                        v-show="!remoteMuteVideo"
                        id="remote__stream-thumbnail-view"
                    />

                    <div
                        v-show="remoteMuteVideo"
                        class="local__stream-video-mute"
                    >
                        <div class="icon__l mx-auto">
                            <img
                                class="avatar__image"
                                :src="remoteStreamDetails.avatar"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <mic-camera-icon
                        v-bind="remoteAudioIconProps"
                        class="absolute"
                    />
                </div>
            </div>
        </div>

        <video-alert
            v-if="!setup"
            v-model="showVideoAlert"
            :state="videoAlertState"
            :position="message_position"
            style="height: calc(100% - 64px)"
            :time-count="timeCount"
            :alert-color="alertColor"
        />
    </div>
</template>

<script>
import VideoAlert from "./video-alert.vue";
import { ref, computed, watch } from "vue";
import { useSetupSettings, useVideoCallDetails } from "../functions";
import { IconArrowDown, IconArrowUp } from "@Icons";

import MicCameraIcon from "./mic-camera-icon.vue";
export default {
    components: {
        MicCameraIcon,
        IconArrowDown,
        IconArrowUp,
        VideoAlert,
    },

    props: {
        connected: Boolean,
        reconnecting: Boolean,
        setup: Boolean,
        loading: Boolean,
        viewScreenShare: Boolean,
        remoteMuteVideo: Boolean,
        remoteMuteAudio: Boolean,
        remoteConnected: Boolean,
        disableShare: Boolean,
        countdown: Boolean,
        settingsChange: { type: [ String, Number ], default: "" },
        timeCount: { type: [ String, Number ], default: "" },
        alertColor: { type: String, default: "" },
    },
    emits: [ "reconnect-call", "screen-share" ],
    setup(props) {
        //DATA
        let minimizeVideo = ref(false);
        const { hasCamera, hasAudio } = useSetupSettings();

        const { localStreamDetails, remoteStreamDetails } =
            useVideoCallDetails();

        const videoAlertState = ref(null);
        const showVideoAlert = ref(false);
        const message_position = ref("");

        const audioIconProps = computed(() => {
            return {
                on: hasAudio.value,
                label: `${localStreamDetails.value.full_name} (Me)`,
                type: "microphone",
                class: "py-1 bg-white ellipsis_container",
            };
        });

        const remoteAudioIconProps = computed(() => {
            return {
                on: !props.remoteMuteAudio,
                label: remoteStreamDetails.value.full_name,
                type: "microphone",
                class: "py-1 bg-white ellipsis_container",
            };
        });

        const setVideoAlert = (state) => {
            showVideoAlert.value = true;
            videoAlertState.value = state;
        };

        watch(
            () => props.connected,
            (newValue) => {
                if (newValue) {
                    setVideoAlert("ls_connected");
                    message_position.value = "bot_left";
                }
            }
        );

        watch(
            () => props.settingsChange,
            (newValue) => {
                if (newValue) {
                    setVideoAlert("ls_update_settings");
                    message_position.value = "bot_left";
                }
            }
        );

        watch(
            () => props.reconnecting,
            (newValue) => {
                if (newValue) {
                    setVideoAlert("ls_disconnected");
                    message_position.value = "bot_left";
                }
            }
        );

        watch(
            () => props.countdown,
            (newValue) => {
                if (newValue) {
                    setVideoAlert("ls_time");
                    message_position.value = "bot_right";
                }
            }
        );

        watch(
            () => props.disableShare,
            (newValue) => {
                if (newValue) {
                    setVideoAlert(`ls_share_${newValue}`);
                    message_position.value = "bot_mid";
                }
            }
        );

        return {
            showVideoAlert,
            videoAlertState,
            audioIconProps,
            minimizeVideo,
            hasCamera,
            hasAudio,
            localStreamDetails,
            remoteStreamDetails,
            message_position,
            remoteAudioIconProps,
        };
    },
};
</script>

<style lang="scss">
.local__stream-state-screen {
    .local__stream-setting-up {
        @apply absolute w-full h-full flex items-center justify-center;
        .setting_icon {
            @apply h-5 w-5;
        }
        #local__stream-view {
            height: 100%;
        }
        .call_icons {
            .microphone_type {
                @apply flex items-center justify-center;
            }
        }
    }

    .local__stream-video-mute {
        @apply bg-gray-100 rounded-t-lg rounded-b-lg;
    }

    .sharescreen__state {
        #local__stream-view {
            div:first-child {
                @apply rounded-b-none #{!important};
            }
        }
        .local__stream_container {
            @apply rounded-b-none #{!important};
            .call_icons {
                @apply rounded-bl-none #{!important};
            }
            .local__stream-video-mute {
                @apply rounded-b-none;
            }
        }
        .remote {
            @apply rounded-b-lg;
            div:first-child {
                @apply rounded-b-lg;
            }
            .local__stream-video-mute {
                @apply rounded-t-none;
            }
        }
    }

    .call_icons {
        @apply bottom-0 max-w-full;
    }

    #local__stream-view, #remote__stream-thumbnail-view {
        video {
            object-fit: contain !important;
        }
    }

    .local__stream_container, .remote {
        @apply rounded-lg h-full w-full;
    }

    .local__stream-view-ongoing {
        @apply absolute top-4 right-4 z-10;
        width: 25%;
        height: 30%;
        max-width: 200px;
        min-width: 120px;
        max-height: 250px;
        min-height: 150px;

        #local__stream-view,
        #remote__stream-thumbnail-view {
            height: 100%;
        }

        #local__stream-view {
            div:first-child {
                @apply rounded-lg;
            }
        }

        .call_icons {
            @apply rounded-bl;
        }

        .local__state_alert {
            @apply w-full;
        }

        .sub__message {
            @apply text-xs;
        }

        .local__stream_container {
            .minimizer__button {
                @apply top-3 right-3 absolute z-10;
            }
        }
    }
    .local__stream-video-mute {
        // height: calc(100% - 38px);
        @apply w-full h-full flex items-center justify-center;
    }
    .avatar__image {
        @apply inline-block rounded-full  w-full h-full object-cover;
    }
}
@media (max-width: 725px) {
    .local__stream-state-screen {
        .local__stream-setting-up {
            .call_icons {
                .microphone_type {
                    @apply block;
                }
            }
        }
        .call_icons {
            @apply rounded-tr;
        }
    }
}

@screen md {
    .local__stream-state-screen {
        .call_icons {
            @apply rounded-tr;
        }
    }
}
</style>