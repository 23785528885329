<template>
    <div class="selectbox__component">
        <div>
            <select
                v-model="selectedValue"
                class="
                    mt-1
                    block
                    w-full
                    pl-3
                    pr-10
                    py-2
                    text-base
                    border-gray-300
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    sm:text-sm
                    rounded-md
                "
                @change="itemSelect"
            >
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="objectReturn ? option : option[itemKey]"
                    :selected="itemSelected(option)"
                >
                    {{ itemName ? option[itemName] : option }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
import { ref, watch } from "vue";
export default {
    name: "SelectBoxComponent",
    props: {
        modelValue: { type: [ Object, String ], default: null },
        options: {
            type: Array,
            default: () => {
                return [];
            },
        },
        itemName: { type: String, default: "" },
        itemKey: { type: String, default: "" },
        objectReturn: Boolean,
    },
    emits: [ "value-change" ],
    setup(props, { emit }) {
        const selectedValue = ref(props.modelValue);

        watch(
            () => props.modelValue,
            (newValue) => {
                selectedValue.value = newValue;
            }
        );

        // const
        const itemSelected = (option) => {
            const value = props.objectReturn
                ? props.modelValue
                : props.modelValue[props.itemKey];

            const optionValue = props.objectReturn
                ? option
                : option[props.itemKey];

            return props.objectReturn
                ? _.isEqual(optionValue, value)
                : optionValue === value;
        };

        const itemSelect = () => {
            if (!_.isEmpty(selectedValue)) {
                const value = props.objectReturn
                    ? selectedValue.value
                    : selectedValue.value[props.itemKey];
                emit("value-change", value);
            }
        };

        return { selectedValue, itemSelected, itemSelect };
    },
};
</script>