<template>
    <div class="room__component">
        <div v-if="!isLoading" class="room__main_container">
            <div v-if="validRoom" class="room__valid">
                <div class="room__header">
                    <h2 class="text-lg leading-6 font-medium">Waiting Room</h2>
                    <button-component
                        v-if="videoCallStarted"
                        type="danger-btn"
                        label="End Appointment"
                        @click="onEndAppointment"
                    />
                </div>

                <div class="room__container mt-4">
                    <div class="room__call sm:grid mb-4">
                        <div class="reciever__container">
                            <!-- STATES:  invalid_date, reciever_out, reciever_in, reciever_in_call-->
                            <receiver-state-component :state="remoteState" />
                        </div>

                        <!-- ... -->
                        <div class="medrep__container">
                            <video-call-state-component :setup="true" />
                        </div>
                    </div>
                    <!-- ... -->
                    <div class="utilities__container lg:grid">
                        <div class="utilities__tips my-8 lg:my-0">
                            <div class="utilities__header">
                                <div class="icons__container">
                                    <div
                                        class="
                                            icon__rounded
                                            icon__secondary
                                            icon__m
                                            mb-2
                                        "
                                    >
                                        <LightBulbIcon class="icon" />
                                    </div>
                                </div>

                                <div class="utilities__message">
                                    <b class="message"> Some quick tips </b>
                                    <p class="sub__message mt-2">
                                        Quick tips before starting your
                                        appointment
                                    </p>
                                </div>
                            </div>

                            <transition
                                name="custom-classes-transition"
                                enter-active-class="animate__animated animate__fadeIn"
                                leave-active-class="animate__animated animate__fadeOut"
                            >
                                <p
                                    v-show="showTip"
                                    class="sub__message text-gray-700"
                                    v-html="tips"
                                />
                            </transition>
                        </div>
                        <div class="utilities__settings">
                            <settings-component
                                v-if="videoCallReady"
                                :setup="true"
                                :remote-state="remoteState"
                                @enter-call="enterCall"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="room__invalid pharma-flex-center">
                <div class="message__container text-center">
                    <div class="pharma-flex-center">
                        <XCircleIcon class="icon__r text-gray-400" />
                    </div>
                    <h1 class="main">Page not found</h1>
                    <p class="sub">
                        The link you have clicked may be broken or the page may
                        have been removed.
                    </p>
                </div>
            </div>
        </div>
        <div v-else class="full_height">
            <spinner-component
                :size="48"
                class="room__invalid pharma-flex-center"
            />
        </div>

        <end-appointment-modal
            v-model="endAppointmentModal"
            @proceed="endAppointment"
        />
    </div>
</template>

<script>
import { ref, computed, onMounted, watch, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { LightBulbIcon, XCircleIcon } from "@heroicons/vue/outline";
import {
    VideoCallStateComponent,
    SettingsComponent,
    EndAppointmentModal,
} from "../index";
import { Status } from "@Utilities/enum";
import { useVideoCallDetails } from "../functions";

import ReceiverStateComponent from "./reciever-state/receiver-state-component.vue";

export default {
    name: "RoomComponent",
    components: {
        ReceiverStateComponent,
        SettingsComponent,
        EndAppointmentModal,
        VideoCallStateComponent,
        LightBulbIcon,
        XCircleIcon,
    },
    props: {
        roomId: { type: String, default: null },
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const remoteState = ref("");
        let default_room_params = {};

        const {
            room_info,
            room_users,
            firebase_timestamp,
            user_type,
            room_uuid,
            user_uuid,
            videoCallReady,
            videoCallStarted,
            emptyVideoCallDetails,
            localStreamDetails,
            queryParams,
            appointmentState,
            callDuration,
        } = useVideoCallDetails();

        const endAppointmentModal = ref(false);

        const isLoading = ref(false);
        const currentTip = ref(0);
        const showTip = ref(true);
        let tipCounter = null;

        onMounted(async () => {
            isLoading.value = true;
            const params = {
                user_type,
                uuid: user_uuid,
                appointment_uuid: room_uuid,
            };

            await store.dispatch("VideoCall/validateVideocall", params);

            await store.dispatch("VideoCall/getRoomInfo", room_uuid);

            if (!emptyVideoCallDetails.value) {
                default_room_params = {
                    room_uuid,
                    type: "medrep_appointment",
                    userId: localStreamDetails.value.id,
                    userStatus: "active",
                    userType: user_type,
                    timestamp: firebase_timestamp.value,
                };

                await store.dispatch(
                    "VideoCall/initAppointmentRoom",
                    default_room_params
                );

                tipCounter = setInterval(() => {
                    showTip.value = false;

                    setTimeout(() => {
                        showTip.value = true;

                        if (currentTip.value === 5) {
                            currentTip.value = 0;
                        }
                        ++currentTip.value;
                    }, 800);
                }, 8800);
            } else {
                isLoading.value = false;
            }
        });

        onBeforeUnmount(() => {
            clearInterval(tipCounter);
        });

        const validRoom = computed(() => {
            let valid = !emptyVideoCallDetails.value;

            if (
                valid &&
                !_.isEmpty(room_info.value) &&
                room_info.value.status === Status.Ongoing
            ) {
                valid = callDuration.value > 0;

                if (!valid) {
                    endAppointment("video-call-ended", "time");
                }
            }

            return valid;
        });

        const tips = computed(() => {
            const contents = [
                "Make sure you have a stable internet connection. Please note that your time will continue to run if anyone got disconnected.",
                "Make sure that your camera and speaker are working properly.",
                "Close idle programs on your device or restart the call if it lags persistently or if the quality is bad.",
                "Patience is a virtue. Kindly wait if the other party is not in the waiting room yet.",
                "Kindly secure all necessary documents before your appointment. ",
                "Need help? You can reach us at <b>we.care@ppd.ph</b> or <b>+63 919 075 2868</b> if you encounter any issues.",
            ];

            return contents[currentTip.value];
        });

        watch(
            () => room_users.value,
            () => {
                remoteState.value = appointmentState(room_users.value);
            }
        );

        watch(
            () => room_info.value,
            () => {
                console.warn(room_info.value);
                if (!_.isEmpty(room_info.value)) {
                    isLoading.value = false;
                }
            }
        );

        const onEndAppointment = () => {
            if (videoCallStarted.value) {
                endAppointmentModal.value = true;
            }
        };

        const endAppointment = (screen = "video-call-remarks", status) => {
            const query = {
                ...queryParams,
                status,
            };

            store.dispatch("VideoCall/onUpdateRoomUser", {
                ...default_room_params,
                userStatus: "left",
            });

            router.push({ name: screen, query });
        };

        const enterCall = async () => {
            store.dispatch("VideoCall/onUpdateRoomUser", {
                ...default_room_params,
                userStatus: "in-call",
            });

            if (room_info.value.status === Status.Active) {
                store.dispatch("VideoCall/onUpdateRoom", {
                    room_uuid,
                    status: Status.Ongoing,
                    timer: firebase_timestamp.value,
                });
            }

            await store.dispatch("VideoCall/enterVideoCall", {
                channel: room_uuid,
                uuid: user_uuid,
                user_type,
            });

            store.commit("VideoCall/setVideoCallStarted", true);

            router.push({
                name: "video-in-call",
                query: queryParams,
            });
        };

        return {
            remoteState,
            videoCallReady,
            videoCallStarted,
            isLoading,
            endAppointmentModal,
            validRoom,
            tips,
            showTip,
            room_info,
            endAppointment,
            onEndAppointment,
            enterCall,
        };
    },
};
</script>

<style lang="scss">
.room__component {
    @apply mx-auto h-full p-8;
    .room__header {
        @apply flex justify-between items-center pb-4;
    }
    .room__container {
        .room__call,
        .utilities__container {
            @apply grid-cols-2 gap-4 h-1/2;
        }
    }
    .reciever__container,
    .medrep__container {
        @apply h-full;
    }

    .medrep__container {
        @apply mt-4;
    }

    @media (min-width: 640px) {
        .medrep__container {
            @apply mt-0;
        }
    }

    .utilities__tips {
        @apply text-center;
        .utilities__header {
            @apply w-full mx-auto mb-6;
        }
    }
    .room__invalid {
        height: calc(100vh - 64px);
        .message__container {
            .main {
                @apply text-sm leading-5 font-medium text-gray-800 my-4;
            }
            .sub {
                @apply text-xs leading-4 font-normal text-gray-500;
            }
        }
    }
    .full_height {
        height: calc(100vh - 64px);
    }

    @media (max-width: 640px) {
        .video__call__screen,
        .state__container {
            height: 280px;
        }
    }
}

@screen lg {
    .room__component {
        // padding: 5% 8%;
        width: 100%;
        .utilities__tips {
            .utilities__header {
                @apply w-1/2;
            }
        }
        .state__container,
        .video__call__screen {
            min-height: 420px;
        }
    }
}
</style>