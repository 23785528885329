<template>
    <div :class="['video__call__screen', { full__screen: !setup }]">
        <local-stream-state-component
            :setup="setup"
            :remote-mute-video="remoteVideoMute"
            :remote-mute-audio="remoteAudioMute"
            :connected="connected"
            :reconnecting="reconnecting"
            :view-screen-share="viewScreenShare"
            :remote-connected="remoteConnected"
            :countdown="countdown"
            :time-count="timeCount"
            :alert-color="alertTimeMessageColor"
            :settings-change="settingsChange"
            :disable-share="remoteDisableShare"
        />

        <remote-stream-state-component
            v-if="!setup"
            :connected="remoteConnected"
            :disconnected="!remoteConnected"
            :remote-connected-once="remoteConnectedOnce"
            :remote-left-count="remoteLeftCount"
            :mute-video="remoteVideoMute"
            :mute-audio="remoteAudioMute"
            :alert-color="alertTimeMessageColor"
            :time-count="timeCount"
            :show-remote="showEndCall"
            :end-time="typeExitPage"
            :channel-id="channelId"
            :view-screenshare="viewScreenShare"
        />

        <video-screen-buttons-component
            v-if="!setup"
            :remote-connected="remoteConnected"
            :show-monitor="showMonitor"
            :time-remaining="timeRemaining"
            @show-settings="onShowSettings()"
            @leave-call="onEndVideoCall()"
            @screen-share="onScreenShare()"
            @end-callpoints="onEndCallPoints($event)"
            @time-alertmessage="onAlertTimeColor($event)"
            @time-count="getTimeCount($event)"
            @stop-sharing="stopSharing($event)"
            @disable-share="disableSharing($event)"
            @flip-camera="flipCamera"
        />

        <settings-modal-component
            v-model="showSettings"
            @settings-applied="settingsApplied"
        />
    </div>
</template>

<script>
/* eslint-disable indent */
/* eslint-disable eqeqeq */
import AgoraRTC from "agora-rtc-sdk-ng";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, isRef, computed, watch, onMounted, onBeforeUnmount } from "vue";

import { useSetupSettings, useVideoCallDetails } from "./functions";

import {
    VideoScreenButtonsComponent,
    LocalStreamStateComponent,
    RemoteStreamStateComponent,
    SettingsModalComponent,
} from "./utilities";

export default {
    name: "VideoScreenShareComponent",
    components: {
        VideoScreenButtonsComponent,
        LocalStreamStateComponent,
        RemoteStreamStateComponent,
        SettingsModalComponent,
    },
    props: {
        setup: Boolean,
        destroyedKey: { type: Number, default: null },
    },
    emits: [
        "devices",
        "onAllowInteraction",
        "videocall-ready",
        "videocallDestroyed",
        "permission-failed",
    ],
    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();

        const {
            hasCamera,
            hasAudio,
            savedVideoDevice,
            savedAudioDevice,
            flipCamera,
        } = useSetupSettings(props);

        const { videoCallDetails, videoCallStarted, room_uuid, room_info } =
            useVideoCallDetails();

        const showSettings = ref(false);
        const settingUp = ref(true);
        const showEndCall = ref(false);
        const showMonitor = ref(true);

        let client = {};

        const clientRef = ref({});
        const videoCallSettings = ref({});

        const typeExitPage = ref("endtime");
        const timeRemaining = ref(0);
        const alertTimeMessageColor = ref("");
        const timeCount = ref("");
        const audiences = [];

        // Local
        const localTracks = {
            videoTrack: {},
            audioTrack: {},
        };
        const localStreamId = ref("");
        const localScreenSharing = ref(false);
        const reconnecting = ref(false);
        const disconnected = ref(false);
        const connected = ref(false);
        const countdown = ref(false);
        const settingsChange = ref(null);

        // Remote
        // let remoteStream = reactive({});
        const remoteTracks = {
            videoTrack: {},
            audioTrack: {},
            uid: "",
        };
        const remoteVideoMute = ref(false);
        const remoteAudioMute = ref(false);
        const remoteConnected = ref(false);
        const remoteConnectedOnce = ref(false);
        const remoteDisableShare = ref(false);
        const remoteLeftCount = ref(0);

        //SCREENSHARE
        const viewScreenShare = ref(false);
        let setShareScreen = false;
        let screenClient = {};
        let screenTracks = {};
        let shareScreenUid = "";

        const channelId = computed(() => {
            const random = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                /[xy]/g,
                (c) => {
                    const r = (Math.random() * 16) | 0;
                    const v = c === "x" ? r : (r & 0x3) | 0x8;
                    return v.toString(16);
                }
            );

            if (!props.setup && !_.isEmpty(videoCallDetails.value)) {
                return videoCallDetails.value.appointment_details.uuid;
            }
            return random;
        });

        watch(
            () => savedVideoDevice.value,
            () => {
                updateSettings("video", savedVideoDevice);
            }
        );

        watch(
            () => savedAudioDevice.value,
            () => {
                updateSettings("audio", savedAudioDevice);
            }
        );

        watch(
            () => hasCamera.value,
            () => {
                handleCamera();
            }
        );

        watch(
            () => hasAudio.value,
            () => {
                handleMic();
            }
        );

        watch(
            () => timeCount.value,
            (newValue) => {
                if (_.includes([ 10, 30, 60 ], newValue)) {
                    countdown.value = true;
                    setTimeout(() => {
                        countdown.value = false;
                    }, 5000);
                }
            }
        );

        watch(
            () => room_info.value,
            () => {
                if (
                    _.has(room_info.value, "shareScreenStreamId") &&
                    !_.isEmpty(room_info.value.shareScreenStreamId)
                ) {
                    shareScreenUid = room_info.value.shareScreenStreamId;
                    setShareScreen = true;
                }
            }
        );

        //ONMOUNTED
        onMounted(async () => {
            await initCallSession();
            await store.dispatch("VideoCall/listenToRoom", room_uuid);
        });

        //BEFOREUNMOUNT
        onBeforeUnmount(() => {
            destroyCallSession();
        });

        //METHODS
        const updateSettings = async (type, setting) => {
            // alert(JSON.stringify(videoCallSettings.value));
            const settingValue = isRef(setting) ? setting.value : setting;
            if (!_.isEmpty(videoCallSettings.value)) {
                const deviceSetting = videoCallSettings.value[type] || {};

                // Checking if the device is already selected
                if (
                    !_.isEmpty(deviceSetting) &&
                    deviceSetting.deviceId === settingValue.deviceId
                ) {
                    console.warn("ALREADY SET");
                    return;
                } else {
                    console.warn("NOT SET");
                }
            }

            console.warn(`SETTING UP NEW ${type} DEVICE`);
            // let success = 0;
            try {
                // CHANGING VIDEO TRACK

                if (!_.isEmpty(localTracks.videoTrack) && type === "video") {
                    await localTracks.videoTrack.setDevice(
                        settingValue.deviceId
                    );
                }

                // CHANGING AUDIO TRACK
                if (!_.isEmpty(localTracks.audioTrack) && type === "audio") {
                    await localTracks.audioTrack.setDevice(
                        settingValue.deviceId
                    );
                }

                videoCallSettings.value[type] = settingValue;
            } catch (error) {
                console.warn("_updateSettings =>", error);
            }
        };

        const settingsApplied = () => {
            settingsChange.value = new Date().getTime();
        };

        const removeVideoStream = (trackId) => {
            const previewPlayer = document.getElementById(
                `agora-video-player-${trackId}`
            );
            if (previewPlayer) {
                previewPlayer.remove();
            }
        };

        const handleMic = () => {
            if (!_.has(localTracks, "audioTrack")) {
                return;
            }

            try {
                // TOGGLING MICROPHONE
                localTracks.audioTrack.setEnabled(hasAudio.value);
            } catch (error) {
                console.warn("TOGGLING MICROPHONE ERROR =>", error);
            }
        };

        const handleCamera = () => {
            if (!_.has(localTracks, "videoTrack")) {
                return;
            }

            try {
                localTracks.videoTrack.setEnabled(hasCamera.value);
            } catch (error) {
                console.warn("TOGGLING CAMERA ERROR =>", error);
            }
        };

        const onShowSettings = () => {
            showSettings.value = true;
        };

        const onEndVideoCall = async () => {
            router.push({
                name: "video-call-room",
                query: router.currentRoute.value.query,
            });
        };

        const setupCall = () => {
            try {
                updateSettings("audio", savedAudioDevice, false);
                updateSettings("video", savedVideoDevice, false);

                handleMic();
                handleCamera();
                settingUp.value = false;
            } catch (error) {
                console.warn(error);
            }
        };

        const setUpVideoCallProperties = () => {
            if (_.isEmpty(localTracks)) {
                settingUp.value = true;
                setTimeout(() => {
                    setupCall();
                }, 5000);
            } else {
                setupCall();
            }
        };

        const getDevices = async () => {
            try {
                // FILTERING AUDIO AND VIDEO DEVICES
                const audioDevices = await AgoraRTC.getMicrophones();
                const cameraDevices = await AgoraRTC.getCameras();

                const selectedAudioDevice = _.find(audioDevices, {
                    label: localTracks.audioTrack.getTrackLabel(),
                });
                const selectedVideoDevice = _.find(cameraDevices, {
                    label: localTracks.videoTrack.getTrackLabel(),
                });

                // EMIT THE INITIAL DEVICES
                videoCallSettings.value = {
                    audio: selectedAudioDevice,
                    video: selectedVideoDevice,
                };

                if (!videoCallStarted.value) {
                    emit("devices", {
                        cameraDevices,
                        audioDevices,
                        selectedAudioDevice,
                        selectedVideoDevice,
                    });
                }
            } catch (error) {
                console.warn("Something wrong getting devices =>", error);
                emit("onAllowInteraction", false);
            }
        };

        // eslint-disable-next-line no-unused-vars
        const initCallSession = async () => {
            try {
                settingUp.value = true;

                client = AgoraRTC.createClient(
                    {
                        mode: "rtc",
                        codec: "h264",
                    },
                    (clientErr) => {
                        console.warn("CLIENT ERROR", clientErr);
                    }
                );

                console.warn("CLIENT CREATED", client);
                const AGORA_APP_ID = process.env.VUE_APP_AGORA_APP_ID;

                subscribeToStream();

                const localStream = await Promise.all([
                    client.join(AGORA_APP_ID, channelId.value, null, null),
                    AgoraRTC.createMicrophoneAudioTrack(),
                    AgoraRTC.createCameraVideoTrack(),
                ]);

                if (!localStream) {
                    console.warn("FAILED TO JOIN ON CHANNEL");
                    return;
                } else {
                    console.warn("SUCCESS TO JOIN ON CHANNEL");
                }

                localStreamId.value = localStream[0];
                localTracks.audioTrack = localStream[1];
                localTracks.videoTrack = localStream[2];

                console.warn("getUserMedia successfully, create LOCAL view");

                clearPlayerContainer("local__stream-view");

                localTracks.videoTrack.play("local__stream-view");
                console.warn("PLAYING local__stream VIEW");

                // PUBLIC LOCAL STREAM
                await client.publish(Object.values(localTracks));

                //alert(props.setup)
                if (props.setup) {
                    await getDevices();
                } else {
                    setUpVideoCallProperties();
                }

                store.commit("VideoCall/setVideoCallReady", true);

                settingUp.value = false;
            } catch (error) {
                if (error.code === "PERMISSION_DENIED") {
                    emit("permission-failed", true);
                }
                console.warn("Failed to init video__call");
            }
        };

        const clearPlayerContainer = (id) => {
            const playerContainer = document.getElementById(id);

            if (playerContainer && playerContainer.hasChildNodes()) {
                playerContainer.textContent = "";
            }
        };

        const subscribeToStream = () => {
            try {
                // const $ = this
                client.on("user-joined", async (user) => {
                    console.warn("user-joined =>", user.uid);

                    audiences.push(user.uid);

                    if (
                        localScreenSharing.value &&
                        user.uid === shareScreenUid
                    ) {
                        console.warn("LOCAL STREAM SHARING");
                        return;
                    }

                    if (
                        remoteConnected.value &&
                        user.uid !== remoteTracks.uid
                    ) {
                        setShareScreen = true;
                        shareScreenUid = user.uid;
                        return;
                    }

                    processRemoteUser(user);
                    remoteConnected.value = true;
                    remoteConnectedOnce.value = true;
                });

                // WHEN REMOTE USER PUBLISHED STREAM (AUDIO/VIDEO)
                client.on("user-published", async (user, mediaType) => {
                    console.warn("user-published => ", user, mediaType);

                    if (
                        localScreenSharing.value &&
                        user.uid === shareScreenUid
                    ) {
                        console.warn("LOCAL STREAM SHARING");
                        return;
                    }

                    await client.subscribe(user, mediaType);

                    console.warn(
                        `Subscribe to remote ${mediaType} stream`,
                        user.uid
                    );

                    if (setShareScreen && user.uid === shareScreenUid) {
                        screenTracks = user._videoTrack;
                        processRemoteScreenShare(true);

                        return;
                    }

                    processRemoteUser(user);

                    if (mediaType === "video") {
                        console.warn(
                            "addVideoStream view, create remote Stream view =>",
                            user.uid
                        );
                        const remoteVideoTrack = user._videoTrack;
                        remoteTracks.videoTrack = remoteVideoTrack;

                        if (!remoteVideoMute.value) {
                            const remoteVideoPlayerContainer =
                                viewScreenShare.value
                                    ? "remote__stream-thumbnail-view"
                                    : "remote__stream-view";
                            clearPlayerContainer(remoteVideoPlayerContainer);
                            remoteVideoTrack.play(remoteVideoPlayerContainer);
                        }
                    }

                    if (mediaType === "audio") {
                        // Get `RemoteAudioTrack` in the `user` object.
                        const remoteAudioTrack = user.audioTrack;
                        remoteTracks.audioTrack = remoteAudioTrack;

                        if (!remoteAudioMute.value) {
                            // Play the audio track. No need to pass any DOM element.
                            remoteAudioTrack.play();
                        }
                    }
                });

                // When a remote user unpublishes the stream or leaves the channel, stop the stream playback, and remove its view.
                client.on("user-unpublished", (user) => {
                    console.warn(
                        "a remote user unpublishes the stream or leaves the channel",
                        user.uid
                    );

                    _.remove(audiences, function (n) {
                        return n === user.uid;
                    });

                    if (shareScreenUid === user.uid) {
                        processRemoteScreenShare(false);
                    }

                    if (remoteTracks.uid === user.uid) {
                        processRemoteUser(user);
                    }
                });

                // Remove the corresponding view when a remote user leaves the channel.
                client.on("user-left", (user, reason) => {
                    console.warn("a remote user leaves the channel", user.uid);
                    console.warn("reason =>", reason);

                    if (reason === "Quit") {
                        if (shareScreenUid === user.uid) {
                            processRemoteScreenShare(false);
                        } else {
                            removeVideoStream(remoteTracks.videoTrack._ID);
                            remoteConnected.value = false;
                            if (shareScreenUid) {
                                stopSharing();
                            }
                        }
                        remoteLeftCount.value = ++remoteLeftCount.value;
                    }

                    if (reason === "ServerTimeOut") {
                        remoteConnected.value = false;
                    }
                });

                // CONNECTION LISTENER
                client.on("connection-state-change", function (evt) {
                    console.warn("connection-state-change", evt);
                    handleConnectionChange(evt);
                });
            } catch (error) {
                console.warn("_subscribeToStream", error);
            }
        };

        const processRemoteScreenShare = (showScreenShare) => {
            viewScreenShare.value = showScreenShare;
            clearPlayerContainer("remote__stream-view");
            clearPlayerContainer("remote__stream-thumbnail-view");

            if (viewScreenShare.value && !_.isEmpty(remoteTracks)) {
                removeVideoStream(remoteTracks.videoTrack._ID);
                if (!localScreenSharing.value) {
                    screenTracks.play("remote__stream-view");
                }
                remoteTracks.videoTrack.play("remote__stream-thumbnail-view");
            } else {
                removeVideoStream(screenTracks._ID);
                screenTracks.close();
                localScreenSharing.value = false;
                showMonitor.value = true;
                remoteTracks.videoTrack.play("remote__stream-view");
                setShareScreen = false;
                // Remove the screen sharing and move the thumbnail to main screen
            }
            // Removing the stream of remote, then move to thumbnail
            // removeVideoStream(remoteTracks.videoTrack._ID);
            //remote__stream-thumbnail-view
        };

        const processRemoteUser = (user) => {
            try {
                const { hasVideo, hasAudio, uid } = user;

                remoteVideoMute.value = !hasVideo;
                remoteAudioMute.value = !hasAudio;
                remoteTracks.uid = uid;

                if (
                    remoteVideoMute.value &&
                    _.has(remoteTracks, "videoTrack") &&
                    !setShareScreen
                ) {
                    removeVideoStream(remoteTracks.videoTrack._ID);
                }
            } catch (error) {
                console.warn("_processRemoteUser =>", error);
            }
        };

        const onScreenShare = async () => {
            await AgoraRTC.createScreenVideoTrack({
                video: false,
                screen: true,
                audio: true,
                screenAudio: true,
                mediaSource: [ "screen", "application", "window" ],
            }).then(async (localScreenTrack) => {
                screenClient = AgoraRTC.createClient({
                    mode: "rtc",
                    codec: "vp8",
                });

                localScreenSharing.value = true;
                showMonitor.value = false;
                screenTracks = localScreenTrack;
                const AGORA_APP_ID = process.env.VUE_APP_AGORA_APP_ID;

                shareScreenUid = await screenClient.join(
                    AGORA_APP_ID,
                    channelId.value,
                    null,
                    null
                );

                store.dispatch("VideoCall/onUpdateRoom", {
                    room_uuid,
                    screenShareStreamId: shareScreenUid,
                });

                localScreenTrack.on("track-ended", async () => {
                    console.warn("track-ended");
                    console.warn("you can run your code here to stop screen");
                    stopSharing();
                });

                await screenClient.publish(localScreenTrack);

                processRemoteScreenShare(true);
                return localScreenTrack;
            });
        };

        const stopSharing = async () => {
            store.dispatch("VideoCall/onUpdateRoom", {
                room_uuid,
                screenShareStreamId: null,
            });

            processRemoteScreenShare(false);
        };

        const onEndCallPoints = (event) => {
            showEndCall.value = event;
        };

        const onAlertTimeColor = (event) => {
            alertTimeMessageColor.value = event;
        };

        const getTimeCount = (event) => {
            timeCount.value = event;
        };

        const disableSharing = async (event) => {
            remoteDisableShare.value = null;
            setTimeout(() => {
                remoteDisableShare.value = event;
            }, 500);
        };

        const destroyCallSession = async () => {
            try {
                for (const trackName in localTracks) {
                    const track = localTracks[trackName];
                    if (!_.isEmpty(track)) {
                        track.stop();
                        track.close();
                        localTracks[trackName] = undefined;
                    }
                }

                if (!_.isEmpty(screenTracks)) {
                    screenTracks.close();
                }

                localScreenSharing.value = false;
                viewScreenShare.value = false;
                showMonitor.value = false;
                screenTracks = {};

                await client.leave();

                console.warn("Client succeed to leave.");
                handleDestroyVideoCall(true);
            } catch (error) {
                console.warn(error);
            }
        };

        const handleDestroyVideoCall = (response) => {
            if (response) {
                settingUp.value = false;
                reconnecting.value = false;
                store.commit("VideoCall/setVideoCallReady", false);
            }
            emit("videocallDestroyed", response);
        };

        const handleConnectionChange = (evt) => {
            if (evt === "CONNECTED") {
                reconnecting.value = false;
                disconnected.value = false;
                connected.value = true;
                console.warn("LOCAL CONNECTED");
            }

            const reconnectingx = _.includes(
                [ "CONNECTING", "DISCONNECTED", "RECONNECTING" ],
                evt
            );

            if (reconnectingx) {
                reconnecting.value = true;
                connected.value = false;
                console.warn("LOCAL IS RECONNECTING");
                setTimeout(() => {
                    if (reconnecting.value && !props.setup) {
                        disconnected.value = true;
                        destroyCallSession();
                        router.push({
                            name: "video-call-ended",
                            query: {
                                ...router.currentRoute.value.query,
                                status: "disconnected",
                            },
                        });
                        console.warn("LOCAL DISCONNECTED");
                    }
                }, 30000);
            }
        };

        return {
            settingsChange,
            timeRemaining,
            channelId,
            showSettings,
            client,
            connected,
            disconnected,
            reconnecting,
            remoteVideoMute,
            remoteAudioMute,
            remoteConnected,
            remoteConnectedOnce,
            onShowSettings,
            onEndVideoCall,
            onScreenShare,
            onEndCallPoints,
            settingsApplied,
            showEndCall,
            typeExitPage,
            onAlertTimeColor,
            timeCount,
            getTimeCount,
            alertTimeMessageColor,
            viewScreenShare,
            showMonitor,
            screenClient,
            stopSharing,
            countdown,
            disableSharing,
            remoteDisableShare,
            flipCamera,
            remoteLeftCount,
            clientRef,
        };
    },
};
</script>

<style lang="scss">
.video__call__screen {
    @apply bg-gray-50 relative;
    min-height: 50vmin;

    .local__state_alert {
        .microphone_type {
            @apply overflow-hidden overflow-ellipsis whitespace-pre;
        }
    }
    .ellipsis_container {
        .microphone_type {
            @apply overflow-hidden overflow-ellipsis whitespace-pre;
        }
    }
}
.full__screen {
    height: 100vh;
}
.button-container {
    button {
        @apply mx-2;
    }
}
</style>
