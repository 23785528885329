<template>
    <div id="total-height" ref="totalHeight" class="doctor-profile-card-component">
        <slideover-header-component
            title="Doctor Profile"
            :show-close-btn="showCloseBtn"
            @on-click-close-btn="$emit('on-click-close-btn')"
        />

        <profile-info-card-component
            :profile="doctor"
            :inline="false"
            img-class="h-16 w-16"
            :have-hover-effect="haveHoverEffect"
            :is-card="isCard"
        />

        <div class="appointment-settings-container">
            <template v-if="doctor.clinic_name">
                <appointment-settings-card-component
                    settings="clinic"
                    :appointment="doctor"
                />
                <appointment-settings-card-component
                    v-if="doctor.location"
                    settings="city"
                    :appointment="doctor"
                />
            </template>

            <button-component
                v-if="showAppointmentBtn && $userType === AccountType.Medrep"
                label="Request an appointment"
                :disabled="hasNoBalance"
                @click="$router.push({ path: '/medrep/doctors/appointment', query: { id: doctor.id } })"
            />
            <p v-if="hasNoBalance" class="hasNoBalance">You need at least <span class="underline text-gray-700">1 credit</span> to request an appointment</p>
        </div>

        <navigation-tabs-component
            v-model="tabs"
            :is-fullwidth="true"
            @update:modelValue="setTablist(tabs)"
            @click-tab="$emit('click-tab', $event)"
        />
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { SlideoverHeaderComponent } from "@CommonComponents/slideovers";
import { useStore } from "vuex";
import {
    ProfileInfoCardComponent,
    AppointmentSettingsCardComponent,
    NavigationTabsComponent,
} from '@CommonComponents'
import { AccountType } from "@Utilities/enum";

export default {
    name: "DoctorProfileCardComponent",
    components: {
        SlideoverHeaderComponent,
        ProfileInfoCardComponent,
        AppointmentSettingsCardComponent,
        NavigationTabsComponent,
    },
    props: {
        tablists: { type: Array, default: () => {} },
        title: { type: String, default: "" },
        doctor: { type: Object, default: () => {} },
        isCard: { type: Boolean, default: true },
        haveHoverEffect: { type: Boolean, default: true },
        showAppointmentBtn: { type: Boolean, default: true },
        showCloseBtn: { type: Boolean, default: true },
    },

    emits: [ "get-height", "update:modelValue", "on-click-close-btn", "click-tab" ],

    setup(props, { emit }) {
        const store = useStore();
        const totalHeight = ref(0);
        const tabs = ref(props.tablists);
        onMounted(() => {
            emit("get-height", totalHeight.value);
        });

        const setTablist = (event) => {
            emit("update:modelValue", event);
        };

        // CREDIT BALANCE
        const hasNoBalance = computed(
            () => store.getters["CommonCredit/hasNoBalance"]
        );

        return {
            totalHeight,
            setTablist,
            tabs,
            hasNoBalance,
            AccountType,
        };
    },
};
</script>

<style lang="scss">
.doctor-profile-card-component {
    @apply px-4 space-y-3;
    .profile-info-card-component {
        @apply mt-0 #{!important};
        & > div {
            @apply shadow-none #{!important};
        }
        .profile-card {
            @apply p-0 border-0 #{!important};
            &:hover {
                @apply bg-white shadow-none #{!important};
            }
        }
    }
    .appointment-settings-container {
        @apply space-y-3;
        .appointment-settings-card-component {
            .appointment-settings {
                &:hover {
                    @apply shadow-none;
                }
            }
        }
    }
    .navigation-tabs-component {
        @apply -mx-4;
    }
    .hasNoBalance {
        @apply text-sm leading-5 font-normal text-gray-500;
    }
}
@screen sm {
    .doctor-profile-card-component {
        @apply px-6;
        .navigation-tabs-component {
            @apply -mx-6;
        }
    }
}
</style>