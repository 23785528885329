<template>
    <div class="appointment-body-card-component">
        <template v-if="showMedrepName">
            <name-card-component :name="doctor.medrep_name" :is-appointment-card="true" :is-loading="isLoading" />
        </template>
        <template v-if="showName">
            <name-card-component :name="doctor.doctor_name" :is-appointment-card="true" :is-loading="isLoading" />
        </template>
        <template v-if="showBrand">
            <brand-card-component
                v-bind="brandCardProps"
            />
        </template>
        <template v-if="showCreditToUse">
            <credit-to-use-card-component
                v-bind="creditToUseCardProps"
            />
        </template>

        <!-- NOTE TO DOCTOR -->
        <template v-if="showNote">
            <note-to-doctor-component :data="doctor" :is-appointment-card="true" :is-loading="isLoading" />
        </template>
        <template v-if="showInvited">
            <invited-card-component :data="doctor.invited" :is-appointment-card="true" :is-loading="isLoading" />
        </template>
        <template v-if="showRemarks && (doctor.status === 'served' || isLoading)">
            <remarks-card-component
                v-bind="remarksCardProps"
            />
        </template>
    </div>
</template>

<script>
// import {
//     NoteToDoctorComponent,
// } from "@CommonComponents";
import NoteToDoctorComponent from './note-to-doctor-component.vue';
import InvitedCardComponent from './card-body/invited-card-component';
import BrandCardComponent from './card-body/brand-card-component';
import NameCardComponent from './card-body/name-card-component';
import RemarksCardComponent from './card-body/remarks-card-component';
import CreditToUseCardComponent from './card-body/credit-to-use-card-component'
import { computed } from "vue";

export default {
    name: "AppointmentBodyCardComponent",
    components: {
        NoteToDoctorComponent,
        InvitedCardComponent,
        BrandCardComponent,
        NameCardComponent,
        RemarksCardComponent,
        CreditToUseCardComponent
    },
    props: {
        showNote: { type: Boolean, default: true },
        showInvited: { type: Boolean, default: true },
        showBrand: { type: Boolean, default: true },
        showName: { type: Boolean, default: true },
        showMedrepName: { type: Boolean, default: true },
        showRemarks: { type: Boolean, default: true },
        showRemarksFields: Boolean,
        showCreditToUse: { type: Boolean, default: true },
        width: { type: String, default: 'unset' },
        doctor: { type: [ Object, Number ], default: null },
        isLoading: Boolean,
        previewAppointment: Boolean,
        showStatus: Boolean,
    },
    setup(props) {
        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const creditToUseCardProps = computed(() => {
            return {
                item: props.doctor,
                isAppointmentCard: true,
                previewAppointment: props.previewAppointment,
                showStatus: props.showStatus,
                isLoading: props.isLoading
            }
        })

        const remarksCardProps = computed(() => {
            return {
                data: props.doctor,
                showRemarksFields: props.showRemarksFields,
                isAppointmentCard: true,
                isLoading: props.isLoading
            }
        })

        const brandCardProps = computed(() => {
            return {
                data: props.doctor.brands,
                isAppointmentCard: true,
                previewAppointment: props.previewAppointment,
                isLoading: props.isLoading
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        return {
            creditToUseCardProps,
            remarksCardProps,
            brandCardProps
        }
    }
};
</script>

<style lang="scss">
.appointment-body-card-component {
    @apply bg-white py-2 px-3 rounded-md;
}
</style>