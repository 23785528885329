import { createStore } from 'vuex'
import { Tokens } from '@Utilities/enum'
import { STORAGE } from '@Plugins/storage'
// import _ from "lodash";

// MEDREP STORE
import {
    MedrepPreferences,
} from '@MedrepStore';

// ADMIN STORE
import {
    AdminTeam,
} from '@AdminStore';

// COMMON STORE
import {
    CommonDoctor,
    CommonAppointment,
    CommonUtilities,
    CommonCredit,
    CommonBrand,
    CommonPharmaUser,
} from '@CommonStore';

// VIDEO CALL STORE
import {
    VideoCall,
    VideoCallAppointment
} from '@VideoCallStore';

export default createStore({
    state: {
        personal_token: {},
        client_token: {},
        account: {},
        cancelSources: {},
        routeParams: {},
        isLoading: false,
        email: '',
    },
    getters: {
        personal_token: state => {
            return state.personal_token
        },
        client_token: state => {
            return state.client_token
        },
        account: state => {
            return state.account
        },
        isLoading: state => {
            return state.isLoading
        },
        email: state => {
            return state.email
        }
    },
    actions: {
        async clientTokenRequest( { commit } ) {
            const params = {
                grant_type: 'client_credentials',
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                scope: '*',
                authorization: Tokens.Client,
            }
            const response = await api('POST', 'oauth/token', params)
            commit('setClientToken', response.data)
        },

        async onVerifyEmail( { commit }, payload ) {
            commit('setIsLoading', true)
            try {
                const url = 'pharma/api/auth/send/otp'
                const response = await api('post', url, payload, Tokens.Client)

                if (response && response.data.success) {
                    commit('setEmail', payload.email)
                }

                commit('setIsLoading', false)
                return response.data
            } catch (error) {
                console.warn(error)
            }
            commit('setIsLoading', false)
        },

        async onVerifyOtp( { commit }, payload ) {
            commit('setIsLoading', true)
            try {
                const url = '/pharma/api/auth/login'
                const response = await api('post', url, payload, Tokens.Client)

                if (response.data.success) {
                    commit("setPersonalToken", response.data.user)
                }

                commit('setIsLoading', false)
                return response.data
            } catch (error) {
                console.warn(error)
            }
            commit('setIsLoading', false)
        },

        async onLogout( { commit } ) {
            commit('setIsLoading', true)
            await STORAGE.REMOVE_COOKIES(Tokens.Personal)
            await STORAGE.REMOVE_COOKIES(Tokens.UserType)
            window.location.href = "/"
            commit('setIsLoading', false)
        },

    },
    mutations: {
        setClientToken(state, payload) {
            var now = new Date()
            payload.expiration_date = new Date(now.getTime() + (payload.expires_in * 1000))
            state.client_token = payload
            STORAGE.SET_COOKIES(Tokens.Client, JSON.stringify(payload))
            if (!_.isEmpty(localStorage.client_token)) {
                STORAGE.REMOVE_COOKIES(Tokens.Client)
            }
        },
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
        setPersonalToken(state, user) {
            STORAGE.REMOVE_COOKIES(Tokens.Personal)
            user.expiration_date = user.expires_at;
            // SET_COOKIES(Tokens.Personal, user, { expires: new Date(user.expiration_date), sameSite: true, })
            STORAGE.SET_COOKIES(Tokens.Personal, JSON.stringify(user));
            STORAGE.SET_COOKIES(Tokens.UserType, JSON.stringify(user.user_info.user_type));

            state.personal_token = user;
        },
        setEmail(state, payload) {
            state.email = payload
        }

    },
    modules: {
        MedrepPreferences,
        CommonDoctor,
        CommonAppointment,
        CommonUtilities,
        CommonCredit,
        CommonBrand,
        CommonPharmaUser,
        VideoCall,
        VideoCallAppointment,
        AdminTeam,
    }
})