<template>
    <div class="faqs-card-component">
        <div class="faqs-card-container">
            <div class="faqs-card-content">
                <image-card-component
                    :img-src="
                        require('@Images/dashboard-images/faqs-card.png')
                    "
                    :have-shadow="false"
                />
                <div class="faqs-card-header-container">
                    <div class="faqs-card-header-content">
                        <p class="header-title">Got questions?</p>
                        <p class="header-subtitle">Read our frequently asked questions page about PPD Clinic Pharma</p>
                    </div>
                    <div>
                        <button-component
                            label="Read FAQs"
                            custom-class="bg-indigo-700"
                            @on-click="onClickReadFaqs"
                        />
                    </div>
                </div>
            </div>
        </div>
        <faq-component v-if="showFaqs" :is-dashboard-faqs="true" @on-click-back="onClickReadFaqs" />
    </div>
</template>

<script>
import ImageCardComponent from "./admin-image-card-component.vue";
import { FaqComponent } from '@CommonComponents';
import { ref } from "vue";

export default {
    name: 'FaqsCardComponent',
    components: {
        ImageCardComponent,
        FaqComponent
    },
    setup() {
        const showFaqs = ref(false)

        const onClickReadFaqs = () => {
            showFaqs.value = !showFaqs.value
        }

        return {
            showFaqs,
            onClickReadFaqs
        }
    }
}
</script>

<style lang="scss">
.faqs-card-component {
    .faqs-card-container {
        .faqs-card-content {
            @apply relative;
            .faqs-card-header-container {
                @apply absolute top-0 mt-6 ml-6 space-y-3 w-40;
                .faqs-card-header-content {
                    @apply space-y-2;
                    .header-title {
                        @apply text-xl font-medium text-indigo-700;
                    }
                    .header-subtitle {
                        @apply text-sm font-normal leading-5 text-gray-500;
                    }
                }
            }
        }
    }
}
</style>