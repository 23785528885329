import {
    CalendarIcon as CalendarIconOutline,
    ChartBarIcon as ChartBarIconOutline,
    HomeIcon as HomeIconOutline,
    InboxIcon as InboxIconOutline,
    UsersIcon as UsersIconOutline,
    UserGroupIcon as UserGroupIconOutline,
    DatabaseIcon as DatabaseIconOutline,
    ClockIcon as ClockIconOutline
} from '@heroicons/vue/outline'
import {
    HomeIcon,
    UsersIcon,
    CalendarIcon,
    InboxIcon,
    ChartBarIcon,
    UserGroupIcon,
    DatabaseIcon,
    ClockIcon
} from '@heroicons/vue/solid'

let environment = `${ process.env.VUE_APP_ENVIRONMENT }_`

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
    environment = ''
}

export const Tokens = {
    Personal: `${ environment }medrep_personal_token`,
    Client: `${ environment }medrep_client_token`,
    UserType: `${ environment }medrep_user_type`,
    UserDetails: `${ environment }medrep_user_details`
}

export const Modules = {
    VideoCall: 'video_call',
    Pharma: 'pharma'
}

export const AccountType = {
    Doctor: "doctor",
    Medrep: "medrep",
    Admin: "district_manager",
}

export const UserType = {
    Doctor: "Doctor",
    Medrep: "MedRep"
}

export const ClinicType = {
    Physical: 'physical',
    Online: 'online'
}

export const Environment = {
    Production: 'production',
    Stage: 'stage',
    Develop: 'develop'
}

export const Roles = {
    MedRep: 'medrep',
    DistrictManager: 'district_manager',
    Doctor: 'doctor'
}

export const MedrepNavItems = [
    { name: 'Home', type: 'dashboard',  icon: HomeIcon, iconOutLine: HomeIconOutline, current: true },
    { name: 'Doctors', type: 'doctors', icon: UsersIcon, iconOutLine: UsersIconOutline, current: false },
    { name: 'Schedule', type: 'schedule', icon: CalendarIcon, iconOutLine: CalendarIconOutline, current: false },
    { name: 'Inbox', type: 'inbox', icon: InboxIcon, iconOutLine: InboxIconOutline, current: false, disable: true },
    { name: 'Reports', type: 'reports', icon: ChartBarIcon, iconOutLine: ChartBarIconOutline, current: false, disable: true },
]

export const AdminNavItems = [
    { name: 'Home', type: 'dashboard',  icon: HomeIcon, iconOutLine: HomeIconOutline, current: true },
    { name: 'Team', type: 'team', icon: UserGroupIcon, iconOutLine: UserGroupIconOutline, current: false },
    { name: 'Credits', type: 'credits', icon: DatabaseIcon, iconOutLine: DatabaseIconOutline, current: false },
    { name: 'Activity', type: 'activity', icon: ClockIcon, iconOutLine: ClockIconOutline, current: false, disable: true },
    { name: 'Inbox', type: 'inbox', icon: InboxIcon, iconOutLine: InboxIconOutline, current: false, disable: true },
    { name: 'Reports', type: 'reports', icon: ChartBarIcon, iconOutLine: ChartBarIconOutline, current: false, disable: true },
]

export const Status = {
    Approved: 'approved',
    Served: 'served',
    Pending: 'pending',
    Declined: 'declined',
    Requested: 'requested',
    Signed: 'signed',
    Reschedule: 'reschedule',
    Scheduled: 'scheduled',
    Accepted: 'accepted',
    Done: 'done',
    Paid: 'paid',
    Inactive: 'inactive',
    Active: 'active',
    Expired: 'expired',
    Ongoing: 'ongoing'
}

export const ModalConfiguration = {
    scrollable: true,
    overlayOpacity: "95",
    overlayColor: "#E5E5E5"
}

export const UserAction = [
    { name: 'Profile Information', type: 'profile', href: '#' },
    { name: 'Walkthrough Guide', type: 'guide', href: '#' },
    { name: 'Terms and conditions', type: 'terms-and-conditions', href: '#' },
    { name: 'Privacy Policy', type: 'privacy-policy', href: '#' },
    { name: 'Sign Out', type: 'signout', href: '#' },
]