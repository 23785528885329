
<template>
    <div v-if="modelValue" :class="['video__alert p-4 lg:p-8']">
        <div v-if="!_.isEmpty(alertProps)" class="alert__box">
            <div
                :class="[
                    'alert__container',
                    $device.isMobile ? alertMobilePosition : alertPosition,
                    alertCustomClass,
                ]"
            >
                <div class="alert__content">
                    <component
                        :is="alertProps.icon"
                        class="h-6 w-6 mr-2"
                        :class="alertProps.iconColor"
                    />

                    <div class="alert__text">
                        <!-- time remaining alert -->
                        <p
                            v-if="state === 'ls_time'"
                            class="text-sm"
                            :class="alertColor"
                        >
                            {{ message_alert }} <br />
                            <span class="text-sm leading-5 font-normal text-gray-500">
                                {{ sub_message_alert }}
                            </span>
                        </p>
                        <!-- call status alerts -->
                        <p v-else class="message text-sm">
                            {{ alertProps.message }}
                        </p>
                        <p
                            v-if="!_.isEmpty(alertProps.subMessage)"
                            class="sub__message"
                        >
                            {{ alertProps.subMessage }}
                        </p>
                    </div>
                </div>

                <div
                    class="icon icon__sm rounded cursor-pointer"
                    @click="$emit('update:modelValue', false)"
                >
                    <XIcon aria-hidden="true" class="text-gray-400" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import {
    XIcon,
    CheckCircleIcon,
    ExclamationIcon,
    InformationCircleIcon,
    ClockIcon,
    DesktopComputerIcon,
} from "@heroicons/vue/outline";
import { useVideoCallDetails } from "../functions";
export default {
    name: "VideoAlert",
    components: {
        XIcon,
        CheckCircleIcon,
        ExclamationIcon,
        InformationCircleIcon,
        ClockIcon,
        DesktopComputerIcon,
    },
    props: {
        modelValue: Boolean,
        state: { type: String, default: null },
        position: { type: String, default: "center_left" },
        alertCustomClass: { type: String, default: "" },
        timeCount: { type: [ String, Number ], default: "" },
        alertColor: { type: String, default: "" },
    },
    emits: [ "update:modelValue" ],

    setup(props, { emit }) {
        const message_alert = ref("");
        const sub_message_alert = ref("");

        const { remoteStreamDetails } = useVideoCallDetails();

        const alertProps = computed(() => {
            let alert = {};
            switch (props.state) {
            case "ls_disconnected":
                alert = {
                    icon: ExclamationIcon,
                    iconColor: "text-yellow-400",
                    message: "You’ve got disconnected",
                    subMessage: "Connecting you back...",
                };
                break;
            case "ls_connected":
                alert = {
                    icon: CheckCircleIcon,
                    iconColor: "text-green-400",
                    message: "You’re connected",
                    subMessage: "",
                };
                break;
            case "ls_update_settings":
                alert = {
                    icon: CheckCircleIcon,
                    iconColor: "text-green-400",
                    message: "Device settings saved",
                    subMessage: "",
                };
                break;
            case "ls_time":
                alert = {
                    icon: ClockIcon,
                    iconColor: props.alertColor,
                    message: "remaining",
                    subMessage: "",
                };
                break;
            case "rs_connected":
                alert = {
                    icon: CheckCircleIcon,
                    iconColor: "text-green-400",
                    message: `Your ${remoteStreamDetails.value.user_type} entered the call`,
                    subMessage: "",
                };
                break;
            case "rs_disconnected":
                alert = {
                    icon: ExclamationIcon,
                    iconColor: "text-yellow-400",
                    message: `Your ${remoteStreamDetails.value.user_type} has been disconnected`,
                    // subMessage: `${remoteStreamDetails.value.user_type} is not yet joining the call`,
                    subMessage: `Waiting for the ${remoteStreamDetails.value.user_type} to rejoin...`,
                };
                break;
            case "rs_left":
                alert = {
                    icon: InformationCircleIcon,
                    iconColor: "text-gray-400",
                    message: `Your ${remoteStreamDetails.value.user_type} has left the call`,
                    subMessage: "",
                };
                break;
            case "ls_share_invalid_user":
                alert = {
                    icon: DesktopComputerIcon,
                    iconColor: "text-green-400",
                    message: "Screenshare disabled",
                    subMessage:
                            "Only MedReps are allowed to share a screen as of the moment",
                };
                break;
            case "ls_share_invalid_device":
                alert = {
                    icon: DesktopComputerIcon,
                    iconColor: "text-green-400",
                    message: "Screenshare disabled",
                    subMessage:
                            "You can only use this feature in desktop/laptop devices",
                };
                break;
            }

            return alert;
        });
        const alertPosition = computed(() => {
            let positionClass = "";
            switch (props.position) {
            case "top_left":
                positionClass = "";
                break;
            case "top_mid":
                positionClass = "alert_horizontal_center";
                break;
            case "top_right":
                positionClass = "right-0 z-10";
                break;
            case "center_left":
                positionClass = "alert_vertical_center";
                break;
            case "center_mid":
                positionClass = "alert__mid left-1/2 top-1/2";
                break;
            case "center_right":
                positionClass = "alert_vertical_center right-0";
                break;
            case "bot_left":
                positionClass = "bottom-0";
                break;
            case "bot_mid":
                positionClass = "alert__mid left-1/2 bottom-2";
                break;
            case "bot_right":
                positionClass = "bottom-0 right-0";
                break;
            default:
                positionClass = "bottom-0";
            }

            return positionClass;
        });

        const alertMobilePosition = computed(() => {
            let positionMobileClass = "";
            switch (props.position) {
            case "top_left":
                positionMobileClass = "alert_horizontal_center";
                break;
            case "top_mid":
                positionMobileClass = "alert_horizontal_center";
                break;
            case "top_right":
                positionMobileClass = "alert_horizontal_center";
                break;
            case "center_left":
                positionMobileClass = "alert__mid left-1/2 top-1/2";
                break;
            case "center_mid":
                positionMobileClass = "alert__mid left-1/2 top-1/2";
                break;
            case "center_right":
                positionMobileClass = "alert__mid left-1/2 top-1/2";
                break;
            case "bot_left":
                positionMobileClass = "alert__mid left-1/2 alert_bottom_mobile";
                break;
            case "bot_mid":
                positionMobileClass = "alert__mid left-1/2 alert_bottom_mobile";
                break;
            case "bot_right":
                positionMobileClass = "alert__mid left-1/2 alert_bottom_mobile";
                break;
            default:
                positionMobileClass = "bottom-0";
            }
            return positionMobileClass;
        });

        watch(
            () => props.modelValue,
            () => {
                dismissableAlert();
            }
        );

        watch(
            () => props.timeCount,
            (newValue) => {
                if (newValue === 60) {
                    message_alert.value = "1 minute remaining";
                } else if (newValue === 30) {
                    message_alert.value = "30 seconds remaining";
                } else if (newValue === 10) {
                    message_alert.value = "10 seconds remaining";
                    sub_message_alert.value = 'Your time is almost out!'
                }
            }
        );

        //ONMOUNTED
        onMounted(() => {
            dismissableAlert();
        });

        //METHOD
        const dismissableAlert = () => {
            if (!_.isEmpty(alertProps.value) && props.modelValue) {
                setTimer();
            }
        };

        const setTimer = () => {
            setTimeout(() => {
                emit("update:modelValue", false);
            }, 5000);
        };

        return {
            alertProps,
            alertPosition,
            message_alert,
            alertMobilePosition,
            sub_message_alert
        };
    },
};
</script>

<style lang="scss">
.video__alert {
    @apply absolute h-full w-full;
    .alert__box {
        @apply relative w-full h-full;
    }
    .alert__container {
        @apply flex items-start justify-between p-4 absolute bg-white rounded shadow-lg z-10 bottom-0;
        min-width: 360px;
    }
    .alert__content {
        @apply flex items-center;
    }
    .alert__mid {
        transform: translate(-50%, 0%);
    }
    .alert_vertical_center {
        transform: translate(0%, -50%);
        @apply top-1/2;
    }
    .alert_horizontal_center {
        transform: translate(-50%, 0%);
        @apply left-1/2;
    }

    .alert_bottom_mobile {
        bottom: 2.5rem !important;
    }
}
</style>