<template>
    <svg
        class="pharma__icon"
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.35096 5.9532H5.12019C5.52822 5.9532 5.91953 5.79111 6.20805 5.5026C6.49657 5.21408 6.65865 4.82276 6.65865 4.41474C6.65865 4.21073 6.7397 4.01507 6.88396 3.87081C7.02821 3.72655 7.22387 3.64551 7.42788 3.64551H12.0433C12.2473 3.64551 12.4429 3.72655 12.5872 3.87081C12.7315 4.01507 12.8125 4.21073 12.8125 4.41474C12.8125 4.82276 12.9746 5.21408 13.2631 5.5026C13.5516 5.79111 13.9429 5.9532 14.351 5.9532H15.1202C15.5282 5.9532 15.9195 6.11529 16.208 6.4038C16.4966 6.69232 16.6587 7.08364 16.6587 7.49166V14.4147C16.6587 14.8228 16.4966 15.2141 16.208 15.5026C15.9195 15.7911 15.5282 15.9532 15.1202 15.9532H4.35096C3.94294 15.9532 3.55162 15.7911 3.2631 15.5026C2.97459 15.2141 2.8125 14.8228 2.8125 14.4147V7.49166C2.8125 7.08364 2.97459 6.69232 3.2631 6.4038C3.55162 6.11529 3.94294 5.9532 4.35096 5.9532"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M9.15466 12.8025C9.49588 12.8912 9.85289 12.9006 10.1983 12.8299C10.5437 12.7591 10.8683 12.6102 11.1472 12.3945C11.4261 12.1789 11.6519 11.9021 11.8072 11.5856C11.9625 11.2691 12.0432 10.9212 12.0431 10.5686M10.3123 8.33402C9.97132 8.24597 9.61466 8.23714 9.2697 8.30823C8.92474 8.37931 8.60062 8.52841 8.32218 8.74411C8.04374 8.9598 7.81837 9.23637 7.66333 9.55263C7.50829 9.86888 7.4277 10.2164 7.42773 10.5686"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.2734 10.5693H12.8119V12.1078"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.19666 10.5687H6.6582V9.03027"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    props: {
        height: { type: String, default: "20" },
        width: { type: String, default: "20" },
    },
};
</script>