<template>
    <button
        :type="buttonType"
        :class="[buttonTheme, full, prependIcon, appendIcon, disable, customClass]"
        :disabled="disabled"
        @click="onClick"
    >
        <spinner-component
            v-if="loading"
            :color="color"
            class="mx-auto inline-block"
            :size="spinnerSize"
        />
        <template v-else>
            <slot v-if="$slots.prepend" name="prepend" />
            {{ buttonLabel }}
            <slot v-if="$slots.append" name="append" />
            <slot />
        </template>
    </button>
</template>

<script>
import { computed, ref } from "vue";
export default {
    name: "ButtonComponent",
    props: {
        buttonType: { type: String, default: "button" },
        type: { type: String, default: "primary-btn" },
        size: { type: String, default: "" }, // " ", xs, s, l, xl
        label: { type: String, default: "" },
        customClass: { type: String, default: "" },
        disabled: Boolean,
        block: Boolean,
        loading: Boolean,
    },
    emits: [ "on-click" ],
    setup(props, { slots, emit  }) {
        // THEME ACCORDING TO SIZE
        const buttonTheme = computed(() => {
            return _.isEmpty(props.size)
                ? props.type
                : `${props.type}__${props.size}`;
        });

        // SPINNER COLOR
        const color = ref(
            _.includes(props.type, "primary") ? "white" : "gray-500"
        );

        // SPINNER SIZE
        const spinnerSize = computed(() => {
            let size
            switch (props.size) {
            case "xs":
            case "s":
                size = 16
                break;
            case "":
                size = 20
                break;
            default:
                size = 24
                break;
            }
            return size
        })

        // BUTTON LABELS
        const isIcon = ref(
            props.type === "primary-icon-btn" || props.type === "white-icon-btn"
        );
        const buttonLabel = computed(() => {
            let item = "";
            if (isIcon.value) {
                item = "";
            } else {
                item = props.label;
            }
            return item;
        });

        // ICON
        const prependIcon = computed(() => {
            let item = "";
            if (isIcon.value) {
                item = "";
            } else if (slots.prepend) {
                item = "prepend-icon";
            }
            return item;
        });
        const appendIcon = computed(() => {
            let item = "";
            if (isIcon.value) {
                item = "";
            } else if (slots.append) {
                item = "append-icon";
            }
            return item;
        });

        // DISABLE
        const disable = computed(() => {
            return props.disabled ? "disabled" : "";
        });

        // FULL WIDTH
        const full = computed(() => {
            let item = "";
            if (isIcon.value) {
                item = "";
            } else if (props.block) {
                item = "full";
            }
            return item;
        });

        const onClick = () => {
            if (props.disabled) return
            emit('on-click')
        };

        return {
            buttonTheme,
            buttonLabel,
            prependIcon,
            appendIcon,
            disable,
            full,
            color,
            spinnerSize,
            onClick
        };
    },
};
</script>

<style>
</style>