<template>
    <div class="remarks-card-component">
        <div
            :class="[
                isAppointmentCard
                    ? 'card-no-shadow'
                    : 'card-with-shadow default',
            ]"
        >
            <OutlinePencilIcon class="card-icon" aria-hidden="true" />
            <div v-if="!isLoading" class="remarks-container">
                <p v-if="!hasRemarks" class="header-title">
                    {{ showForm ? 'Add Remarks' : "No remarks added" }}
                </p>
                <p v-else class="header-title">Remarks</p>

                <!-- CONTENT -->
                <div class="remarks-content">
                    <div v-if="showForm" class="pr-1">
                        <div class="text-fields">
                            <input-field-component
                                v-model="form.remark"
                                v-bind="remarksInputFieldProps"
                            />

                            <!-- refactor: hide reason field for the mean time -->
                            <!-- <input-field-component
                                v-model="form.sample_given"
                                v-bind="reasonInputFieldProps"
                            /> -->
                        </div>
                        <div class="action">
                            <button-component
                                v-bind="buttonProps"
                                @click="submitRemarks"
                            />
                        </div>
                    </div>
                    <p v-else-if="hasRemarks">
                        {{ data.remarks.content.remark }}
                    </p>
                </div>
            </div>
            <div v-else class="remarks-container load_card_animate w-full">
                <p class="header-title h-4 bg-primary-100 rounded w-1/3 mt-1" />
            </div>
        </div>
    </div>
</template>

<script>
import { PencilIcon as OutlinePencilIcon } from "@heroicons/vue/outline";
import { InputFieldComponent } from "@CommonComponents";
import { computed, reactive, ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { STORAGE } from "@Plugins/storage.js";
import { Tokens, AccountType } from "@Utilities/enum";

export default {
    name: "RemarksCardComponent",
    components: {
        OutlinePencilIcon,
        InputFieldComponent,
    },

    props: {
        data: { type: [ Object, Number ], default: null },
        showRemarksFields: Boolean,
        isAppointmentCard: Boolean,
        isLoading: Boolean,
    },

    setup(props) {
        // refactor: add 24hrs deadline for remarks submission
        const store = useStore();
        const router = useRouter();
        const { currentRoute } = router;

        const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));
        const hasRemarks = computed(() => {
            return !_.isEmpty(props.data.remarks) && _.has(props.data.remarks.content, 'remark');
        });
        const showForm = computed(() => {
            return props.showRemarksFields && userType.value === AccountType.Medrep;
        });

        //------------------COMPUTED-------------------

        const buttonProps = computed(() => {
            return {
                type: "primary-btn",
                size: "s",
                label: "Save",
                loading: isRemarksLoading.value,
                class: "remarks-save-btn"
            }
        })

        const reasonInputFieldProps = computed(() => {
            return {
                type: "text",
                label: "",
                placeholder: "Type reason"
            }
        })

        const remarksInputFieldProps = computed(() => {
            return {
                type: "textarea",
                label: "",
                height: "78px",
                placeholder: "Type your Remarks",
                class: "mb-2"
            }
        })

        // REMARKS SUBMISSION
        const remarksResult = computed(() => {
            return store.getters['CommonAppointment/remarksResult']
        })
        const isRemarksLoading = computed(() => {
            return store.getters['CommonAppointment/remarksLoading']
        })
        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        // FORM DATA
        const form = reactive({
            remark: "",
            sample_given: 1,
        });
        const submitRemarks = async () => {
            const payload = {
                doctor_id: props.data.doctor_id,
                doctor_appointment_id: props.data.id,
                content: form,
            }
            await store.dispatch('CommonAppointment/submitRemarks', payload)
            if (remarksResult.value.success) {
                if (currentRoute.value.name === "dashboard-page") {
                    await store.dispatch('CommonAppointment/getAppointmentList', { status: 'served', limit: 9999 })
                    store.commit('CommonAppointment/setAppointmentSlideover', false)
                }
                if (currentRoute.value.name === "done-schedule-page") {
                    await store.dispatch('CommonAppointment/getAppointmentDetails', props.data.uuid);
                }
            }
        }

        //-------------------METHODS ENDs HERE------------------

        return {
            showForm,
            form,
            submitRemarks,
            isRemarksLoading,
            buttonProps,
            reasonInputFieldProps,
            remarksInputFieldProps,
            AccountType,
            hasRemarks,
        };
    },
};
</script>

<style lang="scss">
.remarks-card-component {
    .remarks-container {
        @apply w-full ml-0 space-y-1 #{!important};
        .header-title {
            @apply text-sm font-medium text-gray-700;
        }
        .remarks-content {
            p {
                @apply text-sm text-gray-500;
            }
        }
    }
    .action {
        @apply mt-2 flex justify-end;
        .remarks-save-btn {
            min-width: 60px;
        }
    }
}
</style>