<template>
    <div ref="popover_card" class="popover-card-component">
        <Popover v-slot="{ open }" class="relative flex">
            <PopoverButton
                :class="[
                    'popover-button-container',
                    open ? 'popover-button-opened' : 'text-opacity-90',
                ]"
            >
                <slot name="popover_button" />

                <button-component v-if="hasButton" v-bind="buttonProps">
                    <template v-slot:prepend>
                        <component
                            :is="buttonIcon"
                            :class="popOverBtnIconColor"
                            aria-hidden="true"
                        />
                    </template>
                </button-component>
                <div v-if="sideLabel" :class="sideLabelClass">
                    <p :class="sideLabelClass">{{ sideLabel }}</p>
                </div>
            </PopoverButton>

            <transition v-bind="transitionProps">
                <PopoverPanel
                    v-slot="{ close }"
                    ref="popover_card_panel"
                    :class="[
                        isPanelCenter ? 'center-panel' : 'right-panel',
                        popoverPanelClass,
                    ]"
                    class="popover-card-panel"
                >
                    <div
                        v-if="!_.isEmpty(lists)"
                        class="popover-card-panel-container"
                    >
                        <div class="popover-card-panel-content">
                            <div
                                v-for="(list, index) in lists"
                                :key="index"
                                class="popover-card-panel-list"
                                @click="onClickList(list)"
                            >
                                <component
                                    :is="list.icon"
                                    aria-hidden="true"
                                    class="list-icon"
                                />
                                <p class="list-label">
                                    {{ list.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        :class="[
                            'popover-card-panel-container',
                            popoverPanelClass,
                        ]"
                    >
                        <div
                            :class="['popover-card-slot-container', slotClass]"
                        >
                            <slot />
                            <slot name="popover_close_button" />
                            <button
                                id="popover_close_button"
                                class="hidden"
                                @click="closePopOver(close)"
                            />
                        </div>
                    </div>
                </PopoverPanel>
            </transition>
        </Popover>
    </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { ref, computed } from "vue";
export default {
    name: "PopoverCardComponent",
    components: {
        Popover,
        PopoverButton,
        PopoverPanel,
    },
    props: {
        lists: { type: Array, default: () => {} },
        popOverBtnIconColor: { type: String, default: "text-gray-500" },
        buttonLabel: { type: String, default: "" },
        buttonType: { type: String, default: "white-btn" },
        buttonIcon: { type: Function, default: () => {} },
        buttonSize: { type: String, default: "" },
        buttonCustomClass: { type: String, default: "" },
        popoverPanelClass: { type: String, default: "" },
        isPanelCenter: { type: Boolean, default: true },
        hasButton: { type: Boolean, default: true },
        slotClass: { type: String, default: "" },
        sideLabel: { type: String, default: "" },
        sideLabelClass: { type: String, default: "" },
    },
    emits: [ "onClickList" ],
    setup(props, { emit }) {
        const popover_close_button = ref(null);
        const onClosePopOver = () => {
            document.getElementById("popover_close_button").click();
        };

        const closePopOver = (close) => {
            close();
        };

        const onClickList = (list) => {
            emit("onClickList", list);
        };

        //------------------COMPUTED-------------------

        const buttonProps = computed(() => {
            return {
                type: props.buttonType,
                size: props.buttonSize,
                label: props.buttonLabel,
                class: props.buttonCustomClass,
            };
        });

        const transitionProps = computed(() => {
            return {
                enterActiveClass: "transition duration-200 ease-out",
                enterFromClass: "translate-y-1 opacity-0",
                enterToClass: "translate-y-0 opacity-100",
                leaveActiveClass: "transition duration-150 ease-in",
                leaveFromClass: "translate-y-0 opacity-100",
                leaveToClass: "translate-y-1 opacity-0",
            };
        });

        //------------------COMPUTED ENDS HERE------------------

        return {
            onClickList,
            buttonProps,
            transitionProps,
            closePopOver,
            onClosePopOver,
            popover_close_button,
        };
    },
};
</script>
<style lang="scss">
.popover-card-component {
    .center-panel {
        @apply top-7 left-1/2 transform -translate-x-1/2;
    }
    .right-panel {
        @apply right-0 top-10 transform -translate-x-0;
    }
}
.popover-button-container {
    @apply inline-flex items-center text-base font-medium text-white bg-transparent rounded-md group hover:text-opacity-100 focus:outline-none;
}
.popover-card-panel {
    @apply absolute z-10;
    .popover-card-panel-container {
        @apply overflow-hidden ring-1 ring-black ring-opacity-5 shadow-lg rounded-lg shadow-lg;
        .popover-card-panel-content {
            @apply relative bg-white divide-y divide-gray-200;
            .popover-card-panel-list {
                @apply px-4 py-3 flex items-start hover:bg-gray-50 cursor-pointer;
                .list-icon {
                    @apply text-gray-400 h-5 w-5;
                }
                .list-label {
                    @apply text-sm font-normal text-gray-700 ml-2;
                }
            }
        }
        .popover-card-slot-container {
            @apply relative bg-white p-3;
        }
    }
}
</style>
