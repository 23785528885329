<template>
    <div class="remote__stream-state">
        <div class="remote__stream-container">
            <div
                v-show="!viewScreenshare"
                class="center__content pharma-flex-center"
            >
                <div
                    v-if="muteVideo && connected"
                    class="remote__stream-video-mute"
                >
                    <div class="icon__l mx-auto">
                        <img
                            class="avatar__image"
                            :src="remoteStreamDetails.avatar"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div v-if="!connected">
                    <p class="message text-center">
                        Waiting for the {{ remoteStreamDetails.user_type }} to
                        {{ remoteConnectedOnce ? "rejoin" : "join" }} the
                        call...
                    </p>
                </div>
            </div>

            <div
                v-show="viewScreenshare"
                class="center__content pharma-flex-center"
            >
                <div class="share__screen_state">
                    <div class="screen_share_icon icon__m">
                        <DesktopComputerIcon />
                    </div>
                    <p class="sub_message text-gray-800">
                        You are sharing your screen
                    </p>
                </div>
            </div>

            <video-alert
                v-model="showVideoAlert"
                :state="videoAlertState"
                :position="message_position"
            />

            <div
                v-show="(!muteVideo && connected) || viewScreenshare"
                id="remote__stream-view"
                class="h-full"
            />
        </div>

        <div v-if="connected" class="doctor_info_style">
            <MicCameraIcon
                type="microphone"
                :on="!muteAudio"
                :label="remoteStreamDetails.full_name"
            />
        </div>
    </div>
</template>

<script>
import { ref, watch } from "vue";
import VideoAlert from "./video-alert.vue";
import MicCameraIcon from "./mic-camera-icon.vue";
import { DesktopComputerIcon } from "@heroicons/vue/solid";
// import { VideoExitPageComponent } from "./video-idle";
import { useVideoCallDetails } from "../functions";
export default {
    name: "RemoteStreamStateComponent",
    components: {
        VideoAlert,
        MicCameraIcon,
        DesktopComputerIcon,
        // VideoExitPageComponent,
    },
    props: {
        connected: Boolean,
        disconnected: Boolean,
        remoteConnectedOnce: Boolean,
        muteVideo: Boolean,
        muteAudio: Boolean,
        showRemote: Boolean,
        viewScreenshare: Boolean,
        remoteLeftCount: { type: Number, default: 0 },
        state: { type: String, default: "" },
        alertColor: { type: String, default: "" },
        timeCount: { type: [ String, Number ], default: "" },
        endTime: { type: String, default: "" },
        channelId: { type: String, default: "" },
    },
    setup(props) {
        const { videoCallDetails, remoteStreamDetails } = useVideoCallDetails();
        //DATA
        const showVideoAlert = ref(false);
        const showTimeAlert = ref(false);
        const videoAlertState = ref(null);
        const message_position = ref("");

        const setVideoAlert = (state) => {
            showVideoAlert.value = true;
            videoAlertState.value = state;
        };
        //COMPUTED

        //WATCH
        watch(
            () => props.timeCount,
            (newValue) => {
                if (newValue === 60) {
                    showTimeAlert.value = true;
                } else if (newValue === 55) {
                    showTimeAlert.value = false;
                } else if (newValue === 30) {
                    showTimeAlert.value = true;
                } else if (newValue === 25) {
                    showTimeAlert.value = false;
                } else if (newValue === 10) {
                    showTimeAlert.value = true;
                }
            }
        );

        watch(
            () => props.connected,
            (newValue) => {
                if (props.remoteLeftCount) {
                    message_position.value = "bot_left";
                    setVideoAlert(newValue ? "rs_connected" : "rs_left");
                }
            }
        );

        watch(
            () => props.disconnected,
            (newValue) => {
                if (newValue) {
                    setVideoAlert("rs_disconnected");
                    message_position.value = "bot_left";
                }
            }
        );

        const closeAlertMessage = () => {
            showTimeAlert.value = false;
        };

        return {
            remoteStreamDetails,
            videoAlertState,
            closeAlertMessage,
            showVideoAlert,
            showTimeAlert,
            videoCallDetails,
            message_position,
        };
    },
};
</script>
<style lang="scss">
.remote__stream-state {
    @apply absolute w-full;
    height: calc(100% - 108px);
    .remote__stream-container {
        @apply w-full h-full relative;
    }
    .center__content {
        @apply w-full h-1/3 absolute;
        top: calc(50% - 16%);
    }
    .bottom__content {
        @apply w-full h-1/3 bottom-0 absolute;
    }

    .alert_message_style {
        @apply absolute flex bottom-2 right-2 p-3 pl-5 pr-5 text-primary bg-white rounded-md border border-primary animate-pulse;
    }
    .doctor_info_style {
        @apply absolute flex left-4 bottom-4 lg:left-8 lg:bottom-8 p-2 text-gray-500 bg-white rounded-md rounded-3xl;
        .microphone_type {
            white-space: pre;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .share__screen_state {
        .screen_share_icon {
            @apply mx-auto mb-2 text-green-500;
        }
    }
    .avatar__image {
        @apply inline-block rounded-full  w-full h-full object-cover;
    }
    #remote__stream-view {
        video {
            object-fit: contain !important;
        }
    }
    @screen md {
        height: calc(100% - 64px);
    }

    @media (max-width: 520px) {
        .doctor_info_style {
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}
</style>