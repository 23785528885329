<template>
    <div :class="['video-call-settings', setup ? '__card' : 'modal__settings']">
        <div class="video-call-ready">
            <h3 v-if="setup" class="header__title">Controls</h3>

            <div
                class="settings__options items-center"
                :class="$device.isMobile ? '' : 'flex'"
            >
                <switch-component
                    :default-value="hasCamera"
                    :label="!videoCallStarted ? 'Join with video' : 'Camera'"
                    class="mr-4"
                    :class="$device.isMobile ? 'mb-2' : ''"
                    @changed:value="onCameraChange($event)"
                />
                <switch-component
                    :default-value="hasAudio"
                    :label="
                        !videoCallStarted ? 'Join with audio' : 'Microphone'
                    "
                    @changed:value="onAudioChange($event)"
                />
            </div>

            <div class="settings__options">
                <span class="icon__container">
                    <VideoCameraIcon class="icon text-gray-400" />
                    <label>Camera</label>
                </span>
                <select-box-component
                    v-model="savedVideoDevice"
                    v-bind="selectProperties('camera')"
                    @value-change="onCameraDeviceChange($event)"
                />
            </div>

            <div class="settings__options">
                <span class="icon__container">
                    <MicrophoneIcon class="icon text-gray-400" />
                    <label>Microphone</label>
                </span>
                <select-box-component
                    v-model="savedAudioDevice"
                    v-bind="selectProperties('audio')"
                    @value-change="onAudioDeviceChange($event)"
                />
            </div>
        </div>

        <div class="footer__card right">
            <button-component
                v-if="setup"
                type="primary-btn"
                label="Enter call"
                :disabled="!canEnterCall"
                custom-class="inline-block ml-auto w-full md:w-auto md:block bg-green-700"
                @click="onEnterCall()"
            >
                <template v-slot:append>
                    <PhoneOutgoingIcon aria-hidden="true" />
                </template>
            </button-component>
            <button-component
                v-if="!setup"
                label="Save Settings"
                custom-class="inline-block ml-auto w-full md:w-auto md:block"
                @click="onApplySettings()"
            />
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import {
    VideoCameraIcon,
    MicrophoneIcon,
    PhoneOutgoingIcon,
} from "@heroicons/vue/solid";

import SelectBoxComponent from "@CommonComponents/forms/select-box-component";
import SwitchComponent from "@CommonComponents/forms/switch-component";
import { AccountType } from "@Utilities/enum";

import { useSetupSettings, useVideoCallDetails } from "../functions";
export default {
    name: "SettingsComponent",
    components: {
        SelectBoxComponent,
        VideoCameraIcon,
        MicrophoneIcon,
        PhoneOutgoingIcon,
        SwitchComponent,
    },
    props: {
        setup: Boolean,
        videoCallReady: Boolean,
        remoteState: { type: String, default: null },
    },
    emits: [ "settings-applied", "enter-call" ],
    setup(props, { emit }) {
        // Variables use for modal settings

        const { videoCallStarted, user_type } = useVideoCallDetails();

        const {
            hasAudio,
            hasCamera,
            savedVideoDevice,
            savedAudioDevice,
            selectProperties,
            updateSelectedDevice,
            cameraChange,
            audioChange,
            onCameraChange,
            onAudioChange,
            onAudioDeviceChange,
            onCameraDeviceChange,
            applySettings,
        } = useSetupSettings(props);

        const onApplySettings = async () => {
            emit("settings-applied");
            applySettings();
        };

        const canEnterCall = computed(() => {
            const validStates = [ "remote_stream_call" ];

            if (user_type === AccountType.Doctor) {
                validStates.push("remote_stream_in");
            }

            return _.includes(validStates, props.remoteState);
        });

        const onEnterCall = async () => {
            if (!canEnterCall.value) {
                return;
            }

            emit("enter-call");
        };

        return {
            savedVideoDevice,
            savedAudioDevice,
            hasAudio,
            hasCamera,
            videoCallStarted,
            canEnterCall,
            updateSelectedDevice,
            onEnterCall,
            selectProperties,
            onApplySettings,
            cameraChange,
            audioChange,
            // Functions used for Modal Settings
            onCameraChange,
            onAudioChange,
            onAudioDeviceChange,
            onCameraDeviceChange,
            applySettings,
        };
    },
};
</script>

<style lang="scss">
.video-call-settings {
    .header__title {
        @apply text-lg leading-6 font-medium text-gray-700;
    }
    .settings__options {
        @apply my-4 w-full;
        .icon__container {
            label {
                @apply text-gray-500;
            }
        }
    }
}
</style>