<template>
    <div>
        <slot-modal-component
            v-model="showModal"
            v-bind="slotModalProps"
            @update:modelValue="onProceed"
        >
            <load-more-component
                v-bind="loadMoreProps"
            >
                <!-- HEADER -->
                <template v-slot:header>
                    <div class="legal-icon">
                        <DocumentTextIcon aria-hidden="true" class="text-white h-8 w-8" />
                    </div>
                    <DialogTitle as="h3">
                        {{ title }}
                    </DialogTitle>
                    <DialogDescription
                        as="p"
                        class="text text-center mb-4"
                    >
                        {{ description }}
                    </DialogDescription>
                </template>

                <!-- BODY -->
                <div v-if="!isLoading">
                    <!-- TERMS AND CONDITION CONTENT -->
                    <div v-if="type === 'terms-and-conditions'" class="legal-content text">
                        <p>These terms and conditions ("Terms", "Agreement") constitute as the legal agreement between you ("User", "you" or "your") and Medicomm Pacific, Inc. ("Application Developer", "us", "we" or "our"). This Agreement sets forth the general terms and conditions of your use of the <strong>PPD Clinic Pharma</strong> application and any of its products or services (collectively, "Application" or "Services"). If you do not accept these Terms, we will not grant you access to the Service and the joining process will immediately terminate. By joining and using the Service, you agree to be legally bound by this Agreement.</p>

                        <p class="section-title">Warranties and Representations</p>
                        <p>
                            By using this Application and by agreeing to these Terms, you warrant and represent that:
                        </p>

                        <ul class="alpha-list">
                            <li>
                                <span>(a)</span>You are of legal age and with legal capacity to enter into this Agreement and perform the obligations hereunder. Your capacity to dispense medicine is not prohibited by a law, regulation, statute, order, or any other lawful government or professional authority.
                            </li>
                            <li>
                                <span>(b)</span>All necessary approvals required for the due execution of the Agreement and the performance of your obligations under the same have been obtained and are in full force and effect.
                            </li>
                            <li>
                                <span>(c)</span>You are an individual duly licensed by the Professional Regulatory Commission (PRC) of the Republic of the Philippines to practice pharmacy in the Philippines as a medical representative or a professional sales representative, or you are an authorized officer, director, employee, or representative of a partnership or a corporation, whose Articles of Partnership or Incorporation allows it to engage in the business of pharmacy.
                            </li>
                            <li>
                                <span>(d)</span>You will obtain consent for the collection, processing, and storage of any and all of the personal information or sensitive personal information that you will disclose through the Application for the purposes provided in this Agreement.
                            </li>
                            <li>
                                <span>(e)</span>You will use the Application to market and promote only the products that you are authorized to present to the physicians through the Application. However, should you act beyond the authority given to you by the company/companies that you represent, you shall hold the Application Developer free and harmless from any obligations or liabilities that may arise from your acts omissions beyond the authority given by your principal.
                            </li>
                            <li>
                                <span>(f)</span>You shall remain professional in discussing your products and services with the doctors and physicians through the Application. You will treat any and all correspondences and communications with doctors and physicians through the Application as confidential.
                            </li>
                            <li>
                                <span>(g)</span>You shall comply with all existing and future laws, decrees, orders, rules and regulations promulgated by the authorities and shall hold the Application Developer free and harmless from any obligations or liabilities that may arise from its violation of any of such laws, decrees, orders and rules and regulations;
                            </li>
                        </ul>

                        <p>These representations and warranties shall survive the termination of the Agreement.</p>

                        <p class="section-title">Use of the Application</p>
                        <p>
                            You agree that you will use the Services in accordance with this Agreement. You also agree that by creating an account in the Application, you commit to be responsible for maintaining the security of your account. You shall be held fully accountable for all activities that occur under your account and any other actions taken in connection with it. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. In the event of any unauthorized use of your account or other breaches of security, you must immediately notify us.
                        </p>
                        <p>
                            You agree that you will not use the Services for any unlawful, immoral or malicious purpose. You are likewise prohibited from using the Application or its Content: (a) to solicit others to perform or participate in any unlawful acts; (b) to violate any international, national federal, provincial, or state regulations, rules, laws, or local ordinances; (c) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (d) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (e) to submit false or misleading information, or to send, receive, publish, distribute, transmit, upload or download any material which is offensive, abusive, defamatory, indecent, obscene, unlawful; or otherwise objectionable; (f) to introduce, upload or transmit viruses or any other type of malicious code, virus, or data that will or may be used in any way that will affect the functionality or operation of the Services or of any related mobile application, other mobile applications, any telecommunications equipment or any computer software or hardware, or the Internet; (g) to collect or track the personal information, or otherwise invade the privacy of or cause annoyance or anxiety to any other person; (h) to spam, phish, pharm, pretext, spider, crawl, scrape, or otherwise transmit any unsolicited correspondence to any other person; (i) for any obscene or immoral purpose; or (j) to damage, disable, overburden, impair, compromise, circumvent, or otherwise interfere with the security features of the  Application, the systems or security of the Application Developer or any related application, other applications, or the Internet.
                        </p>
                        <p>
                            We reserve the right to suspend or terminate your use of the Services or any related application for violating any of the prohibited uses. We may likewise suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.
                        </p>

                        <p class="section-title">User content</p>
                        <p>
                            We do not own any data, information or material ("Content") that you submit in the Application in the course of using the Services. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor Content in the Application submitted or created using our Services by you. You expressly grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you and other users of the Application. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable.
                        </p>

                        <p class="section-title">Intellectual property rights</p>
                        <p>
                            This Agreement does not transfer to you any intellectual property owned by Application Developer or third parties, other than the non-exclusive and nontransferable right to use it in accordance with this Agreement. All rights, titles, and interests in and to such property remain (as between the parties) solely with Application Developer. All trademarks, service marks, graphics and logos used in connection with our Application or Services, are trademarks or registered trademarks of Application Developer or Application Developer licensors. Other trademarks, service marks, graphics and logos used in connection with our Application or Services may be the trademarks of other third parties. Your use of our Application and Services grants you no right or license to reproduce or otherwise use any Application Developer or third-party trademarks.
                        </p>

                        <p class="section-title">Backups</p>
                        <p>
                            We perform regular backups of the Content and will do our best to ensure completeness and accuracy of these backups. In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.
                        </p>

                        <p class="section-title">Links to other applications</p>
                        <p>
                            Although this Application may be linked to other applications, we are not, directly or indirectly, implying any approval, association, sponsorship, or affiliation with any linked application, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their applications. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You are advised to review the legal statements and other conditions of use of any application which you access through a link from this Application. Your linking to any other off-site applications is at your own risk.
                        </p>

                        <p class="section-title">Limitation of liability</p>
                        <p>
                            The Application is provided to you on an “as is” and “as available” basis and therefore all warranties, conditions and other terms implied by statute or common law are, to the fullest extent permitted by law, excluded from this Agreement.
                        </p>
                        <p>
                            To the fullest extent permitted by applicable law, in no event will the Application Developer, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use or content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if Application Developer has been advised as to the possibility of such damages or could have foreseen such damages. The Application Developer shall not be liable to you in respect of any losses caused by failures, errors, delays or interruptions relating to the Services, including any failure to supply the Services due to events which are beyond our reasonable control. The Application Developer shall not also be liable to you if any third party gains access to your connection to the service or your device or destroys or damages any data or information held by you or information about you which is held by us. The Application Developer shall have no liability in respect of indirect or consequential loss, such as loss of profits, business, costs, expenses, or any other form of economic loss.
                        </p>
                        <p>
                            To the maximum extent permitted by applicable law, the aggregate liability of Application Developer and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one peso or any amounts actually paid in cash by you to Application Developer for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.
                        </p>

                        <p class="section-title">Termination</p>
                        <p>
                            Notwithstanding any provision in this Agreement, the Application Provider may terminate this Agreement immediately without notice to you for any reason whatsoever. In which case, all rights granted to you under this Agreement shall cease and you must immediately cease all activities authorized by this Agreement, including your use of any Services. The Application Provider shall not be liable to you or any third person for any losses arising from termination of the Agreement under this section. However, termination of this Agreement for any cause shall be without prejudice to the rights or remedies of either party accrued prior to the date of termination.
                        </p>

                        <p class="section-title">Indemnification</p>
                        <p>
                            You agree to indemnify and hold Application Developer and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Application or Services, or any willful misconduct on your part.
                        </p>

                        <p class="section-title">Relationship of the Parties</p>
                        <p>
                            The legal relationship between you and the Application Developer is one of independent contractorship. Nothing contained in this Agreement shall be deemed to constitute any party the partner, employee, agent, or legal representative of any other party or parties except as herein expressly provided. Neither does this agreement create a physician-patient relationship between the Application Developer and any person using the Application.
                        </p>

                        <p class="section-title">Severability</p>
                        <p>
                            All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
                        </p>

                        <p class="section-title">Dispute resolution</p>
                        <p>
                            The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of the Republic of the Philippines. The exclusive venue for actions related to the subject matter hereof shall be the appropriate courts of Pasig City, Philippines, to the exclusion of other courts.
                        </p>

                        <p class="section-title">Changes and amendments</p>
                        <p>
                            We reserve the right to modify this Agreement or its policies relating to the Application or Services at any time, effective upon posting of an updated version of this Agreement in the Application. When we do, we will revise the updated date at the bottom of this page. Continued use of the Application after any such changes shall constitute your consent to such changes.
                        </p>

                        <p class="section-title">Acceptance of these terms</p>
                        <p>
                            You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Application or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Application and its Services.
                        </p>

                        <p class="section-title">Privacy</p>
                        <p>
                            The terms of the Privacy Policy, available through a link on the log in page (Privacy Policy), are incorporated into this Agreement by reference and apply to the Service. Additionally, by using the Application, you acknowledge and agree that internet transmissions are never completely private or secure. You understand that any message or information you send using the Application may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.
                        </p>

                        <p class="section-title">Contact Information</p>
                        <p>
                            If you have any questions about this Agreement, you may send an e-mail to Medicomm Pacific, Inc. through <a href="mailto:we.care@ppd.ph" class="link"><u>we.care@ppd.ph</u></a> or call 86381461.
                        </p>

                        <p>
                            This document was last updated on September 14, 2021.
                        </p>
                    </div>

                    <!-- PRIVACY POLICY CONTENT -->
                    <div v-else class="legal-content text">
                        <p>
                            Medicomm Pacific, Inc . ("Application Developer", "us", "we" or "our") built the PPD Pharma application and any of its products or services (collectively, "Application" or "Services") as a service for you ("User", "you" or "your").
                        </p>
                        <p>
                            This page is used to inform you regarding our policies with the collection, use, and disclosure of Personal Information for any person who will make use of our Services (“Privacy Policy”). If you choose to use our Application, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                        </p>
                        <p>
                            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at PPD Pharma application, unless otherwise defined in this Privacy Policy.This applies to all Personal Information (as defined in the Data Privacy Act of the Country) collected, stored, used and disseminated by the Service Provider in relation to your access and use of Application. Your agreement with this Privacy Policy is necessary in order to have access to the Services.
                        </p>

                        <p class="section-title">Information Collection and Use</p>
                        <p>
                            Personal information, sensitive personal information and privileged information (collectively, “Personal Data”) that we collect from you, includes, but is not limited to, your name, valid identification number (including, specifically, your Professional Regulatory Commission identification card details, if applicable), birth date, address, telephone/mobile number, gender, occupation and/or profession, employer, status, email address, source of funds or income, age, social security identification number, family pedigree and relations, medical conditions, illness history, and other information as may be required by law, regulation, and/or necessary for us to process your transactions through the Application. Such information will be retained by us and used as described in this Privacy Policy.
                        </p>
                        <p>
                            Other information that may also constitute Personal Data, including, but not limited to, information which you may have provided in the application registration, your device information, your browser type, operating system, Internet Protocol (“IP”) address, location data, domain name, number of times and dates you visited the accessed the Application, the doctors and medical or professional sales representatives that you have conversed with through the Application – including the frequency and duration of each consultation/correspondences –and the amount of time you spent browsing through the Application, may be collected via cookies and other tracking technologies.
                        </p>

                        <p class="section-title">Purposes for Collection and Use of Information</p>
                        <p>
                            In addition to your enjoyment of the Services, and the continued development and improvement of the Application, the following is a non-exhaustive list of the purposes for which we collect and use your Personal Data:
                        </p>
                        <ul class="dot-list">
                            <li>
                                Compliance with any applicable law, regulation, legal process and/or government and law enforcement request, including but not limited to “Know Your Customer” processes, customer due diligence, anti-money laundering and anti-terrorist financing related purposes;
                            </li>
                            <li>
                                Performance of the Services through the use of telephone, mobile phone, computer or other information and communication technologies that will enable medical representatives, sales representatives, and representatives of pharmaceutical companiesto communicate with doctor for the purpose of discussion and presentation of products, which may involve live two-way audio and video correspondences, screen sharing, presentation of products, which may include images and photos of actual medical casesother documents that may be pertinent to use of the Services, whenever elected by the doctor and/or representatives;
                            </li>
                            <li>
                                Comparison and verification of transaction history of medical representatives with respect to medicine and pharmaceutical products presented, promoted, marketed, and discussed through the Application in order to assess performance, success rate, and frequency of correspondence of Medical Representatives in relation to their products;
                            </li>
                            <li>
                                Marketing research, promotional, and customer relationship management activities, including data analysis and data science research, to be conducted by us, our advertisers, or selected third parties, including licensed physicians and our partners in the pharmaceutical industry, to help determine how to improve services and systems to better serve patients and customers of Application Provider and find out what services and products will respond to the needs of these patients and customers;
                            </li>
                            <li>
                                Generation of reports of aggregate non-sensitive information to our advertisers or selected third parties including licensed physicians and our partners in the pharmaceutical industry, for consumer analysis and/or marketing and promotions;
                            </li>
                            <li>
                                Periodic notice about goods and services that may be of interest to you, including those goods and services of the venue, the advertisers, and Third Parties;
                            </li>
                            <li>
                                Troubleshooting any errors that any person may encounter while using the Application;
                            </li>
                            <li>
                                Communication, correspondence, and other administrative purposes regarding any concern with any of the Services;
                            </li>
                            <li>
                                Other legitimate and related services.
                            </li>
                        </ul>
                        <p>
                            You expressly consent to our collection and use of your Personal Data for the purposes mentioned above, including passing your personal information on to our advertisers and selected third parties that we work with for consumer analysis and/or marketing purposes.
                        </p>

                        <p class="section-title">Data Sharing and Disclosure</p>
                        <p>
                            In addition to the provisions above, you also consent to the disclosure of your Personal Data, for the same purposes above, to (a) the Government of the Republic of the Philippines and its agencies; (b) subsidiaries and affiliates of Application Developer; (c) current and prospective business partners of Application Developer with regard to current and future products and services for its customers; and/or (d) authorized service providers of Application Developer, including but not limited to cloud storage providers, legal and auditing services providers and other information technology service providers.
                        </p>

                        <p class="section-title">Cookies</p>
                        <p>
                            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. While the Application does not use “cookies” explicitly. it, however, may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you understand that you will not be able to use some portions of our Services.
                        </p>

                        <p class="section-title">Third Party Service Providers</p>
                        <p>
                            You are hereby informed that the Application uses third party services that may collect information used to identify you. These third party service providers have been given access to your Personal Data for the following purposes: (a) to facilitate our service, (b) to provide the service on our behalf, (c) to perform service-related services; or (d) to assist us in analyzing how our Service is used. The third party service providers are expressly prohibited from disclosing or using your personal information for any purpose other than those mentioned above.
                        </p>
                        <p>
                            Please see below the links to the respective privacy policies of such third party service providers:
                        </p>
                        <ul class="dot-list">
                            <li>
                                <a href="https://policies.google.com/privacy" target="_blank" class="link"><u>Google Play Services</u></a>
                            </li>
                        </ul>

                        <p class="section-title">Security</p>
                        <p>
                            We value your trust in providing us your Personal Data, thus we are striving to use commercially acceptable means of protecting it. However, you acknowledge that no method of transmission over the Internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                        </p>

                        <p class="section-title">Link to Other Sites</p>
                        <p>
                            The Application may contain links to other web sites. If you click on a third-party link, you will be directed to that site. Note that we do not operate these external sites, which may have their own privacy policies distinct and separate from this Privacy Policy. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                        </p>

                        <p class="section-title">Retention of Data</p>
                        <p>
                            We retain your Personal Data for no longer than is necessary for the purposes set out above, after which your Personal Data is anonymized and all personal identifying elements of it are destroyed.
                        </p>

                        <p class="section-title">Children’s Privacy</p>
                        <p>
                            The Application does not cater to anyone under the age of thirteen (13) years old. We do not knowingly collect personally identifiable information from children under thirteen (13) years old. In the event we discover that a child under thirteen (13) years of age has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
                        </p>

                        <p class="section-title">Changes and amendments</p>
                        <p>
                            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page. Continued use of the Application after any such changes shall constitute your consent to such changes.
                        </p>

                        <p class="section-title">Acceptance of these terms</p>
                        <p>
                            You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Application or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Application and its Services.
                        </p>

                        <p class="section-title">Contact Us</p>
                        <p>
                            If you have any questions regarding the processing of your Personal Data or wish to exercise any or your rights as a data subject, you may send an e-mail to the Data Protection Officer of Medicomm Pacific, Inc. through <a href="mailto:we.care@ppd.ph" class="link"><u>we.care@ppd.ph</u></a> or call 86381461.
                        </p>

                        <p>
                            This document was last updated on October 26, 2021.
                        </p>
                    </div>
                </div>
                <spinner-component v-else class="center" :size="40" />

                <!-- FOOTER -->
                <template v-slot:footer>
                    <div class="px-6">
                        <button
                            type="button"
                            class="primary-btn"
                            @click="onProceed"
                        >
                            I Understand
                        </button>
                    </div>
                </template>
            </load-more-component>
        </slot-modal-component>
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { DialogTitle, DialogDescription } from "@headlessui/vue";
import { DocumentTextIcon  } from "@heroicons/vue/outline";
import { SlotModalComponent } from ".";
import LoadMoreComponent from "../load-more-component.vue"
export default {
    name: "LegalModalComponent",
    components: {
        SlotModalComponent,
        LoadMoreComponent,
        DialogTitle,
        DialogDescription,
        DocumentTextIcon,
    },
    props: {
        modelValue: Boolean,
        type: { type: String, default: 'terms-and-conditions' }, // terms-and-conditions , privacy-policy
        title: { type: String, default: '' },
        description: { type: String, default: '' },
        content: { type: String, default: '' },
    },
    emits: [ "update:modelValue" ],
    setup(props, { emit }) {
        const showModal = ref(false);
        const isLoading = ref(true);

        //-----------------------COMPUTED-----------------------

        const slotModalProps = computed(() => {
            return {
                height: 0,
                scrollable: false,
                footerClass: "mt-0",
                distinctClass: "legal-modal",
                containerClass: "legal-dialog"
            }
        })

        const loadMoreProps = computed(() => {
            return {
                height: 350,
                uniqueBodyId: `${props.type}-legal-body`,
                uniqueId: `${props.type}-slideover-read-more`
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        // START OF SHOW T&C MODAL
        const onHideModal = () => {
            showModal.value = false;
            emit("update:modelValue", showModal.value);
        };

        const onCancel = () => {
            onHideModal();
        };

        const onProceed = () => {
            onHideModal();
        };
        // END OF SHOW T&C MODAL

        //-------------------METHODS ENDS HERE------------------
        watch(
            () => props.modelValue,
            (newVal) => {
                showModal.value = newVal;
                if (newVal) {
                    setTimeout(() => {
                        const body = document.querySelector(".load-more-body");
                        body.scrollTop = 0;
                        isLoading.value = false;
                    }, 100);
                } else {
                    setTimeout(() => {
                        isLoading.value = true;
                    }, 200);
                }
            }
        );

        return {
            onCancel,
            onProceed,
            showModal,
            slotModalProps,
            loadMoreProps,
            isLoading,
        };
    },
};
</script>

<style lang="scss">
.pharma-modal {
    &.slot-modal {
        &.legal-dialog {
            .slot-modal__container {
                .slot-modal__overlay {
                    @apply bg-white #{!important};
                }
                .legal-modal {
                    @apply shadow-none;
                }
            }
        }
        .legal-icon {
            @apply mx-auto flex items-center justify-center h-12 w-12 text-center rounded-full bg-primary;
        }
        h3 {
            @apply text-lg leading-6 text-center mt-4 mb-2 font-medium text-gray-900;
        }
        .text {
            @apply text-sm leading-5 font-normal text-gray-500;
        }
        .legal-content {
            p {
                @apply mb-3 text-justify;
                &.section-title {
                    @apply mt-6 font-bold;
                }
            }
            .alpha-list {
                @apply pl-4;
                li {
                    @apply flex pl-0;
                    span {
                        @apply mr-2;
                    }
                }
            }
            .dot-list {
                @apply list-disc;
            }
            ul, ol {
                @apply mb-3 pl-8;
                li {
                    @apply pl-2;
                }
            }
            .link {
                @apply text-primary cursor-pointer;
                &:focus-visible {
                    @apply outline-none;
                }
            }
        }
        .action-btn {
            @apply flex justify-end;
        }
    }
}
</style>