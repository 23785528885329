
import { useStore } from "vuex";
import { computed } from 'vue'
export default function useSetupSettings (props) {
    const store = useStore()

    // const videoCallDetails = computed(
    //     () => store.getters["VideoCall/videoCallDetails"]
    // );

    const cameraDevices = computed(
        () => store.getters["VideoCall/cameraDevices"]
    );

    const audioDevices = computed(
        () => store.getters["VideoCall/audioDevices"]
    );

    const savedVideoDevice = computed(
        () => store.getters["VideoCall/selectedVideoDevice"]
    );
    const savedAudioDevice = computed(
        () => store.getters["VideoCall/selectedAudioDevice"]
    );

    const hasCamera = computed(
        () => store.getters["VideoCall/hasCamera"]
    );

    const hasAudio = computed(
        () => store.getters["VideoCall/hasAudio"]
    );

    const videoCallReady = computed(
        () => store.getters["VideoCall/videoCallReady"]
    )

    const optionsId = (items) => {
        return _.map(items, (item, i) => {
            item.id = i + 1
            return item
        })
    };

    // SETTING UP DEFAULT DEVICES AND DEVICE LIST
    const setupDevices = function({ audioDevices, cameraDevices, selectedAudioDevice, selectedVideoDevice })  {
        audioDevices = optionsId(audioDevices)
        cameraDevices = optionsId(cameraDevices)
        setSettings({ audioDevices, cameraDevices, selectedAudioDevice, selectedVideoDevice })

    };

    const setSettings = ({ audioDevices, cameraDevices, selectedAudioDevice, selectedVideoDevice }) => {
        updateSelectedDevice('cameraDevices', cameraDevices)
        updateSelectedDevice('audioDevices', audioDevices)
        updateSelectedDevice('selectedAudioDevice', selectedAudioDevice)
        updateSelectedDevice('selectedVideoDevice', selectedVideoDevice)
    };

    const selectProperties = (type) => {
        return {
            itemName: 'label',
            objectReturn: true,
            options: type === 'camera' ? cameraDevices.value : audioDevices.value

        }
    };

    const updateSelectedDevice = (key, device) => {
        const params = {
            key,
            data: device
        }

        store.commit("VideoCall/setVideoCallProperties", params);
    };

    const cameraChange = (camera) => {
        updateSelectedDevice('hasCamera', camera)

    }

    const audioChange = (audio) => {
        updateSelectedDevice('hasAudio', audio)
    }

    // THIS IS FOR MODAL TYPE SETTINGS

    let camera = hasCamera.value
    let audio = hasAudio.value;
    let cameraDevice = savedVideoDevice.value;
    let audioDevice = savedAudioDevice.value;

    const onCameraChange = (value) => {
        if (props.setup) {
            cameraChange(value);
        } else {
            camera = value;
        }
    };

    const onAudioChange = (value) => {
        if (props.setup) {
            audioChange(value);
        } else {
            audio = value;
        }
    };

    const onCameraDeviceChange = (value) => {
        if (props.setup) {
            updateSelectedDevice("selectedVideoDevice", value);
            audioChange(value);
        } else {
            cameraDevice = value;
        }
    };

    const onAudioDeviceChange = (value) => {
        if (props.setup) {
            updateSelectedDevice("selectedAudioDevice", value);
        } else {
            audioDevice = value;
        }
    };

    const flipCamera = () => {
        if (_.size(cameraDevices.value) === 1) return;

        let newCameraDevice = {};

        _.forEach(cameraDevices.value, (video) => {
            if (video.deviceId !== savedVideoDevice.value.deviceId) {
                newCameraDevice = video;
            }
        });

        if (_.isEmpty(newCameraDevice)) return;
        updateSelectedDevice("selectedVideoDevice", newCameraDevice);
    };

    const applySettings = () => {
        updateSelectedDevice('selectedAudioDevice', audioDevice)
        updateSelectedDevice('selectedVideoDevice', cameraDevice)
        updateSelectedDevice('hasCamera', camera)
        updateSelectedDevice('hasAudio', audio)

    }

    return {
        savedVideoDevice,
        savedAudioDevice,
        hasCamera,
        hasAudio,
        videoCallReady,
        cameraDevices,
        setupDevices,
        selectProperties,
        updateSelectedDevice,
        cameraChange,
        audioChange,
        onCameraChange,
        onAudioChange,
        onAudioDeviceChange,
        onCameraDeviceChange,
        setSettings,
        applySettings,
        flipCamera
    };

}