
export default {
    install: (app) => {
        function  CAPITALIZE(value) {
            return value
                .toLowerCase()
                .split(" ")
                .map(function (word) {
                    return word[0].toUpperCase() + word.substr(1);
                })
                .join(" ");
        }

        app.config.globalProperties.$CAPITALIZE = CAPITALIZE;
        app.provide("i18n", { CAPITALIZE });

        function COVERTOMINUTES(value) {
            const sec = parseInt(value, 10); // convert value to number if it's string
            let hours   = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
            let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            // return minutes+':'+seconds; // Return is HH : MM : SS
            return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
        }

        app.config.globalProperties.$COVERTOMINUTES = COVERTOMINUTES;
        app.provide("i18n", { COVERTOMINUTES });
    }
}