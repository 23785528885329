<template>
    <component :is="$route.meta.layout" :module-name="moduleName">
        <router-view @set-module-name="moduleName = $event" />
    </component>
</template>
<script>
import {
    MedrepDashboardLayoutComponent,
    AdminDashboardLayoutComponent,
    MedrepAccessLayoutComponent,
    VideoCallLayout,
} from "@GlobalLayouts";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";

export default {
    components: {
        MedrepDashboardLayoutComponent,
        MedrepAccessLayoutComponent,
        VideoCallLayout,
        AdminDashboardLayoutComponent
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const personalToken = ref(STORAGE.GET_COOKIES(Tokens.Personal));
        const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));
        const moduleName = ref("");
        const authPage = computed(() => {
            return _.includes(route.path, '/medrep') || _.includes(route.path, '/admin')
        })

        watch(
            () => authPage.value,
            (newVal) => {
                if (newVal) {
                    if (_.isNull(personalToken.value)) {
                        window.location.href = "/"
                    }
                    // REDIRECT TO MEDREP PAGE WHEN MEDREP TRIES TO ACCESS THE ADMIN PAGE
                    if (userType.value === 'medrep' && _.includes(route.path, '/admin')) {
                        window.location.href = "/medrep/dashboard"
                    }
                    // REDIRECT TO ADMIN PAGE WHEN ADMIN TRIES TO ACCESS THE MEDREP PAGE
                    if (userType.value === 'district_manager' && _.includes(route.path, '/medrep')) {
                        window.location.href = "/admin/dashboard"
                    }
                }
            },
        )
        onMounted(() => {
            // TOKEN REQUEST ON API
            store.dispatch("clientTokenRequest");
        });

        return {
            moduleName,
        };
    },
};
</script>
<style>
</style>
