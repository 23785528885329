<template>
    <div class="my-doctors-component">
        <doctor-thread-component
            v-bind="myDoctorsProps"
            @get-doctor-id="$emit('get-doctor-id', $event)"
        />
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import {
    PlusIcon,
    UsersIcon,
    SearchIcon,
} from '@heroicons/vue/outline'
import {
    DoctorThreadComponent,
} from '@MedrepComponents';

export default {
    name: 'MyDoctorsComponent',
    components: {
        DoctorThreadComponent,
    },
    props: {
        tabName: { type: String, default: '' },
    },
    emits: [ "get-doctor-id", "set-tab-name" ],
    setup(props, { emit }) {
        const store = useStore();

        // START OF SETTING ACTIVE TAB
        onMounted(async () => {
            emit("set-tab-name", props.tabName)
        })
        // END OF SETTING ACTIVE TAB

        // RESET DOCTOR LIST WHEN UNMOUNTED
        onUnmounted(() => {
            store.commit("CommonDoctor/resetMyDoctorList")
        })

        // MY DOCTORS DATA
        const doctors= computed(() => {
            return store.getters['CommonDoctor/myDoctorList']
        })

        // START OF EMPTY STATE
        const dashboard = ref('/medrep/doctors')
        const emptyMenuList = ref([
            { icon: UsersIcon, name: 'Suggested', link: `${dashboard.value}/suggested` },
            { icon: SearchIcon, name: 'Find a Doctor', link: `${dashboard.value}/search` },
        ])
        // END OF EMPTY STATE

        // MY DOCTORS BINDED PROPS
        const myDoctorsProps = ref({
            title: "My Doctors",
            type: "my_doctors",
            doctors,
            emptyTitle: "No doctors to show",
            emptyButtonLabel: "New Appointment",
            emptyMenuList,
            icon: PlusIcon,
            uniqueId: "my-doctors-read-more",
            hasInfiniteLoading: true,
        })

        return {
            myDoctorsProps,
        }
    }
}
</script>

<style lang="scss">
.my-doctors-component {
    .thread-component {
        &.doctor-thread-component {
            .thread-header {
                @apply pb-4;
            }
        }
    }
}
</style>