<template>
    <div class="carousel-component">
        <div :class="['carousel-container', useDefaultClass ? 'default' : '']">
            <Splide
                :options="carouselProps"
                @splide:moved="onMoved($event)"
                @splide:move="onMove($event)"
                @splide:mounted="onMounted($event)"
            >
                <SplideSlide v-for="(item, index) in items" :key="index">
                    <img class="carousel__item" :src="item">
                </SplideSlide>
                <template v-slot:after-track>
                    <slot />
                </template>
            </Splide>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { computed } from "vue";

export default defineComponent({
    name: "CarouselComponent",
    components: {
    },
    props: {
        items: { type: Array, default: () => [] },
        type: { type: String, default: 'slide' }, // slide, loop, fade
        rewind: Boolean, // Determines whether to rewind the slider or not
        speed: { type: Number, default: 300 }, // The transition speed in milliseconds
        rewindSpeed: { type: Number, default: 1000 }, // The transition speed on rewind in milliseconds
        width: { type: [ Number, String ], default: "100%" },
        fixedWidth: { type: String, default: "" },
        fixedHeight: { type: String, default: "" },
        gap: { type: String, default: "0px" },
        start: { type: Number, default: 0 },
        perPage: { type: Number, default: 3 },
        useDefaultClass: { type: Boolean, default: true },
        focus: { type: String, default: "center" },
        trimSpace: Boolean,
        pagination: { type: [ Boolean, String ], default: true },
        arrows: { type: [ Boolean, String ], default: false },
        drag: { type: [ Boolean, String ], default: false },
        autoplay: { type: [ Boolean, String ], default: false },
        setIndex: { type: Number, default: 3 },
        interval: { type: Number, default: 5000 }, // The autoplay interval duration in milliseconds.
        keyboard: Boolean
    },
    emits: [ "on-move", "on-moved", "on-mounted" ],
    setup(props, { emit }) {
        const carouselProps = computed(() => {
            return {
                type: props.type,
                rewind: props.rewind,
                speed: props.speed,
                rewindSpeed: props.rewindSpeed,
                width: props.width,
                fixedWidth: props.fixedWidth,
                fixedHeight: props.fixedHeight,
                gap: props.gap,
                start: props.start,
                perPage: props.perPage,
                focus: props.focus,
                trimSpace: props.trimSpace,
                pagination: props.pagination,
                arrows: props.arrows,
                drag: props.drag,
                autoplay: props.autoplay,
                interval: props.interval,
                setIndex: props.setIndex,
                keyboard: props.keyboard
            }
        })

        const onMoved = (event) => {
            emit('on-moved', event)
        }

        const onMove = (event) => {
            emit('on-move', event)
        }

        const onMounted = (event) => {
            emit('on-mounted', event)
        }

        return {
            carouselProps,
            onMoved,
            onMove,
            onMounted
        };
    },
});
</script>

<style lang="scss">
.carousel-component {
    .carousel-container {
    }
    .carousel-container.default {
        .splide__slide > .carousel__item {
            transform: scale(1);
            opacity: 1;
            transition: 0.5s;
        }

        .is-visible > .carousel__item {
            opacity: 1;
            transform: rotateY(0);
        }

        .is-next > .carousel__item {
            transform: translate(-10px);
        }
        .is-prev > .carousel__item {
            transform: translate(10px);
        }

        .carousel__item {
            @apply overflow-hidden rounded shadow;
            min-height: 151px;
            min-width: 213px;
            color: white;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: visible;
        }
        .is-active > .carousel__item {
            transform: scale(1.6);
            // min-height: 160px;
            // min-width: 224px;
            @apply z-50;
        }
        .splide__slide {
            @apply py-7;
        }
        .splide__pagination {
            li {
                .splide__pagination__page {
                    @apply bg-indigo-200;
                }
                .splide__pagination__page.is-active {
                    @apply bg-indigo-700;
                }
            }
        }
    }
}
</style>