<template>
    <RadioGroup v-model="value" class="radio-button-component">
        <RadioGroupLabel
            :class="{ 'sr-only': !label }"
            class="radio-group__label"
        >
            {{ label }}
        </RadioGroupLabel>
        <div class="space-y-3">
            <RadioGroupOption
                v-for="option in options"
                :key="option.value"
                v-slot="{ checked }"
                as="div"
                :value="option"
                class="radio-group__option"
                @click="handleClick(option)"
            >
                <div class="radio-group__wrapper focus:outline-none">
                    <span
                        :class="
                            checked
                                ? 'bg-indigo-600 border-transparent'
                                : 'bg-white border-gray-300'
                        "
                        class="radio-group__round"
                        aria-hidden="true"
                    >
                        <span class="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <div class="ml-3 flex flex-col">
                        <RadioGroupLabel
                            as="span"
                            class="radio-group__label"
                        >
                            {{ option.label }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                            v-if="option.description"
                            as="span"
                            :class="
                                checked ? 'text-indigo-700' : 'text-gray-500'
                            "
                            class="radio-group__description"
                        >
                            {{ option.description }}
                        </RadioGroupDescription>
                    </div>
                </div>
            </RadioGroupOption>
        </div>
    </RadioGroup>
</template>

<script>
import { ref, watch } from "vue";
import {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
} from "@headlessui/vue";

export default {
    components: {
        RadioGroup,
        RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
    },
    props: {
        modelValue: { type: [ Object, String, Number ], default: () => {} },
        options: { type: Array, default: () => {} },
        label: { type: String, default: "" },
        returnObject: Boolean,
    },
    emits: [ "update:model-value" ],
    setup(props, { emit }) {
        const value = ref(props.modelValue);
        const handleClick = (event) => {
            if (props.returnObject) {
                emit("update:model-value", event);
            } else {
                emit("update:model-value", event.value);
            }
        };
        watch(
            () => props.modelValue,
            (newVal) => {
                value.value = newVal
            }
        )

        return {
            handleClick,
            value,
        };
    },
};
</script>
<style lang="scss">
.radio-button-component {
    @apply space-y-3 ;
    .radio-group {
        &__label {
            @apply block text-sm font-medium text-gray-700 ;
        }
        &__option {
            &:focus-visible {
                @apply outline-none;
            }
        }
        &__wrapper {
            @apply relative flex cursor-pointer ;
            span {

            }
        }
        &__round {
            @apply h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center ;
        }
        &__label {
            @apply block text-sm font-medium text-gray-700 ;
        }
        &__description {
            @apply block text-sm ;
        }

    }
}
</style>