<template>
    <div v-if="showImageCard" class="for-remarks-list-component">
        <image-card-component :img-src="imgSrc">
            <div class="for-remarks-appointment-container">
                <list-component
                    v-bind="listProps"
                >
                    <template v-slot:card="{ data }">
                        <appointment-settings-card-component
                            v-bind="appointmentSettingsCardProps(data)"
                            @click="onClickAppointmentCard(data)"
                        />
                    </template>
                </list-component>
            </div>
        </image-card-component>
    </div>
</template>

<script>
import ListComponent from "@CommonComponents/list-component.vue";
import AppointmentSettingsCardComponent from "@CommonComponents/cards/appointment-settings-card-component.vue";
import { ImageCardComponent } from "../components";
import { computed, onUpdated, ref } from "vue";

export default {
    name: "ForRemarksListComponent",
    components: {
        ListComponent,
        ImageCardComponent,
        AppointmentSettingsCardComponent,
    },
    props: {
        imgSrc: { type: String, default: "" },
        items: { type: Array, default: () => [] },
        loadMoreFooterClass: { type: String, default: "" },
        isLoading: Boolean,
        numberOfLoadingCards: { type: Number, default: 2 },
    },

    emits: [ "on-click-appointment-card" ],

    setup(props, { emit }) {

        //-----------------------COMPUTED-----------------------

        // COMPONENT PROPERTIES
        const listProps = computed(() => {
            const forRemarks = []
            _.map(_.cloneDeep(props.items), (item) => {
                if (_.isEmpty(item.remarks) || _.isEmpty(item.remarks.content)) {
                    forRemarks.push(item)
                }
            });
            return {
                items: props.isLoading ? props.numberOfLoadingCards : forRemarks,
                scrollable: false,
                isCard: false,
                loadMoreFooterClass: props.loadMoreFooterClass
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------

        const onClickAppointmentCard = (data) => {
            emit("on-click-appointment-card", data);
        };

        const appointmentSettingsCardProps = (data) => {
            return {
                settings: "date",
                dualLine: false,
                appointment: data,
                forListing: true,
                forRemarks: true,
                class: "py-4 px-3",
                isLoading: props.isLoading
            }
        }

        // THE WHOLE IMAGE CARD SHOULD BE HIDDEN WHEN ALL APPOINTMENTS HAS REMARKS RECORD
        const showImageCard = ref(true);
        onUpdated(() => {
            showImageCard.value = _.findIndex(props.items, i => _.isEmpty(i.remarks) || _.isEmpty(i.remarks.content)) >= 0;
        });

        //-------------------METHODS ENDs HERE------------------

        return {
            onClickAppointmentCard,
            listProps,
            appointmentSettingsCardProps,
            showImageCard,
        };
    },
};
</script>

<style lang="scss">
.for-remarks-list-component {
}
</style>