<template>
    <div class="lazy-image" :class="customClass" :style="styleObject">
        <div
            flat
            :tile="!isRounded"
            class="d-flex tw-w-full tw-h-full image-box th-images"
            :class="imageClass"
            :rounded="false"
        >
            <div>
                <img
                    v-show="loadImage"
                    v-bind="imageProperties"
                    aspect-ratio="1"
                    @error="loadFailed($event)"
                    @load="onImageLoad()"
                />
                <div
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <spinner-component indeterminate color="primary" :size="40" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, computed, watch } from "vue";
import { processExpression } from '@vue/compiler-dom';
export default {
    name: "LazyImageComponent",
    props: {
        image: { type: String, default: "" },
        height: { type: [ String, Number ], default: 50 },
        width: { type: [ String, Number ], default: 50 },
        customClass: { type: [ String ], default: "" },
        imageClass: { type: [ String ], default: "" },
        advertisement: Boolean,
        isRounded: Boolean,
    },
    setup(props) {
        const avatar = ref(null);
        const currentImage = ref("")
        const event_name = ref("")
        const loadImage = ref(false)
        const brokenImage = ref("")

        //COMPUTED
        const imageProperties = computed(() => {
            return {
                src: currentImage.value,
                lazySrc: currentImage.value,
            };
        });

        const styleObject = computed(() => {
            if (props.advertisement) {
                return {
                    height: "200px",
                    width: "540px",
                    borderRadius: "4px",
                };
            }
            if (_.isEmpty(props.customClass)) {
                return {
                    height: `${props.height}px`,
                    width: `${props.width}px`,
                };
            }
            return "";
        });

        watch(() => props.image, () => {
            currentImage.value = props.image;
        });

        onMounted(() => {
            currentImage.value = processExpression.image;
        });

        const loadFailed = (event) => {
            event_name.value = event;
            currentImage.value = brokenImage.value;
        };

        const onImageLoad = () => {
            loadImage.value = true;
        };

        return {
            avatar,
            currentImage,
            event_name,
            loadImage,
            imageProperties,
            styleObject,
            loadFailed,
            onImageLoad,
            brokenImage

        };
    }
};
</script>