import { Tokens } from '@Utilities/enum'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        brandsCovered: []
    },
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        brandsCoveredList(state) {
            return state.brandsCoveredList
        }
    },
    actions: {
        async getBrandsCoveredList({ commit }) {
            commit('setIsLoading', true)

            try {
                const url = '/pharma/api/brand/covered'
                const response = await api('get', url, {}, Tokens.Personal)
                if (response?.data?.success) {
                    commit('setBrandsCoveredList', response.data.data)
                }
            } catch (error) {
                console.warn(error)
            }

            commit('setIsLoading', false)
        }
    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload
        },
        setBrandsCoveredList(state, payload) {
            state.brandsCoveredList = payload
        },
    },
}
