<template>
    <div class="credits-page-component">
        <div class="credits-page-container dashboard-grid">
            <div class="credit-history-container">
                <div class="credit-balance-details-container">
                    <div class="credit-balance-details-card">
                        <div class="credit-balance-info-container">
                            <p class="header-title">Credit Balance</p>
                            <div class="flex space-x-3">
                                <p class="credit-balance"> {{ userCredits.credits }} </p>
                                <div class="action-contianer">
                                    <button-component
                                        type="primary-btn"
                                        label="Use credits"
                                        @on-click="onShowCreditsModal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="credit-history-details-container space-y-3">
                    <div class="credit-history-details-header-container">
                        <div class="flex justify-between">
                            <p class="header-title"> Credit History </p>
                            <div class="credit-status-container">
                                <popover-component
                                    v-bind="popoverProps"
                                >
                                    <popover-status-list-card-component />
                                </popover-component>
                            </div>
                        </div>
                        <div>
                            <navigation-tabs-component
                                v-model="creditHistoryTablists"
                                v-bind="navigationTabProperties"
                                @update:modelValue="onChangeTab($event)"
                            />
                        </div>
                        <div v-if="showFilter || isLoadingCreditHistoryList" class="filter-container space-y-1">
                            <div class="flex space-x-4">
                                <p class="text-sm font-medium text-gray-700">
                                    Filter by date
                                </p>
                                <p
                                    v-if="showResetFilterButton"
                                    class="text-sm font-medium text-primary cursor-pointer"
                                    @click="onClickResetFilter"
                                >
                                    Reset filter
                                </p>
                            </div>
                            <div class="flex items-center justify-between">
                                <div class="flex items-center space-x-3">
                                    <datepicker
                                        v-model="date.dateFrom"
                                        placeholder="Select a Date"
                                        :minimum-view="'day'"
                                        :maximum-view="'day'"
                                        :calendar-button-icon="calendarIcon"
                                    />
                                    <p> to </p>
                                    <datepicker
                                        v-model="date.dateTo"
                                        placeholder="Select a Date"
                                        :minimum-view="'day'"
                                        :maximum-view="'day'"
                                        :calendar-button-icon="calendarIcon"
                                    />
                                    <button-component
                                        type="secondary-btn"
                                        label="Filter"
                                        :disabled="disableFilterButton"
                                        @on-click="onClickFilter"
                                    />
                                </div>
                                <div v-if="selectedTab.name !== 'You'">
                                    <multiple-select-component
                                        v-if="selectedTab.name === 'All'"
                                        v-model="filterValue"
                                        v-bind="filteStatusProps"
                                    />
                                    <multiple-select-component
                                        v-else
                                        v-model="filterValue"
                                        v-bind="filterMedrepProps"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="credit-history-details-body-container">
                        <div ref="listHeight" class="credit-history-list-container">
                            <div class="credit-history-list-content">
                                <div v-if="!isLoadingCreditHistoryList">
                                    <list-component
                                        v-if="!_.isEmpty(creditHistory)"
                                        v-bind="creditHistoryListProps"
                                        @on-load-more="onLoadMore"
                                    >
                                        <template v-slot:card="{ data }">
                                            <credit-to-use-card-component
                                                v-bind="creditToUseCardProps(data)"
                                            />
                                        </template>
                                    </list-component>
                                    <empty-state-component
                                        v-else
                                        v-bind="creditHistoryEmptyStateProps"
                                        class="mt-16"
                                    />
                                </div>
                                <spinner-component v-else class="center mt-32" :size="40" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QuestionMarkCircleIcon, CalendarIcon } from "@heroicons/vue/solid";
import { ClockIcon as OutlineClockIcon } from "@heroicons/vue/outline";
import { ref, onMounted, computed, onUpdated } from "vue";
import { useStore } from "vuex";
import PopoverComponent from '@CommonComponents/cards/popover-card-component.vue'
import ListComponent from "@CommonComponents/list-component.vue";
import CreditToUseCardComponent from '@CommonComponents/cards/card-body/credit-to-use-card-component.vue'
import PopoverStatusListCardComponent from './components/popover-status-list-card-component.vue'
import NavigationTabsComponent from "@CommonComponents/navigation-tabs-component.vue";
import {
    Datepicker,
    MultipleSelectComponent
} from '@CommonComponents'
import { EmptyStateComponent } from '@MedrepComponents'
import useCreditHistory from "@AdminFunctions/useCreditHistory.js"

export default {
    name: "CreditsPageComponent",
    components: {
        PopoverComponent,
        ListComponent,
        CreditToUseCardComponent,
        PopoverStatusListCardComponent,
        NavigationTabsComponent,
        Datepicker,
        EmptyStateComponent,
        MultipleSelectComponent,
    },
    props: {
        pageName: { type: String, default: '' }
    },
    emits: [],
    setup(props) {
        const store = useStore();
        const questionMarkCircleIcon = ref(QuestionMarkCircleIcon);
        const outlineClockIcon = ref(OutlineClockIcon);
        const creditHistoryEmptyStateProps = ref({
            icon: outlineClockIcon,
            title: "No credit history",
            showPopOver: false,
        })
        const calendarIcon = ref(CalendarIcon)

        const {
            onShowCreditsModal,
            creditHistory,
            isLoadingCreditHistoryList,
            loadMoreLoading,
            onChangeTab,
            onClickFilter,
            onClickResetFilter,
            filterValue,
            filteStatusProps,
            filterMedrepProps,
            selectedTab,
            onLoadMore,
            creditHistoryTablists,
            date,
            showLoadMore,
            showResetFilterButton,
            disableFilterButton,
            showFilter,
            onChangeRoute,
            route
        } = useCreditHistory();

        // GET CREDIT BALANCE
        const userCredits = computed(() => {
            return store.getters['CommonCredit/userCredits']
        })

        onMounted(async() => {
            store.commit('CommonUtilities/setPageName', props.pageName)
            onChangeRoute(true)
            await store.dispatch("CommonCredit/getUserCredits");
            if (!_.isEmpty(route.query.id)) {
                filterValue.value = route.query.id
            }
        });

        const viewportHeight = ref(window.innerHeight);
        const creditHistoryTotalHeight = ref(0)
        const listHeight = ref(0)
        // COMPONENT HEIGHT COMPUTATION
        onUpdated(() => {
            const paddingTop = listHeight.value.getBoundingClientRect().top + 4;
            const paddingBottom = 64;

            creditHistoryTotalHeight.value =
                viewportHeight.value -
                paddingTop -
                paddingBottom
        })

        //------- START OF BINDED PROPERTIES --------

        // TAB PROPERTIES
        const navigationTabProperties = computed(() => {
            return {
                ref: "navTabs",
                contentType: "component",
                tabType: "tab-in-pills",
                alignment: "left",
                componentClass: "mt-4 mb-6"
            }
        });

        // POPOVER PROPERTY
        const popoverProps = computed(() => {
            return {
                buttonType: "white-icon-btn",
                popOverBtnIconColor: "text-gray-500",
                buttonIcon: questionMarkCircleIcon.value,
                isPanelCenter: false,
                slotClass: "px-0 py-0",
                sideLabel: "What are credit statuses?",
                sideLabelClass: "text-sm font-normal text-gray-500"
            }
        })

        // CREDIT HISTORY LIST PROPERTY
        const creditHistoryListProps = computed(() => {
            return {
                items: creditHistory.value,
                scrollable: true,
                isCard: true,
                loadMoreFooterClass: "bg-transparent",
                listMaxHeight: creditHistoryTotalHeight.value,
                showLoadMore: showLoadMore.value,
                loadMoreLabel: "More credit history",
                hasInfiniteLoading: true,
                loadMoreLoading: loadMoreLoading.value,
                showEndNote: true
            }
        })

        // CREDIT TO USE CARD PROPERTY
        const creditToUseCardProps = (data) => {
            return {
                item: data,
                isAppointmentCard: false,
                isForCreditHistory: true,
                reason: data.reason,
                dateLastUpdate: data.date_last_update,
                class: "credit-history"
            }
        }

        //------- END OF BINDED PROPERTIES --------

        return {
            creditHistory,
            userCredits,
            creditHistoryListProps,
            creditToUseCardProps,
            popoverProps,
            creditHistoryTablists,
            navigationTabProperties,
            onChangeTab,
            listHeight,
            calendarIcon,
            onShowCreditsModal,
            date,
            onClickFilter,
            onClickResetFilter,
            showResetFilterButton,
            disableFilterButton,
            isLoadingCreditHistoryList,
            showFilter,
            creditHistoryEmptyStateProps,
            filterValue,
            filteStatusProps,
            filterMedrepProps,
            selectedTab,
            onLoadMore,
            loadMoreLoading,
        }
    }
}
</script>

<style lang="scss">
.credits-page-component {
    .credits-page-container {
        .credit-history-container {
            @apply col-span-2;
            .credit-balance-details-container {
                @apply mb-6;
                .credit-balance-details-card {
                    @apply shadow rounded-md w-auto bg-white;
                    .credit-balance-info-container {
                        @apply flex justify-between py-4 px-6 items-center;
                        .credit-balance {
                            @apply text-4xl font-medium text-gray-500;
                        }
                    }
                }
            }
            .credit-history-details-container {
                .credit-history-details-header-container {
                    @apply flex flex-col;
                    .credit-status-container {
                        @apply flex items-center;
                        button {
                            @apply bg-gray-50;
                            &:focus {
                                @apply outline-none ring-0 ring-offset-0;
                            }
                        }
                    }
                    .vdp-datepicker {
                        .vdp-datepicker__calendar {
                            @apply left-0;
                        }
                    }
                    .multiple-select-component {
                        width: 170px;
                        .multiselect-dropdown {
                            @apply overflow-y-auto;
                            max-height: 220px;
                            .multiselect-options {
                                max-height: 220px;
                            }
                        }
                    }
                }
                .credit-history-details-body-container {
                    .credit-to-use-card-component {
                        &.credit-history {
                            .credit-to-use-container {
                                .credit-to-use-card-content {
                                    .credit-value {
                                        @apply text-gray-500 #{!important};
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .header-title {
                @apply text-lg font-medium text-gray-900;
            }
        }
        .list-component {
           .spinner-component {
               &.load-more-loading {
                   @apply h-auto mt-2;
               }
           }
        }
    }
}
</style>