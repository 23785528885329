<template>
    <div class="doctor-profile-component">
        <!-- STATIC HEIGHT COMPONENT -->
        <template v-if="!isLoading">
            <doctor-profile-card-component
                v-bind="doctorProfileProperties"
                :show-appointment-btn="showAppointmentBtn"
                :show-close-btn="showCloseBtn"
                @get-height="componentHeight = $event"
                @on-click-close-btn="onShowSlideover"
                @update:modelValue="doctorTablists = $event"
                @click-tab="selectNavTab($event)"
            />

            <!-- DYNAMIC HEIGHT/ TAB CONTENT -->
            <template v-for="(tab, index) in doctorTablists" :key="index">
                <template v-if="tab.current">
                    <load-more-component
                        :unique-body-id="`${tab.name}-body`"
                        :unique-id="`${tab.name}-slideover-read-more`"
                        :height="totalHeight"
                        :show-scroll-more="true"
                        :load-more-loading="hasInfiniteLoading ? loadMoreLoading : false"
                        @get-action-btn-height="actionBtnHeight = $event"
                        @infinite-scroll="handleScroll(tab.name)"
                    >
                        <component
                            :is="selectedComponent(tab.name)"
                            :doctor-id="doctorId"
                            :schedules="schedules"
                            :appointments="appointments"
                            :coverage="coverage"
                            :is-loading="listLoading"
                        />
                        <template
                            v-if="tab.name === 'Appointments' && $userType === AccountType.Medrep && !hasInfiniteLoading"
                            v-slot:footer
                        >
                            <div class="w-full px-6 pb-6">
                                <button-component
                                    type="white-btn"
                                    label="View schedule"
                                    block
                                    @click="onViewSchedule"
                                />
                            </div>
                        </template>
                    </load-more-component>
                </template>
            </template>
        </template>
        <spinner-component v-else class="center h-screen" :size="40" />
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { LoadMoreComponent } from "@CommonComponents";
import CoverageTabComponent from "@CommonComponents/coverage-tab-component.vue";
import AppointmentsTabComponent from "@CommonComponents/appointments-tab-component.vue";
import DoctorProfileCardComponent from "./doctor-profile-card-component.vue";
import ScheduleTabComponent from "./schedule-tab-component.vue";
import useProfileSlide from '@GlobalFunctions/useProfileSlide.js';
import useScrollHeight from '@GlobalFunctions/useScrollHeight.js';
import { AccountType } from "@Utilities/enum";

export default {
    name: "DoctorProfileComponent",
    components: {
        LoadMoreComponent,
        DoctorProfileCardComponent,
        ScheduleTabComponent,
        AppointmentsTabComponent,
        CoverageTabComponent,
    },
    props: {
        doctorId: { type: [ String, Number ], default: null, },
        showAppointmentBtn: { type: Boolean, default: true, },
        showCloseBtn: { type: Boolean, default: true, },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        const {
            doctorTablists,
            getDoctorProfile,
            doctorProfileProperties,
            schedules,
            getDoctorSchedule,
            appointments,
            getAppointment,
            coverage,
            getCoverage,
            listLoading,
            loadMoreData,
            loadMoreLoading,
            resetData,
        } = useProfileSlide();

        const {
            componentHeight,
            actionBtnHeight,
            totalHeight,
        } = useScrollHeight();

        // START OF FETCHING DOCTOR DATA
        const isLoading = ref(true)
        onMounted( async () => {
            isLoading.value = true;
            await getDoctorProfile(props.doctorId);
            await getDoctorSchedule(props.doctorId);
            isLoading.value = false;
        })

        const selectNavTab = async (tabname) => {
            resetData();
            switch (tabname) {
            case 'Appointments':
                await getAppointment(props.doctorId);
                break;
            case 'Coverage':
                await getCoverage(props.doctorId);
                break;
            default:
                // 'Schedule'
                await getDoctorSchedule(props.doctorId);
                break;
            }
        }
        // END OF FETCHING DOCTOR DATA

        watch(
            () => props.doctorId,
            async (newVal) => {
                isLoading.value = true;
                await getDoctorProfile(newVal);
                // RESETTING TO SCHEDULE TAB EVERYTIME DOC ID IS CHANGED
                _.forEach(doctorTablists.value, i => {
                    i.name === 'Schedule' ? i.current = true : i.current = false
                });

                // FETCH NEW SCHEDULE WHEN DOCTOR ID IS CHANGED
                await getDoctorSchedule(newVal)
                isLoading.value = false;
            }
        )

        // CLOSE SLIDEOVER
        const onShowSlideover = () => {
            store.commit("CommonDoctor/setProfileSlideover", false);
        };

        // REDIRECT TO SCHEDULE PAGE
        const onViewSchedule = () => {
            router.push({ path: '/medrep/schedule' })
        }

        // SETTING OF SELECTED TAB CONTENT COMPONENT
        const selectedComponent = (name) => {
            return `${_.toLower(name)}-tab-component`;
        };

        // START OF INFINITE LOADING
        const hasInfiniteLoading = ref(false);
        const handleScroll = (tab) => {
            switch (tab) {
            case 'Coverage':
                hasInfiniteLoading.value = true
                loadMoreData(_.toLower(tab), props.doctorId);
                break;
            default:
                // 'Schedule and Appointments'
                hasInfiniteLoading.value = false
                break;
            }
        }
        // END OF INFINITE LOADING

        return {
            doctorTablists,
            onShowSlideover,
            componentHeight,
            actionBtnHeight,
            totalHeight,
            selectedComponent,
            doctorProfileProperties,
            isLoading,
            listLoading,
            selectNavTab,
            schedules,
            appointments,
            coverage,
            onViewSchedule,
            AccountType,
            loadMoreLoading,
            handleScroll,
            hasInfiniteLoading,
        };
    },
};
</script>

<style lang="scss">
.slide-over-modal-component {
    .doctor-profile-slideover__content {
        .load-more-component {
            .load-more-body {
            }
        }
    }
}
</style>