<template>
    <div class="preferences-remarks-component">
        <div class="preference-remarks-container">
            <div class="preference-remarks-content">
                <div class="preference-remarks-header-container">
                    <img
                        src="@Images/preferences-first-screen-pic.svg"
                        class="header-image"
                    >
                    <div class="header-message">
                        <p
                            class="header-title"
                        >
                            Setting up your account
                        </p>
                        <p
                            class="header-subtitle"
                        >
                            Before getting started at PPD Clinic Pharma, you'll need to carry out the following steps to complete your account setup.
                        </p>
                    </div>
                </div>
                <div class="task-container">
                    <div class="task-list">
                        <ul class="list-disc">
                            <div v-for="(content, index) in taskList" :key="index">
                                <li class="text-sm font-medium text-gray-500">
                                    <p class="text-sm font-medium text-gray-700">
                                        {{ content.header }}
                                    </p>
                                </li>
                                <p class="text-sm font-normal text-gray-500">
                                    {{ content.details }}
                                </p>
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="preference-remarks-action">
                    <button
                        type="button"
                        class="primary-btn"
                        @click="onClickGetStarted"
                    >
                        Get started
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    name: "PreferencesRemarksComponent",
    components: {
    },
    props: {

    },
    emits: [ "on-click-get-started" ],
    setup(props, { emit }) {
        const taskList = ref([
            { header: 'Configure preferences', details: 'Providing your preferences will help us recommend doctors.'  },
            { header: 'Set profile photo', details: 'Uploading your profile photo will help doctors acknowledge your account credibility.' },
        ])
        const onClickGetStarted = () => {
            emit('on-click-get-started')
        }
        return {
            taskList,
            onClickGetStarted
        }
    }
}
</script>

<style lang="scss">
.preferences-remarks-component {
    @apply flex h-full items-center;
    .preference-remarks-container {
        .preference-remarks-content {
            @apply space-y-6;
            .preference-remarks-header-container {
                @apply flex items-center flex-col space-y-6;
                .header-message {
                    @apply flex items-center flex-col space-y-2 text-center;
                    .header-title {
                        @apply text-lg font-medium text-gray-700;
                    }
                    .header-subtitle {
                        @apply text-sm font-normal text-gray-500;
                    }
                }
            }
            .task-container {
                .task-list {
                    @apply pl-6;
                    .list-disc {
                        @apply space-y-5;
                    }
                }
            }
            .preference-remarks-action {
                @apply flex justify-center;
            }
        }
    }
}
</style>