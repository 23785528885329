<template>
    <div class="admin-profile-page-component">
        <div class="admin-profile-page-container component-container-grid">
            <div class="admin-personal-details-container col-span-3">
                <admin-personal-details-component />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import AdminPersonalDetailsComponent from './components/admin-personal-details-component.vue'

export default {
    name: "AdminProfilePageComponent",
    components: {
        AdminPersonalDetailsComponent
    },
    props: {
        pageName: { type: String, default: '' }
    },
    emits: [],
    setup(props) {
        const store = useStore()

        onMounted(async() => {
            store.commit('CommonUtilities/setPageName', props.pageName)
        });

    }
}
</script>

<style lang="scss">
.admin-profile-page-component {
    .admin-profile-page-container {
    }
}
</style>