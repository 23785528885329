<template>
    <div class="coverage-tab-component">
        <template v-if="!isLoading">
            <template v-if="!_.isEmpty(coverage)">
                <div class="coverage-tab coverage-tab__title">
                    <p>Brands</p>
                    <p>Last date promoted</p>
                </div>
                <div class="divide-y divide-gray-200">
                    <div
                        v-for="(item, index) in coverage"
                        :key="index"
                        class="coverage-tab coverage-tab__content"
                        @click="item.last_date_coverage === 'No Record' ? '': showAppointmentSlideover(item)"
                    >
                        <p class="text-gray-500">{{ item.brand_name }}</p>
                        <p :class="item.last_date_coverage === 'No Record' ? 'text-gray-400' : 'text-primary'">{{ item.last_date_coverage }}</p>
                    </div>
                </div>
            </template>
            <empty-state-component
                v-else
                :icon="chatIcon"
                title="No brand has been promoted"
                class="mt-6"
            />
        </template>
        <spinner-component v-else class="center mt-16" :size="40" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { ChatAltIcon } from '@heroicons/vue/outline'
import { EmptyStateComponent } from "@MedrepComponents";
import { useStore } from 'vuex';

export default {
    name: 'CoverageTabComponent',
    components: {
        EmptyStateComponent,
    },
    props: {
        coverage: { type: [ Array, Object ], default: () => {}, },
        isLoading: { type: Boolean, default: false },
    },
    setup() {
        const store = useStore();

        // EMPTY STATE
        const chatIcon = ref(ChatAltIcon)

        // START OF SHOWING APPOINTMENT SLIDEOVER
        const showAppointmentSlideover = async (appointment) => {
            store.commit('CommonAppointment/setAppointmentSlideover', true)
            store.dispatch('CommonAppointment/getAppointmentDetails', appointment.uuid);
        }
        // END OF SHOWING APPOINTMENT SLIDEOVER

        return {
            chatIcon,
            showAppointmentSlideover,
        }
    }
}
</script>

<style lang="scss">
.coverage-tab-component {
    .coverage-tab {
        @apply flex justify-between items-center px-6 py-2 cursor-pointer ;
        p {
            @apply text-sm leading-5 font-normal ;
        }
        &__title {
            p {
                @apply text-gray-700 font-medium ;
            }
        }
        &__content {
            &:last-of-type {
                @apply border-b border-gray-200 #{!important} ;
            }
        }
    }
}
</style>
