<template>
    <client-only>
        <v-app id="video-layout">
            <purge-css-component />
            <v-app-bar
                v-if="!videoCallStarted"
                app
                color="white"
                class="z-50 pharma-flex-center"
                height="80"
                flat
            >
                <div class="w-full">
                    <img
                        src="@Images/ppd-pharma-logo.png"
                        class="mx-auto"
                    />
                </div>
            </v-app-bar>
            <v-main app>
                <nuxt
                    :class="{
                        'lg:w-2/3 mx-auto w-full': !videoCallStarted,
                    }"
                />
            </v-main>
        </v-app>
    </client-only>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'VideoCallLayout',
    computed: {
        ...mapGetters({
            videoCallStarted: "VIDEOCALL/videoCallStarted"
        })
    },
    //pit hide
    // async mounted() {
    //     await this.$store.dispatch('GLOBAL/getApiMessages')
    // }
}
</script>