
<template>
    <div class="video__in__call">
        <div class="video__call_container">
            <video-screen-share-component
                v-if="room_info.status === 'ongoing'"
            />
            <div v-else class="h-screen">
                <spinner-component
                    :size="48"
                    class="room__invalid pharma-flex-center h-full"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { VideoCameraIcon, MicrophoneIcon } from "@heroicons/vue/solid";
import VideoScreenShareComponent from "./video-screen-share-component.vue";
import { ref, onMounted, watch } from "vue";
import router from "@/router";
import { useSetupSettings, useVideoCallDetails } from "./functions";
export default {
    name: "VideoInCallComponent",
    components: {
        VideoScreenShareComponent,
    },
    props: {
        setup: Boolean,
    },
    setup() {
        const icons = ref([ VideoCameraIcon, MicrophoneIcon ]);

        const videoCallReady = ref(false);

        const { setupDevices } = useSetupSettings();

        const {
            emptyVideoCallDetails,
            room_info,
            queryParams,
            room_users,
            remoteState,
        } = useVideoCallDetails();

        onMounted(() => {
            if (emptyVideoCallDetails.value) {
                router.push({
                    name: "video-call-room",
                    query: queryParams,
                });
            }
        });

        watch(
            () => room_users.value,
            () => {
                const remoteStateStatus = remoteState(room_users.value);

                if (remoteStateStatus === "remote_stream_left") {
                    router.push({
                        name: "video-call-remarks",
                        query: queryParams,
                    });
                }
            }
        );

        return {
            icons,
            videoCallReady,
            setupDevices,
            room_info,
        };
    },
};
</script>

<style lang="scss">
.video__call__state {
    .permission__state {
        @apply w-full h-full flex items-center justify-center bg-gray-100 p-4;

        .icons__container {
            @apply flex items-center justify-center mb-4;
        }
    }
}
</style>