<template>
    <div class="replenish-team-credits-modal-component">
        <slot-modal-component
            v-model="showModal"
            v-bind="slotModalProps"
            @update:modelValue="onHideModal"
        >
            <!-- HEADER -->
            <template v-slot:header>
                <div class="replenish-team-credits-header-container">
                    <p class="header-title">
                        Allocate credits
                    </p>
                    <OutlineXIcon
                        aria-hidden="true"
                        class="close-icon"
                        @click="onHideModal()"
                    />
                </div>
            </template>
            <!-- BODY -->
            <template v-slot:body>
                <div v-if="!teamMemberLoading" class="replenish-team-credits-body-container">
                    <div class="credit-balance-container">
                        <p class="label"> Credit Balance </p>
                        <p class="credit-balance"> {{ userCredits.credits }} </p>
                    </div>
                    <div class="medrep-list-and-total-credits-computation-container">
                        <div class="medrep-list-container">
                            <table-card-component
                                v-bind="tableProps"
                                @input-value-change="teamMemberList=$event"
                            />
                        </div>
                        <div class="total-credits-computation-container">
                            <div class="total-credit-consumed-container computation-container">
                                <p class="label">
                                    Total credits to be consumed
                                </p>
                                <p class="label-value">
                                    {{ totalCreditsConsumed }}
                                </p>
                            </div>
                            <div class="balance-after-replenishment-container computation-container">
                                <p class="label">
                                    Balance after replenishment
                                </p>
                                <p class="label-value">
                                    {{ balanceAfterReplenishment }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <spinner-component v-else class="center my-16" :size="40" />
            </template>
            <!-- FOOTER -->
            <template v-slot:footer>
                <div class="footer-action">
                    <span v-if="balanceAfterReplenishment < 0 && totalCreditsConsumed !== 0" class="reminder">You do not have enough credits</span>
                    <button-component
                        v-bind="buttonProps"
                        @on-click="onClickConfirm"
                    />
                </div>
            </template>
        </slot-modal-component>
    </div>
</template>

<script>
import { ref, computed, onUpdated, watch } from "vue";
import SlotModalComponent from "@CommonComponents//modal/slot-modal-component.vue";
import { XIcon as OutlineXIcon } from "@heroicons/vue/outline";
import { useStore } from "vuex";
import TableCardComponent from '@CommonComponents/table-card-component.vue'

export default {
    name: "ReplenishTeamCreditsModalComponent",
    components: {
        SlotModalComponent,
        OutlineXIcon,
        TableCardComponent
    },
    props: {
        teamMembers: { type: Array, default: () => {} },
        teamMemberLoading: { type: Boolean, default: false },
    },
    setup(props) {
        const store = useStore()
        const teamMemberList = ref([])

        //-----------------------COMPUTED-----------------------

        // GETTERS

        const userCredits = computed(() => {
            return store.getters['CommonCredit/userCredits']
        })

        const addTeamCreditMessage = computed(
            () => store.getters["CommonCredit/addTeamCreditMessage"]
        );

        const isLoading = computed(() => store.getters["CommonCredit/addTeamCreditLoading"]);

        // TABLE DATA
        const cardData = computed(
            () => {
                return {
                    data: teamMemberList.value,
                    thClass: "px-0 bg-white",
                    tdClass: "px-0",
                    config: [
                        {
                            label: "MEDICAL REPRESENTATIVE",
                            type: "label-with-avatar",
                            key: {
                                nameKey: "full_name",
                                avatarKey: "avatar"
                            },
                            class: "text-gray-900",
                        },
                        {
                            label: "Credits",
                            type: "input-field",
                            inputType: "number",
                            placeholder: "0",
                            key: "credits",
                            class: "w-16",
                            perTdClass: "grid justify-items-end pr-px",
                            perThClass: "text-right",
                        }
                    ]
                }
            }
        )

        // CREDIT COMPUTATION
        const totalCreditsConsumed = computed(
            () => {
                let totalCreditsConsumed = 0
                _.map(teamMemberList.value, (data) => {
                    if (data.credits) {
                        totalCreditsConsumed = _.toInteger(totalCreditsConsumed) + _.toInteger(data.credits)
                    }
                })
                return totalCreditsConsumed
            }
        )

        const balanceAfterReplenishment = computed(
            () => {
                return _.toInteger(userCredits.value.credits) - _.toInteger(totalCreditsConsumed.value)
            }
        )

        // PROPERTIES

        // TABLE PROPERTIES
        const tableProps  = computed(
            () => {
                return {
                    cardData: cardData.value,
                    isCard: false,
                    showProfile: false,
                }
            }
        );

        // SLOT MODAL PROPERTIES
        const slotModalProps = computed(() => {
            return {
                height: 0,
                scrollable: false,
                footerClass: "mt-0 border-t border-gray-200",
                bodyClass: "mt-6",
                distinctClass: "replenish-team-credits-modal",
                isSemiTransparent: true
            }
        })

        // BUTTON PROPERTIES
        const notEnoughCredit = computed(() => {
            return balanceAfterReplenishment.value < 0 || totalCreditsConsumed.value === 0 || isLoading.value
        })
        const buttonProps = computed(() => {
            return {
                type: "primary-btn",
                label: "Confirm",
                disabled: notEnoughCredit.value,
                loading: isLoading.value,
                class: "replenish-credit-confirm-button"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        //------------------------METHODS-----------------------
        const showModal = ref(false);

        const showReplenishModal = computed(
            () => store.getters["AdminTeam/showReplenishModal"]
        );

        watch(
            () => showReplenishModal.value,
            (newVal) => {
                showModal.value = newVal
            }
        )

        const onHideModal = () => {
            showModal.value = false
            store.commit('AdminTeam/setShowReplenishModal', false)
        };

        const onClickConfirm = async() => {
            const payload= {
                items: _.map(teamMemberList.value, (data) => {
                    return {
                        id: data.id,
                        credits: data.credits
                    }
                })
            }
            _.remove(payload.items, (data) => {
                return !data.credits || !(data.credits > 0)
            })
            await store.dispatch('CommonCredit/addTeamCredit', payload)
            onHideModal();
            await store.dispatch("CommonCredit/getUserCredits");
            // GET TEAM MEMBER AGAIN LIST TO UPDATE THE CREDITS OF EACH MEMBER AFTER ALLOCATING CREDITS
            const memberPayload = {
                data: {
                    limit: 5,
                },
            }
            await store.dispatch('AdminTeam/getTeamMembers', memberPayload);

            const feedback = {
                title: addTeamCreditMessage.value,
            }
            store.commit('CommonUtilities/setFeedbackObject', feedback);
        }

        //-------------------METHODS ENDs HERE------------------

        onUpdated(async() => {
            teamMemberList.value = props.teamMembers
        });

        return {
            showModal,
            onHideModal,
            userCredits,
            tableProps,
            slotModalProps,
            teamMemberList,
            totalCreditsConsumed,
            balanceAfterReplenishment,
            onClickConfirm,
            addTeamCreditMessage,
            isLoading,
            buttonProps,
            notEnoughCredit,
        };
    },
};
</script>

<style lang="scss">
.replenish-team-credits-modal-component {

}
.replenish-team-credits-modal {
    min-width: 720px;
    .replenish-team-credits-header-container {
        @apply flex justify-between items-center;
        .header-title {
            @apply text-lg font-medium text-gray-900;
        }
        .close-icon {
            @apply  text-white bg-gray-300 h-6 w-6 rounded-md cursor-pointer;
        }
    }
    .replenish-team-credits-body-container {
        @apply space-y-6;
        .credit-balance-container {
            @apply flex justify-between py-4 px-3 bg-gray-100 rounded;
            .label {
                @apply text-lg font-medium text-gray-900;
            }
            .credit-balance {
                @apply text-2xl font-medium text-gray-500;
            }
        }
        .medrep-list-and-total-credits-computation-container {
            .total-credits-computation-container {
                @apply space-y-2 border-t border-gray-200 py-4;
                .computation-container {
                    @apply flex justify-between;
                    .label {
                        @apply text-sm font-medium text-gray-900;
                    }
                    .label-value {
                        @apply text-sm font-medium text-gray-900;
                    }
                }
            }
            .medrep-list-container {
                .table-card-component {
                    .table-card-container {
                        .table-container {
                            .table-tbody-container, .table-thead-container {
                                tr {
                                    display: table;
                                    width: 100%;
                                    table-layout: fixed;
                                }
                            }
                            .table-tbody-container {
                                display: block;
                                max-height: 219px;
                                overflow-y: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-action {
        @apply flex justify-end items-center mt-6;
        .reminder {
            @apply text-sm leading-5 font-normal text-red-500 mr-5;
        }
        .replenish-credit-confirm-button {
            min-width: 87px
        }
    }
}
</style>