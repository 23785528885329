import { ref } from "vue";
import { useStore } from "vuex"
export default function useSendNotification({ value }, room_uuid) {
    let notificationResend = ref(false)
    let interval = ref(0)

    const store = useStore()

    const onSendNofication = async () => {
        if (notificationResend.value) {
            return
        }
        interval.value = 30
        notificationResend.value = true

        const params = {
            user_type: value.user_type.toLowerCase(),
            room_uuid
        }

        const countdown = setInterval(() => {
            --interval.value
            if (!interval.value) {
                clearInterval(countdown)
                notificationResend.value = false
            }
        }, 1000);

        await store.dispatch("VideoCall/sendCallNotification", params)
    }

    return {
        notificationResend,
        interval,
        onSendNofication
    };
}