<template>
    <div class="top-navbar-component">
        <Popover class="top-navbar-container">
            <div class="top-navbar-content">
                <div class="page-name">
                    {{ page }}
                </div>
                <PopoverGroup as="nav" class="popover-group">
                    <div class="credits-info-container">
                        <DatabaseIcon
                            :class="['credits-icon']"
                            aria-hidden="true"
                        />
                        <p class="credit-value">
                            {{ credits.credits }} {{ credits.credits > 1 ? 'Credits' : 'Credit' }}
                        </p>
                    </div>

                    <SearchIcon
                        v-if="moduleName === 'medrep'"
                        :class="['search-button']"
                        aria-hidden="true"
                        @click="onSearch"
                    />
                    <Popover v-slot="{ open }" class="popover-container">
                        <PopoverButton
                            :class="[
                                open ? 'text-gray-900' : 'text-gray-500',
                                'popover-button',
                            ]"
                        >
                            <div class="profile-photo-container">
                                <img
                                    v-if="avatar"
                                    class="avatar"
                                    :src="avatar"
                                    alt=""
                                />
                                <UserCircleIcon
                                    v-else
                                    :class="[
                                        open
                                            ? 'text-gray-600'
                                            : 'text-gray-500',
                                        'empty-avatar',
                                    ]"
                                    aria-hidden="true"
                                />
                            </div>
                        </PopoverButton>

                        <transition
                            v-bind="transitionProps"
                        >
                            <PopoverPanel class="popover-panel-container">
                                <div class="popover-panel-content">
                                    <div class="popover-card-container">
                                        <div class="header-container">
                                            <p class="header-title">
                                                Signed in as
                                            </p>
                                            <p class="user-email">
                                                {{ userInfo.email }}
                                            </p>
                                        </div>
                                        <div
                                            v-for="item in UserAction"
                                            :key="item.name"
                                            class="action-button"
                                            @click="clickItem(item)"
                                        >
                                            <div>
                                                <p class="action-lable">
                                                    {{ item.name }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PopoverPanel>
                        </transition>
                    </Popover>
                </PopoverGroup>
            </div>
        </Popover>
        <onboarding-modal-component v-model="showModal" />
        <!-- TERMS AND CONDITION MODAL -->
        <legal-modal-component
            v-model="showLegalModal"
            :type="type"
            :title="title"
        />
    </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { UserAction, Tokens } from "@Utilities/enum";
import { LegalModalComponent } from "@CommonComponents";
import {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from "@headlessui/vue";
import { UserCircleIcon, SearchIcon } from "@heroicons/vue/outline";
import { DatabaseIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { STORAGE } from "@Plugins/storage.js";
import OnboardingModalComponent from "../common/modal/onboarding-modal-component.vue";

export default {
    components: {
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverPanel,
        UserCircleIcon,
        SearchIcon,
        DatabaseIcon,
        OnboardingModalComponent,
        LegalModalComponent,
    },
    props: {
        page: { type: String, default: "" },
        moduleName: { type: String, default: null },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        const userInfo = ref(null);
        const avatar = ref(null);
        const userAction = ref(UserAction);
        const showModal = ref(false);

        if (STORAGE.GET_COOKIES(Tokens.Personal)) {
            userInfo.value = STORAGE.GET_COOKIES(Tokens.Personal).user_info;
            avatar.value = STORAGE.GET_COOKIES(Tokens.Personal).avatar;
        }

        //-----------------------COMPUTED-----------------------

        // GETTERS

        // CREDIT BALANCE
        const credits = computed(
            () => store.getters["CommonCredit/userCredits"]
        );

        // COMPONENT PROPERTIES
        const transitionProps = computed(() => {
            return {
                enterActiveClass: "transition ease-out duration-200",
                enterFromClass: "opacity-0 translate-y-1",
                enterToClass: "opacity-100 translate-y-0",
                leaveActiveClass: "transition ease-in duration-150",
                leaveFromClass: "opacity-100 translate-y-0",
                leaveToClass: "opacity-0 translate-y-1"
            }
        })

        //------------------COMPUTED ENDS HERE------------------

        // START OF SHOW TERMS AND CONDITION MODAL
        const type = ref('');
        const title = ref('');
        const showLegalModal = ref(false);
        const onShowLegalModal = (val) => {
            type.value = val;
            switch (val) {
            case 'terms-and-conditions':
                title.value = 'Terms and conditions'
                break;
            case 'privacy-policy':
                title.value = 'Privacy Policy'
                break;
            }
            showLegalModal.value = true;
        };

        //------------------------METHODS-----------------------

        const onSearch = () => {
            router.push({ path: "/medrep/doctors/search" });
        };

        const clickItem = (item) => {
            switch (item.type) {
            case "signout":
                store.dispatch("onLogout");
                break;
            case "guide":
                onShowOnBoarding();
                break;
            case "profile":
                router.push({ path: `/${props.moduleName}/profile` });
                break;
            case "privacy-policy":
                onShowLegalModal(item.type);
                break;
            case "terms-and-conditions":
                onShowLegalModal(item.type);
                break;
            default:
                break;
            }
        };

        const onShowOnBoarding = () => (showModal.value = true);

        //-------------------METHODS ENDs HERE------------------

        onMounted(async () => {
            await store.dispatch("CommonCredit/getUserCredits");
            store.commit(
                "CommonPharmaUser/setAvatar",
                ref(STORAGE.GET_COOKIES(Tokens.Personal).avatar)
            );
            if (props.moduleName !== "medrep") {
                await _.remove(userAction.value, { type: 'guide' })
            }
        });

        return {
            UserAction,
            userInfo,
            clickItem,
            avatar,
            onSearch,
            showModal,
            credits,
            transitionProps,
            userAction,
            showLegalModal,
            type,
            title,
        };
    },
};
</script>

<style lang="scss">
.top-navbar-component {
    .top-navbar-container {
        @apply relative bg-white shadow-sm;
        height: 78px;
        .top-navbar-content {
            @apply flex pl-5 pr-7 h-full justify-between items-center border-b-2 border-gray-100 md:justify-start md:space-x-10;
            .page-name {
                @apply flex justify-start lg:w-0 lg:flex-1 text-2xl font-bold;
            }
            .popover-group {
                @apply flex justify-end items-center space-x-5;
                .credits-info-container {
                    @apply flex space-x-2;
                    .credits-icon {
                        @apply h-5 w-5 group-hover:text-gray-500 text-gray-400;
                    }
                    .credit-value {
                        @apply text-sm font-medium text-gray-700;
                    }
                }
                .search-button {
                    @apply h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-600;
                }
                .popover-container {
                    @apply flex relative;
                    .popover-button {
                        @apply group bg-white rounded-full inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
                        .profile-photo-container {
                            .avatar {
                                @apply h-7 w-7;
                                object-fit: cover;
                                border-radius: 50%;
                            }
                            .empty-avatar {
                                @apply group-hover:text-gray-600 h-7 w-7;
                            }
                        }
                    }
                    .popover-panel-container {
                        @apply absolute top-8 right-0 z-10 transform min-w-max;
                        .popover-panel-content {
                            @apply rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden;
                            .popover-card-container {
                                @apply relative bg-white divide-y divide-gray-200;
                                .header-container {
                                    @apply px-4 py-3;
                                    .header-title {
                                        @apply text-sm font-normal text-gray-700;
                                    }
                                    .user-email {
                                        @apply text-sm font-semibold text-gray-700;
                                    }
                                }
                                .action-button {
                                    @apply px-4 py-3 flex items-start hover:bg-gray-50 cursor-pointer;
                                    .action-lable {
                                        @apply text-sm font-normal text-gray-700;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>