<template>
    <div class="personal-details-component">
        <div class="personal-details-container">
            <div class="profile-card">
                <div class="profile-card-header-container">
                    <p class="header-title"> {{ cardData.header }} </p>
                </div>
                <div class="profile-card-body-container">
                    <div v-for="(data, index) in cardData.config" :key="index" class="profile-card-body-content">
                        <p class="lable"> {{ data.label }} </p>
                        <p v-if="data.type==='label'" class="label-value"> {{ getData(data.key) }} </p>
                        <div v-if="data.type==='img'" class="profile-photo-container">
                            <img
                                v-if="getData(data.key)"
                                class="rounded-full h-12 w-12"
                                :src="getData(data.key)"
                                alt=""
                            />
                            <UserIcon
                                v-else
                                class="text-gray-300 bg-gray-100 rounded-full h-12 w-12"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { UserIcon } from "@heroicons/vue/solid";
import { STORAGE } from "@Plugins/storage.js";
import { Tokens } from "@Utilities/enum";
import { ref, onMounted } from "vue";

export default {
    name: "PersonalDetailsComponent",
    components: {
        UserIcon
    },
    props: {
        items: { type: Object, default: null },
    },
    setup() {
        const userInfo = ref(STORAGE.GET_COOKIES(Tokens.Personal).user_info);
        const avatar = ref(STORAGE.GET_COOKIES(Tokens.Personal).avatar);
        const userData = ref({ ...userInfo.value, avatar: avatar.value })
        const cardData = ref({
            header: 'Personal details',
            data: userData.value,
            config: [
                {
                    label: "Full Name",
                    type: "label",
                    key: "full_name"
                },
                {
                    label: "Email address",
                    type: "label",
                    key: "email"
                },
                {
                    label: "Profile Photo",
                    type: "img",
                    key: "avatar"
                },
                {
                    label: "Company",
                    type: "label",
                    key: "company"
                },
                {
                    label: "District Manager",
                    type: "label",
                    key: "district_manager_name"
                }
            ]
        })

        //------------------------METHODS-----------------------

        const getData = (key) => {
            const data = cardData.value.data[key]
            if (!data || _.isEmpty(data) ) return
            return data ? data : ''
        }

        //-------------------METHODS ENDs HERE------------------

        onMounted(async() => {
        });

        return {
            userInfo,
            avatar,
            cardData,
            userData,
            getData,
        }
    }
}
</script>

<style lang="scss">
.personal-details-component {
    .personal-details-container {
        .profile-card {
            @apply shadow rounded-md w-auto bg-white divide-y divide-gray-200;
            .profile-card-header-container {
                @apply py-4 px-6;
                .header-title {
                    @apply  text-lg font-medium text-gray-900;
                }
            }
            .profile-card-body-container {
                @apply divide-y divide-gray-200;
                .profile-card-body-content {
                    @apply flex py-4 px-6;
                    .lable {
                        @apply text-sm font-medium text-gray-500 w-2/5;
                    }
                    .label-value {
                        @apply text-sm font-normal text-gray-900 w-3/5;
                    }
                    .profile-photo-container {
                        @apply overflow-hidden w-3/5;
                        img {
                            object-fit: cover;
                            border-radius:50%;
                        }
                    }
                }
            }
        }
    }
}
</style>