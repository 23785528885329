export default {
    namespaced: true,
    state: {
        pageName: '',
        showFeedback: false,
        feedbackObject: {},
    },
    getters: {
        pageName(state) {
            return state.pageName
        },
        feedbackObject(state) {
            return state.feedbackObject
        },
        showFeedback(state) {
            return state.showFeedback
        },
    },
    actions: {
    },
    mutations: {
        setPageName(state, payload) {
            state.pageName = payload
        },
        setFeedbackObject(state, payload) {
            if (!_.has(payload, 'duration')) {
                payload.duration = 5000
            }
            if (!_.has(payload, 'delay')) {
                payload.delay = 500
            }
            state.feedbackObject = payload
            setTimeout(() => state.showFeedback = true, payload.delay );
        },
        setShowFeedback(state, payload) {
            state.showFeedback = payload
        },
    },
}
