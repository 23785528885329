<template>
    <div class="admin-dashboard-page-component">
        <div class="admin-dashboard-page-container component-container-grid">
            <div class="team-appointment-container">
                <div class="team-appointment-content">
                    <div ref="appointmentHeaderHeight" class="team-appointment-header-container" @click="onClickOutside()">
                        <div class="team-appointment-header">
                            <div class="header-container">
                                <p class="header-title">Team Appointments</p>
                            </div>
                        </div>
                        <div>
                            <navigation-tabs-component
                                v-model="appointmentTablists"
                                v-bind="navigationTabProperties"
                                @update:modelValue="onChangeTab($event)"
                            />
                        </div>
                    </div>
                    <div>
                        <empty-state-component
                            v-if="_.isEmpty(appointmentList) && !isLoadingAppointment"
                            v-bind="appointmentEmptyStateProps"
                            class="mt-16"
                        />
                        <appointment-list-component
                            v-else
                            v-bind="appointmentListProperties"
                            :height="appointmentTotalHeight"
                            @on-click-view-schedule="onClickViewSchedule"
                            @get-action-btn-height="appointmentFooterHeight=$event"
                            @on-click-appointment-card="
                                onClickAppointmentCard($event)
                            "
                        />
                    </div>
                </div>
            </div>
            <div ref="rightContainerHeight" class="other-appointment-and-team-member-container col-span-2 space-y-8">
                <div ref="otherAppointmentAndFaqs" class="other-appointment-and-faqs grid grid-cols-2 gap-6" style="min-height: 527px">
                    <div class="other-team-appointment-content">
                        <div ref="otherAppointmentHeaderHeight" class="other-team-appointment-header-container" @click="onClickOutside()">
                            <div class="other-team-appointment-header">
                                <div class="header-container">
                                    <p class="header-title">Other Appointments</p>
                                </div>
                            </div>
                            <div>
                                <navigation-tabs-component
                                    v-model="otherAppointmentTablists"
                                    v-bind="navigationTabProperties"
                                    @update:modelValue="onChangeOtherAppointmentTab($event)"
                                />
                            </div>
                        </div>
                        <div>
                            <empty-state-component
                                v-if="_.isEmpty(otherAppointmentList) && !isLoadingOtherAppointment"
                                v-bind="otherAppointmentEmptyStateProps"
                                class="mt-16"
                            />
                            <appointment-list-component
                                v-else
                                v-bind="otherAppointmentListProperties"
                                :height="otherAppointmentTotalHeight"
                                @on-click-view-schedule="onClickOtherAppointmentViewSchedule"
                                @get-action-btn-height="otherAppointmentFooterHeight=$event"
                                @on-click-appointment-card="
                                    onClickAppointmentCard($event)
                                "
                            />
                        </div>
                        <!-- <spinner-component v-else class="center mt-10" :size="40" /> -->
                    </div>
                    <div class="faqs-and-guide-container space-y-6" @click="onClickOutside()">
                        <div class="credit-balance-details-container">
                            <credit-balance-card-component @on-click-check-history="onClickCheckHistory" />
                        </div>
                        <div class="faqs-container">
                            <faqs-card-component />
                        </div>
                    </div>
                </div>
                <div class="team-member-container col-span-2" @click="onClickOutside()">
                    <team-member-list-component :member-limit="5" @table-ref="tableRef=$event" @get-member-id="onSelectMember($event)" />
                </div>
            </div>
        </div>
        <appointment-slideover-component
            v-model="showAppointmentSlideover"
        />
        <profile-slideover-component
            v-model="showProfileSlideover"
            :doctor-id="memberId"
            :type="type"
        />
    </div>
</template>

<script>
import { onMounted, computed, shallowRef, ref, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import NavigationTabsComponent from "@CommonComponents/navigation-tabs-component.vue";
import AppointmentListComponent from "@CommonComponents/appointment-list-component.vue"
import EmptyStateComponent from '../empty-state-component.vue'
import TeamMemberListComponent from "./components/team-member-list-component.vue";
import FaqsCardComponent from "./components/faqs-card-component.vue";
import { AppointmentSlideoverComponent, ProfileSlideoverComponent } from "@CommonComponents/slideovers";
import {
    CalendarIcon as OutlineCalendarIcon,
} from "@heroicons/vue/outline";
import { useRouter } from "vue-router";
import CreditBalanceCardComponent from "@CommonComponents/cards/credit-balance-card-component";
import { STORAGE } from "@Plugins/storage.js";
import { AccountType, Tokens } from "@Utilities/enum"

export default {
    name: "AdminDashboardPageComponent",
    components: {
        NavigationTabsComponent,
        AppointmentListComponent,
        EmptyStateComponent,
        TeamMemberListComponent,
        FaqsCardComponent,
        AppointmentSlideoverComponent,
        ProfileSlideoverComponent,
        CreditBalanceCardComponent
    },
    props: {
        pageName: { type: String, default: '' }
    },
    setup(props) {
        const store = useStore()
        const router = useRouter();
        const otherAppointmentButtonLabel = ref("")
        const memberId = ref(null);
        const type = ref('member')

        const appointmentHeaderHeight = ref(0)
        const tableRef = ref(0)
        const appointmentFooterHeight = ref(0);
        const rightContainerHeight = ref(0);
        const otherAppointmentHeaderHeight = ref(0)
        const otherAppointmentFooterHeight = ref(0);
        const otherAppointmentAndFaqs = ref(0);
        const outlineCalendarIcon = ref(OutlineCalendarIcon)

        //-----------------------COMPUTED-----------------------

        // GETTERS

        // LIST
        const appointmentList = computed(
            () => store.getters["CommonAppointment/appointmentList"]
        );
        const otherAppointmentList = computed(
            () => store.getters["CommonAppointment/otherAppointmentList"]
        );

        // SLIDER
        const showAppointmentSlideover = computed(() => store.getters['CommonAppointment/appointmentSlideover']
        )
        const showProfileSlideover = computed(() => store.getters["CommonDoctor/profileSlideover"]);

        // LOAD STATE
        const isLoadingAppointment = computed(() => {
            return store.getters['CommonAppointment/appointmentListLoading']
        })
        const isLoadingOtherAppointment = computed(() => {
            return store.getters['CommonAppointment/otherAppointmentListLoading']
        })

        // LIST META
        const appointmentListMeta = computed(() => store.getters['CommonAppointment/appointmentListMeta'])
        const otherAppointmentListMeta = computed(() => store.getters['CommonAppointment/otherAppointmentListMeta'])

        const appointmentTotalHeight = computed(() => {
            const paddingBottom = 35;
            return (
                (otherAppointmentAndFaqs.value.offsetHeight + 112)+
                (tableRef.value.offsetHeight - 22) -
                (paddingBottom +
                appointmentHeaderHeight.value.offsetHeight +
                appointmentFooterHeight.value.offsetHeight))
        })

        const otherAppointmentTotalHeight = computed(() => {
            const paddingBottom = 35;
            return (
                (otherAppointmentAndFaqs.value.offsetHeight) -
                (paddingBottom +
                otherAppointmentHeaderHeight.value.offsetHeight +
                otherAppointmentFooterHeight.value.offsetHeight))
        })

        // EMPTY STATE PROPERTIES
        const appointmentEmptyStateProps = computed(() => {
            return {
                icon: outlineCalendarIcon.value,
                title: appointmentEmptyStateTitle.value,
            }
        });
        const appointmentEmptyStateTitle = computed(() => {
            return (_.find(appointmentTablists.value, { current: true })).name === "Upcoming" ? "No upcoming appointments" : "No pending appointments"
        });
        const otherAppointmentEmptyStateProps = computed(() => {
            return {
                icon: outlineCalendarIcon.value,
                title: otherAppointmentEmptyStateTitle.value,
            }
        });
        const otherAppointmentEmptyStateTitle = computed(() => {
            switch ((_.find(otherAppointmentTablists.value, { current: true })).name) {
            case "Completed":
                return "No done appointments"
            case "Declined":
                return "No declined appointments"
            default:
                return "No cancelled appointments"
            }
        });

        // TAB PROPERTIES
        const navigationTabProperties = computed(() => {
            return {
                ref: "navTabs",
                contentType: "component",
                tabType: "tab-in-pills",
                alignment: "left",
                componentClass: "my-4"
            }
        });

        // APPOINTMENT PROPERTIES
        const appointmentListProperties = computed(() => {
            return {
                items: appointmentList.value,
                appointmentCardProperties: appointmentCardProperties.value,
                scrollable: true,
                showViewScheduleButton: appointmentList.value.length >= 5 && showViewScheduleButton.value,
                loadMoreFooterClass: "bg-transparent border-0 pt-3"
            }
        });

        const appointmentCardProperties = computed(() => {
            return {
                showNote: false,
                showCreditToUse: false,
                showInvited: false,
                isLoading: isLoadingAppointment.value,
                numberOfLoadingCards: 4
            }
        });

        const otherAppointmentListProperties = computed(() => {
            return {
                items: otherAppointmentList.value,
                appointmentCardProperties: otherAppointmentCardProperties.value,
                scrollable: true,
                buttonLabel: otherAppointmentButtonLabel.value,
                showViewScheduleButton: otherAppointmentList.value.length >= 5 && showOtherAppoimentViewScheduleButton.value,
                loadMoreFooterClass: "bg-transparent border-0 pt-3",
                isLoading: isLoadingOtherAppointment.value
            }
        });

        const userType = ref(STORAGE.GET_COOKIES(Tokens.UserType));
        const otherAppointmentCardProperties = computed(() => {
            const selectedTab = _.find(otherAppointmentTablists.value, { current: true });
            if (selectedTab.value === "served") {
                return {
                    showNote: false,
                    showCreditToUse: false,
                    showInvited: false,
                    isLoading: isLoadingOtherAppointment.value,
                    numberOfLoadingCards: 4
                }
            } else {
                return {
                    showBrand: false,
                    showInvited: false,
                    isLoading: isLoadingOtherAppointment.value,
                    numberOfLoadingCards: 4,
                    showStatus: userType.value === AccountType.Admin,
                }
            }
        })

        const showViewScheduleButton = computed(() => {
            return !_.isEmpty(appointmentListMeta.value) && appointmentListMeta.value.current_page !== appointmentListMeta.value.last_page
        })

        const showOtherAppoimentViewScheduleButton = computed(() => {
            return !_.isEmpty(otherAppointmentListMeta.value) && otherAppointmentListMeta.value.current_page !== otherAppointmentListMeta.value.last_page
        })

        //------------------COMPUTED ENDS HERE------------------

        //-----------------------METHODS------------------------

        // FOR SLIDER

        const onSelectMember = (id) => {
            memberId.value = id;
            type.value = 'member';
        }

        const onClickAppointmentCard = (event) => {
            if (event.status === 'declined' || event.status === 'cancelled') {
                return
            }
            store.commit('CommonAppointment/setAppointmentSlideover', true)
            store.dispatch('CommonAppointment/getAppointmentDetails', event.uuid)
        }

        const hideSlideover = () => {
            store.commit("CommonDoctor/setProfileSlideover", false);
            store.commit('CommonAppointment/setAppointmentSlideover', false);
        }

        const onClickOutside = () => {
            store.commit('CommonAppointment/setAppointmentSlideover', false)
        }

        // TAB METHODS
        const onChangeTab = async(event) => {
            if (event) {
                appointmentTablists.value = event;
            }
            const selectedTab = await _.find(appointmentTablists.value, { current: true });
            await store.dispatch('CommonAppointment/getAppointmentList', { status: selectedTab.name === 'Upcoming' ? 'accepted' : 'pending', limit: 5 })
        };

        const onChangeOtherAppointmentTab = async(event) => {
            if (event) {
                otherAppointmentTablists.value = event;
            }
            const selectedTab = await _.find(otherAppointmentTablists.value, { current: true });
            await store.dispatch('CommonAppointment/getAppointmentList', { status: selectedTab.value, limit: selectedTab.value==="served" ? 10 : 5 })

            switch (selectedTab.value) {
            case "served":
                otherAppointmentButtonLabel.value = "View done appointments"
                break;
            case "declined":
                otherAppointmentButtonLabel.value = "View declined appointments"
                break;
            case "cancelled":
                otherAppointmentButtonLabel.value = "View cancelled appointments"
                break;
            }
        };

        const onClickViewSchedule = async() => {
            const selectedTab = await _.find(appointmentTablists.value, { current: true });
            switch (selectedTab.name) {
            case "Upcoming":
                router.push({ path: 'team/upcoming' });
                break;
            case "Pending":
                router.push({ path: 'team/pending' });
                break;
            }
        }

        const onClickOtherAppointmentViewSchedule = async() => {
            const selectedTab = await _.find(otherAppointmentTablists.value, { current: true });
            switch (selectedTab.name) {
            case "Completed":
                router.push({ path: 'team/done' });
                break;
            case "Declined":
                router.push({ path: 'team/declined' });
                break;
            case "Cancelled":
                router.push({ path: 'team/cancelled' });
                break;
            }
        }

        const onClickCheckHistory = () => {
            router.push({ path: '/admin/credits' })
        }

        //------------------METHODS ENDs HERE-------------------

        // watch(
        //     () => appointmentListMeta.value,
        //     () => {
        //         if (_.isEmpty(appointmentListMeta.value)) return
        //         if (appointmentListMeta.value.current_page === 1) {
        //             appointmentList.value=[]
        //             appointmentList.value.push(...getAppointmentList.value)
        //         }
        //     }
        // );

        // watch(
        //     () => otherAppointmentListMeta.value,
        //     () => {
        //         if (_.isEmpty(otherAppointmentListMeta.value)) return
        //         if (otherAppointmentListMeta.value.current_page === 1) {
        //             otherAppointmentList.value=[]
        //             otherAppointmentList.value.push(...getOtherAppointmentList.value)
        //         }
        //     }
        // );

        const appointmentTablists = shallowRef([
            { name: "Upcoming", current: true },
            { name: "Pending", current: false },
        ]);

        const otherAppointmentTablists = shallowRef([
            { name: "Completed", current: true, value: "served" },
            { name: "Declined", current: false, value: "declined" },
            { name: "Cancelled", current: false, value: "cancelled" },
        ]);

        // END OF HIDE SLIDEOVER
        onBeforeUnmount(() => {
            hideSlideover();
            store.commit('CommonAppointment/resetAppointmentList', [])
        })

        onMounted(async() => {
            store.commit("CommonUtilities/setPageName", props.pageName);
            onChangeTab()
            onChangeOtherAppointmentTab()
        });

        return {
            navigationTabProperties,
            appointmentTablists,
            otherAppointmentTablists,
            onChangeTab,
            appointmentList,
            isLoadingAppointment,
            appointmentEmptyStateProps,
            outlineCalendarIcon,
            appointmentEmptyStateTitle,
            appointmentListProperties,
            appointmentCardProperties,
            otherAppointmentEmptyStateTitle,
            otherAppointmentListProperties,
            otherAppointmentList,
            otherAppointmentEmptyStateProps,
            onChangeOtherAppointmentTab,
            isLoadingOtherAppointment,
            appointmentHeaderHeight,
            appointmentFooterHeight,
            appointmentTotalHeight,
            rightContainerHeight,
            otherAppointmentHeaderHeight,
            otherAppointmentFooterHeight,
            otherAppointmentTotalHeight,
            otherAppointmentAndFaqs,
            tableRef,
            appointmentListMeta,
            onClickViewSchedule,
            showViewScheduleButton,
            otherAppointmentButtonLabel,
            otherAppointmentListMeta,
            showOtherAppoimentViewScheduleButton,
            onClickOtherAppointmentViewSchedule,
            onClickAppointmentCard,
            showAppointmentSlideover,
            onSelectMember,
            memberId,
            showProfileSlideover,
            type,
            onClickOutside,
            otherAppointmentCardProperties,
            onClickCheckHistory
        }
    }
}
</script>

<style lang="scss">
.admin-dashboard-page-component {
    .admin-dashboard-page-container {
        .header-container {
            @apply space-y-1;
            .header-title {
                @apply text-lg font-medium text-gray-900;
            }
        }
    }
}
</style>